function handlePostSave(activeSettingId, setShowLoader, setUnSavedChanges, setNewAnimObj, sectionChanges, sectionId, handleSectionSave, componentDimensions, handleComponentDimensionUpdate, multiSelectIds, handleEditAnimation, newAnimObj, handleAddAnimation, newStateAnimObj, setNewStateAnimObj, unSavedLayerIds, menuObj, setSectionId, setActiveNarrativeHeading, handleCloseSettings, setForceVersion) {

    // console.log('handlePostSave 1.0', activeSettingId, sectionChanges)
    setShowLoader(true);
    setUnSavedChanges(false);
    setNewAnimObj({});

    var newValueInputs = document.getElementsByClassName('newValueInput');
    var i;
    for (i = 0; i < newValueInputs.length; i++) {
      newValueInputs[i].value = "";
    }
    
    for(var j = 0; j < sectionChanges.length; j++) {
      if (sectionChanges[j].id === sectionId) {
        handleSectionSave(j);
      } 
    }   

    var dimensions = componentDimensions;

    let unSavedIds;
    if (unSavedLayerIds && unSavedLayerIds.length > 0) {
      unSavedIds = unSavedLayerIds
    } else {
      unSavedIds = []
    }
    console.log('unSavedIds', unSavedLayerIds, dimensions)
    
    unSavedIds.forEach(((id, index) => {

      var saveIndex = dimensions.findIndex((dimension) => dimension.id === id);
      // console.log('dimensions[saveIndex].height', dimensions[saveIndex].height)
      if (dimensions[saveIndex]) {
  
        var dataJSON = {
          'typeName': dimensions[saveIndex].typeName,
          'width': dimensions[saveIndex].width,
          'height': dimensions[saveIndex].height,
          'x_pos': dimensions[saveIndex].x_pos,
          'y_pos': dimensions[saveIndex].y_pos,
          'background': dimensions[saveIndex].background,
          'component_ordering': dimensions[saveIndex].component_ordering,
          'component_link': dimensions[saveIndex].component_link,
          'component_link_heading': dimensions[saveIndex].component_link_heading,
          'component_state_heading': dimensions[saveIndex].component_state_heading,
          'is_popup': dimensions[saveIndex].is_popup,
          'is_locked': dimensions[saveIndex].is_locked,
          "html_text": dimensions[saveIndex].html_text,
          'layer_name': dimensions[saveIndex].layer_name,
          'active': dimensions[saveIndex].active,
          'change_lock': dimensions[saveIndex].change_lock
        }

        handleComponentDimensionUpdate(dataJSON, dimensions[saveIndex].id, (index === unSavedIds.length - 1));
  
        multiSelectIds.filter(a => a.id !== dimensions[saveIndex].id).forEach((obj, i) => {
          let saveIndex = componentDimensions.findIndex((dimension) => dimension.id === obj.id);
          if (saveIndex > -1) {  
            let dataJSON = {
              'typeName': componentDimensions[saveIndex].typeName,
              'width': componentDimensions[saveIndex].width,
              'height': componentDimensions[saveIndex].height,
              'x_pos': componentDimensions[saveIndex].x_pos,
              'y_pos': componentDimensions[saveIndex].y_pos,
              'background': componentDimensions[saveIndex].background,
              'component_ordering': componentDimensions[saveIndex].component_ordering,
              'component_link': componentDimensions[saveIndex].component_link,
              'component_link_heading': componentDimensions[saveIndex].component_link_heading,
              'component_state_heading': componentDimensions[saveIndex].component_state_heading,
              'is_popup': componentDimensions[saveIndex].is_popup,
              'is_locked': componentDimensions[saveIndex].is_locked,
              "html_text": componentDimensions[saveIndex].html_text,
              'layer_name': componentDimensions[saveIndex].layer_name,
              'active': componentDimensions[saveIndex].active,
              'change_lock': dimensions[saveIndex].change_lock
            }
            console.log('multi', dataJSON, saveIndex)
            handleComponentDimensionUpdate(dataJSON, dimensions[saveIndex].id, false);
          }
          
        });

        
  
        // Animation Edit Loop
        if (dimensions[saveIndex].is_locked === true) {
          if (dimensions[saveIndex].display_name === 'HD') {
            for(var j = 0; j < dimensions[saveIndex]['animations'].length; j++) {
              var animDataJSON = {
                'componentdimension': dimensions[saveIndex].id,
                'id': dimensions[saveIndex]['animations'][j].id,
                'property': dimensions[saveIndex]['animations'][j].property,
                'start': dimensions[saveIndex]['animations'][j].start,
                'end': dimensions[saveIndex]['animations'][j].end,
                'startParallax': dimensions[saveIndex]['animations'][j].startParallax,
                'endParallax': dimensions[saveIndex]['animations'][j].endParallax,
                'duration': dimensions[saveIndex]['animations'][j].duration,
                'delay': dimensions[saveIndex]['animations'][j].delay,
                'ease': dimensions[saveIndex]['animations'][j].ease,
                'is_state': false,
              }
              console.log('animDataJSON', animDataJSON);
              handleEditAnimation(animDataJSON);
            }
          }
        }  else {
  
          for(var j = 0; j < dimensions[saveIndex]['animations'].length; j++) {
            var animDataJSON = {
              'componentdimension': dimensions[saveIndex].id,
              'id': dimensions[saveIndex]['animations'][j].id,
              'property': dimensions[saveIndex]['animations'][j].property,
              'start': dimensions[saveIndex]['animations'][j].start,
              'end': dimensions[saveIndex]['animations'][j].end,
              'startParallax': dimensions[saveIndex]['animations'][j].startParallax,
              'endParallax': dimensions[saveIndex]['animations'][j].endParallax,
              'duration': dimensions[saveIndex]['animations'][j].duration,
              'delay': dimensions[saveIndex]['animations'][j].delay,
              'ease': dimensions[saveIndex]['animations'][j].ease,
              'is_state': false
            }
            console.log('animDataJSON', animDataJSON);
            handleEditAnimation(animDataJSON);
          }    
  
        }
          
      }

    }))


  if (Object.keys(newAnimObj).length > 0) {
    var animJSON = newAnimObj;
    animJSON['componentdimension'] = activeSettingId;
    handleAddAnimation(animJSON);
    setNewAnimObj({});
  }

  if (Object.keys(newStateAnimObj).length > 0) {
    var animJSON2 = newStateAnimObj;
    animJSON2['componentdimension'] = activeSettingId;
    handleAddAnimation(animJSON2);
    setNewStateAnimObj({});
  }

  if (menuObj){
    handleCloseSettings();
    if (menuObj.page) {
      setSectionId(menuObj.page);
      setActiveNarrativeHeading(menuObj);
    } else if (menuObj.page === null){
      setActiveNarrativeHeading(menuObj);
    } else {
      setSectionId(menuObj.id);
    }
    localStorage.removeItem("versionHistory");
    console.log('menuObj', menuObj)
  }

 
  setForceVersion(null);
  setShowLoader(false)
}

export {handlePostSave};