import React, { useState, useEffect, useRef } from "react";
import {
    NavLink,
  useNavigate,
  useParams
} from "react-router-dom";
import axios from "axios";
import Cookies from 'js-cookie';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import gsap from "gsap";
import { SketchPicker } from 'react-color';
import { Rnd } from "react-rnd";
import MenuList from './module.menulist';
import Config from "../Config.json"
import Loader from "../static/svg/loader.svg";
import imagePlaceholder from '../static/img/image.png'

// Icons
import OpenLink from "../static/svg/openLink-w.svg";
import {ReactComponent as Edit} from "../static/icons/edit.svg" 
// import {ReactComponent as Add} from "../static/icons/library_add.svg" 
// import {ReactComponent as WebGL} from "../static/modules/3d.svg" 
// import {ReactComponent as Doc} from "../static/modules/doc_library.svg" 
// import {ReactComponent as GalleryImage} from "../static/modules/gallery_image.svg" 
// import {ReactComponent as GalleryVr} from "../static/modules/gallery_vr.svg" 
// import {ReactComponent as GalleryVideo} from "../static/modules/gallery_video.svg" 
// import {ReactComponent as Map} from "../static/modules/map.svg" 
// import {ReactComponent as Stack} from "../static/modules/stack_typical.svg" 
import {ReactComponent as ArrowBack} from "../static/icons/arrow_back.svg";
import {ReactComponent as Close} from "../static/svg/close.svg"
import {ReactComponent as NoteAdd} from "../static/icons/note_add.svg";
import {ReactComponent as PhotoAdd} from "../static/icons/add_photo.svg";
import {ReactComponent as Image} from "../static/icons/image.svg"
import {ReactComponent as Video} from "../static/icons/video.svg"
import {ReactComponent as Svg} from "../static/icons/svg.svg"
import {ReactComponent as ImageOff} from "../static/icons/image_off.svg"
import {ReactComponent as Delete} from "../static/icons/delete.svg"
import {ReactComponent as AddBox} from "../static/icons/add_box.svg"
import { el } from "date-fns/locale";

mapboxgl.accessToken = 'pk.eyJ1IjoiYnBzdjMiLCJhIjoiY2tpNG5oZXpoMjA2ODJxcGRmYnJlajV2ayJ9.ibWqAVMhvzPSqEZfLJE63w';

function Module(props) {

  const navigate = useNavigate();
  let { id } = useParams();
  const originY = React.useRef();
  const originYpx = React.useRef();
  const isDragging = React.useRef();
  const rnds = React.useRef([]);
  const tempAfterObjs = React.useRef(null);
  const layerFormat = React.useRef();
  const colorPicker = useRef();
  const mapBox = useRef();
  const [module, setModule] = useState(null);
  const [moduleObj, setModuleObj] = useState(null);
  const [modules, setModules] = useState([]);
  const [categories, setCategories] = useState([]);
  const [showError, setShowError] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [animOpen, setAnimOpen] = useState(false);
  const [categoryDrags, setCategoryDrags] = useState(true);
  const [acceptValues, setAcceptValues] = useState('init');
  const [dropDown, setDropDown] = useState();
  const [editField, setEditField] = useState(null);
  const [showAnimDropDown, setShowAnimDropDown] = useState(false);
  const [map, setMap] = useState();
  const [mapboxes, setMapboxes] = useState();
  const [activeMap, setActiveMap] = useState();
  const [format, setFormat] = useState('desktop');
  const [showNewMapForm, setShowNewMapForm] = useState(false);
  const [activeOverlays, setActiveOverlays] = useState([]);
  const [overlaysLoaded, setOverlaysLoaded] = useState(false);
  const [selectedOverlays, setSelectedOverlays] = useState();
  const [overlaySettingId, setOverlaySettingId] = useState();
  const [showMaps, setShowMaps] = useState(true);
  const [mapChanges, setMapChanges] = useState();
  const [overlayChanges, setOverlayChanges] = useState();

  function getContentPath(path) {
    return Config.mediaURL + path
  }

  useEffect(() => {
    props.setTools("canvas")
    fetchJSON(null);
    
    // login();
  }, []);

  useEffect(() => {
    module && setCategories(module.all_filecategories)
    if (module && module.mapboxes) {
      let nId = module.id.replace('mapbox-', '');
      let maps = module.mapboxes.filter(a => a.narrative === parseInt(nId) );
      console.log('maps', maps, nId)
      maps && setMap(maps[0])
      maps && setMapboxes(maps);
    }
    moduleObj && setModuleObj(module.files.find(obj => obj.id === moduleObj.id))
    
  }, [module])

  useEffect(() => {
    if (map) {
      // if (activeMap) {
      //   activeMap.remove();
      // }
      

      var mapObj = new mapboxgl.Map({
        container: mapBox.current,
        pitchWithRotate: false,
        style: map.style_url,
        center: [map.longitude, map.latitude],
        touchPitch: false,
        scrollZoom: true,
        doubleClickZoom: true,
        zoom: (format === 'iPad') ? (map.zoom_iPad) : (map.zoom),
        minZoom: (format === 'iPad') ? (map.zoom_min_iPad) : (map.zoom_min),
        maxZoom: (format === 'iPad') ? (map.zoom_max_iPad) : (map.zoom_max)
      });
      animModalIn(map.id);
      mapObj.dragRotate.disable();
      mapObj.touchZoomRotate.disableRotation();
      mapObj.addControl(new mapboxgl.NavigationControl());
      mapObj.once("render", function(){
        setActiveMap(mapObj);
        
        setTimeout(() => {
          mapObj.resize();
          console.log('resize on render');
        }, 250);


      });
    }
    return () => {
      mapObj && mapObj.remove();
    }
  }, [map])

  useEffect(() => {
    categories && console.log('categories', categories)
  }, [categories])

  useEffect(() => {
    if (dropDown) {
      animDropDown(true)
    } else {
      animDropDown(false)
    }
  }, [dropDown])

  useEffect(() => {
    if (activeOverlays && activeMap && map){
      console.log('activeOverlays recd', activeOverlays)
			if (activeOverlays.length > 0) {
				const activeFileMap = map;
				var center = [activeFileMap.longitude, activeFileMap.latitude];
				var mapBox = activeMap;
			
				var activeOverlayLength = activeOverlays.length;

				let activeOverlayIds = [];
				activeOverlays.forEach((file, i) => {

					
					activeOverlayIds.push(file.id);
						if (activeOverlayLength === (i + 1)) {
							setSelectedOverlays(activeOverlayIds);
					
						}
					
					if (file.latitude) {
						var markerContainer = document.createElement('div');
						if (file.interactive === false) {
							markerContainer.className = `marker markerOverlay markerOn nonInteractive`;
						} else {
							if (activeFileMap.default_on) {
                markerContainer.className = `marker markerOverlay markerOn`;
              } else {
                markerContainer.className = `marker markerOverlay`;
              }
						}

						markerContainer.id = "mapboxOverlay_" + file.id
						var imageURL = getContentPath(file.file);
						var ext = file.file.split('.').pop();
						
						if (ext === 'svg') {
							fetch(imageURL)
							.then(response => response.text())
							.then(result => {
								markerContainer.innerHTML = result;
								new mapboxgl.Marker({
									element: markerContainer,
									anchor: file.anchor
								})
									.setLngLat([file.longitude, file.latitude])
									.addTo(mapBox);
							})
						} else {
							markerContainer.style.backgroundImage = 'url(' + imageURL + ')';
							markerContainer.style.backgroundSize = '100%';
							markerContainer.style.height = file.height;
							markerContainer.style.width = file.width;
							
							new ClickableMarker({
								element: markerContainer,
								anchor: file.anchor
								})
							.setLngLat([file.longitude, file.latitude])
							.onClick(() => { 
								// handleMapFileClick(file);
								console.log('file', file);
							})
							.addTo(mapBox);
						}

					} else  {
						var markerOverlayContainer = document.createElement('div');

						if (file.interactive === false) {
							markerOverlayContainer.className = `markerOverlay markerOn nonInteractive`;
						} else {
							
							if (selectedOverlays.includes( file.id )){
								markerOverlayContainer.className = `markerOverlay markerOn`;
							} else {
								if (activeFileMap.default_on) {
									markerOverlayContainer.className = `markerOverlay markerOn`;
								} else {
									if (file.default_on) {
										markerOverlayContainer.className = `markerOverlay markerOn`;
										var currOverlays = [ ...selectedOverlays ];
										currOverlays.push(file.id)
										setSelectedOverlays(currOverlays);
									} else {
										markerOverlayContainer.className = `markerOverlay`;
									}
									// markerOverlayContainer.className = `markerOverlay`;
								}	
									
								
							}
						}

						markerOverlayContainer.id = "mapboxOverlay_" + file.id;
						// console.log('file id', file.id);
						if (file.file_type === 'svg') {
							if (file.file) {
								var imageURL = getContentPath(file.file);
								fetch(imageURL)
								.then(response => response.text())
								.then(result => {
									markerOverlayContainer.innerHTML = result;
									var delay = 0;
									var duration = '1.5';
									var paths = markerOverlayContainer.getElementsByTagName('path');
										
									if (paths.length > 0) {
										if (file.delay) {
											var delay = file.delay;
										}
										if (file.duration) {
											var duration = file.duration;   
										}
										// path[0].setAttribute('style', 'animation-duration: ' + duration + 's; animation-delay: ' + delay + 's;');
										var pathsArray = [].slice.call(paths);
										   
										for (let i = 0; i < pathsArray.length; i++) {
											pathsArray[i].setAttribute('style', 'animation-duration: ' + duration + 's; animation-delay:' + (delay + (0.005 * i)) + 's');
										}
										
										if (file.link_target_url) {
											paths[0].setAttribute('data-target', file.link_target_url);
										}
										new ClickableMarker(markerOverlayContainer)
										.setLngLat(center)
										.onClick(() => { 
											console.log('clicked', file);
											// handleMapFileClick(file);
										})
										.addTo(map);
									} 
								})
							}
						} 
						
						if (file.file_type === 'png') {
							var imageURL = getContentPath(file.file);
							var delay = 0;
							var duration = '1.5';
							var imageElem = "<img style='animation-duration: " + duration + 's; animation-delay:' + delay + "s; src='" + imageURL +  "' />";
							markerOverlayContainer.innerHTML = imageElem
							new ClickableMarker(markerOverlayContainer)
							.setLngLat(center)
							.onClick(() => { 
								// handleMapFileClick(file);
							})
							.addTo(mapBox);
						}

						function getScale(fileWidth, fileHeight) {
							var center = map.getCenter();
							var zoom = map.getZoom() + (Math.round(activeFileMap.zoom/activeFileMap.zoom_min, 0));
							var metersPerPixel = 156543.03392 * Math.cos(center.lat * Math.PI/180) / Math.pow(2, zoom);
							var meterSizeInPixelWidth = fileWidth/metersPerPixel;
							var meterSizeInPixelHeight = fileHeight/metersPerPixel;
							return [meterSizeInPixelWidth, meterSizeInPixelHeight];
						}

						function render() {
							var pixelSizes = getScale(
								activeFileMap.width,
								activeFileMap.height
							);
							markerOverlayContainer.style.width = pixelSizes[0] + "px";
							markerOverlayContainer.style.height = pixelSizes[1] + "px";
						}

						mapBox.on("viewreset", render);
						mapBox.on("zoom", render);
						mapBox.on("drag", render);
						mapBox.on("rotate", render);

						render();
						
					}

					if (i === (activeOverlayLength - 1)) {  
						setOverlaysLoaded(true);
						
					}
					
					
				});
				// if (activeOverlayGroups.length > 0){
			
				// 	const activeFileMap = files.filter(item => ((item.id === activeFile)))[0];
				// 	var center = [activeFileMap.longitude, activeFileMap.latitude];
				// 	var map = activeMap;
				// 	var activeOverlayGroupSvgIds = [];
				// 	activeOverlayGroups.forEach((file, i) => {
					
				// 		var newId = "mapboxOverlayGroup_" + file.id;
				// 		var exists = document.getElementById(newId);
				
				// 		if (!exists) {
						
				// 			var markerOverlayGroupContainer = document.createElement('div');
				// 			console.log('exists', markerOverlayGroupContainer);
				// 			if (selectedOverlays.includes( file.id )){
				// 				markerOverlayGroupContainer.className = `markerOverlayGroup} markerOn`;
				// 			} else {
				// 				markerOverlayGroupContainer.className = `markerOverlayGroup}`;

				// 				if (activeFileMap.default_on) {
				// 					setTimeout(() => {
				// 						markerOverlayGroupContainer.classList.add(`svgReady}`)
				// 					}, 1000);
				// 				}
								
				// 			}
		
				// 			markerOverlayGroupContainer.id = newId;
		
				// 			var imageURL = getContentPath(file.file);
				// 			fetch(imageURL)
				// 			.then(response => response.text())
				// 			.then(result => {
				// 				markerOverlayGroupContainer.innerHTML = result;
				// 				var delay = 0;
				// 				var duration = '1.5';
								
				// 				var paths = markerOverlayGroupContainer.getElementsByTagName('path');
				// 				// console.log('pathsArray', paths);
				// 				if (paths.length > 0) {
								
				// 					new mapboxgl.Marker(markerOverlayGroupContainer)
				// 					.setLngLat(center)
				// 					.addTo(map);
		
				// 					var svg_ids = file.svg_ids.split(",");
				// 					for (var i = 0; i < svg_ids.length; i++) {
				// 						var svgId = svg_ids[i];

				// 						activeOverlayGroupSvgIds.push(svgId);
				// 						// console.log('svgId', svgId);
				// 						// eslint-disable-next-line no-loop-func
				// 						const overlayFile = activeOverlays.filter(item => ((item.svg_id === svgId)))[0];
				// 						// console.log('activeOverlays', activeOverlays);
				// 						if (overlayFile) {
				// 							// console.log('overlayFile', overlayFile);
				// 							if (overlayFile.delay) {
				// 								var delay = overlayFile.delay;
				// 							}
				// 							if (overlayFile.duration) {
				// 								var duration = overlayFile.duration;   
				// 							}
											
				// 							var pathsArray = [].slice.call(paths);
										   
				// 							for (let i = 0; i < pathsArray.length; i++) {
				// 								pathsArray[i].setAttribute('style', 'animation-duration: ' + duration + 's; animation-delay:' + delay + 's');
				// 							}

				// 							var target = markerOverlayGroupContainer.querySelector("#" + svgId);
				// 							// let groupIds = [ ...selectedOverlayGroups ];
				// 							// groupIds.push(svgId)
				// 							if (activeFileMap.default_on) {
				// 								target.classList.add(`markerOn`);
				// 								// console.log('activeFileMap', activeFileMap.default_on, target)
				// 							}
										
										

				// 							target.addEventListener('click', function(){
												
				// 								handleMapFileClick(overlayFile);
				// 							});

											
				// 						} 
				// 					}
									
				// 					// console.log('activeOverlayGroupSvgIds', activeOverlayGroupSvgIds)
								
									
				// 				} 
				// 			})
							
				// 			function getScale(fileWidth, fileHeight) {
				// 				var center = map.getCenter();
				// 				var zoom = map.getZoom() + (Math.round(activeFileMap.zoom/activeFileMap.zoom_min, 0));
				// 				var metersPerPixel = 156543.03392 * Math.cos(center.lat * Math.PI/180) / Math.pow(2, zoom);
				// 				var meterSizeInPixelWidth = fileWidth/metersPerPixel;
				// 				var meterSizeInPixelHeight = fileHeight/metersPerPixel;
				// 				return [meterSizeInPixelWidth, meterSizeInPixelHeight];
				// 			}
		
				// 			function render() {
				// 				var pixelSizes = getScale(
				// 					activeFileMap.width,
				// 					activeFileMap.height
				// 				);
				// 				markerOverlayGroupContainer.style.width = pixelSizes[0] + "px";
				// 				markerOverlayGroupContainer.style.height = pixelSizes[1] + "px";
				// 			}
				// 			map.on("viewreset", render);
				// 			map.on("zoom", render);
				// 			map.on("drag", render);
				// 			map.on("rotate", render);
				// 			render();

				// 			if (activeOverlayGroups.length === (i + 1)) {
				// 				map.on("render", function(){
				// 					// console.log('loaded', activeOverlayGroups.length)
				// 					setOverlayGroupsLoaded(true);
				// 				});

				// 				if (activeFileMap.default_on) {
				// 					setSelectedOverlayGroups(activeOverlayGroupSvgIds);
									
				// 					console.log('activeOverlayGroupSvgIds', activeOverlayGroupSvgIds)
									
				// 				}

				// 			}
				// 		}
				// 	});
					
				// }

				
			} else {
        let overlays = document.querySelectorAll('.markerOverlay');
        overlays.forEach((obj, i) => {
          obj.remove();
        })
      }

				// if (pngGroups && (pngGroups.length > 0)){
				// 	var map = activeMap;
				// 	var newPopups = [ ...popUps ];
				// 	let pngGroupIds = [];
				// 	const activeFileMap = files.filter(item => ((item.id === activeFile)))[0];
				// 	pngGroups.forEach((group, i) => {

				// 		if (activeFileMap.default_on) {
				// 			pngGroupIds.push(group.id);
				// 			if (pngGroups.length === (i + 1)) {
				// 				setSelectedPngGroups(pngGroupIds);
				// 				console.log('pngGrp ids', pngGroupIds);
								
				// 			}
				// 		}

						

				// 		group['pngs'].forEach((file, i) => {
				// 			var markerContainer = document.createElement('div');
							
				// 			var imageURL = getContentPath(file.file);
				// 			markerContainer.style.backgroundImage = 'url(' + imageURL + ')';
				// 			markerContainer.style.backgroundSize = '100%';
				// 			markerContainer.style.height = file.height;
				// 			markerContainer.style.width = file.width;

				// 			if (file.popup_text) {
								
				// 				if (activeFileMap.default_on) {
				// 					markerContainer.className = `marker markerPopup ${'pngGroup-' + group.id} markerOn`;
				// 				} else {
				// 					markerContainer.className = `marker markerPopup ${'pngGroup-' + group.id} `;
				// 				}
				// 				const popup = new mapboxgl.Popup({ 
				// 									offset: 25,
				// 									className: `popup}`,
				// 									focusAfterOpen: false,
				// 									closeButton: false,
				// 									closeOnClick: false,
				// 									})
				// 									.setHTML("<p>" + file.popup_text + "</p>")

				// 				function handleMarkerClick(marker) {
				// 					marker.togglePopup();
				// 				}
								
				// 				const marker = new ClickableMarker({
				// 						element: markerContainer,
				// 						anchor: file.anchor
				// 						})
				// 					.setLngLat([file.longitude, file.latitude])
				// 					.setPopup(popup)
				// 					.onClick(() => { 
				// 						handleMarkerClick(marker);
				// 					})
				// 					.addTo(map);
								
					   
				// 				newPopups.push({'id': group.id, 'marker': marker, 'popup': popup, 'zoom': file.popup_zoom});
								
				// 				if (i === pngGroups.length - 1) {
				// 					setPopUps(newPopups);
				// 				}
				// 			} else {
				// 				markerContainer.className = `marker markerPopup ${'pngGroup-' + group.id}`;
								
				// 				new mapboxgl.Marker(markerContainer) 
				// 					.setLngLat([file.longitude, file.latitude])
				// 					.addTo(map);
				// 			}
							
				// 		});   
				// 		if (i === pngGroups.length - 1) {
				// 			let params = new URLSearchParams(props.history.location.search);
				// 			let pngs = params.get('pngs');
				// 			if (pngs) {
				// 				handlePngParams();
								
				// 			}
				// 		}
				// 	});
	
				// }
			
		}
  }, [activeOverlays, map, activeMap])


	class ClickableMarker extends mapboxgl.Marker {
		onClick(handleClick) {
		  this._handleClick = handleClick;
		  return this;
		}
		_onMapClick(e) {
		  const targetElement = e.originalEvent.target;
		  const element = this._element;
		  if (this._handleClick && (targetElement === element || element.contains((targetElement)))) {
			this._handleClick();
		  }
		}
	  };


  function handleBackBtn(){
    if (!showMaps) {
      setShowMaps(true);
      setOverlaySettingId(null)
    } else {
      navigate('/narratives/' + module.id.replace("mapbox-", ""))
      setShowError(false);
    }
    
  }

  function handleMapClick(obj) {
    setShowMaps(false);
    setMap(obj)
  }

  function animDropDown(open){
    if (open) {
      // let parent = document.getElementById('settingsRow_' + dropDown).getBoundingClientRect().x;
      let parent = document.getElementById('settingsRow_' + dropDown).offsetLeft;
      let dropDownElem = document.getElementById("inputField_" + dropDown + "_" + moduleObj.id ).offsetLeft;
      let offsetLeft = parent - dropDownElem;
      // console.log('offsetLeft', offsetLeft);
      // gsap.to(dropDownElem, {y: offsetLeft, duration: 0});
      var tl = gsap.timeline();
      tl.to("#inputField_" + dropDown + "_" + moduleObj.id + " .dropDownChoices", {height: "auto", duration: 0});
      tl.to("#inputField_" + dropDown + "_" + moduleObj.id, {zIndex: 3, x: offsetLeft, height: "auto", duration: 0}, "<");
      tl.to("#inputField_" + dropDown + "_" + moduleObj.id, {scale: 1, opacity: 1, duration: 0.1});
    } else {
      gsap.to(".dropDownChoices", {height: 0, duration: 0});
      gsap.to(".dropDownField", {opacity: 0, scale: 0, zIndex: -1, duration: 0.1});
    }
  }

  function getFileType(string) {
    let fileType;
    var ext = string.split('.').pop();
    switch (ext) {
      case "png":
        fileType = "img";
        break;
      case "jpg":
        fileType = "img";
        break;
      case "jpeg":
        fileType = "img";
        break;
      case "webp":
        fileType = "img";
        break;
      case "mp4":
        fileType = "vid";
        break;
      case "svg":
        fileType = "svg";
        break;
      default:
        break;
    }
    return fileType;
  }
  

  function fileNameType(string, layerName) {
    let x0 = string.replace('mapbox/', '');
    var ext = string.split('.').pop();
    let x2;
    if (layerName) {
      x2 = layerName;
    } else {
      x2 = x0.replace('.'+ ext, '');
    }
  
    // console.log('fileNameType', ext, x2, string);
    let icon;
    switch (ext) {
      case "png":
        icon = <Image className="icon" />
        break;
      case "jpg":
        icon = <Image className="icon" />
        break;
      case "jpeg":
        icon = <Image className="icon" />
        break;
      case "webp":
        icon = <Image className="icon" />
        break;
      case "svg":
        icon = <Svg className="icon" />
        break;
      case "mp4":
        icon = <Video className="icon" />
        break;
      default:
        icon = <ImageOff className="icon" />
        break;
    }
    if (x2.length > 18) {
      x2 = x2.substring(0,18) + ' ...'
    }
    let result = [icon, x2];
    return result;
  }

  let moduleObjs = [];

  function animColorPicker(open, id){
    if (open !== id) {
      var tl = gsap.timeline();
      tl.to("#" + id, {height: "auto", duration: 0});
      tl.to("#" + id, {zIndex: 3, height: "auto", duration: 0}, "<");
      tl.to("#" + id, {scale: 1, opacity: 1, duration: 0.1});
      setAnimOpen(id);
    } else {
      gsap.to("#" + id, {height: 0, duration: 0});
      gsap.to("#" + id, {opacity: 0, scale: 0, zIndex: -1, duration: 0.1});
      setAnimOpen(null);
    }
  }

  function getModules(paths) {
    let moduleObjs = [];
    for(var i = 0; i < paths.length; i++) {            
      try {
        axios({
            method: "get",
            url: paths[i],
            headers: {
              Authorization: 'Token ' + Cookies.get('_udxdid')
            },
        }).then((response) => {
            const objJSON = response.data;
            moduleObjs.push(objJSON);

            if (paths.length === moduleObjs.length) {
              setModules(moduleObjs);
              console.log('modules', moduleObjs, paths.length)
            }
           
        });
      } catch (error) {
          console.error(error);
      }  
    }
   
    return paths.length === moduleObjs.length;
  }

  function fetchJSON(objId) {
 
    try {
        axios({
            method: "get",
            url: Config.baseURL + "/api/cms/mapbox/" + id,
            headers: {
              Authorization: 'Token ' + Cookies.get('_udxdid')
            },
        }).then((response) => {
            const assetJSON = response.data;
            setModule(assetJSON);
            setShowLoader(false);
            if (objId) {
              setMap(assetJSON.mapboxes.find(obj => obj.id === objId))
              console.log('objId', objId)
            }
            // async function modulesLoaded(){
            //   await getModules(assetJSON.modules);
            //   setShowLoader(false);
            // };
            // modulesLoaded();
           
            console.log('fetchJSON mapbox', assetJSON)
        });
    } catch (error) {
        console.error(error);
    }
}

    function handleAddFile() {
      // setShowLoader(true)
      // const api_url = Config.baseURL + '/api/cms/mapbox/' + id + "/";
      // var dataJSON = {};
      // dataJSON['status'] = "new"
      // axios({
      //     method: 'POST',
      //     url: api_url,
      //     data: dataJSON
      // })
      // .then(response => {
      //     console.log('add file response', response.data['id'])
      //     fetchJSON(response.data['id']);
        
      // })
      // .catch(error => {
      //     console.log('error', error.response);
      // });
    }

    function handleOverlaySaveForm(dataJSON, objId) {
      console.log(dataJSON, objId)
      setShowLoader(true)
      const api_url = Config.baseURL + '/api/cms/mapbox-overlay/' + objId + "/";
      console.log('handleModuleSave', dataJSON)
      axios({
          method: 'POST',
          url: api_url,
          data: dataJSON,
          headers: {
            Authorization: 'Token ' + Cookies.get('_udxdid')
          },
      })
      .then(response => {
          console.log('handleModuleSave', response);
          setDropDown(null);
          fetchJSON(null);
          setOverlayChanges();
      })
      .catch(error => {
          console.log('error', error.response);
      });
    }

    function handleModuleSaveForm(dataJSON, mapId) {
      console.log(dataJSON, mapId)
      setShowLoader(true)
      const api_url = Config.baseURL + '/api/cms/mapbox/' + mapId + "/";
      console.log('handleModuleSave', dataJSON)
      axios({
          method: 'POST',
          url: api_url,
          data: dataJSON,
          headers: {
            Authorization: 'Token ' + Cookies.get('_udxdid')
          },
      })
      .then(response => {
          console.log('handleModuleSave', response);
          setDropDown(null);
          fetchJSON(null);
          setMapChanges();
      })
      .catch(error => {
          console.log('error', error.response);
      });
    }
  

  function handleModuleSave(field, value, mapId) {
    // setShowLoader(true)
    const api_url = Config.baseURL + '/api/cms/mapbox/' + mapId + "/";
    var dataJSON = {};
    dataJSON[field] = value;
    console.log('handleModuleSave', dataJSON)
    axios({
        method: 'POST',
        url: api_url,
        data: dataJSON,
        headers: {
          Authorization: 'Token ' + Cookies.get('_udxdid')
        },
    })
    .then(response => {
        console.log('handleModuleSave', response);
        setDropDown(null);
        fetchJSON(null);
        setMapChanges();
    })
    .catch(error => {
        console.log('error', error.response);
    });
  }



  function animModalIn(objId){
    let target = "#moduleDetail_" + objId;
    gsap.to(target, {opacity: 1, duration: 0.2})
  }

  function animModalOut(objId){
    let target = "#moduleDetail_" + objId;
    gsap.to(target, {opacity: 0, duration: 0.2, onComplete: setModuleObj(null)});
  }

  function round30(x) {
    let y = Math.ceil(x / 30) * 30;
    // console.log('y', y);
    return y;
}

function handleShowAnimDropDown() {
  if (showAnimDropDown) {
    setShowAnimDropDown(false);
  } else {
    setShowAnimDropDown(true);
  }
}

function handleDropDown(e, field) {
  if (field === dropDown) {
    setDropDown(null); 
  } else {
    setDropDown(field)
  }
}

function handleOnKeyUp(e, field) {
  let value = e.target.value;
  if (overlaySettingId) {
    let changes = { ...overlayChanges };
      changes[field] = value;
      if (!changes['id']) {
        changes['id'] = overlaySettingId;
      }
      setOverlayChanges(changes)
  }

    if (map && !overlaySettingId) {
      let changes = { ...mapChanges };
      changes[field] = value;
      if (!changes['id']) {
        changes['id'] = map.id;
      }
      setMapChanges(changes)
    }
}

function handleSaveMap() {
  handleModuleSaveForm(mapChanges, mapChanges['id'])
}

function handleDeleteMap() {
  
}



function handleSaveOverlay() {
  handleOverlaySaveForm(overlayChanges, overlayChanges['id'])
}

function handleDeleteOverlay() {
  
}


function addMapbox(title){
  console.log('moduleObj.id', map.narrative)
  const api_url = Config.baseURL + '/api/cms/mapbox/' + map.narrative + "/";
  var dataJSON = {};
  dataJSON['status'] = 'new';
  dataJSON['title'] = title;
  console.log('handleModuleSave', dataJSON)
  axios({
      method: 'POST',
      url: api_url,
      data: dataJSON,
      headers: {
        Authorization: 'Token ' + Cookies.get('_udxdid')
      },
  })
  .then(response => {
      console.log('handleModuleSave', response);
      setShowNewMapForm(false);
      fetchJSON(response.data.id);
    
  })
  .catch(error => {
      console.log('error', error.response);
  });

}

function reOrder(array, obj, d, setObj) {
  let reordered;
  let newArray = [ ...array ];   
  let yObj = newArray.find(obj0 => obj0.id === obj.id);

  if (yObj) {
    
    if (parseInt(round30(d.y)/30) !== yObj.ordering) {
      
      let yObj_posY;
      if (d.y/30 === 0) {
        yObj_posY = d.y;
      } else {
        yObj_posY = round30(d.y);
      }

      let lastNumber = newArray.length - 1;
                        
      let newOrdering = parseInt((yObj_posY/30))-1;
      if (newOrdering > lastNumber) {
        newOrdering = lastNumber
      }
      
      if (newOrdering < 0) {
        newOrdering = 0
      }
      let beforeNumber = [];
      let afterNumber = [];
      let sameNumber;
      if (newOrdering === 0) {
        console.log('%cFirst', 'color: red; font-weight: bold;');
        afterNumber = newArray.filter(obj0 => ((obj0.ordering >= newOrdering)&&(obj0.id !== yObj.id)));

      } else if (newOrdering === (lastNumber)) {
        console.log('%cLast', 'color: green; font-weight: bold;');
        beforeNumber = newArray.filter(obj0 => ((obj0.ordering <= newOrdering)&&(obj0.id !== yObj.id)));

      } else {
        console.log('%cMiddle', 'color: gold; font-weight: bold;');
        beforeNumber = newArray.filter(obj0 => ((obj0.ordering < newOrdering)&&(obj0.id !== yObj.id)));
        afterNumber = newArray.filter(obj0 => ((obj0.ordering > newOrdering)&&(obj0.id !== yObj.id)));
        sameNumber = newArray.find(obj0 => ((obj0.ordering === newOrdering)&&(obj0.id !== yObj.id)));
        if ((newOrdering > originY.current)) {
          sameNumber && (sameNumber['ordering']=newOrdering-1);
        } else {
          sameNumber && (sameNumber['ordering']=newOrdering+1);
        }

      }
    
      beforeNumber.forEach((obj, i) => {
        obj.ordering = i
      });
      
      afterNumber.forEach((obj, i) => {
        obj.ordering = newOrdering+i+1
      });

      console.log('newOrdering', newOrdering)
      console.log('beforeNumber', beforeNumber)
      console.log('sameNumber', sameNumber)
      console.log('afterNumber', afterNumber)

      yObj.ordering = newOrdering;
      originY.current = null;
      reordered = newArray.sort((a, b) => a.ordering - b.ordering);
      let reorderedCopy = [ ...reordered ];

      reorderedCopy.forEach((obj, i) => {
        obj.ordering = i
      })
      console.log('onDragStop', yObj.ordering, reorderedCopy)

      
    
      setObj(reorderedCopy);

    }

    
  }


  return reordered
}





    return (
        <section className="main" id="mapBox">
        {(showLoader)?(<div id="loading"><img src={Loader} alt="" /></div>):null}
        {(showError)&&(<div id="errorPopup"><div className="modal"><span><h3>Unsaved Changes</h3><p>Are you sure you want to go back?</p></span> <div className="fullRow errorRow"><span onClick={() => handleBackBtn('cancel')}>Cancel</span> <span onClick={() => handleBackBtn('force')}>Continue</span></div></div></div>)}
        {(showNewMapForm)&&(<div className="modalContainer"><div className="modal"><span><h3>New Map</h3>

        <div className="settingsRow">
          <input type="text" autoComplete="off" id="newMapTitle" autoFocus placeholder="Add Map Title" />
        </div>

        </span> <div className="fullRow errorRow"><span onClick={() => {
          setShowNewMapForm(false)
        }}>Cancel</span> <span onClick={() => addMapbox(document.getElementById('newMapTitle').value)}>Continue</span></div></div></div>)}
        
        
        {module && map && <div className="col appGrid">

        {module && map &&
          <div className={"moduleDetail mapBox"} id={"moduleDetail_" + map.id}>
            <div className="map" ref={mapBox}></div>
          </div>
          
        }


        <div id="narrativeTools">
          
  
          <div className="iconField ui"  onClick={() => handleAddFile()} ><PhotoAdd className="icon ui" title="Add Layer" /><div className="iconLabel">Add Overlay</div></div>

          <h1>{module.title}</h1>

          <a href={ Config.previewURL + "/mapbox/" + module.id} className="previewBtn" target="_blank" rel="noreferrer" title="preview">
          <img src={OpenLink} className="icon" alt="" /></a>

          </div>
            <nav className="leftNav">
            <div className="leftArrow iconField" onClick={() => handleBackBtn()}>
                <ArrowBack className="icon" /> 
  
                <div className="pages">{!showMaps ? "Maps" : "Presentation Overview"}</div>
              </div>

              <div className="mapPages">
              {showMaps && <div className="mapOverlays">
              <div  className="sectionRows">
              <div className="sectionTools"><div className="sectionLabel">Maps</div><AddBox className="icon ui" title="Add Category" onClick={() => setShowNewMapForm(true)} /></div>

      
                <div className="pageBtnContainer open">
                    <div className="draggablePages mapPages">
            

             
              {mapboxes && mapboxes.map((obj, i) => (
                <span className="mapBtn ui" onClick={() => handleMapClick(obj)} key={'pageBtn_' + i}>{obj.title}</span>
              ))}
              </div>
              </div>
              </div> </div>}
              {map && !showMaps && <div className="mapOverlays">
              <div  className="sectionRows">
              <div className="sectionTools"><div className="sectionLabel">Overlays</div><AddBox className="icon ui" title="Add Category" onClick={() => handleAddFile(true)} /></div>
            </div>
                <div className="pageBtnContainer open">
                    <div className="draggablePages">
                      <span className="groupFiles">
                          {map && 
                            map.overlays
                            .map((obj1, j) => (
                              <Rnd
                                className={"mediaFile ui pageBtn"}
                                key={'catFile_' + j}
                                id={"page_" + obj1.id}
                                style={{'cursor': "default"}}
                                lockAspectRatio={true}
                                enableResizing={false}
                                disableDragging={true}
                                dragAxis={'y'}
                                default={{
                                  x: 0,
                                  y: j*30,
                                  height: 30
                                }}
                                position={{
                                  x: 0,
                                  y: j*30,
                                }}
                                onClick={(e) =>{
                             
                                  if ((isDragging.current === false || isDragging.current === undefined) && obj1.file) {
                                    // setTimeout(() => {
                                    //   e.target.classList.add('dragging');
                                    // }, 200);
                                 
                                    let overlays = [];
                                    if (activeOverlays && activeOverlays.includes(obj1)) {
                                      let copy = [...activeOverlays ];
                                      let index = copy.findIndex(a => a.id === obj1.id)
                                      copy.splice(index, 1)
                                      overlays = copy;

                                      
                                    } else {
                                      overlays.push(obj1)
                                    }
                                    // overlays.push(obj1)
                                    setActiveOverlays(overlays)
                                    setOverlaySettingId(obj1.id)

                               
                                  }
                                }}
                                onDragStart={(e, d) => { 
                                  originYpx.current = d.y
                                  originY.current = parseInt(round30(d.y)/30)
                                  console.log('onDragStart', originY.current, d.y, rnds.current)
                                }}
                                onDrag={(e, d) => {
                                  if (d.y - originYpx > 28 || d.y - originYpx < 28) {
                                    isDragging.current = true;
                                    // setTimeout(() => {
                                    //   e.target.classList.add('dragging');
                                    // }, 100);
                                  }
                                 
                                }}
                                onDragStop={(e, d) => {                          
                                  // reOrder(map.overlays, obj1, d, setMap)
                                  isDragging.current = false;
                                }}
                                >
                                <span
                                className="layerIconContainer"
                                
                                >
                  
                                  {fileNameType(obj1.file, obj1.name).map((obj2, j) => (
                                    <span
                                    key={"fileName_" + j}>
                                    
                                    {obj2}
                          
                                    </span>))}
                                </span>
                              </Rnd>
                            ))
                          }
                        </span>
                    </div>
                   </div>       
              </div>}
            </div>
            
            </nav>
            <div className="core">
              <div className="twoCol">
                  <div className="flex"></div>
    
                  <div className="rightNav settingsOn" id="manifestColumn">
                    {map && <div className={"mediaSettings showSettings on"}>
                      <div className="settingsGroup">
                        <div className="groupLabel"><p>Map Settings</p></div>
                        <div className="settingsRow">
                          <div className="fieldRow">
                              <div className="errors"></div>
                                <div className="canvasLabel">Title</div>
                                <input type="text" autoComplete="off" id="title" key={map.title} defaultValue={map.title} onKeyUp={(e) => handleOnKeyUp(e, 'title')} />
                          </div>
                        </div>
                        <div className="settingsRow halfRow">
                          <div className="fieldRow">
                              <div className="errors"></div>
                                <div className="canvasLabel">Latitude</div>
                                <input type="text" autoComplete="off" id="latitude" key={map.latitude} defaultValue={map.latitude} onKeyUp={(e) => handleOnKeyUp(e, 'latitude')} />
                          </div>
                       
                        </div>
                        <div className="settingsRow halfRow">

                          <div className="fieldRow">
                          <div className="errors"></div>
                            <div className="canvasLabel">Longitude</div>
                            <input type="text" autoComplete="off" id="longitude" key={map.longitude} defaultValue={map.longitude} onKeyUp={(e) => handleOnKeyUp(e, 'longitude')} />
                          </div>
                        </div>
                        <div className="settingsRow">
                          <div className="fieldRow">
                              <div className="errors"></div>
                                <div className="canvasLabel">zoom</div>
                                <input type="text" autoComplete="off" id="zoom"  key={map.zoom} defaultValue={map.zoom} onKeyUp={(e) => handleOnKeyUp(e, 'zoom')} />
                          </div>
                        </div>
                        <div className="settingsRow halfRow">
                          <div className="fieldRow">
                              <div className="errors"></div>
                                <div className="canvasLabel">zoom min</div>
                                <input type="text" autoComplete="off" id="zoom_min" key={map.zoom_min}  defaultValue={map.zoom_min} onKeyUp={(e) => handleOnKeyUp(e, 'zoom_min')} />
                          </div>
                        </div>
                        <div className="settingsRow halfRow">
                          <div className="fieldRow">
                              <div className="errors"></div>
                                <div className="canvasLabel">zoom max</div>
                                <input type="text" autoComplete="off" id="zoom_max" key={map.zoom_max}  defaultValue={map.zoom_max} onKeyUp={(e) => handleOnKeyUp(e, 'zoom_max')} />
                          </div>
                        </div>


                      </div>

                      <div className="settingsRow" id="saveRow">
                        <div onClick={() => handleSaveMap()} className={"closeFormBtn btn"}>Save</div>
                       <div className="iconField ui" onClick={() => handleDeleteMap()}><Delete className="icon"/> <div className="canvasLabel">Delete Map</div></div>
                      </div>  


                    </div>}


                    {overlaySettingId && (activeOverlays.length > 0) && 
                      <div className={"mediaSettings showSettings on"}>
                      {activeOverlays.filter(a => a.id === overlaySettingId).map((obj, i) => (
                      <div className="settingsGroup" key={i}>
                        <div className="groupLabel"><p>Overlay Settings</p></div>
                        <div className="settingsRow">
                          <div className="fieldRow">
                              <div className="errors"></div>
                                <div className="canvasLabel">Title</div>
                                <input type="text" autoComplete="off" id="title" key={obj.name} defaultValue={obj.name} onKeyUp={(e) => handleOnKeyUp(e, 'name')} />
                          </div>
                        </div>
                        <div className="settingsRow halfRow">
                          <div className="fieldRow">
                              <div className="errors"></div>
                                <div className="canvasLabel">Latitude</div>
                                <input type="text" autoComplete="off" id="latitude" key={obj.latitude} defaultValue={obj.latitude} onKeyUp={(e) => handleOnKeyUp(e, 'latitude')} />
                          </div>
                       
                        </div>
                     
                        <div className="settingsRow halfRow">

                          <div className="fieldRow">
                          <div className="errors"></div>
                            <div className="canvasLabel">Longitude</div>
                            <input type="text" autoComplete="off" id="longitude" key={obj.longitude} defaultValue={obj.longitude} onKeyUp={(e) => handleOnKeyUp(e, 'longitude')} />
                          </div>
                        </div>
                        
                        <div className="settingsRow halfRow">

                        <div className="fieldRow">
                        <div className="errors"></div>
                          <div className="canvasLabel">Width</div>
                          <input type="text" autoComplete="off" id="width" key={obj.width} defaultValue={obj.width} onKeyUp={(e) => handleOnKeyUp(e, 'longitude')} />
                        </div>
                      </div>
                      <div className="settingsRow halfRow">
                        <div className="fieldRow">
                            <div className="errors"></div>
                              <div className="canvasLabel">Height</div>
                              <input type="text" autoComplete="off" id="height" key={obj.height} defaultValue={obj.height} onKeyUp={(e) => handleOnKeyUp(e, 'height')} />
                        </div>
                     
                      </div>

                      </div> ))}

                      <div className="settingsRow" id="saveRow">
                        <div onClick={() => handleSaveOverlay()} className={"closeFormBtn btn"}>Save</div>
                       <div className="iconField ui" onClick={() => handleDeleteOverlay()}><Delete className="icon"/> <div className="canvasLabel">Delete Overlay</div></div>
                      </div>  


                    </div>}



                  

                     



                  </div>
                  </div> 

     
            </div>     
           
          

         </div>}
        

      </section>
    );
}

export default Module;
// {(showDeleteSection)?(<div id="errorPopup">Are you sure you want to delete this page? <div className="fullRow errorRow"><span onClick={() => handleDeleteSection('cancel')}>[Cancel]</span> <span onClick={() => handleDeleteSection('continue')}>[Continue]</span></div></div>):null}