import React, { useState, useEffect, useRef } from "react";
import {
    NavLink,
  useNavigate,
  useParams
} from "react-router-dom";
import axios from "axios";
import { Rnd } from "react-rnd";
import Cookies from 'js-cookie';
import gsap from "gsap";

import Modal from './module.modal';
import Config from "../Config.json"
import Loader from "../static/svg/loader.svg";
import imagePlaceholder from '../static/img/image.png'

// Icons
import OpenLink from "../static/svg/openLink-w.svg";
import {ReactComponent as OpenObjLink} from "../static/svg/openLink-w.svg";
import {ReactComponent as Edit} from "../static/icons/edit.svg" 
// import {ReactComponent as Add} from "../static/icons/library_add.svg" 
// import {ReactComponent as WebGL} from "../static/modules/3d.svg" 
import {ReactComponent as Doc} from "../static/modules/doc_library.svg" 
// import {ReactComponent as GalleryImage} from "../static/modules/gallery_image.svg" 
// import {ReactComponent as GalleryVr} from "../static/modules/gallery_vr.svg" 
// import {ReactComponent as GalleryVideo} from "../static/modules/gallery_video.svg" 
// import {ReactComponent as Map} from "../static/modules/map.svg" 
// import {ReactComponent as Stack} from "../static/modules/stack_typical.svg" 
import {ReactComponent as ArrowBack} from "../static/icons/arrow_back.svg";
import {ReactComponent as Close} from "../static/svg/close.svg"
import {ReactComponent as NoteAdd} from "../static/icons/note_add.svg";
import {ReactComponent as PhotoAdd} from "../static/icons/add_photo.svg";
import {ReactComponent as Image} from "../static/icons/image.svg"
import {ReactComponent as Video} from "../static/icons/video.svg"
import {ReactComponent as Svg} from "../static/icons/svg.svg"
import {ReactComponent as Delete} from "../static/icons/delete.svg"
import {ReactComponent as AutoRenew} from "../static/icons/autorenew.svg"

import {ReactComponent as Pdf} from "../static/modules/pdf.svg"


function Module(props) {
  const navigate = useNavigate();
  let { id } = useParams();
  const originY = React.useRef();
  const originYpx = React.useRef();
  const isDragging = React.useRef();
  const rnds = React.useRef([]);
  const tempAfterObjs = React.useRef(null);
  const layerFormat = React.useRef();
  const colorPicker = useRef();
  const [module, setModule] = useState(null);
  const [moduleObj, setModuleObj] = useState(null);
  const [modules, setModules] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [files, setFiles] = useState([]);
  const [showError, setShowError] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [animOpen, setAnimOpen] = useState(false);
  const [categoryDrags, setCategoryDrags] = useState(true);
  const [acceptValues, setAcceptValues] = useState('init');
  const [dropDown, setDropDown] = useState();
  const [editField, setEditField] = useState(null);
  const [showAnimDropDown, setShowAnimDropDown] = useState(false);
  const [showCategoryForm, setShowCategoryForm] = useState(false);
  const [narrativeUpdating, setNarrativeUpdating] = useState(false);
  const [formError, setFormError] = useState();

  useEffect(() => {
    props.setTools("canvas")
    fetchJSON(null);
    
    // login();
  }, []);

  useEffect(() => {
    moduleObj && animModalIn(moduleObj.id)
  }, [moduleObj])

  useEffect(() => {
    // module && setDocuments(module.all_filedocuments)
    if (module) {
      let docs = [...module.files ]
      module.pdf && docs.push(module.pdf)
      setFiles(docs)
      moduleObj && setModuleObj(module.files.find(obj => obj.id === moduleObj.id))
      console.log('files', docs)
    }
  }, [module])

  // useEffect(() => {
  //   documents && console.log('documents', documents)
  // }, [documents])

  useEffect(() => {
    if (dropDown) {
      animDropDown(true)
    } else {
      animDropDown(false)
    }
  }, [dropDown])

  function handleBackBtn(){
    navigate('/narratives/' + module.narrative)
    setShowError(false);
  }

  function animDropDown(open){
    if (open) {
      // let parent = document.getElementById('settingsRow_' + dropDown).getBoundingClientRect().x;
      let parent = document.getElementById('settingsRow_' + dropDown).offsetLeft;
      let dropDownElem = document.getElementById("inputField_" + dropDown + "_" + moduleObj.id ).offsetLeft;
      let offsetLeft = parent - dropDownElem;
      // console.log('offsetLeft', offsetLeft);
      // gsap.to(dropDownElem, {y: offsetLeft, duration: 0});
      var tl = gsap.timeline();
      tl.to("#inputField_" + dropDown + "_" + moduleObj.id + " .dropDownChoices", {height: "auto", duration: 0});
      tl.to("#inputField_" + dropDown + "_" + moduleObj.id, {zIndex: 3, x: offsetLeft, height: "auto", duration: 0}, "<");
      tl.to("#inputField_" + dropDown + "_" + moduleObj.id, {scale: 1, opacity: 1, duration: 0.1});
    } else {
      gsap.to(".dropDownChoices", {height: 0, duration: 0});
      gsap.to(".dropDownField", {opacity: 0, scale: 0, zIndex: -1, duration: 0.1});
    }
  }

  function getFileType(string) {
    let fileType;
    var ext = string.split('.').pop();
    switch (ext) {
      case "png":
        fileType = "img";
        break;
      case "jpg":
        fileType = "img";
        break;
      case "jpeg":
        fileType = "img";
        break;
      case "webp":
        fileType = "img";
        break;
      case "mp4":
        fileType = "vid";
        break;
      case "svg":
        fileType = "svg";
        break;
      default:
        break;
    }
    return fileType;
  }
  

  function fileNameType(string, layerName) {
    if (string) {
      let x0 = string.replace('narrative-pdf/' + module.narrative + '/', '');
      x0 = x0.replace('documents/', '');
      var ext = string.split('.').pop();
      let x2;
      if (layerName) {
        x2 = layerName;
      } else {
        x2 = x0.replace('.'+ext, '');
      }
    
      // console.log('fileNameType', ext, x2, string);
      let icon;
      switch (ext) {
        case "png":
          icon = <Image className="icon" />
          break;
        case "jpg":
          icon = <Image className="icon" />
          break;
        case "jpeg":
          icon = <Image className="icon" />
          break;
        case "webp":
          icon = <Image className="icon" />
          break;
        case "svg":
          icon = <Svg className="icon" />
          break;
        case "mp4":
          icon = <Video className="icon" />
          break;
        case "pdf":
          icon = <Pdf className="icon" />
          break;
        default:
          break;
      }
      if (x2.length > 18) {
        x2 = x2.substring(0,18) + ' ...'
      }
      let result = [icon, x2];
      return result;
    }
    
  }

  function handleReorderFileSave(objs) {
    console.log('handleReorderFileSave', objs);      
    axios({
        method: 'POST',
        url: Config.baseURL + '/api/cms/document-reorder/',
        data: {
            'documentfiles': objs
          },
        headers: {
          'Authorization': 'Token ' + Cookies.get('_udxdid')
        },
    })
    .then(response => {
        console.log('handleReorderFileSave: response', response);
        // isLast && handleReloadSection(response);
        // handleReloadSection(response);
        fetchJSON()
        
    })
    .catch(error => {
        console.log('error', error.response);
    });
  }

  function fetchJSON(objId, showLoader) {
    showLoader && setShowLoader(true);
    try {
        axios({
            method: "get",
            url: Config.baseURL + "/api/cms/documents/" + id,
            headers: {
              Authorization: 'Token ' + Cookies.get('_udxdid')
            },
        }).then((response) => {
            const assetJSON = response.data;
            setModule(assetJSON);
            setShowLoader(false);
            console.log('assetJSON', response)  
            if (response.data['id']) {
              let obj = assetJSON.files.find(a => a.id === objId)
              setModuleObj(obj)
            }
        });
    } catch (error) {
        console.error(error);
    }
}

    function handleAddFile() {
      setShowCategoryForm(true);
      // setShowLoader(true)
      // const api_url = Config.baseURL + '/api/cms/documents/' + id + "/";
      // var dataJSON = {};
      // dataJSON['status'] = "new"
      // axios({
      //     method: 'POST',
      //     url: api_url,
      //     data: dataJSON,
      //     headers: {
      //       Authorization: 'Token ' + Cookies.get('_udxdid')
      //     },
      // })
      // .then(response => {
      //     console.log('add file response', response.data['id'])
      //     fetchJSON(response.data['id']);
        
      // })
      // .catch(error => {
      //     console.log('error', error.response);
      // });
    }

    function handleAddCategory(name) {
      setShowLoader(true)

      var dataJSON = {};
      const api_url = Config.baseURL + '/api/cms/documents/' + id + "/";

      dataJSON['status'] = "new"
      dataJSON['caption'] = name
      axios({
          method: 'POST',
          url: api_url,
          data: dataJSON,
          headers: {
            Authorization: 'Token ' + Cookies.get('_udxdid')
          },
      })
      .then(response => {
          console.log('add file response', response.data['id'])
          fetchJSON(response.data['id']);
          setShowCategoryForm(false);
          
      })
      .catch(error => {
          console.log('error', error.response);
      });
    }

    function handleCategorySave(field, value, objId) {
      // setShowLoader(true)
      const api_url = Config.baseURL + '/api/cms/gallery-category/' + objId + "/";
      var dataJSON = {};
      dataJSON['gallery'] = id.replace('gallery-', '')
      dataJSON[field] = value;
      console.log('handleCategorySave', dataJSON)
      axios({
          method: 'POST',
          url: api_url,
          data: dataJSON,
          headers: {
            Authorization: 'Token ' + Cookies.get('_udxdid')
          },
      })
      .then(response => {
          console.log('handleCategorySave', response);
          document.getElementById('pageBtnContainer_'+ response.data['id']).classList.remove('on')
          setDropDown(null);
          setEditField(null);
          fetchJSON(null);
      })
      .catch(error => {
          console.log('error', error.response);
      });
    }

  function handleModuleSave(field, value) {

    if (moduleObj.id) {
      const api_url = Config.baseURL + '/api/cms/documents-file/' + moduleObj.id + "/";
      var dataJSON = {};
      dataJSON['document'] = id
      dataJSON[field] = value;
      console.log('handleModuleSave', dataJSON)
      axios({
          method: 'POST',
          url: api_url,
          data: dataJSON,
          headers: {
            Authorization: 'Token ' + Cookies.get('_udxdid')
          },
      })
      .then(response => {
          console.log('handleModuleSave', response);
          setDropDown(null);
          if (field === 'caption') {
            fetchJSON(null, false)
          } else {
            fetchJSON(null, true)
          }
        
      })
      .catch(error => {
          console.log('error', error.response);
      });

    }
    
  }

  

  function handleModuleMedia(e, fileName){
    setShowLoader(true)
    var fileList = e.target.files;
    var arrayLength = fileList.length;
    const api_url = Config.baseURL + '/api/cms/documents-file/' + moduleObj.id + "/";

    for (var i = 0; i < arrayLength; i++) {

      // File Checks
      let sizeMB = parseFloat(fileList[i].size / (1024 ** 2)).toFixed(2);

      if (sizeMB > Config.validations.maxFileSize) {

          let newError = {
            "heading": "Error",
            "text": "The selected file is <strong>" + sizeMB + "MB</strong>.<br/>This file exceeds maximum upload size of <strong>10MB</strong>.<br/><br/>Please select a new file that is less than 10MB."
          };
          setFormError(newError);
         setShowLoader(false);

      } else {

        var formData = new FormData();
        formData.append(fileName, fileList[i], fileList[i].name) 
        formData.append('document', id)
        formData.append('active', moduleObj.active)
        axios({
            method: 'POST',
            url: api_url,
            data: formData,
            headers: {
              Authorization: 'Token ' + Cookies.get('_udxdid')
            },
        })
        .then(response => {
            console.log('handleModuleMedia', response);
            fetchJSON(null);
        })
        .catch(error => {
            console.log('error', error.response);
        });
        

      }


      

    } 
  }

  function handleThemeCss(field, value){
    let newCss;
    if (module.css) {
      newCss = module.css;
    } else {
      newCss = {};
    }
    if (value) {
      newCss[field] = value;
    } else {
      delete newCss[field]; 
    }
    handleModuleSave('css', newCss);

  }

  function animModalIn(objId){
    let target = "#moduleDetail_" + objId;
    gsap.to(target, {opacity: 1, duration: 0.2})
  }

  function animModalOut(objId){
    let target = "#moduleDetail_" + objId;
    gsap.to(target, {opacity: 0, duration: 0.2, onComplete: setModuleObj(null)});
  }

  function round30(x) {
    let y = Math.ceil(x / 30) * 30;
    // console.log('y', y);
    return y;
}

function reOrderForOpen(array, obj, setObj, height) {
  let newArray = [ ...array ];   
  let yObj = newArray.find(obj0 => obj0.id === obj.id);
  if (yObj) {
    if (height) {
      let afterNumberCopy = [];
      let afterNumber = newArray.filter(obj0 => ((obj0.ordering > yObj.ordering)&&(obj0.id !== yObj.id)));
      afterNumber.map(obj => afterNumberCopy.push({id: obj.id, ordering: obj.ordering}));
      tempAfterObjs.current = afterNumberCopy;
      
      afterNumber.forEach((obj, i) => {
        obj.ordering = (height/30)+i+1
      });
      let reordered = newArray.sort((a, b) => a.ordering - b.ordering);
      setObj(reordered);
      
    } else {
      tempAfterObjs.current.forEach((z) => {
        let match = newArray.find(a => a.id === z.id)
        match['ordering'] = z.ordering
      });
      
      let reordered = newArray.sort((a, b) => a.ordering - b.ordering);
      setObj(reordered);
    }
  }
}

 
function reOrder(array, obj, d, setObj) {
  let reordered;
  let newArray = [ ...array ];   
  let yObj = newArray.find(obj0 => obj0.id === obj.id);

  if (yObj) {
    
    if (parseInt(round30(d.y)/30) !== yObj.ordering) {
      
      let yObj_posY;
      if (d.y/30 === 0) {
        yObj_posY = d.y;
      } else {
        yObj_posY = round30(d.y);
      }

      let lastNumber = newArray.length - 1;
                        
      let newOrdering = parseInt((yObj_posY/30))-1;
      if (newOrdering > lastNumber) {
        newOrdering = lastNumber
      }
      
      if (newOrdering < 0) {
        newOrdering = 0
      }
      let beforeNumber = [];
      let afterNumber = [];
      let sameNumber;
      if (newOrdering === 0) {
        console.log('%cFirst', 'color: red; font-weight: bold;');
        afterNumber = newArray.filter(obj0 => ((obj0.ordering >= newOrdering)&&(obj0.id !== yObj.id)));
        // sameNumber && (sameNumber['ordering'] = 1);

      } else if (newOrdering === (lastNumber)) {
        console.log('%cLast', 'color: green; font-weight: bold;');
        beforeNumber = newArray.filter(obj0 => ((obj0.ordering <= newOrdering)&&(obj0.id !== yObj.id)));
        // sameNumber = newArray.find(obj0 => ((obj0.ordering === newOrdering)&&(obj0.id !== yObj.id)));
        // sameNumber && (sameNumber['ordering']=newArray.length-1);
        
      } else {
        console.log('%cMiddle', 'color: gold; font-weight: bold;');
        beforeNumber = newArray.filter(obj0 => ((obj0.ordering < newOrdering)&&(obj0.id !== yObj.id)));
        afterNumber = newArray.filter(obj0 => ((obj0.ordering > newOrdering)&&(obj0.id !== yObj.id)));
        sameNumber = newArray.find(obj0 => ((obj0.ordering === newOrdering)&&(obj0.id !== yObj.id)));
        if ((newOrdering > originY.current)) {
          sameNumber && (sameNumber['ordering']=newOrdering-1);
        } else {
          sameNumber && (sameNumber['ordering']=newOrdering+1);
        }

      }
    
      beforeNumber.forEach((obj, i) => {
        obj.ordering = i
      });
      
      afterNumber.forEach((obj, i) => {
        obj.ordering = newOrdering+i+1
      });

      console.log('newOrdering', newOrdering)
      console.log('beforeNumber', beforeNumber)
      console.log('sameNumber', sameNumber)
      console.log('afterNumber', afterNumber)

      yObj.ordering = newOrdering;
      originY.current = null;
      let reordered0 = newArray
                  .filter(z => z.id > 0)
                  .sort((a, b) => a.ordering - b.ordering);
      reordered = newArray
                  .sort((a, b) => a.ordering - b.ordering);
      let reorderedCopy = [ ...reordered ];

      reorderedCopy.forEach((obj, i) => {
        obj.ordering = i
      })
      console.log('onDragStop', yObj.ordering, reorderedCopy)
      setObj(reorderedCopy)
      
      setTimeout(() => {
         handleReorderFileSave(reordered0)
      }, 300);
  
    }

    
  }


  return reordered
}

function handleShowAnimDropDown() {
  if (showAnimDropDown) {
    setShowAnimDropDown(false);
  } else {
    setShowAnimDropDown(true);
  }
}

function handleDropDown(e, field) {
  if (field === dropDown) {
    setDropDown(null); 
  } else {
    setDropDown(field)
  }
}

function handleOnKeyUp(e, field, group, objId) {
    e.target.classList.add('editing');
    if (group === "file") {
      if (e.key === "Enter") {
        e.target.classList.remove('editing');
        handleModuleSave(field, e.target.value);
        }
    }
    if (group === "category") {
      if (e.key === "Enter") {
        e.target.classList.remove('editing');
        handleCategorySave(field, e.target.value, objId);
        }
    }
   

}

// function updateNarrativePDF() {
//   // console.log('module.narrative', module.narrative)
//   if (module) {
//     setShowLoader(true);
//     axios({
//       method: 'POST',
//       url:  Config.baseURL + "/api/cms/generate-narrative-pdf/",
//       data: {id: module.narrative},
//       headers: {
//         Authorization: 'Token ' + Cookies.get('_udxdid')
//       },
//     })
//     .then(response => {
//         console.log('generate-narrative pdf', response);
//         fetchJSON(false);
//         setShowLoader(false);
        
//     })
//     .catch(error => {
//         console.log('error', error.response);
//     });
//     }
  
// }

function updateNarrativePDF() {
  if (module) {
    // setShowLoader(true);
    setNarrativeUpdating(module.narrative_modified);
    console.log('narrative.modified', module.narrative_modified)
    
    axios({
      method: 'POST',
      url:  Config.baseURL + "/api/cms/generate-narrative-pdf/",
      data: {id: module.narrative},
      headers: {
        Authorization: 'Token ' + Cookies.get('_udxdid')
      },
    })
    .then(response => {
        console.log('generate-narrative pdf', response);
        
        setInterval(() => {
          try {
            axios({
                method: "get",
                url: Config.baseURL + "/api/cms/narrative-landing/" + id,
                headers: {
                  Authorization: 'Token ' + Cookies.get('_udxdid')
                },
            }).then((response) => {
                const assetJSON = response.data;
                if (assetJSON.modified !== narrativeUpdating) {
                  fetchJSON();
                  setNarrativeUpdating(false);
                  console.log('pdf ready', assetJSON.modified)
                } else {
                  console.log('still generating pdf', assetJSON.modified)
                }
                
                
               
               
                
            });
        } catch (error) {
            console.error(error);
        }
        }, 10000);

        
    })
    .catch(error => {
        console.log('error', error.response);
    });
    }
  
}




    return (
        <section className="main">
        {(showLoader)?(<div id="loading"><img src={Loader} alt="" /></div>):null}

        {(formError)&&<Modal heading={formError.heading} text={formError.text} onBtn1Click={setFormError} onBtn1={null} btn1Text={"Close"} onBtn2Click={null} onBtn2={null} btn2Text={null} />}


        {(showError)&&(<div id="errorPopup"><div className="modal"><span><h3>Unsaved Changes</h3><p>Are you sure you want to go back?</p></span> <div className="fullRow errorRow"><span onClick={() => handleBackBtn('cancel')}>Cancel</span> <span onClick={() => handleBackBtn('force')}>Continue</span></div></div></div>)}
        {(showCategoryForm)&&(<div className="modalContainer"><div className="modal"><span><h3>New Presentation</h3>

        <div className="settingsRow">
          <input type="text" autoComplete="off" id="newCategoryName" autoFocus placeholder="Add Name" />
        </div>

        </span> <div className="fullRow errorRow"><span onClick={() => {
          setShowCategoryForm(false)
        }}>Cancel</span> <span onClick={() => handleAddCategory(document.getElementById('newCategoryName').value)}>Continue</span></div></div></div>)}
        
        
        {module && <div className="col appGrid">

        <div id="narrativeTools">
          
  
          <div className="iconField ui"  onClick={() => handleAddFile()} ><PhotoAdd className="icon ui" title="Add Layer" /><div className="iconLabel">Add File</div></div>

          <div className={"iconField ui" + (narrativeUpdating ? " rotate":"")}  onClick={() => updateNarrativePDF()} ><AutoRenew className="icon ui" title="Add Layer" /><div className="iconLabel">Update Presentation PDF</div></div>

          <h1>{module.title ? module.title : "Document Library"}</h1>

          <a href={ Config.previewURL + "/narrative/" + module.narrative + '?u=' + props.user.link_token + "&module=documents-" + module.id} className="previewBtn previewBtnGallery" target="_blank" rel="noreferrer" title="preview">
          <img src={OpenLink} className="icon" alt="" /></a>
         

          </div>
            <nav className="leftNav">
            <div className="leftArrow iconField" onClick={() => handleBackBtn()}>
                <ArrowBack className="icon" /> 
  
                <div className="pages">Presentation Overview</div>
              </div>

              <div className="pages fieldRow modulePages">
                  <div  className="sectionRows">
                    <div className="sectionTools"><div className="sectionLabel">Documents</div></div>
                  </div>
                  
                 <div className="nonDraggablePages moduleLeftNav">
                 <div style={{opacity: 0.3, paddingBottom: "10px"}}>Presentation PDF</div>
                 <span
                 className="layerIconContainer"
               
                 >
            
                 {files && files
                   .filter(c => !c.id)
                   .map((obj1, j) => (
               
                    obj1.document && fileNameType(obj1.document, obj1.caption).map((obj2, k) => (
                     <span key={"fileDocName_" + k}
                     onClick={(e) =>{
                      
                      if (j !== 0) {
                        setModuleObj(obj1)
                      }
                      
                    }}
                     >{obj2}</span>)
                     
                     )
                  
                 ))}

                 </span>
                 </div>
                    <div className="draggableHeading draggablePages">
                    <div style={{opacity: 0.3}}>Other Files</div>
                    <div className="draggablePages moduleLeftNav documentNav">
                  
                  
                    {files && files
                      .filter(c => c.id > 0)
                      .sort((a, b) => a.ordering - b.ordering)
                      .map((obj1, j) => (
                        <Rnd
                          className={"mediaFile ui pageBtn"}
                          key={'catFile_' + j}
                          id={"page_" + obj1.id}
                          style={{'cursor': "default"}}
                          lockAspectRatio={true}
                          enableResizing={false}
                          dragAxis={'y'}
                          default={{
                            x: 0,
                            y: j*30,
                            height: 30
                          }}
                          position={{
                            x: 0,
                            y: j*30,
                          }}
                          onClick={(e) =>{
                            if (isDragging.current === false) {
                              setTimeout(() => {
                                e.target.classList.add('dragging');
                              }, 100);
                              setModuleObj(obj1)
                              
                            }
                          }}
                          onDragStart={(e, d) => { 
                            originY.current = parseInt(round30(d.y)/30)
                            console.log('onDragStart', originY.current, d.y, rnds.current)
                          }}
                          onDrag={(e, d) => {
                            isDragging.current = true;
                            setTimeout(() => {
                              e.target.classList.add('dragging');
                            }, 100);
                          }}
                          onDragStop={(e, d) => {                          
                            // setDocuments(reordered)
                            
                            
                            reOrder(files, obj1, d, setFiles)

                            setTimeout(() => {
                              isDragging.current = false;
                            }, 200);
                
                          }}
                          >
                          <span
                          className="layerIconContainer"
                        
                          >
                        
                            {obj1.document && fileNameType(obj1.document, obj1.caption).map((obj2, j) => (
                              <span key={"fileDocName_" + j}>{obj2}</span>))}
                          </span>
                        </Rnd>
                      ))
                    }
                    </div>
                    
                    </div>
                  
              </div>
            </nav>
            <div className="core">
  
              <div className="twoCol">
                  <div className="flex">

                    {moduleObj && 
                      <div className="moduleDetail" id={"moduleDetail_" + moduleObj.id}>
                        
                        <div className="moduleContainer">
                          <div className="closeModal ui" onClick={() => {animModalOut(moduleObj.id) }}><Close className="icon" /></div>

                          <span className="file fileForm">
                            
             
                       
                                  {moduleObj.document ? <div>
                                    <div className="iconField">{ fileNameType(moduleObj.document, null).map((obj2, j) => (
                                      <span key={"fileDocName_" + j}>{obj2}</span>))} <a href={moduleObj.document && Config.mediaURL + moduleObj.document
                                  } target="_blank" rel="noreferrer">
                                      <div className="iconField ui fileOpen"><OpenObjLink className="icon" /></div>
                                      </a></div>
                                    <span className="btn1 autoWidth" style={{marginTop: "20px"}} onClick={() => document.getElementById("inputField_file_" + moduleObj.id).click()}>Replace File</span></div> : <span className="btn1 autoWidth" onClick={() => document.getElementById("inputField_file_" + moduleObj.id).click()}>Add File</span>}
                                  <input type="file" style={{display: "none"}} autoComplete="off" id={"inputField_file_" + moduleObj.id} accept={"image/png, image/jpeg, image/webpm, .pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"} onChange={(e) => handleModuleMedia(e, 'file')}  />
                          
                          
                            <input type="file" style={{display: "none"}} autoComplete="off" id={"inputField_image_" + moduleObj.id} accept={"image/png, image/jpeg, image/webp"} onChange={(e) => handleModuleMedia(e, 'image')}  />
                          </span>
                          

                          <div className="moduleSettings">
                            <div className="groupLabel"><p>File Settings</p></div>
                            

                            <div className="settingsRow">
                              <div className="moduleHeading" id={"inputField_caption" + moduleObj.id}>
                              <div className="fieldRow">
                                  <div className="errors"></div>
                          
                                    <div className="canvasLabel">Title</div>
                                    {moduleObj.id ? <input type="text" autoComplete="off" id="caption" defaultValue={moduleObj.caption} onKeyUp={(e) => handleOnKeyUp(e, 'caption', 'file', null)} />: moduleObj.caption}
                                    
                                
                              </div>
                              
                              </div>
                            </div>
                            
                            <div className="settingsRow">
                            <div className="moduleHeading" id={"inputField_thumbnail" + moduleObj.id}>
                            <div className="fieldRow">
                                <div className="errors"></div>
                        
                                  


                                  <div className="canvasLabel">Thumbnail</div>
                                  {moduleObj.image ? <img src={Config.mediaURL + moduleObj.image} alt="" className="thumbnail" onClick={() => document.getElementById("inputField_image_" + moduleObj.id).click()} /> : <span className="btn1 autoWidth" onClick={() => document.getElementById("inputField_image_" + moduleObj.id).click()}>Add Thumbnail</span>}
                                  <input type="file" style={{display: "none"}} autoComplete="off" id={"inputField_image_" + moduleObj.id} accept={"image/png, image/jpeg, image/webp"} onChange={(e) => handleModuleMedia(e, 'image')}  />
                      
                              
                            </div>

                   

                            
                            
                          
                            </div>

                            {moduleObj.active && <div className="settingsRow deleteRow">             
                              <div className="iconField ui" onClick={() => handleModuleSave('delete', true)}><Delete className="icon"/> <div className="canvasLabel">Delete File</div>
                              </div>
                            </div>}     

                          </div>
                            


                          </div>
              
                          
                        </div>
                      </div>
                      
                    }
                    <div>
                    <div className="images documents">
                    {files && files
                      .filter(z => !z.id)
                      .sort((a, b) => a.ordering - b.ordering)
                      .map((file, i) => (
                      <div 
                          onClick={() => 
                            
                            {
                              if (i !== 0) {
                                setModuleObj(file)
                              }
                            
                          }
                          
                          }
                          key={'gallery_file_' + i} 
                          className={"thumbnail ui"}
                      >
                      {file.image ? <img key={'gallery_image_' + i}  src={Config.mediaURL + file.image} alt="" /> : <figure className="thumbnail ui"/>}
                      {(file.caption) && 
                          <div 
                          className="thumbnailCaption"
                          >{file.caption}</div> 
                         }
                      </div>
                      ))}
                      
                    {files && files
                      .filter(z => z.id > 0)
                      .sort((a, b) => a.ordering - b.ordering)
                      .map((file, i) => (
                      <div 
                          onClick={() => 
                            setModuleObj(file)
                          }
                          key={'gallery_file_' + i} 
                          className={"thumbnail ui"}
                      >
                      {file.image ? <img src={Config.mediaURL + file.image } alt="" /> : <figure className="thumbnail ui"/>}
                      {(file.caption) && 
                          <div 
                          className="thumbnailCaption"
                          >{file.caption}</div> 
                         }
                      </div>
                      ))}
                    </div>
                
                  </div>
                  </div>
   
                </div>
            </div>     
           
          

         </div>}
        

      </section>
    );
}

export default Module;
// {(showDeleteSection)?(<div id="errorPopup">Are you sure you want to delete this page? <div className="fullRow errorRow"><span onClick={() => handleDeleteSection('cancel')}>[Cancel]</span> <span onClick={() => handleDeleteSection('continue')}>[Continue]</span></div></div>):null}