import React, { useState, useEffect } from "react";
import {
    useNavigate} from "react-router-dom";
import axios from "axios";
import Cookies from 'js-cookie';
import gsap from "gsap";
import Config from "../Config.json"
import Loader from "../static/svg/loader.svg";
import Modal from './module.modal';
import MenuList from './module.menulist';
// Icons
import {ReactComponent as OpenLink} from "../static/svg/openLink-w.svg";
import {ReactComponent as ArrowBack} from "../static/icons/arrow_back.svg";
import {ReactComponent as PhotoAdd} from "../static/icons/add_photo.svg";
import {ReactComponent as Image} from "../static/icons/image.svg"
import {ReactComponent as Video} from "../static/icons/video.svg"
import {ReactComponent as Svg} from "../static/icons/svg.svg"
import {ReactComponent as AddBox} from "../static/icons/add_box.svg"

function Module(props) {
  const navigate = useNavigate();
//   let { id } = useParams();
  const id = 1;
  const originY = React.useRef();
  const originYpx = React.useRef();
  const isDragging = React.useRef();
  const rnds = React.useRef([]);
  const tempAfterObjs = React.useRef(null);
  const [module, setModule] = useState(null);
  const [moduleObj, setModuleObj] = useState(null);
  const [modules, setModules] = useState([]);
  const [categories, setCategories] = useState();
  const [files, setFiles] = useState([]);
  const [showError, setShowError] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [animOpen, setAnimOpen] = useState(false);
  const [dropDown, setDropDown] = useState();
  const [editField, setEditField] = useState(null);
  const [showAnimDropDown, setShowAnimDropDown] = useState(false);
  const [showCategoryForm, setShowCategoryForm] = useState(false);
  const [activeCategory, setActiveCategory] = useState();
  const [formError, setFormError] = useState();
  const [openParentId, setOpenParentId] = useState(null);
  const [assignCatId, setAssignCatId] = useState();

  const fileTypes = ['image', 'video','vr']
  const rotations = ['horizontal', 'vertical','360']

  useEffect(() => {
    props.setTools("canvas")
    fetchJSON(null);
    
    // login();
  }, []);

  useEffect(() => {

    if (moduleObj) {
      animModalIn(moduleObj.id)
      if (assignCatId) {
        handleModuleSave('filecategory', assignCatId)
      }
     
    }
  }, [moduleObj])

  useEffect(() => {
    module && setCategories(module.all_filecategories)
    module && setFiles(module.files_nocat)
    moduleObj && setModuleObj(module.files.find(obj => obj.id === moduleObj.id))
  }, [module])
  

  useEffect(() => {
    categories && console.log('categories', categories)
    if (categories && (categories.length === 0)) {
      setShowCategoryForm(true)
    } else {
    
      setShowCategoryForm(false)
    }
  }, [categories])

  useEffect(() => {
    if (dropDown) {
      animDropDown(true)
    } else {
      animDropDown(false)
    }
  }, [dropDown])

  function handleBackBtn(){
    navigate('/narratives/')
    setShowError(false);
  }

  function animDropDown(open){
    if (open) {
      // let parent = document.getElementById('settingsRow_' + dropDown).getBoundingClientRect().x;
      let parent = document.getElementById('settingsRow_' + dropDown).offsetLeft;
      let dropDownElem = document.getElementById("inputField_" + dropDown + "_" + moduleObj.id ).offsetLeft;
      let offsetLeft = parent - dropDownElem;
      // console.log('offsetLeft', offsetLeft);
      // gsap.to(dropDownElem, {y: offsetLeft, duration: 0});
      var tl = gsap.timeline();
      tl.to("#inputField_" + dropDown + "_" + moduleObj.id + " .dropDownChoices", {height: "auto", duration: 0});
      tl.to("#inputField_" + dropDown + "_" + moduleObj.id, {zIndex: 3, x: offsetLeft, height: "auto", duration: 0}, "<");
      tl.to("#inputField_" + dropDown + "_" + moduleObj.id, {scale: 1, opacity: 1, duration: 0.1});
    } else {
      gsap.to(".dropDownChoices", {height: 0, duration: 0});
      gsap.to(".dropDownField", {opacity: 0, scale: 0, zIndex: -1, duration: 0.1});
    }
  }

  function getModelFileType(string) {
    let fileType;
    var ext = string.split('.').pop();
    switch (ext) {
      case "png":
        fileType = "image";
        break;
      case "jpg":
        fileType = "image";
        break;
      case "jpeg":
        fileType = "image";
        break;
      case "webp":
        fileType = "image";
        break;
      case "mp4":
        fileType = "video";
        break;
      case "svg":
        fileType = "image";
        break;
      default:
        break;
    }
    return fileType;
  }

  function getFileType(string) {
    let fileType;
    var ext = string.split('.').pop();
    switch (ext) {
      case "png":
        fileType = "img";
        break;
      case "jpg":
        fileType = "img";
        break;
      case "jpeg":
        fileType = "img";
        break;
      case "webp":
        fileType = "img";
        break;
      case "mp4":
        fileType = "vid";
        break;
      case "svg":
        fileType = "svg";
        break;
      default:
        break;
    }
    return fileType;
  }
  

  function fileNameType(string, layerName) {
    let x0 = string.replace('learn/', '');
    var ext = string.split('.').pop();
    let x2;
    if (layerName) {
      x2 = layerName;
    } else {
      x2 = x0.replace('.'+ ext, '');
    }
  
    // console.log('fileNameType', ext, x2, string);
    let icon;
    switch (ext) {
      case "png":
        icon = <Image className="icon" />
        break;
      case "jpg":
        icon = <Image className="icon" />
        break;
      case "jpeg":
        icon = <Image className="icon" />
        break;
      case "webp":
        icon = <Image className="icon" />
        break;
      case "svg":
        icon = <Svg className="icon" />
        break;
      case "mp4":
        icon = <Video className="icon" />
        break;
      default:
        break;
    }
    if (x2.length > 18) {
      x2 = x2.substring(0,18) + ' ...'
    }
    let result = [icon, x2];
    return result;
  }

  let moduleObjs = [];

  function animColorPicker(open, id){
    if (open !== id) {
      var tl = gsap.timeline();
      tl.to("#" + id, {height: "auto", duration: 0});
      tl.to("#" + id, {zIndex: 3, height: "auto", duration: 0}, "<");
      tl.to("#" + id, {scale: 1, opacity: 1, duration: 0.1});
      setAnimOpen(id);
    } else {
      gsap.to("#" + id, {height: 0, duration: 0});
      gsap.to("#" + id, {opacity: 0, scale: 0, zIndex: -1, duration: 0.1});
      setAnimOpen(null);
    }
  }

  // function fetchPage(path) {
  //   try {
  //     axios({
  //         method: "get",
  //         url: path,
  //         // headers: {
  //         //   Authorization: 'Token ' + Cookies.get('ud_token')
  //         // },
  //     }).then((response) => {
  //         const assetJSON = response.data;
  //         console.log('fetchPage', assetJSON);
  //         let modulesNew = [ ...modules ];
  //         modulesNew.push(assetJSON);
  //         setModules(modulesNew);
  //         console.log('module', assetJSON, 'modules', modulesNew);
  //     });
  //   } catch (error) {
  //       console.error(error);
  //   } 
    
  // }
  
  function getModules(paths) {
    let moduleObjs = [];
    for(var i = 0; i < paths.length; i++) {            
      try {
        axios({
            method: "get",
            url: paths[i],
            headers: {
              'Authorization': 'Token ' + Cookies.get('_udxdid')
            },
        }).then((response) => {
            const objJSON = response.data;
            moduleObjs.push(objJSON);

            if (paths.length === moduleObjs.length) {
              setModules(moduleObjs);
              console.log('modules', moduleObjs, paths.length)
            }
           
        });
      } catch (error) {
          console.error(error);
      }  
    }
   
    return paths.length === moduleObjs.length;
  }

  function fetchJSON(objId) {
 
    try {
        axios({
            method: "get",
            url: Config.baseURL + "/api/cms/learn/" + id,
            headers: {
              'Authorization': 'Token ' + Cookies.get('_udxdid')
            },
        }).then((response) => {
            const assetJSON = response.data;
            setModule(assetJSON);
            setShowLoader(false);
            if (objId) {
              setModuleObj(assetJSON.files.find(obj => obj.id === objId))
              // console.log('objId', objId)
            }
            if (activeCategory) {
              setActiveCategory(assetJSON.categories.find(obj => obj.id === activeCategory.id))
            }
            // async function modulesLoaded(){
            //   await getModules(assetJSON.modules);
            //   setShowLoader(false);
            // };
            // modulesLoaded();
           
            
        });
    } catch (error) {
        console.error(error);
    }
}

    function handleAddFile(catId) {
      setShowLoader(true)
      const api_url = Config.baseURL + '/api/cms/learn/' + id + "/";
      var dataJSON = {};
      dataJSON['status'] = "new"
      axios({
          method: 'POST',
          url: api_url,
          data: dataJSON,
          headers: {
            'Authorization': 'Token ' + Cookies.get('_udxdid')
          },
      })
      .then(response => {
          console.log('add file response', response.data['id'])
          fetchJSON(response.data['id']);
          if (catId){
            console.log('catId', catId)
            setAssignCatId(catId);
            
          }
      })
      .catch(error => {
          console.log('error', error.response);
      });
    }

    function handleAddCategory(name) {
      setShowLoader(true)
      const api_url = Config.baseURL + '/api/cms/learn/' + id + "/";
      var dataJSON = {};
      dataJSON['new-category'] = name
      axios({
          method: 'POST',
          url: api_url,
          data: dataJSON,
          headers: {
            'Authorization': 'Token ' + Cookies.get('_udxdid')
          },
      })
      .then(response => {
          fetchJSON(response.data['id']);
          setShowCategoryForm(false);
          handleAddFile(response.data['id']);

      })
      .catch(error => {
          console.log('error', error.response);
      });
    }

    function handleLearnMedia(e, fileName, objId){
   
      var fileList = e.target.files;
      var arrayLength = fileList.length;
      const api_url = Config.baseURL + '/api/cms/learn/' + id.replace('learn-', '') + "/";
    
      for (var i = 0; i < arrayLength; i++) {
    
        // File Checks
        let sizeMB = parseFloat(fileList[i].size / (1024 ** 2)).toFixed(2);
    
        if (sizeMB > Config.validations.maxFileSize) {
    
            let newError = {
              "heading": "Error",
              "text": "The selected file is <strong>" + sizeMB + "MB</strong>.<br/>This file exceeds maximum upload size of <strong>10MB</strong>.<br/><br/>Please select a new file that is less than 10MB."
            };
            setFormError(newError);
    
        } else {
    
            setShowLoader(true)
            var formData = new FormData();
            formData.append(fileName, fileList[i], fileList[i].name) 
            formData.append('learn', id.replace('learn-', ''))
          
            console.log(fileName, fileList[i], fileList[i].name)
    
    
            axios({
                method: 'POST',
                url: api_url,
                data: formData,
                headers: {
                  'Authorization': 'Token ' + Cookies.get('_udxdid')
                },
            })
            .then(response => {
                console.log('handleLearnMedia', response);
                fetchJSON();
            })
            .catch(error => {
                console.log('error', error.response);
            });
    
          }
        
    
      } 
    }
    

  

    function handleCategorySave(field, value, objId) {
      // setShowLoader(true)
      const api_url = Config.baseURL + '/api/cms/learn-category/' + objId + "/";
      var dataJSON = {};
      dataJSON['learn'] = id.replace('learn-', '')
      dataJSON[field] = value;
      console.log('handleCategorySave', dataJSON)
      axios({
          method: 'POST',
          url: api_url,
          data: dataJSON,
          headers: {
            'Authorization': 'Token ' + Cookies.get('_udxdid')
          },
      })
      .then(response => {
          console.log('handleCategorySave', response);
          let d = document.getElementById('pageBtnContainer_'+ response.data['id']);
          d && d.classList.remove('open')
          setDropDown(null);
          setEditField(null);
          fetchJSON(null);
         
      })
      .catch(error => {
          console.log('error', error.response);
      });
    }

    function handleLearnCategoryDelete(objId) {
      handleCategorySave('delete', true, objId)
    }

  function handleModuleSave(field, value) {
    // setShowLoader(true)
    const api_url = Config.baseURL + '/api/cms/learn-file/' + moduleObj.id + "/";
    var dataJSON = {};
    dataJSON['learn'] = id.replace('learn-', '')
    // let category = categories.find(obj => obj.id === moduleObj.filecategory);
    // let index;
    // if (category) {
    //   index = category.categoryfiles.findIndex(obj => obj.id === moduleObj.id);
     
    // }
    // if (index > -1) {
    //   dataJSON['ordering'] = index
     
    // }

    if (field === 'end_pos' && value === "") {
      value = null
    }
    
    dataJSON[field] = value;

   

    console.log('handleModuleSave', dataJSON)
    axios({
        method: 'POST',
        url: api_url,
        data: dataJSON,
        headers: {
          'Authorization': 'Token ' + Cookies.get('_udxdid')
        },
    })
    .then(response => {
        console.log('handleModuleSave', response);
        setDropDown(null);
        fetchJSON(null);
        setAssignCatId(null)
    })
    .catch(error => {
        console.log('error', error.response);
    });
  }

  

  function handleModuleMedia(e, fileName){
   
    var fileList = e.target.files;
    var arrayLength = fileList.length;
    const api_url = Config.baseURL + '/api/cms/learn-file/' + moduleObj.id + "/";

    for (var i = 0; i < arrayLength; i++) {

      // File Checks
      let sizeMB = parseFloat(fileList[i].size / (1024 ** 2)).toFixed(2);

      if (sizeMB > Config.validations.maxFileSize) {

          let newError = {
            "heading": "Error",
            "text": "The selected file is <strong>" + sizeMB + "MB</strong>.<br/>This file exceeds maximum upload size of <strong>10MB</strong>.<br/><br/>Please select a new file that is less than 10MB."
          };
          setFormError(newError);

      } else {

          setShowLoader(true)
          var formData = new FormData();
          formData.append(fileName, fileList[i], fileList[i].name) 
          formData.append('learn', id.replace('learn-', ''))
          formData.append('active', moduleObj.active)
          if (!moduleObj.file) {
            formData.append('file_type', getModelFileType(fileList[i].name))
          }
          
          console.log('moduleOb', moduleObj, fileName, fileList[i], fileList[i].name)

          axios({
              method: 'POST',
              url: api_url,
              data: formData,
              headers: {
                'Authorization': 'Token ' + Cookies.get('_udxdid')
              },
          })
          .then(response => {
              console.log('handleModuleMedia', response);
              fetchJSON(null);
          })
          .catch(error => {
              console.log('error', error.response);
          });

        }
      

    } 
  }

  

  function handleLearnReorderSave(obj, reload) {
    console.log('handleLearnReorderSave', obj, reload);      
    axios({
        method: 'POST',
        url: Config.baseURL + '/api/cms/learn-reorder/',
        data: {
            'filecategories': obj
          },
          headers: {
            'Authorization': 'Token ' + Cookies.get('_udxdid')
          },
    })
    .then(response => {
        console.log('handleLearnReorderSave: response', response);
        // isLast && handleReloadSection(response);
        // handleReloadSection(response);
        fetchJSON(null);
    })
    .catch(error => {
        console.log('error', error.response);
    });

  }

  function handleLearnReorderFileSave(obj, reload) {
    console.log('handleLearnReorderSave', obj, reload);      
    axios({
        method: 'POST',
        url: Config.baseURL + '/api/cms/learn-reorder/',
        data: {
            'files': obj
          },
        headers: {
          'Authorization': 'Token ' + Cookies.get('_udxdid')
        },
    })
    .then(response => {
        console.log('handleLearnReorderSave: response', response, obj);
        // isLast && handleReloadSection(response);
        // handleReloadSection(response);
        // fetchJSON()
        try {
          setOpenParentId(obj[0].filecategory)
          console.log(obj[0].filecategory)
        } catch (error) {
          
        }
       
        fetchJSON(null);
   
        
    })
    .catch(error => {
        console.log('error', error.response);
    });
  }

  function handleThemeCss(field, value){
    let newCss;
    if (module.css) {
      newCss = module.css;
    } else {
      newCss = {};
    }
    if (value) {
      newCss[field] = value;
    } else {
      delete newCss[field]; 
    }
    handleModuleSave('css', newCss);

  }

  function animModalIn(objId){
    let target = "#moduleDetail_" + objId;
    gsap.to(target, {opacity: 1, duration: 0.2})
  }

  function animModalOut(objId){
    let target = "#moduleDetail_" + objId;
    gsap.to(target, {opacity: 0, duration: 0.2, onComplete: setModuleObj(null)});
  }

  function round30(x) {
    let y = Math.ceil(x / 30) * 30;
    // console.log('y', y);
    return y;
}

function reOrderForOpen(array, obj, setObj, height) {
  
  let newArray = [ ...array ];   
  let yObj = newArray.find(obj0 => obj0.id === obj.id);
  if (yObj) {
    if (height) {
      let afterNumberCopy = [];
      let afterNumber = newArray.filter(obj0 => ((obj0.ordering > yObj.ordering)&&(obj0.id !== yObj.id)));
      afterNumber.map(obj => afterNumberCopy.push({id: obj.id, ordering: obj.ordering}));
      tempAfterObjs.current = afterNumberCopy;
      
      afterNumber.forEach((obj, i) => {
        obj.ordering = (height/30)+i+1
      });
      let reordered = newArray.sort((a, b) => a.ordering - b.ordering);
      setObj(reordered);
      
    } else {
      tempAfterObjs.current && tempAfterObjs.current.forEach((z) => {
        let match = newArray.find(a => a.id === z.id)
        match['ordering'] = z.ordering
      });
      
      let reordered = newArray.sort((a, b) => a.ordering - b.ordering);
      setObj(reordered);
    }
  }
}

 
function reOrder(array, obj, d, setObj) {
  let reordered;
  let newArray = [ ...array ];   
  let yObj = newArray.find(obj0 => obj0.id === obj.id);

  if (yObj) {
    
    if (parseInt(round30(d.y)/30) !== yObj.ordering) {
      
      let yObj_posY;
      if (d.y/30 === 0) {
        yObj_posY = d.y;
      } else {
        yObj_posY = round30(d.y);
      }

      let lastNumber = newArray.length - 1;
                        
      let newOrdering = parseInt((yObj_posY/30))-1;
      if (newOrdering > lastNumber) {
        newOrdering = lastNumber
      }
      
      if (newOrdering < 0) {
        newOrdering = 0
      }
      let beforeNumber = [];
      let afterNumber = [];
      let sameNumber;
      if (newOrdering === 0) {
        console.log('%cFirst', 'color: red; font-weight: bold;');
        afterNumber = newArray.filter(obj0 => ((obj0.ordering >= newOrdering)&&(obj0.id !== yObj.id)));
        // sameNumber && (sameNumber['ordering'] = 1);

      } else if (newOrdering === (lastNumber)) {
        console.log('%cLast', 'color: green; font-weight: bold;');
        beforeNumber = newArray.filter(obj0 => ((obj0.ordering <= newOrdering)&&(obj0.id !== yObj.id)));
        // sameNumber = newArray.find(obj0 => ((obj0.ordering === newOrdering)&&(obj0.id !== yObj.id)));
        // sameNumber && (sameNumber['ordering']=newArray.length-1);
        
      } else {
        console.log('%cMiddle', 'color: gold; font-weight: bold;');
        beforeNumber = newArray.filter(obj0 => ((obj0.ordering < newOrdering)&&(obj0.id !== yObj.id)));
        afterNumber = newArray.filter(obj0 => ((obj0.ordering > newOrdering)&&(obj0.id !== yObj.id)));
        sameNumber = newArray.find(obj0 => ((obj0.ordering === newOrdering)&&(obj0.id !== yObj.id)));
        if ((newOrdering > originY.current)) {
          sameNumber && (sameNumber['ordering']=newOrdering-1);
        } else {
          sameNumber && (sameNumber['ordering']=newOrdering+1);
        }

      }
    
      beforeNumber.forEach((obj, i) => {
        obj.ordering = i
      });
      
      afterNumber.forEach((obj, i) => {
        obj.ordering = newOrdering+i+1
      });

      console.log('newOrdering', newOrdering)
      console.log('beforeNumber', beforeNumber)
      console.log('sameNumber', sameNumber)
      console.log('afterNumber', afterNumber)

      yObj.ordering = newOrdering;
      originY.current = null;
      reordered = newArray.sort((a, b) => a.ordering - b.ordering);
      let reorderedCopy = [ ...reordered ];

      reorderedCopy.forEach((obj, i) => {
        obj.ordering = i
      })
      console.log('onDragStop', yObj.ordering, reorderedCopy)

      
    
      setObj(reorderedCopy);

    }

    
  }


  return reordered
}

function handleShowAnimDropDown() {
  if (showAnimDropDown) {
    setShowAnimDropDown(false);
  } else {
    setShowAnimDropDown(true);
  }
}

function handleDropDown(e, field) {
  if (field === dropDown) {
    setDropDown(null); 
  } else {
    setDropDown(field)
  }
}

function handleCategoryMedia(e, fileName, objId){
   
  var fileList = e.target.files;
  var arrayLength = fileList.length;
  const api_url = Config.baseURL + '/api/cms/learn-category/' + objId + "/";

  for (var i = 0; i < arrayLength; i++) {

    // File Checks
    let sizeMB = parseFloat(fileList[i].size / (1024 ** 2)).toFixed(2);

    if (sizeMB > Config.validations.maxFileSize) {

        let newError = {
          "heading": "Error",
          "text": "The selected file is <strong>" + sizeMB + "MB</strong>.<br/>This file exceeds maximum upload size of <strong>10MB</strong>.<br/><br/>Please select a new file that is less than 10MB."
        };
        setFormError(newError);
        setShowLoader(false);

    } else {

        setShowLoader(true)
        var formData = new FormData();
        formData.append(fileName, fileList[i], fileList[i].name) 
        formData.append('learn', id.replace('learn-', ''))
   


        axios({
            method: 'POST',
            url: api_url,
            data: formData,
            headers: {
              'Authorization': 'Token ' + Cookies.get('_udxdid')
            },
        })
        .then(response => {
            console.log('handleCategoryMedia', response);
            fetchJSON();
        })
        .catch(error => {
            console.log('error', error.response);
        });

      }
    

  } 
}

function handleOnKeyUp(e, field, group, objId) {
    if (group === "file") {
      if (e.key === "Enter") {
        handleModuleSave(field, e.target.value);
        }
    }
    if (group === "category") {
      if (e.key === "Enter") {
        handleCategorySave(field, e.target.value, objId);
        }
    }
   

}

function handleActiveCategory(obj) {
  if (activeCategory && (activeCategory.id === obj.id)) {
    setActiveCategory(null)
  } else {
    setActiveCategory(obj)
  }
  
}

function onModuleObj(obj) {
    if (obj.fileType === 'pdf' && obj.file) {
        window.open(Config.mediaURL + obj.file);
    } else {
        setModuleObj(obj)
    }
}


    return (
        <section className="main" id="learnPage">
        {(showLoader)?(<div id="loading"><img src={Loader} alt="" /></div>):null}

        {(formError)&&<Modal heading={formError.heading} text={formError.text} onBtn1Click={setFormError} onBtn1={null} btn1Text={"Close"} onBtn2Click={null} onBtn2={null} btn2Text={null} />}

        {(showError)&&(<div id="errorPopup"><div className="modal"><span><h3>Unsaved Changes</h3><p>Are you sure you want to go back?</p></span> <div className="fullRow errorRow"><span onClick={() => handleBackBtn('cancel')}>Cancel</span> <span onClick={() => handleBackBtn('force')}>Continue</span></div></div></div>)}
        {(showCategoryForm)&&(<div className="modalContainer"><div className="modal"><span><h3>New Category</h3>

        <div className="settingsRow">
          <input type="text" autoComplete="off" id="newCategoryName" autoFocus placeholder="Add Category Name" />
        </div>

        </span> <div className="fullRow errorRow"><span onClick={() => {
          setShowCategoryForm(false)
        }}>Cancel</span> <span onClick={() => handleAddCategory(document.getElementById('newCategoryName').value)}>Continue</span></div></div></div>)}
        
        
        {module && <div className="col appGrid">

        <div id="narrativeTools">

          <h1>{module.title}</h1>


          </div>
            <nav className="leftNav">
            
  
              <div className="leftArrow iconField" onClick={() => handleBackBtn()}>
                <ArrowBack className="icon" /> 
  
                <div className="pages">Back</div>
              </div>

              <div className="pages fieldRow modulePages">
                  <div  className="sectionRows" style={{display: "none"}}>
                    <div className="sectionTools"><div className="sectionLabel">Categories</div><AddBox className="icon ui" title="Select Category" onClick={() => setShowCategoryForm(true)} /></div>
                  </div>
                    
                  <div className="groupCategories">

                    {categories &&  <MenuList 
                      baseObjs={categories} 
                      subObjsField={"categoryfiles"}
                      headingField={"name"}
                      subField={"caption"}
                      parentField={"filecategory"}
                      linkField={"file"}
                      subIsMedia={true}
                      setBaseObjs={setCategories}
                      handleParentClick={null}
                      handlePageClick={setModuleObj}
                      handleSave={null}
                      handleSubSave={null}
                      deleteParent={null}
                      elevateSubs={false}
                      offsetTop={files ? (files.length * 30 + 140 + "px") : 140 + "px"}
                      showPageCount={false}
                      openParentId={null}
                      disableDragging={true}
                      />}

                  </div>
           
              </div>
            </nav>
            <div className="core">
  
              <div className="twoCol">
                  <div className="flex">

                    {moduleObj && 
                      <div className="moduleDetail learnDetail" id={"moduleDetail_" + moduleObj.id}>
                        
                        <div className="learnContainer">
                          <div className="leftArrow iconField backBtn ui" onClick={() => {animModalOut(moduleObj.id) }}>
                            <ArrowBack className="icon" />   
                            Back
                        </div>

                          {(moduleObj.file && getFileType(moduleObj.file) === 'vid') && <span className="learnVideo">
                          <video width="100%" height="auto" autoPlay loop controls>
                            <source src={Config.mediaURL + moduleObj.file} type="video/mp4" />
                          </video>
                        </span>}

                      
              
                          
                        </div>
                      </div>
                      
                    }
                    <div className="categoryRows">
                    <div className="categoryRow">
                        <div className="files images">
                    {files && files
                      .sort((a, b) => a.ordering - b.ordering)
                      .map((file, i) => (
                        

                      <div 
                          onClick={() => setModuleObj(file)}
                          key={'learn_file_' + i} 
                          className={"thumbnail ui"}
                      >
                      {file.thumbnail ? <img src={Config.mediaURL + file.thumbnail} alt="" /> : <figure className="emptyThumbnail ui"/>}
                      {(file.caption) ? 
                          <div 
                          >{file.caption}</div> 
                          : null}
                      </div>
                      ))}
                     
                  
                      </div>
                      </div>

                    {categories && categories
                      .sort((a, b) => a.ordering - b.ordering)
                      .map((category, i0) => (
                        <div className="categoryRow learnRow" key={"catFiles_" + i0}>
                        <h2>{category.name}</h2>
                        <div className="files images">
                        
                      {category && category.categoryfiles && category.categoryfiles
                      // .filter(a => a.filecategory === file.id)
                      .sort((a, b) => a.ordering - b.ordering)
                      .map((file, i) => (
                      <div 
                          onClick={() => onModuleObj(file)}
                          key={'learn_file_' + i} 
                          className={"thumbnail ui"}
                      >
                      {file.thumbnail ? <img src={Config.mediaURL + file.thumbnail} alt="" /> : <figure className="emptyThumbnail ui"/>}
                      {(file.caption) ? 
                          <div 
                            className="learnCaption"
                          >{file.fileType === 'pdf' && <span className="icon"><OpenLink className="icon" /></span>}</div> 
                          : null}
                      </div>
                      ))}
                      </div>
                      </div>
                      ))}
                    </div>
                
                  </div>
                  
                </div>
            </div>     
           
          

         </div>}
        

      </section>
    );
}
export default Module;