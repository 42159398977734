import React, { useState, useEffect, useRef } from "react";
import Config from "../../Config.json";
import MenuList from '../module.menulist';
import Icons from '../module.icons';
import { apiCall } from "../narrativeUtils/fetch";
import { Rnd } from "react-rnd";
import {fileNameType, round30} from "../../utils/helpers";

import {
    useNavigate
  } from "react-router-dom";


function LeftNav(props) {

    const navigate = useNavigate();
    const rnds = React.useRef([]);
    const originY = React.useRef();
    const originYpx = React.useRef();
    const isDragging = React.useRef();


    function handleNarrativeHeadingEdit(field, value, objId) {
        console.log('handleNarrativeHeadingEdit', props.activeNarrativeHeading, field, value, objId)
        if (props.activeNarrativeHeading || objId) {
          let objIndex;
          let title = value;
          let newObjs = [...props.narrativeHeadings];
          if (objId) {
            objIndex = newObjs.findIndex(x => x.id === objId );
          } else {
            objIndex = newObjs.findIndex(x => x.id === props.activeNarrativeHeading.id );
          }
          
          let newObj = newObjs[objIndex];
  
  
          console.log('newObj', newObj)
          if (newObj) {
            newObj[field] = value;
            props.setNarrativeHeadings(newObjs);
          }
    
          const api_url = Config.baseURLcms + 'narrativeheading-edit/';
          let formData = {}
          if (objId) {
            formData['id'] = objId
          } else {
            formData['id'] = props.activeNarrativeHeading.id
            console.log(props.activeNarrativeHeading.id, value)
          }
          
          formData[field] = value
          function responseTask() {
            props.fetchJSON(null, true)
          }
  
          apiCall("POST", api_url, formData, responseTask)
  
        }
        
      }

    function handleConvertToGroup(method, objID) {
      console.log('handleConvertToGroup', props.multiSelectPageIds, objID)
      const api_url = Config.baseURLcms + 'narrativeheading-group/';
      let formData = {}
        if(method === 'ungroup') {
          if (props.multiSelectPageIds && props.narrative) {
            formData['method'] = 'ungroup'
            formData['narrativeheading_ids'] = objID
            formData['narrative_id'] = props.narrative.id
          } 
          function responseTask() {
            props.setMultiSelectPageIds([]);
            props.fetchJSON(null, true)
          }
          apiCall("POST", api_url, formData, responseTask)
        } else {
          if (props.multiSelectPageIds && props.narrative) {
            formData['method'] = 'group'
            formData['narrativeheading_ids'] = props.multiSelectPageIds
            formData['narrative_id'] = props.narrative.id
          } 
          function responseTask() {
            props.setMultiSelectPageIds([]);
            props.fetchJSON(null, true)
          }
          apiCall("POST", api_url, formData, responseTask)
        }
        
        
    }

    function handleCloseSettings() {
        if (props.activeSettingId) {
            props.setActiveSettingId(null);
          // props.setShowManifest(false);
        } 
        
      }

      function handleNarrativeHeadingsReorderSave(obj, reload) {
             
        let api_url = Config.baseURLcms + 'section-reorder/';
        let dataJSON = {'narrativeheadings': obj};
        console.log('handleNarrativeHeadingsReorderSave', obj, reload, dataJSON); 
        function responseTask() {
          props.fetchJSON(null)
        }
        apiCall('POST', api_url, dataJSON, responseTask)
  
      }
    

    // function handlePageClick(menuItemObj){

    //     console.log('handlePageClick', menuItemObj)
        
    //     handleCloseSettings();
    //     if (menuItemObj.page) {
    //         props.setSectionId(menuItemObj.page);
    //         props.setActiveNarrativeHeading(menuItemObj);
    //     } else if (menuItemObj.page === null){
    //         props.setActiveNarrativeHeading(menuItemObj);
    //     } else {
    //         props.setSectionId(menuItemObj.id);
    //     }

    //     props.setActiveMenuItem(menuItemObj)
    //   }

    function handleOnActive(objID, index) {
        // console.log(objID, index);
        props.setActiveSettingId(objID)
        var newComponents = [...props.componentDimensions];
        var newIndex = newComponents.findIndex(item => item.id === objID);
        var newObj = newComponents[newIndex];
        var obj = newObj['active'];
        if (obj === true) {
          newObj['active'] = false;
  
        } else {
          newObj['active'] = true;
        }
        props.setComponentDimensions(newComponents);
  
        console.log(newObj['active'], objID)
        props.setUnSavedChanges(true);
    }

    function handleOnLock(objID, index) {
      console.log(objID, index);
      props.setActiveSettingId(objID)
      var newComponents = [...props.componentDimensions];
      var newIndex = newComponents.findIndex(item => item.id === objID);
      var newObj = newComponents[newIndex];
      var obj = newObj['change_lock'];
      if (obj === true) {
        newObj['change_lock'] = false;

      } else {
        newObj['change_lock'] = true;
      }
      props.setComponentDimensions(newComponents);

      console.log(newObj['change_lock'], objID)
      props.setUnSavedChanges(true);
  }
  

    function handleBackBtn(step){
        if (step === 'step1') {
          props.setShowError(true);
        } 
        if (step === 'cancel') {
            props.setShowError(false);
            props.setShowDeleteSection(false)
        } 
       
        if (step === 'force') {
          if (!props.showPages) {
            console.log('handleBackBtn: force')
            props.setShowPages(true);
            props.setActiveSettingId(null);
            
            props.setShowError(false);
          } else {
            navigate('/narratives/' + props.narrative.id)
            props.setShowError(false);
            
          }
        } 
      }


    function handleLayerReorderSave(obj) {
 
        let api_url = Config.baseURLcms + 'layers-reorder/';
        let dataJSON = {'layers': obj};
        function responseTask() {
          console.log('handleLayerReorderSave: response')
        }
        apiCall('POST', api_url, dataJSON, responseTask)

    }

    function handleNarrativeHeadingDelete(objId) {
        const api_url = Config.baseURLcms + 'narrativeheading-delete/';
  
        console.log(objId)
        function responseTask() {
          props.setShowLoader(true);
          props.setActiveSectionObj(null);
          props.setActiveSettingId(null);
          props.fetchJSON(null, true);
        }
        apiCall('POST', api_url, {'id': objId}, responseTask)
  
      }
    

    return (
        <div className="leftNav">
        {/* Left Nav Starts */}

        <div className="leftArrow iconField" onClick={() => handleBackBtn((props.unSavedChanges)?('step1'):('force'))}>
          <Icons.ArrowBack className="icon" /> 
          
          {!props.showPages && "Navigation || Pages" }
          {props.showPages && <div className="pages">Presentation Overview</div>}
        </div>

        <div className="sectionRows">
          <div className="fieldRow sectionHeading">
            {!props.showPages && <div className="layers fieldRow">
              <div className="mainLayers">
                  {props.componentDimensions
                    // .filter(obj0 => obj0.background === false)
                    .sort((a, b) => b.component_ordering - a.component_ordering)
                    .map((obj, i) => 
                    (obj) ?
                    (<Rnd
                      
                      key={'settingFile_' + i}
                      style={{'cursor': "default"}}
                      lockAspectRatio={true}
                      enableResizing={false}
                      dragAxis={'y'}
                      default={{
                        x: 0,
                        y: ((i) * 30),
                        height: 20
                      }}
                      ref={c => {
                        let array = [ ...rnds.current ];
                        let exists = array[i];
                        if (exists) {
                          array[i].rnd = c
                          array[i].id = obj.id
                          array[i].height = 20
                        } else {
                          let newObj = {}
                          newObj['rnd'] = c
                          newObj['id']= obj.id
                          newObj['height'] = 20
                          newObj['isOpen'] = false
                          
                          array.push(newObj)
                        }
                        rnds.current = array;
                      }}
                      position={{
                        x: 0,
                        y: (i) * 30
                      }}
                      onDragStart={(e, d) => { 
                        originY.current = parseInt(round30(d.y - 0)/30)
                        originYpx.current = d.y-0
                        // console.log('onDragStart', originYpx.current)
                      }}
                      onDrag={(e, d) => {
                        isDragging.current = true;
                      
                        setTimeout(() => {
                          e.target.classList.add('dragging');
                        }, 200);
                        
                      }}
                      onDragStop={(e, d) => {
                        let newLayers0 = [ ...props.componentDimensions ];
                        let newLayers = newLayers0.filter(obj => obj.background === false);
                        
                        let yObj = newLayers.find(obj0 => obj0.id === obj.id);
                        
                        
                        let isReady = (((d.y - originYpx.current) > 20)||((originYpx.current - d.y) > 20));
                        
                        // console.log('d.y, originYpx', d.y, originYpx.current, isReady)

                        if (yObj && isReady) {

                          // console.log('yObj', yObj.component_ordering)

                          // console.log("no match", yObj.component_ordering, (newLayers.length - parseInt(round30(d.y)/30)))
                          
                          if ((newLayers.length - parseInt(round30(d.y)/30)) !== yObj.component_ordering) {
                            let yObj_posY;
                            yObj_posY = newLayers.length - parseInt(round30(d.y)/30);
                                
                            let newOrdering = yObj_posY;

                            // console.log('newOrdering, length', newOrdering, newLayers.length);

                            let belowNumber = [];
                            let aboveNumber = [];
                            let sameNumber;
                            if (newOrdering === (newLayers.length)) {
                              // console.log('zTop');
                              belowNumber = newLayers.filter(obj0 => ((obj0.component_ordering < newOrdering)&&(obj0.id !== yObj.id)));
                              sameNumber = newLayers.find(obj0 => ((obj0.component_ordering === newOrdering)&&(obj0.id !== yObj.id)));
                              sameNumber && (sameNumber['component_ordering'] = (newLayers.length - 1));

                            } else if (newOrdering === 1) {
                              // console.log('zBottom');
                              belowNumber = newLayers.filter(obj0 => ((obj0.component_ordering > newOrdering)&&(obj0.id !== yObj.id)));
                              sameNumber = newLayers.find(obj0 => ((obj0.component_ordering === newOrdering)&&(obj0.id !== yObj.id)));
                              sameNumber && (sameNumber['component_ordering']=2);
                              
                            } else {
                              // console.log('middle');
                              belowNumber = newLayers.filter(obj0 => ((obj0.component_ordering < newOrdering)&&(obj0.id !== yObj.id)));
                              aboveNumber = newLayers.filter(obj0 => ((obj0.component_ordering > newOrdering)&&(obj0.id !== yObj.id)));
                              sameNumber = newLayers.find(obj0 => ((obj0.component_ordering === newOrdering)&&(obj0.id !== yObj.id)));
                              // console.log('middle: a, b', aboveNumber, belowNumber, newOrdering)
                              sameNumber && (sameNumber['component_ordering']=newOrdering-1);
                            }

                            
                          
                            belowNumber.forEach((obj, i) => {
                              if (i === newLayers.length) {
                                obj.ordering = newLayers.length
                              } else {
                                obj.ordering = i-1
                              }
                            });

                            aboveNumber.forEach((obj, i) => {
                              obj.ordering = newOrdering+i
                            });

                            // console.log('aboveNumber, belowNumber', belowNumber, aboveNumber);
                            
                            yObj.component_ordering = newOrdering;
                            originY.current = null;
                            

                            let backgroundLayer = newLayers0.find(obj => obj.background === true);
                            let reordered = newLayers.sort((a, b) => a.component_ordering - b.component_ordering);
                            let reorderedCopy = [ ...reordered ];
                            reorderedCopy.unshift(backgroundLayer);
                            reorderedCopy.forEach((obj, i) => {
                              if (obj.background === true) {
                                obj['component_ordering'] = 0
                              } else {
                                obj['component_ordering'] = i
                              }
                              })
                            
                            // console.log('reorderedCopy', reorderedCopy)
                            props.setComponentDimensions(reorderedCopy)
                            
                            let layersArray = [];
                            reorderedCopy.forEach((obj, i) => {
                              var layerObj = {}
                              layerObj['id'] = obj.component_id;
                              layerObj['component_ordering'] = obj.component_ordering;
                              layersArray.push(layerObj);
                              if (i === reorderedCopy.length - 1) {
                                handleLayerReorderSave(layersArray);
                                // console.log('layersArray', layersArray)
                              }
                            });
                          }
                        }

                        setTimeout(() => {
                          isDragging.current = false;
                          e.target.classList.remove('dragging');
                        }, 200);
                        
                      }}
                      >
                      {!obj.background && <div 
                        id={"settings_" + obj.id} 
                        data-index={i} 
                        className="layerName"
                        
                        data-ordering={obj.component_ordering}>
                      <div className={"mediaFile" + ((obj.id === props.activeSettingId)?" on":"")}>
                        <div className="iconField">
                        <span 
                        className="layerIconContainer"
                        onClick={() => 
                          {
                            if (!isDragging.current) {
                              originYpx.current = 0;
                              // handleManifestFileClick("card_" + obj.id)
                              if (obj.id !== props.activeSettingId) {
                                props.showSettings(obj.id, false, null)
                              } else {
                                props.setActiveSettingId(null);

                              }
                            }
                          }
                        }>

                       
                        {
                          (obj.file_format === "statePNGButton") ? 
                          (obj.layer_name ? (fileNameType(obj.name, obj.layer_name).map((obj2, j) => (
                            <span key={"fileName_" + j}>{obj2}</span>
                            ))) : (fileNameType(obj.name, "Tab " + (props.activeSectionObj.states.findIndex(o => o.id === obj.component_id) + 1)).map((obj2, j) => (
                              <span key={"fileName_" + j}>{obj2}</span>
                              )))

                          ):
                          (props.activeStateId && obj.componentdimensionstateimages.find(cdsi => parseInt(cdsi.state_id) === props.activeStateId) ? (
                            fileNameType(obj.componentdimensionstateimages.find(cdsi => parseInt(cdsi.state_id) === props.activeStateId).name, null).map((obj2, j) => (
                            <span key={"fileName_" + j}>{obj2}</span>
                            ))
                          
                          ):
                          (
                            fileNameType(obj.name, obj.layer_name).map((obj2, j) => (
                            <span key={"fileName_" + j}>{obj2}</span>
                            ))
                          
                          ))
                        }
                          
                        </span>
            
                        <span className={"visibility"} onClick={() => handleOnActive(obj.id, i)}>
                            {(obj.active)?<Icons.Visible className="icon" />:<Icons.VisibleOff className="icon" />}
                          
                        </span>
                  
                        <span className={"lock" + (obj.change_lock ? " on":"")} onClick={() => handleOnLock(obj.id, i)}>
                            <Icons.Lock className="icon" />
                          
                        </span>

                        </div>
                        
                      </div>
                  
                    </div>}
                    </Rnd>
                    ):"")}
              </div>
            </div>}
          </div>
          {props.showPages && props.narrativeHeadings && <div className="pages fieldRow">
            <div className="sectionTools"><div className="sectionLabel">Page</div><Icons.AddBox className="icon ui noSubEvents" title="Add Link" onMouseEnter={(e) =>props.onMouseEnter(e, "Add a Page")} onMouseLeave={() => props.onMouseLeave() } onClick={() => props.onNewPage()} /></div>
        
          </div>}
          
          {props.showPages && props.narrativeHeadings && <MenuList 
              baseObjs={props.narrativeHeadings} 
              reload={props.reload}
              subObjsField={"subs"}
              parentField={"parent"}
              headingField={"heading"}
              subField={"subs"}
              linkField={"page"}
              sectionId={props.sectionId}
              setBaseObjs={props.setNarrativeHeadings}
              handleParentClick={props.handlePageClick}
              handlePageClick={props.handlePageClick}
              handleSave={handleNarrativeHeadingsReorderSave}
              handleSubSave={handleNarrativeHeadingsReorderSave}
              multiSelectPageIds={props.multiSelectPageIds}
              elevateSubs={true}
              showPageCount={false}
              deleteParent={handleNarrativeHeadingDelete}
              convertToGroup={handleConvertToGroup}
              setEditPageForm={props.setEditPageForm}
              />}
        </div>
        
    
      {/* Left Nav Ends */}
      </div>
    )
}

export default LeftNav;