import React, { useState, useEffect, useRef } from "react";
// import { Rnd } from "react-rnd";
// import Config from "../../Config.json";
// import {animDropDown, processCSS, handleInputFocus} from "../../utils/form.tools";

function TabButtons(props) {

    return(
        <div className="tabRow">
            {props.activeSectionObj && props.activeSectionObj.states && props.activeSectionObj.states.length > 0 && <div className={"tabBtns aspectRatio_" + (props.aspectRatio) + ((props.showFullCanvas) ? " showFull":"")}>
            
                <div className="tabBtnIn">
                {props.activeSectionObj && props.activeSectionObj.states && props.activeSectionObj.states.map((obj, i) => (
                <div className={"tabBtn ui" + ((props.activeStateId === obj.id)?" on":"")} key={'tabBtn_' + i} onClick={() => props.setActiveStateId(obj.id)}>
                    {obj.layer_name ? obj.layer_name : 'Tab ' + (i + 1)}
                </div>
                ))}
                </div>
            </div>}
        </div>
    )
}

export default TabButtons;

// {((props.section.states.length > 1)&&(dimension.file_format !== "statePNGButton"))?(
