import React, { useState, useEffect } from "react";
import {
  Link,
  useParams
} from "react-router-dom";
import axios from "axios";
import Cookies from 'js-cookie';
import Config from "../Config.json"
import {ReactComponent as Logo} from "../static/svg/logo.svg" 

function Reset() {

    function postLogin() {

        var username = document.getElementById('username');
        var password = document.getElementById('password');
        console.log('username', username.value, password.value);
        if (username.value && password.value) {
          axios({
            method: 'post',
            baseURL: Config.baseURL,
            url: '/api-token-auth/',
            data: {
              'username': username.value, 
              'password': password.value
            }
            })
            .then(response => {

                console.log('response', response);
                Cookies.set('_udxdid', response['data'].token);
                axios({
                    method: 'get',
                    baseURL: Config.baseURL,
                    url: '/auth/users/me/',
                    headers: {
                        Authorization: 'Token ' + Cookies.get('_udxdid')
                    }
                    })
                    .then(response => {
                      console.log('response', response)
                      window.location.href = "/";
                    });
                          
            })
            .catch(function (error) {
              if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              } else if (error.request) {
              console.log(error.request);
              } else {
              console.log('Error', error.message);
              }
              console.log(error.config);
          });
        }
		
    }
    useEffect(() => {
    //   fetchJSON();
    }, []);


    function resetPassword(email) {
        console.log('email', email)
        if (email) {
            axios({
                method: 'post',
                baseURL: Config.baseURL,
                url: '/auth/users/reset_password/',
                data: {
                  'email': email
                }
                })
                .then(response => {
                  console.log('response', response)
                  window.location.href = "/";
                });
        }
 
      }
   

    return (
      <section className="main pageItem-enter-active" id="loginFormPage">
   
          <div className="loginForm">
            <div id="logInLogo">
              <Logo />
              <h1>Reset Password</h1>
        
            </div>
            <div className="formField">
                <input autoComplete="off" type="text" name="email" id="email" required placeholder="email" />
            </div>
            <div className="formField">
                <input type="submit" className="btn1 submitBtn" value="Reset" onClick={()=> resetPassword(document.getElementById('email').value)} id="loginSubmit" />
               
            </div>
      
          </div>
      </section>
    );
}

export default Reset;


// {(showLoader)?(<div id="loading"><img src={Loader} alt="" /></div>):null}



// <div className="filters">
// <div className="filterBtn">Date</div>
// <div className="filterBtn">Date</div>
// </div>