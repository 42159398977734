import React, { useState, useEffect, useRef } from "react";
import Cookies from 'js-cookie';
import Config from "../../Config.json";
import gsap from "gsap";
import {stripImg, toTitleCase, humanizeFontWeight} from "../../utils/helpers";
import {animDropDown, handleInputFocus} from "../../utils/form.tools";
import handleMultiSelect from '../narrativeUtils/multiSelect';
import { ChromePicker } from 'react-color';
import { apiCall } from "../narrativeUtils/fetch";
import Icons from '../module.icons';
import Help from '../module.help';
import Versions from '../module.versions';

import { Fragment } from "react/cjs/react.production.min";

const AnimationProperties = [
  {'name': 'opacity', 'slug': 'opacity'},
  {'name': 'x pos', 'slug': 'translateX'},
  {'name': 'y pos', 'slug': 'translateY'},
  {'name': 'scale', 'slug': 'scale'}
]

function LayerSettings(props) {
   
    const [animOpen, setAnimOpen] = useState(false);
    const [editField, setEditField] = useState(null);
    const [tempColor, setTempColor] = useState();
    const [dropDown, setDropDown] = useState();
    const [showAnimPresetDropDown, setShowAnimPresetDropDown] = useState(false);
    const [showAnimDropDown, setShowAnimDropDown] = useState(false);
    // const [lockAr, setLockAr] = useState(true);
    const [showNewAnimObj, setShowNewAnimObj] = useState(false);
    const [animPropOn, setAnimPropOn] = useState(null);
    const [selectionCss, setSelectionCss] = useState();

    function getStyleCss(e) {
      console.log('e', 'on')
    }

    function getSelectedValue(dimension, name, value) {
      let isMixed;
      let result;
      if (selectionCss && selectionCss[name]) {
        result = selectionCss[name];

      } else {
        if (dimension.html_text && dimension.html_text.css[name]) {
          isMixed = showStyleSelected(name, null, dimension);
          switch (name) {
            case "font-weight":
              if (isMixed === 'mixed') {
                result = "---";
              } else {
                result = humanizeFontWeight(dimension.html_text.css[name])
              }
              break;
            default:
              if (isMixed === 'mixed') {
                result = "---";
              } else {
                result = dimension.html_text.css[name];
              }
              break;
          }
        } else {
          switch (name) {
            case "font-size":
              if (isMixed === 'mixed') {
                result = "---";
              } else {
                result = value;
              }
              break;
            default:
              result = '<span className="canvasLabel">Add' + value + '</span>'
              break;
          }
        }
      }
      
     

    
      return result;
    }

    useEffect(() => {
     
      if (props.selection === true) {
        // console.log('props.selection TRUE')
        let selectedDiv;
        let selectedDivs = document.querySelectorAll('.selection');
        if (selectedDivs) {
          console.log('selectedDivs')
          selectedDiv = selectedDivs[0]
          if (selectedDiv) {
           
            // let selectedStyle = selectedDiv.getAttribute('style');
            let tempCss = {}
            let styleArray = selectedDiv.style
            const rgb2hex = (rgb) => `#${rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/).slice(1).map(n => parseInt(n, 10).toString(16).padStart(2, '0')).join('')}`

            for (let i = 0; i < styleArray.length; i++) {
              console.log(styleArray.item(i))
              if (styleArray.item(i) === 'color') {
                tempCss[styleArray.item(i)] = rgb2hex(selectedDiv.style[styleArray.item(i)])
              } else {
                tempCss[styleArray.item(i)] = selectedDiv.style[styleArray.item(i)]
              }
              
            }
           
            // console.log('tempCss', tempCss)
            setSelectionCss(tempCss);
            if (tempCss['color']) {
              setTempColor(tempCss['color'])
            }
          }
          
        }
        
      } else {
        setTempColor(null)
        setSelectionCss(null);
        // console.log('props.selection FALSE')

      }

    }, [props.selection])
 

    // useEffect(() => {
    //   console.log('LayerSettings props', props.componentDimensions)
    // }, [props])

    useEffect(() => {
      if (dropDown) {
        animDropDown(true, props.activeSettingId, dropDown);
      } else {
        animDropDown(false, props.activeSettingId, dropDown)
      }
    }, [dropDown])

    function handleDropDown(e, field) {



      if (field === dropDown) {
        setDropDown(null); 
      } else {
        setDropDown(field)
      }
    }

    function updateSVG(field, value) {
      let newComponents = [...props.componentDimensions];
      let newComponent = newComponents.find(obj => obj.id === props.activeSettingId)
      // let textCopy = tempTextObj.current;
      console.log('update', field, value, newComponent)
  
      if (field !== 'fill') {
        // document.getElementById('inputField_fontSize_text_' + newComponent.id).value = value;
        // let htmlElem = document.getElementById('dim_text_' + newComponent.id)
      }
      let newObj = newComponent['svg_style'];
      if (newObj) {
        newObj['css'][field] = value;
      } else {
        newObj = {
          'css': {}
        }
        newObj['css'][field] = value;
      }
      
  
      // console.log("updateText", field, value, newObj)
      props.setComponentDimensions(newComponents)
      setDropDown(null);
      props.setUnSavedChanges(true);
  }
  
  

    function animColorPicker(open, id){
        if (animOpen) {
        gsap.to('.colorPicker', {height: 0, duration: 0});
        gsap.to('.colorPicker', {opacity: 0, scale: 0, zIndex: -1, duration: 0.1});
        }
        if (open !== id) {
        var tl = gsap.timeline();
        tl.to("#" + id, {height: "auto", duration: 0});
        tl.to("#" + id, {zIndex: 6, height: "auto", duration: 0}, "<");
        tl.to("#" + id, {scale: 1, opacity: 1, duration: 0.1});
        setAnimOpen(id);
        } else {
        gsap.to("#" + id, {height: 0, duration: 0});
        gsap.to("#" + id, {opacity: 0, scale: 0, zIndex: -1, duration: 0.1});
        setAnimOpen(null);
        }
        props.setUnSavedChanges(true);
    }

    function updateBackgroundColor(objId, color) {
        props.setShowLoader(true);
        const api_url = Config.baseURLcms + 'componentdimension-background/';
    
        let formData = {}
        formData['id'] = objId
        formData['color'] = color
    
        function responseTask() {
          props.fetchJSON(false);
          setTempColor(null)
        }
        apiCall("POST", api_url, formData, responseTask)
      }
    
      function handleKeyPress(e, fieldId, fieldValue) {
        if (e.key === "Enter") {
          let newComponents = [...props.componentDimensions];
          let newComponent = newComponents.find(obj => obj.id === props.activeSettingId)
          if (!fieldValue) {
            newComponent[fieldId] = e.target.value;
          } else {
            newComponent[fieldId] = fieldValue;
          }
          console.log('handleKeyPress', fieldId, fieldValue, newComponent, e.target.value)
          props.setComponentDimensions(newComponents);
          setEditField(null);
          props.setUnSavedChanges(true);
        }
      }
  
      function handleKeyChange(e, fieldId, fieldValue) {
        let newComponents = [...props.componentDimensions];
        let newComponent = newComponents.find(obj => obj.id === props.activeSettingId)
        if (!fieldValue) {
          newComponent[fieldId] = e.target.value;
        } else {
          newComponent[fieldId] = fieldValue;
        }
        console.log('handleKeyChange', fieldId, fieldValue, newComponent, e.target.value)
        props.setComponentDimensions(newComponents);
        props.setUnSavedChanges(true);
      }

      function removeLink(objId) {
        let newComponents = [...props.componentDimensions];
          let newComponent = newComponents.find(obj => obj.id === props.activeSettingId)
          newComponent['component_link'] = '';
          newComponent['component_link_heading'] = '';
          document.getElementById('link_' + objId).value = ""
          document.getElementById('link_heading_' + objId).value = ""
          props.setComponentDimensions(newComponents);
          setEditField(null);
          props.setUnSavedChanges(true);
      }

      function handleMediaChoiceValue(mediaChoice, callback) {
        if (mediaChoice === 'vid') {
          props.setAcceptValues("video/mp4");
        } else {
          props.setAcceptValues("image/png, image/jpeg, image/webp, image/svg+xml");
        }
        callback();
      }

      function handleMediaReplace(isBackground, mediaChoice, componentdimension_id, e, isFileOn) {
        // var fileList = document.getElementById('fileReplace').files;
        var fileList = e.target.files;
        console.log('handleMediaReplace', componentdimension_id, fileList);
        var arrayLength = fileList.length;
        for (var i = 0; i < arrayLength; i++) {
          var mediaData = {
            'display': props.aspectRatio,
            'componentdimension_id': componentdimension_id,
            'file': fileList[i],
            'format': mediaChoice
          }
          var currNewMedia = [...props.newMedia];
          currNewMedia.push(mediaData);
          props.setNewMedia(currNewMedia);
  
          const api_url = Config.baseURLcms + 'section-component-replace/';
  
          props.setShowLoader(true);
          var formData = new FormData();
          var fileField = 'image';
          if (mediaChoice === 'svg') {
            var fileField = 'svg';
          }
          if (mediaChoice === 'vid') {
            var fileField = 'video';
          }
          if (isFileOn) {
            var fileField = 'image_on';
          }

          let sizeMB = parseFloat(fileList[i].size / (1024 ** 2)).toFixed(2);
          let sizeKb = fileList[i].size/1024;

          console.log(sizeMB, sizeKb)
  
          if ((sizeMB > Config.validations.maxFileSize) && (sizeKb > 0)) {
              let newError = {
                "heading": "Error",
                "text": "The selected file is <strong>" + sizeMB + "MB</strong>.<br/>This file exceeds maximum upload size of <strong>10MB</strong>.<br/><br/>Please select a new file that is less than 10MB."
              };
              props.setFormError(newError);
              props.setShowLoader(false);

          } else if (fileList[i].name.startsWith(".")) {
            let newError = {
              "heading": "Error",
              "text": "Please select a valid image file."
            };
            props.setFormError(newError);
            props.setShowLoader(false);

          } else {

  
            formData.append(fileField, fileList[i], fileList[i].name) 
            formData.append('id', componentdimension_id);
            formData.append('display_id', 1);
            formData.append('file_format', mediaChoice);
            if (isBackground) {
              formData.append('background', true);
            } else {
              formData.append('background', false);
            }
            function responseTask() {
              props.fetchJSON(false);
            }
    
            let formHeaders = {
              'Content-Disposition': 'attachment; filename=' + fileList[i].name,
              'Authorization': 'Token ' + Cookies.get('_udxdid')
            }
            apiCall("POST", api_url, formData, responseTask, formHeaders, props.setFormError, props.setShowLoader)

          }
        }
      }
    
      function handleShowStateOptions(objID) {
        var newComponents = [...props.componentDimensions];
        var newIndex = newComponents.findIndex(item => item.id === objID);
        var newObj = newComponents[newIndex];
        newObj['has_state_images'] = true;
        props.setComponentDimensions(newComponents);
        props.setUnSavedChanges(true);
      }

      function handleStateMediaAddReplace(dimensionId, e, stateId, stateHeading) {
        console.log('handleStateMediaAddReplace', dimensionId, e, stateId);
        var fileList = e.target.files;
        var arrayLength = fileList.length;
        for (var i = 0; i < arrayLength; i++) {
          const api_url = Config.baseURLcms + 'componentdimension-stateimg-add/';
  
          props.setShowLoader(true);
          var formData = new FormData();
          formData.append('image', fileList[i], fileList[i].name) 
          formData.append('componentdimension', dimensionId);
          formData.append('state_id', stateId)
  
          console.log('state_id', stateId)
          formData.append('state_heading', stateHeading)
  
          function responseTask() {
            props.fetchJSON(false);
          }
  
          let formHeaders = {
            'Content-Disposition': 'attachment; filename=' + fileList[i].name,
            'Authorization': 'Token ' + Cookies.get('_udxdid')
          }
          apiCall("POST", api_url, formData, responseTask, formHeaders)
      
        }
      }

      function updateText(field, value) {

        


        let newComponents = [...props.componentDimensions];
        let newComponent = newComponents.find(obj => obj.id === props.activeSettingId)
        // let textCopy = tempTextObj.current;
        let elem = document.getElementById('inputField_' + field + "_text_" + newComponent.id);
        if (elem) {
          elem.value = value;
        } 
      
        // console.log(field, value)
        
        let htmlElem = document.getElementById('dim_text_' + newComponent.id)
        let target;
        let selects = document.querySelectorAll('.selection');
   
        let newObjCss;

        if (newComponent['html_text']['css']) {
          newObjCss = newComponent['html_text']['css'];
        } else {
          newObjCss = {}
        }

        // console.log('selects.length', selects.length, selects)
        let property;

        if (selects && selects.length) {
          
          target = selects[0];
          target.classList.add('stl');
          switch (field) {
            case 'fontWeight':
              target.style.fontWeight = value;
              break;
            case 'fontSize':
              target.style.fontSize = value + "px";
              break;
            case 'fontFamily':
              target.style.fontFamily = value;
              break;
            case 'textAlign':
              target.style.fontSize = value;
              break;
            case 'color':
              target.style.color = value;
              break;
            case 'lineHeight':
              target.style.lineHeight = value;
              break;
            case 'letterSpacing':
              target.style.letterSpacing = value;
              break;
            default:
              break;
          }

          target.classList.remove('selection');
          newComponent['html_text']['text'] = htmlElem.innerHTML;

        } else {
          newComponent['html_text']['css'] = newObjCss;
          switch (field) {
            case 'fontWeight':
              newObjCss['font-weight'] = value;
              property = 'font-weight';
              break;
            case 'fontSize':
              newObjCss['font-size'] = value;
              property = 'font-size';
              break;
            case 'fontFamily':
              newObjCss['font-family'] = value;
              property = 'font-family';
              break;
            case 'textAlign':
              newObjCss['text-align'] = value;
              property = 'text-align';
              break;
            case 'color':
              newObjCss['color'] = value;
              property = 'color';
              break;
            case 'lineHeight':
              newObjCss['line-height'] = value;
              property = 'line-height';
              break;
            case 'letterSpacing':
              newObjCss['letter-spacing'] = value;
              property = 'letter-spacing';
              break;
            default:
              break;
          }

         
          // Process Internal Styles
          let existingHtmlStr = newComponent['html_text']['text'];
          function stringToHTML(str) {
            var dom = document.createElement('div');
            dom.innerHTML = str;
            return dom;
          };
          let newHtml = stringToHTML(existingHtmlStr)
          let styles = newHtml.querySelectorAll('.stl');
          styles.forEach(span => {
            let newValue;
            if (property === 'font-size') {
              newValue = value + 'px';
            } else {
              newValue = value;
            }
            span.style.setProperty(property, newValue);
          });

          newComponent['html_text']['text'] = newHtml.innerHTML;

        }

        props.setComponentDimensions(newComponents);
        props.setUnSavedChanges(true);
        setDropDown(null);
      }
      
      // function handleAddPopup(objID, format) {
      //   console.log('handleAddPopup', objID);
      //   if (format === 'img') {
      //     props.setAcceptValues("image/png, image/jpeg, image/webp");
      //   } 
      //   if (format === 'vid') {
      //     props.setAcceptValues("video/mp4");
      //   }
      //   if (format==='svg') {
      //     props.setAcceptValues("image/svg+xml");
      //   }
      //   var fileElem = document.getElementById('popUpload');
      //   setTimeout(() => {
      //     fileElem.click();
      //   }, 150);
  
      // }

      function handleShowAnimDropDown() {
        if (showAnimDropDown) {
          setShowAnimDropDown(false);
        } else {
          setShowAnimDropDown(true);
        }
      }

      function handleAnimChoice(typeName, objID){
        var newComponents = [...props.componentDimensions];
        var newIndex = newComponents.findIndex(item => item.id === objID);
        var newObj = newComponents[newIndex];
        if (typeName !== 'none') {
          newObj['typeName'] = typeName;
        } else {
          newObj['typeName'] = "";
        }
        setShowAnimDropDown(false);
        props.setComponentDimensions(newComponents);
        if (!props.unSavedChanges) {
          props.setUnSavedChanges(true);
        }
        
  
        console.log("handleAnimChoice", typeName, objID,  newObj['typeName'])
      }

      function handleAnimValue(componentID, animationID, key, value, e) {
        e.target.classList.add('editing');
        
        if (e.key === "Enter") {
          e.target.classList.remove('editing');
          props.stageLayerIds(componentID) 

          console.log('handleAnim', componentID, animationID, key, value, e)
  
          var newComponents = [...props.componentDimensions];
          var newIndex = newComponents.findIndex(item => item.id === componentID);
          var newObj = newComponents[newIndex];
          var newAnimIndex = newObj['animations'].findIndex(item => item.id === animationID);
          var newAnimObj = newObj['animations'][newAnimIndex];
  
          if ((key === "delay")||(key === "duration")) {
            if (typeof value === "string") {
              value = parseFloat(value)
              e.target.value = value
              // console.log('value', value, parseFloat(value).toFixed(2))
            }
  
          }
          newAnimObj[key] = value; 
          
          var dropdowns = document.getElementsByClassName('inputDropDown');
          // console.log('dropdown', dropdowns);
          var i;
          for (i = 0; i < dropdowns.length; i++) {
            dropdowns[i].classList.add('off');
          }
    
          e.target.classList.remove('on');
  
          props.setComponentDimensions(newComponents);
          props.setUnSavedChanges(true);

        }
   
       
   
        
  
        
    }
    
      function handleDropDownValue(e, value, id, id2, componentID, animationID, key) {
        console.log('handleDropDownValue', e, value)
        let elem = document.getElementById(id);
        elem.value = value;
    
        let elem2 = document.getElementById(id2);
        elem2.classList.add('off');
    
        var onInputs = document.querySelectorAll('.valueInput.on');
        var i;
        for (i = 0; i < onInputs.length; i++) {
          onInputs[i].classList.remove('on');
        }
        
        if (componentID && animationID) {
          handleAnimValue(componentID, animationID, key, value, e)
        } else {
          handleNewAnimValue(key, value, e);
        }
        
      }
      function handleNewAnimValue(key, value, e) {
        if (Object.keys(props.newAnimObj).length > 0) {
          var animObj = Object.assign({}, props.newAnimObj);
        } else {
          var animObj = {};
        }
        animObj[key] = value;
        props.setNewAnimObj(animObj);
    
        console.log('animObj', animObj, key, value);
    
        if (key === 'property') {
          // animPropRef.current = value;
          setAnimPropOn(value);
          
        }
    
        // console.log('handleNewAnimValue', handleNewAnimValue);
      }

      function handleAspectLock(objID) {
        if (props.showFormatLockWarning !== false) {
          var newComponents = [...props.componentDimensions];
          var newIndex = newComponents.findIndex(item => item.id === objID);
  
          console.log('isLocked', newIndex, objID);
  
          var newObj = newComponents[newIndex];
          var is_locked = newObj['is_locked'];
  
        
          if (is_locked === true) {
            newObj['is_locked'] = false;
  
          } else {
            newObj['is_locked'] = true;
          }
          props.setComponentDimensions(newComponents);
          props.setUnSavedChanges(true);
          props.setShowFormatLockWarning(false);
        } else {
          props.setShowFormatLockWarning(objID);
        }
        
      }

      function updateDimension(e, id, width, height, mode) {
        if (props.lockAr) {
          let input;
          let newValue;
          if (mode === "width") {
            input = document.getElementById('height_dim_' + id)
            newValue = e.target.value/(width/height)
          } else {
            input = document.getElementById('width_dim_' + id)
            newValue = e.target.value*(width/height)
          }
          
         
          input.value = Math.round(newValue);
          console.log('updateDimension', e.target.value, id, width, height)
          props.setUnSavedChanges(true);
        }
      }

      function handleAddAnimation(dataJSON, fetch) {
        props.showSettings(props.activeSettingId, true, null)
        let ready;
        do {
          if (!props.unSavedChanges) {
            ready = true
          } 
        }
        while (ready === false)

        const api_url = Config.baseURLcms + 'componentdimension-animation-add/';
        function responseTask() {
          // props.fetchSection(props.sectionId)
          fetch && props.fetchJSON()
          // props.fetchJSON()
        }
        apiCall('POST', api_url, dataJSON, responseTask)
      }
  

      function runAnimPreset(obj, componentDimensionId, isLocked, displayName, partnerIds, isSimple) {
        // console.log('runAnimPreset', obj['presetitems']);
    
        let presets = obj['presetitems'];
        presets.forEach((item) => {
         var animDataJSON = {
          'componentdimension': componentDimensionId,
          'property': item.property,
          'start': item.start,
          'end': item.end,
          'startParallax': item.startParallax,
          'endParallax': item.endParallax,
          'duration': item.duration,
          'delay': item.delay,
          'ease': item.ease,
          'is_state': false,
          'preset_id': obj.id
         }
         if (isLocked === true) {
          if (displayName === 'HD') {
            handleAddAnimation(animDataJSON, true);
            partnerIds && partnerIds.forEach((id) => {
              animDataJSON['componentdimension'] = id;
              // console.log('id', id, animDataJSON);
              handleAddAnimation(animDataJSON, false);
            });
            
    
          }
        } else {
          handleAddAnimation(animDataJSON, true);
        }
        })
        
        props.setShowLoader(true);
        setDropDown(null)
        setShowAnimPresetDropDown(false);
      }

      function handleDeleteAnim(objID) {
        var dataJSON = {
          'animation_id': objID
        }
        const api_url = Config.baseURLcms + 'componentdimension-animation-delete/';
        function responseTask() {
          // props.fetchSection(props.sectionId)
          props.fetchJSON()
        }
        apiCall('POST', api_url, dataJSON, responseTask)
       
      }
      
      function handleAddNewAnimation() {
        var animObj = {};
        animObj['duration'] = '';
        animObj['delay'] = '';
        animObj['ease'] = 'default';
        animObj['start'] = '';
        animObj['end'] = '';
        animObj['is_state'] = false;
        props.setNewAnimObj(animObj);
        setShowNewAnimObj(true);
       
        var newValueInputs = document.getElementsByClassName('newValueInput');
        console.log('newValueInputs', newValueInputs);
        var i;
        for (i = 0; i < newValueInputs.length; i++) {
          newValueInputs[i].value = "";
          // console.log('handleAddNewAnimation', newValueInputs, newValueInputs[i], newValueInputs[i].value);
        }
        
      }
    
      function handleDeleteMedia(objID) {
        props.setShowLoader(true);
        props.showSettings(objID, false, null);
        const api_url = Config.baseURLcms + 'section-componentdimension-delete/';
        var dataJSON = {
          'displaycomponent_id': objID,
        }
        function responseTask() {
          props.updateVersionHistorySub(props.section, "page")

          props.setUnSavedChanges(false);
          props.setActiveSettingId(null);
         
          setTimeout(() => {
            props.fetchJSON(false);
          }, 200);
          
         
        }
        apiCall('POST', api_url, dataJSON, responseTask)
        // console.log('dataJSON', dataJSON)
      }
  
      function handleStateMediaEditHeading(dimensionId, e, stateId, stateHeading) {
        console.log('handleStateMediaEditHeading', dimensionId, e, stateId);
        var fileList = e.target.files;
        var arrayLength = fileList.length;
        for (var i = 0; i < arrayLength; i++) {
          const api_url = Config.baseURLcms + 'componentdimension-stateimg-edit-heading/';
  
          props.setShowLoader(true);
          var formData = new FormData();
          formData.append('componentdimension', dimensionId);
          formData.append('state_id', stateId)
          // var stateHeading = document.getElementById("state_heading_" + stateId).value;
          // console.log('stateHeading', stateHeading)
          formData.append('state_heading', stateHeading)
          apiCall("POST", api_url, formData, null, null)
  
         
        }
      }
       
  function handleAddNewStateAnimation() {
    var animObj = {};
    animObj['duration'] = '';
    animObj['delay'] = '';
    animObj['ease'] = 'default';
    animObj['start'] = '';
    animObj['end'] = '';
    animObj['is_state'] = true;
    props.setNewStateAnimObj(animObj);
    props.setShowNewStateAnimObj(true);
   
    var newValueInputs = document.getElementsByClassName('newValueInput');
    console.log('newValueInputs', newValueInputs);
    var i;
    for (i = 0; i < newValueInputs.length; i++) {
      newValueInputs[i].value = "";
    }
    
  }

  function handleNewStateAnimValue(key, value) {
    if (Object.keys( props.newStateAnimObj).length > 0) {
      var animObj = Object.assign({},  props.newStateAnimObj);
    } else {
      var animObj = {};
    }
    animObj[key] = value;
    props.setNewStateAnimObj(animObj);
    console.log('handleNewStateAnimValue', animObj)
  }

  function onSetComponentDimensions(objId, newObj) {
    let newObjs = [ ...props.componentDimensions ];
    let objIndex = newObjs.findIndex(a => a.id === objId)
    newObjs[objIndex] = newObj
    props.setComponentDimensions(newObjs)
    console.log('newObjs', newObjs, newObj)
  }

  function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }


  function showStyleSelected(property, tempVal, obj) {
    let result;
    let objVal;
    let mixedResult;
    // console.log('showStyleSelected', property, tempVal, obj)
    
    if ((obj.html_text && obj.html_text.css && obj.html_text.css[property])) {
      if ((property === 'color')&&(/^#[0-9A-F]{6}$/i.test( obj.html_text.css[property]))) {
        objVal = hexToRgb(obj.html_text.css[property])
       
      } else if (property === 'font-size') {
        objVal = obj.html_text.css[property].toString() + 'px';
        
      } else {
        objVal = obj.html_text.css[property];
      }
      
    }


   
    if (obj['html_text']) {
      let existingHtmlStr = obj['html_text']['text'];
      function stringToHTML(str) {
        var dom = document.createElement('div');
        dom.innerHTML = str;
        return dom;
      };
      let newHtml = stringToHTML(existingHtmlStr)
      let styles = newHtml.querySelectorAll('.stl');
      styles.forEach(span => {
        let propertyVal = span.style.getPropertyValue(property);
        if (property === 'color') {
          let rgbStr = propertyVal
          let rgbObj = {}
          if (rgbStr) {
            let rgbs = rgbStr.match(/\d+/g).map(Number);
            rgbs.forEach((o, i) => {
              if (i === 0) {
                rgbObj['r'] = o
              }
              if (i === 1) {
                rgbObj['g'] = o
              }
              if (i === 2) {
                rgbObj['b'] = o
              }
            })
            propertyVal = rgbObj;
            // console.log('rgbStr', propertyVal)
        
            if (JSON.stringify(objVal) !== JSON.stringify(propertyVal)) {
              mixedResult = true;
              // console.log('mixedResult', property, objVal, propertyVal)
            }
          } 
          
        } else {
  
          if (objVal !== propertyVal) {
            mixedResult = true;
            // console.log('mixedResult', property, objVal, propertyVal)
          }
        }
       
        
      });
    }
    

    

    if (tempColor) {
      result = tempColor;
    } else {
      if (!mixedResult) {
        result = objVal;
      } else {
        result = "mixed"
      }
      
  
    }
    return result;
  }


  // useEffect(() => {

  // }, [hoverText])

    return(
        <div id="manifestColumn" className={((props.activeSettingId)?("settingsOn rightNav"):("settingsOff show"))}>
    
        <div className="settingsList">
        
        {props.componentDimensions
          .sort((a, b) => a.component_ordering < b.component_ordering ? 1 : -1)
          .filter(file => (file.id === props.activeSettingId))
          .map((dimension, i) => 
          (dimension) ?
           (<div
            key={'settingFile_' + i}
            >
              <div>
              <div id={"settings_" + dimension.id}>
                <div className={"mediaSettings showSettings on" + ((dimension.is_locked && props.aspectRatio === 2)?" locked":"")}>
            
                <div className="mediaSettingsInner">
                {(!dimension.has_state_images)?
                  (<div className="settingsGroup"> 
                  {dimension.background && <div className="row settingsGrid">
                  <div className="canvasLabel ui" onClick={() => animColorPicker(animOpen, 'colorPicker3')}>Select Color</div>
                 
                  <div className="ui colorChip" 
                  style={{
                    background: (dimension.background_color) ? (dimension.background_color) :"none"
                  }}
                  onClick={() => animColorPicker(animOpen, 'colorPicker3')}></div>
                  <span 
                  id="colorPicker3"
                  className="colorPicker"
                  >
                  <input type="hidden" id={"background_" + dimension.id} />
                  <ChromePicker
                  color={
                    tempColor ? tempColor : (dimension.background_color?dimension.background_color:"#ffffff")}
                    disableAlpha={true}
                    onChange={(color) => {
                      setTempColor(color.hex)
                    }}
                    onChangeComplete={(color, event) => {
            
                      if (tempColor === color.hex) {
                        updateBackgroundColor(dimension.id, tempColor)
                      }
                      if (!tempColor && color.hex) {
                        updateBackgroundColor(dimension.id, color.hex)
                      }
                      console.log('e', event, tempColor)
              
                  
                    }}
                />
                </span>
                </div>}
                    <div className="settingsRow">
                      <h4>
                       
                        {(editField === "layer_name") ? 
                          <span className="inputField"><input onKeyUp={(e) => handleKeyPress(e, "layer_name", null)} onChange={(e) => handleKeyChange(e, "layer_name", null)} type="text" autoComplete="off" defaultValue={dimension.layer_name?dimension.layer_name:stripImg(dimension.name)} name="layer_name" id="layer_name" />
                          <span className="smCancel ui" onClick={() => setEditField(null)}><Icons.Cancel/></span>
                        </span> : <span className="fieldValue" onClick={() => setEditField("layer_name")}>{dimension.layer_name?dimension.layer_name:stripImg(dimension.name)}</span>}
                      </h4>
                      
                      {!(dimension.file_format === "text") && <input type="file" id="fileReplace" accept={"image/png, image/jpeg, image/webp, video/mp4, image/svg+xml"} onChange={(e) => handleMediaReplace(dimension.background, dimension.file_format, dimension.id, e, false)} multiple={false} />}
                    
                    </div>
                    {(dimension.file_format === "statePNGButton") ? 
                      (
                        <React.Fragment>
                          <span className="settingsRow">
                          <span className="canvasLabel">On State Image</span>
                            <p>{stripImg(dimension.name_on)}</p>
                            <input type="file" id="fileOnReplace" accept={"image/png, image/jpeg, image/webp, image/svg+xml"} onChange={(e) => handleMediaReplace(dimension.background, dimension.file_format, dimension.id, e, true)} multiple={false} />
                          </span>
                          <span className="settingsRow">
                          <span className="canvasLabel">Heading</span>
                            <input type="text" key={dimension.id + '_state_heading'} placeholder={dimension.component_state_heading} onChange={() => props.setUnSavedChanges(true)} id={'state_heading_' + dimension.id} />
                          </span>
                        </React.Fragment>
                      ) : ("")}
                  </div>):("")}
                
                  {((props.section.states.length > 1)&&(dimension.file_format !== "statePNGButton"))?(
                    <div className="settingsGroup stateGroup">
                    {(dimension.has_state_images) ?
                    (
                      <span>


                      <div className="groupLabel"><p>Tab Image</p></div>
                      {props.section.states.filter(s => s.id === props.activeStateId).map((state, k) => (
                      <span className="stateSettingsRow" id={"state_row_" + k} key={"state_" + k}>
                         <span>
                          <span className="canvasLabel">
                          {state.formats.filter((obj0) => obj0.display_id === props.aspectRatio).map((obj, l) => (
                            <span className="fileNameGroup" key={"state_format_" + l}>
                              <span className="stateRow canvasLabel"></span>
                              <span className="fileName">{state.layer_name}</span>
                            </span>
                          ))}
                          </span>
                          {(!state.first) ? (
                            <span>
              
                            {dimension.componentdimensionstateimages.map((obj1, m) => (
                              (obj1.state_id === String(state.id)) ? (
                                <span className="fileNameGroup" key={"state_format_image_" + m}>
                                  <span className="fileName">{stripImg(obj1.name)}</span>
                                </span>
                                ) : ("")
                            ))}
                         
                            <input type="file" accept={"image/png, image/jpeg, image/webp"} data-stateid={state.id} onChange={(e) => handleStateMediaAddReplace(dimension.id, e, state.id, state.state_heading)} multiple={false} />

                            </span>
                            ) : (
                              <div className="fileNameGroup">
                                <span className="fileName">{stripImg(dimension.name)}</span>
                                <input type="file" id="fileReplace" accept={"image/png, image/jpeg, image/webp"} onChange={(e) => handleMediaReplace(dimension.background, dimension.file_format, dimension.id, e, false)} multiple={false} />
                              </div>
                            )}
                        </span>
                        
                            
                      </span>

                      ))}
                      </span>
                      ):
                      (<div className="btn1" onClick={() => handleShowStateOptions(dimension.id)}>Add State Images +</div>)
                      }
                      </div>
                  ):("")}

                  <div className="settingsGroup">
                      <div className="groupLabel"><p>Format</p></div>
                      <div className="settingsRow">
                      <div className={"ui" + ((dimension.is_locked) ? " on" : " off")}>
                      
                      <span onClick={() => handleAspectLock(dimension.id)}>{(dimension.is_locked)?(<span className="formatLock ui"><Icons.Desktop className="icon on" /><Icons.Tablet className="icon on" /></span>):(<span className="formatLock ui"><Icons.Desktop className={"icon" + ((props.aspectRatio === 1)?" on":"")} /><Icons.Tablet className={"icon" + ((props.aspectRatio === 2)?" on":"")} /></span>)}</span>
                      </div>
                    </div>
                  </div>

                  {!dimension.background  && <div className="settingsGroup"> 
                    <div className="groupLabel"><p>Layout</p></div>
                    <div className="settingsRowIcon">
                      <div className="settingsRow halfRow"><span className="canvasLabel">Width (px): </span> 
                        
                        <input type="number" min="0" className="valueInput" onClick={(e) => handleInputFocus(e)} key={dimension.width + '_width_' + props.versionNumber} onChange={(e) => updateDimension(e, dimension.id, dimension.width, dimension.height, 'width')} defaultValue={dimension.width} id={'width_dim_' + dimension.id} />

                      </div>
                      <span className="iconField ui" onClick={() => props.setLockAr(!props.lockAr)}>{props.lockAr ? <Icons.Lock className="icon" /> : <Icons.LockOpen className="icon" />}</span>
                      <div className="settingsRow halfRow rightRow">
                        <span className="canvasLabel">Height (px)</span>
                        
                        <input type="number" min="0" className="valueInput" onClick={(e) => handleInputFocus(e)} key={dimension.height + props.versionNumber}  onChange={(e) => updateDimension(e, dimension.id, dimension.width, dimension.height, "height")} defaultValue={dimension.height} id={'height_dim_' + dimension.id} />

                      </div>
                    </div>
                    <div className="settingsRow halfRow">
                      <span className="canvasLabel">Left (px)</span>

                      <input type="number" min="0" className="valueInput" onClick={(e) => handleInputFocus(e)} key={dimension.id + '_x_pos_' + props.versionNumber} onKeyUp={() => props.setUnSavedChanges(true)} placeholder={dimension.x_pos} id={'x_pos_dim_' + dimension.id} />

                    </div> 
                    <div className="settingsRow halfRow rightRow">
                      <span className="canvasLabel">Top (px)</span>

                      <input type="number" min="0" className="valueInput" onClick={(e) => handleInputFocus(e)} key={dimension.id + '_y_pos' + props.versionNumber}  onKeyUp={() => props.setUnSavedChanges(true)} placeholder={dimension.y_pos} id={'y_pos_dim_' + dimension.id} />

                    </div>
                    {(props.multiSelectIds.length > 1) && <div className="settingsRow">
                      <span className="canvasLabel">Align</span>
                      <div className="settingsRow grid6">
                        <div className="ui iconField" onClick={() => handleMultiSelect('align', 'left', props.componentDimensions, props.multiSelectIds, props.setComponentDimensions)}><Icons.AlignHoriLeft className="icon" /></div>
                        <div className="ui iconField" onClick={() => handleMultiSelect('align', 'center_w', props.componentDimensions, props.multiSelectIds, props.setComponentDimensions)}><Icons.AlignHoriCenter className="icon" /></div>
                        <div className="ui iconField" onClick={() => handleMultiSelect('align', 'right', props.componentDimensions, props.multiSelectIds, props.setComponentDimensions)}><Icons.AlignHoriRight className="icon" /></div>
                        <div className="ui iconField" onClick={() => handleMultiSelect('align', 'top', props.componentDimensions, props.multiSelectIds, props.setComponentDimensions)}><Icons.AlignVertTop className="icon" /></div>
                        <div className="ui iconField" onClick={() => handleMultiSelect('align', 'center_v', props.componentDimensions, props.multiSelectIds, props.setComponentDimensions)}><Icons.AlignVertCenter className="icon" /></div>
                        <div className="ui iconField" onClick={() => handleMultiSelect('align', 'bottom', props.componentDimensions, props.multiSelectIds, props.setComponentDimensions)}><Icons.AlignVertBottom className="icon" /></div>
                      </div>
                    </div>}
            
                

                    
                  </div>}
                  
                  {(dimension.background && (props.multiSelectIds.length > 1)) && <div className="settingsGroup"><div className="settingsRow">
                  <span className="canvasLabel">Align</span>
                  <div className="settingsRow grid6">
                    <div className="ui iconField" onClick={() => handleMultiSelect('align', 'left', props.componentDimensions, props.multiSelectIds, props.setComponentDimensions)}><Icons.AlignHoriLeft className="icon" /></div>
                    <div className="ui iconField" onClick={() => handleMultiSelect('align', 'center_w', props.componentDimensions, props.multiSelectIds, props.setComponentDimensions)}><Icons.AlignHoriCenter className="icon" /></div>
                    <div className="ui iconField" onClick={() => handleMultiSelect('align', 'right', props.componentDimensions, props.multiSelectIds, props.setComponentDimensions)}><Icons.AlignHoriRight className="icon" /></div>
                    <div className="ui iconField" onClick={() => handleMultiSelect('align', 'top', props.componentDimensions, props.multiSelectIds, props.setComponentDimensions)}><Icons.AlignVertTop className="icon" /></div>
                    <div className="ui iconField" onClick={() => handleMultiSelect('align', 'center_v', props.componentDimensions, props.multiSelectIds, props.setComponentDimensions)}><Icons.AlignVertCenter className="icon" /></div>
                    <div className="ui iconField" onClick={() => handleMultiSelect('align', 'bottom', props.componentDimensions, props.multiSelectIds, props.setComponentDimensions)}><Icons.AlignVertBottom className="icon" /></div>
                  </div>
                </div></div>}


                  {(dimension.file_format === "svg") && (
                    <div className="settingsGroup">
                      <div className="groupLabel"><p>Appearance</p></div>
                      <div className="settingsRow">
                        <div className="row settingsGrid">
                        <div className="canvasLabel ui" onClick={() => animColorPicker(animOpen, 'colorPicker2')}>Fill</div>
              
                        <div className="ui colorChip" 
                        style={{
                          background: (dimension.html_text && dimension.html_text.css && dimension.html_text.css['fill']) ? dimension.html_text.css['fill'] :"none"
                        }}
                        onClick={() => animColorPicker(animOpen, 'colorPicker2')}></div>
                          <span id="colorPicker2"
                          className="colorPicker">
                            <ChromePicker
                              color={ tempColor ? tempColor : ((dimension.html_text && dimension.html_text.css && dimension.html_text.css['fill'])?dimension.html_text.css['fill'] :"")}
                              onChange={(color) => {
                                setTempColor(color.hex)
                                console.log('onChange, tempColor', color.hex, tempColor)
                              }}
                              onChangeComplete={(color, event) => {
                                console.log('temp color', tempColor)
                                updateSVG('fill', tempColor)

                            
                              }}
                            />
                          </span>
                    </div>

                      </div>
                  </div>
                  )}
                    
                  
                  {(dimension.file_format === "text") && (
                    <Fragment>
                    <div className="settingsGroup">
                      <div className="groupLabel"><p>TEXT</p></div>

                      <div className="settingsRow" id="settingsRow_fontFamily">
                      <div className="ui fontBtn" onClick={(e) => handleDropDown(e, 'fontFamily')}>{getSelectedValue(dimension, 'font-family', "Font Family")}</div>
                        <span className="inputField dropDownField" id={"inputField_fontFamily_" + dimension.id}>
                          <span className="dropDown">
                            <span className="dropDownChoices">
                              {Config.fontFamilies.map((obj, i) => (
                                <span key={'fontfamily_'+i} className={"dropDownChoice ui"} onClick={() => updateText('fontFamily', obj)}>{obj}</span>
                              ))}
                            </span>
                          </span>
                        </span>
                      </div>
                      
                      
                      <div className={"settingsRow halfRow"} id="settingsRow_fontWeight">
                      <div className="ui fontBtn" onClick={(e) => handleDropDown(e, 'fontWeight')}>{getSelectedValue(dimension, 'font-weight', "Font Weight")}</div>
                      <span className="inputField dropDownField" id={"inputField_fontWeight_" + dimension.id}>
                        <span className="dropDown">
                          <span className="dropDownChoices">
                            <span className={"dropDownChoice ui"} onClick={() => updateText('fontWeight', '200')}>Light</span>
                            <span className={"dropDownChoice ui"} onClick={() => updateText('fontWeight', 'normal')}>Regular</span>
                            <span className={"dropDownChoice ui"} onClick={() => updateText('fontWeight', 'bold')}>Bold</span>
                          </span>
                        </span>
                      
                      </span> 
                    
                      
                      </div>
                    

                      <div className="settingsRow halfRow" id="settingsRow_fontSize">
                        <div className="ui fontBtn" onClick={(e) => handleDropDown(e, 'fontSize')}>
                        
                        <input type="text" id={"inputField_fontSize_text_" + dimension.id} defaultValue={getSelectedValue(dimension, 'font-size', 32)} key={dimension.html_text + "_" +  + props.versionNumber} onChange={(e) => updateText('fontSize', e.target.value)} />
                        </div>
                        <span className="inputField dropDownField" id={"inputField_fontSize_" + dimension.id}>
                          <span className="dropDown">
                            <span className="dropDownChoices">
                              {Config.fontSizes.map((obj, i) => (
                                <span key={'fontsize_'+i} className={"dropDownChoice ui"} onClick={() => updateText('fontSize', obj)}>{obj}</span>
                              ))}
                            </span>
                          </span>
                        </span> 
                        </div>
                    

                 
                        <div className="settingsRow grid3">
                          <div className="ui iconField" onClick={() => updateText('textAlign', 'left')}><Icons.AlignLeft className="icon" /></div>
                          <div className="ui iconField" onClick={() => updateText('textAlign', 'center')}><Icons.AlignCenter className="icon" /></div>
                          <div className="ui iconField" onClick={() => updateText('textAlign', 'right')}><Icons.AlignRight className="icon" /></div>
                        </div>
                      
                        <div className="settingsRow">
                            <div className="settingsRow halfRow iconTextRow">
                              <Icons.LineHeight className="icon" />
                              <input type="text" min="0" className="valueInput sm" onClick={(e) => handleInputFocus(e)} key={dimension.id + '_lineheight'} onChange={(e) => updateText('lineHeight', e.target.value)} defaultValue={dimension.html_text && dimension.html_text['css'] && dimension.html_text.css['line-height'] ? dimension.html_text.css['line-height'] : ('normal')} id={'lineheight_' + dimension.id} />
                            </div>
                            <div className="settingsRow halfRow iconTextRow">
                              <Icons.LetterSpacing className="icon" />
                              <input type="text" min="0" className="valueInput sm" onClick={(e) => handleInputFocus(e)} key={dimension.id + '_letterspacing'} onChange={(e) => updateText('letterSpacing', e.target.value)} defaultValue={dimension.html_text && dimension.html_text['css'] && dimension.html_text.css['letter-spacing'] ? dimension.html_text.css['letter-spacing'] : ('auto')} id={'letterspacing_' + dimension.id} />
                            </div>
                        </div>
                        

                        

                    

                    </div>

                    <div className="settingsGroup">
                    <div className="groupLabel"><p>COLOR</p></div>
                    <div className="row settingsGrid">
                    <div className="canvasLabel ui" onClick={() => animColorPicker(animOpen, 'colorPicker0')}>Fill</div>
          
                    <div className="ui colorChip" 
                    style={{
                      background: showStyleSelected('color', tempColor, dimension)
                    }}
                    onClick={() => animColorPicker(animOpen, 'colorPicker0')}></div>
                      <span id="colorPicker0"
                      className="colorPicker">
                        <ChromePicker
                        color={ tempColor ? tempColor : (dimension.html_text && dimension.html_text.css && dimension.html_text.css['color'])?dimension.html_text.css['color'] :""}
                        disableAlpha={true}
                        onChange={(color) => {
                          setTempColor(color.hex)
                          console.log('onChange, tempColor', color.hex, tempColor)
                        }}
                        onChangeComplete={(color, event) => {
                          if (color && !tempColor) {
                            updateText('color', color.hex)
                            console.log('onChangeComplete', tempColor, color.hex)
                          } else if (tempColor) {
                            updateText('color', tempColor)
                            console.log('onChangeComplete', tempColor, color.hex)
                          } else {
                            console.log('onChangeComplete: color not found')
                          }
                          
                         
                        }}

                        />
                      </span>
                </div>
                    </div>
                    </Fragment>
                  )}
                  

                  {!dimension.background && <div className={"settingsGroup" + ((dimension.file_format === "statePNGButton") ? (" off"):(""))}> 
                  <div className="groupLabel"><p>REFERENCE</p></div>
                    <div className={"settingsRow" + ((dimension.popup)?(' disabled'):(""))}>
                      <span className="canvasLabel">Link</span>
                      <input type="text" key={dimension.id + '_link'} disabled={(dimension.popup)?('disabled'):(false)} defaultValue={dimension.component_link} id={'link_' + dimension.id} onChange={() => props.setUnSavedChanges(true)} />
                     
                    </div>

                    <div className={"settingsRow" + ((dimension.popup)?(' disabled'):(""))}>
                     
                      <span className="canvasLabel">Link Heading</span>
                      <input type="text" key={dimension.id + '_link_heading'} disabled={(dimension.popup)?('disabled'):(false)} defaultValue={dimension.component_link_heading} id={'link_heading_' + dimension.id} onChange={() => props.setUnSavedChanges(true)} />
                    </div>
                    {dimension.component_link && <span className="canvasLabel ui" onClick={() => removeLink(dimension.id)}>Delete Link</span>}
                  </div>}

                  {(!dimension.background && !dimension.has_state_images) && <div className="settingsGroup" id="publicAnim">
                    {/* Animation Starts */}
                    <div className="groupLabel"><p>ANIMATION</p> </div>
                    {(!dimension.typeName || dimension.typeName === 'parallax')  && <span className="btn1" onClick={() => handleAnimChoice('page', dimension.id)}>Add Animation</span>}
                    {(dimension.typeName && dimension.typeName === 'page') && 
                    <React.Fragment>
                     
                    {<React.Fragment>
                    <ul className="dropDown">
                      <li>
                        <span className="dropDownBtn" onClick={() => setShowAnimPresetDropDown(current => !current)}>

                          <span className="dropDownValue">Select Animation</span>
                          <svg xmlns="http://www.w3.org/2000/svg" id="downArrow" width="21" height="12.968" viewBox="0 0 21 12.968">
                            <path d="M10.5,0,0,10.5l2.467,2.467L10.5,4.952l8.033,8.015L21,10.5Z" transform={(showAnimPresetDropDown)?('translate(0 0) rotate(0)'):('translate(21 12.968) rotate(180)')} fill="#fff"/>
                          </svg> 
                        
                          </span>

                        <ul className={"mediaDropDown " + ((showAnimPresetDropDown) ? ('on') : ('off'))}>
                          {props.animationPresets && props.animationPresets.filter((obj) => obj.animation_type === dimension.typeName).map((item, i) => 
                            <li className="animSelect" key={'animPreset_' + i} onClick={() => runAnimPreset(item, dimension.id, dimension.is_locked, dimension.display_name, dimension.partner_ids, false)}><span>{item.name}</span></li>
                         ) }

                        </ul>
                      </li>
                    </ul>
                    </React.Fragment>}

                    {(dimension.animations.map((anim, i) => 
                      
                      <div className="animObj" key={"anim_" + dimension.id + i}>
                        {anim.property && <div className="settingsRow">
                          <div className="animLabel">{anim.property} Animation <span className="clearValue" onDoubleClick={(e) => handleDeleteAnim(anim.id)}>&#215;</span></div>
                        </div>}
                        {!anim.property && <div className="animRow">
                        <div className="settingsRow">
                        <div className="canvasLabel">Animation Property</div>
                       
                        </div>

                        <div className="settingsRow">
                        { AnimationProperties.map((item, i) => 
                          <div key={'property_' + i} className={"animBtn" + ((anim.property === item.slug) ? (' on') : (''))} onClick={(e) => handleAnimValue(dimension.id, anim.id, 'property', item.slug, e)}>{item.name}</div>
                       ) }</div>
                        </div>}

                        <div className="animRow">
                          <div>                                
                            <div className="settingsRow halfRow">
                              <span className="canvasLabel">Start</span>
                              <input type="text" defaultValue={anim.start} key={anim.start + anim.id} id={"inputValue_start_" + anim.id} className="valueInput" autoComplete="off" onClick={(e) => handleInputFocus(e)} onKeyUp={(e) => handleAnimValue(dimension.id, anim.id, 'start', e.target.value, e)} />
                             
                            </div>

                            <div className="settingsRow halfRow rightRow">
                              <span className="canvasLabel">End</span>
                              <input type="text" placeholder={anim.end} id={"inputValue_end_" + anim.id} className="valueInput" onClick={(e) => handleInputFocus(e)} onKeyUp={(e) => handleAnimValue(dimension.id, anim.id, 'end', e.target.value, e)} />
                            </div>
                            
                            <ul className={"dropDown mediaDropDown inputDropDown off"} id={"inputValue_start_" + anim.id + '_dd'}>
                            
                            {(anim.property === 'opacity') && (Config.opacityChoices.map((item, i) => 
                              <li className="animSelect" key={"startSelect_"+ i}><span onClick={(e) => handleDropDownValue(e, item, "inputValue_start_" + anim.id, "inputValue_start_" + anim.id + '_dd', dimension.id, anim.id, 'start')}>{item}</span></li>
                            ))}

                            {(anim.property === 'scale') && (Config.scaleChoices.map((item, i) => 
                              <li className="animSelect" key={"startSelect_"+ i}><span onClick={(e) => handleDropDownValue(e, item, "inputValue_start_" + anim.id, "inputValue_start_" + anim.id + '_dd', dimension.id, anim.id, 'start')}>{item}</span></li>
                            ))}

                            {(anim.property === 'translateX') && (Config.xPosChoices.map((item, i) => 
                              <li className="animSelect" key={"startSelect_"+ i}><span onClick={(e) => handleDropDownValue(e, item, "inputValue_start_" + anim.id, "inputValue_start_" + anim.id + '_dd', dimension.id, anim.id, 'start')}>{item}</span></li>
                            ))}

                            {(anim.property === 'translateY') && (Config.yPosChoices.map((item, i) => 
                              <li className="animSelect" key={"startSelect_"+ i}><span onClick={(e) => handleDropDownValue(e, item, "inputValue_start_" + anim.id, "inputValue_start_" + anim.id + '_dd', dimension.id, anim.id, 'start')}>{item}</span></li>
                            ))}

                            </ul>

                            <ul className={"dropDown mediaDropDown inputDropDown off"} id={"inputValue_end_" + anim.id + '_dd'}>
                            {(anim.property === 'opacity') &&(Config.opacityChoices.map((item, i) => 
                              <li className="animSelect" key={"endSelect_"+ i}><span onClick={(e) => handleDropDownValue(e, item, "inputValue_end_" + anim.id, "inputValue_end_" + anim.id + '_dd', dimension.id, anim.id, 'end')}>{item}</span></li>
                            ))}
                            {(anim.property === 'scale') &&(Config.scaleChoices.map((item, i) => 
                              <li className="animSelect" key={"endSelect_"+ i}><span onClick={(e) => handleDropDownValue(e, item, "inputValue_end_" + anim.id, "inputValue_end_" + anim.id + '_dd', dimension.id, anim.id, 'end')}>{item}</span></li>
                            ))}
                            {(anim.property === 'translateX') &&(Config.xPosChoices.map((item, i) => 
                              <li className="animSelect" key={"endSelect_"+ i}><span onClick={(e) => handleDropDownValue(e, item, "inputValue_end_" + anim.id, "inputValue_end_" + anim.id + '_dd', dimension.id, anim.id, 'end')}>{item}</span></li>
                            ))}
                            {(anim.property === 'translateY') &&(Config.yPosChoices.map((item, i) => 
                              <li className="animSelect" key={"endSelect_"+ i}><span onClick={(e) => handleDropDownValue(e, item, "inputValue_end_" + anim.id, "inputValue_end_" + anim.id + '_dd', dimension.id, anim.id, 'end')}>{item}</span></li>
                            ))}
                              
                            </ul>

                          </div>
                          

                          {(dimension.typeName === 'page') && (<React.Fragment>

                          <div className="settingsRow halfRow">
                            <span className="canvasLabel">Duration</span>
                            <input type="number" defaultValue={anim.duration} key={anim.duration + anim.id} id={"inputValue_duration_" + anim.id} className="valueInput" min="0" onClick={(e) => handleInputFocus(e)} onKeyUp={(e) => handleAnimValue(dimension.id, anim.id, 'duration', e.target.value, e)} step="0.01" />
                          </div>

                          <div className="settingsRow halfRow rightRow">
                            <span className="canvasLabel">Delay</span>
                            <input type="number" defaultValue={anim.delay} key={anim.delay + anim.id} id={"inputValue_delay_" + anim.id} className="valueInput" min="0" onClick={(e) => handleInputFocus(e)} onKeyUp={(e) => handleAnimValue(dimension.id, anim.id, 'delay', e.target.value, e)} step="0.01" />
                          </div>

                          <ul className={"dropDown mediaDropDown inputDropDown off"} id={"inputValue_duration_" + anim.id + '_dd'}>
                            {(Config.durationChoices.map((item, i) => 
                              <li className="animSelect" key={"durationSelect_"+ i}><span onClick={(e) => handleDropDownValue(e, item, "inputValue_duration_" + anim.id, "inputValue_duration_" + anim.id + '_dd', dimension.id, anim.id, "duration")}>{item}</span></li>
                            ))}
                            </ul>

                          <ul className={"dropDown mediaDropDown inputDropDown off"} id={"inputValue_delay_" + anim.id + '_dd'}>
                            {(Config.delayChoices.map((item, i) => 
                              <li className="animSelect" key={"delaySelect_"+ i}><span onClick={(e) => handleDropDownValue(e, item, "inputValue_delay_" + anim.id, "inputValue_delay_" + anim.id + '_dd', dimension.id, anim.id, "delay")}>{item}</span></li>
                            ))}
                            </ul>
                        
                          </React.Fragment>)}

                      <div className="settingsRow">
                      <span className="canvasLabel">Ease</span>
                        {Config.EasingProperties.map((item, i) => <div key={'easing_' + i} className={"animBtn" + ((anim.ease === item.slug) ? (' on') : (''))} onClick={(e) => handleAnimValue(dimension.id, anim.id, 'ease', item.slug, e)}>{item.name}</div> )}
                      </div>
                    </div>
                  </div>

                    ))}
                    </React.Fragment>}


                  </div>}
                  
                   {/* ANIMATION Ends */}
                  

                  </div>
                 
               
                 
                  
                  <div className="settingsRow" id="saveRow">
                      <div className={"closeFormBtn btn" + ((props.unSavedChanges) ? (" unsaved") : (" saved"))} onClick={() => props.unSavedChanges ? props.showSettings(dimension.id, true, null):null}>Save</div>
                      <div className="iconField ui rightNavDeleteBtn noSubEvents" onMouseEnter={(e) =>props.onMouseEnter(e, "Double click to delete")} onMouseLeave={() => props.onMouseLeave() } onDoubleClick={() => handleDeleteMedia(dimension.id)}><Icons.Delete className="icon"/><div className="canvasLabel">Delete File</div></div>
                  </div>
                  
                </div>   
              </div>
            </div>
           </div>
           ):null
         )}
         
         </div>     
         
    

         </div>
        
    )
}

export default LayerSettings;