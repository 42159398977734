import Cookies from 'js-cookie';
import {getFileType} from "../../utils/helpers";
import gsap from "gsap";
import { apiCall } from "../narrativeUtils/fetch";

function handleLayerAddedMain(
    format, 
    subformat, 
    y_pos, 
    x_pos, 
    Config,
    files,
    activeSectionObj, 
    unSavedChanges, 
    sectionId, 
    layerFormat, 
    aspectRatio, 
    newMedia, 
    setNewMedia, 
    setAcceptValues,
    setFormError,
    setShowLoader,
    setNewComponentID,
    setNewComponentDimension
    ) 
    
    {

    let layerCount = activeSectionObj.components.length

    if (layerCount < Config.validations.maxLayers) {
      let newError;
      console.log('handleLayerAdded', format, subformat)

      if (unSavedChanges) {
        newError = {
          "heading": "Warning",
          "text": "You have unsaved changes."
        };
      } 
     
      var formData = new FormData();
      let fileFormat;
      let svgShape;
      let formHeaders = {};
      let hasBackground = activeSectionObj.has_background;
      
      formData.append('section_id', sectionId);
      formData.append('display_id', 1);

      // let formatArray = format0.split("-");
      // let format = formatArray[0];
      switch (format) {
        case "media":
          console.log("hasBackground", hasBackground);
          var fileList = files;
          var arrayLength = fileList.length;
          for (var i = 0; i < arrayLength; i++) {
            if (layerFormat.current === "normal") {
              fileFormat = getFileType(fileList[i].name);
          
            } else {
              fileFormat = layerFormat.current
            }
            formHeaders = {
              'Content-Disposition': 'attachment; filename=' + fileList[i].name,
              'Authorization': 'Token ' + Cookies.get('_udxdid')
            }
            let fileType = getFileType(fileList[i].name);
            let fileField;
            switch (fileType) {
              case "vid":
                fileField = 'video';
                break;
              case "svg":
                fileField = 'svg';
                break;
              default:
                fileField = 'image';
                break;
            }

            // File Checks
            let sizeMB = parseFloat(fileList[i].size / (1024 ** 2)).toFixed(2);

            if (sizeMB > Config.validations.maxFileSize) {
                newError = {
                  "heading": "Error",
                  "text": "The selected file is <strong>" + sizeMB + "MB</strong>.<br/>This file exceeds maximum upload size of <strong>10MB</strong>.<br/><br/>Please select a new file that is less than 10MB."
                };

            } else {

              var mediaData = {
                'display': aspectRatio,
                'section_id': sectionId,
                'file': fileList[i]
              }
          

              var currNewMedia = [...newMedia];
              currNewMedia.push(mediaData);
              setNewMedia(currNewMedia);

                formData.append(fileField, fileList[i], fileList[i].name) 
                if (hasBackground) {
                  formData.append('background', false);
                } else {
                  formData.append('background', true);
                }

              } 

            }
          break;
      
        case "text":
          formData.append('background', false);
          formData.append('width', "600");
          formData.append('height', "60");
          fileFormat = "text";
          // setNewText(true);
          formHeaders = {
            'Authorization': 'Token ' + Cookies.get('_udxdid')
          }
          break
        case "svg":
          fileFormat = "svg";
          let svgFile = document.createElementNS("http://www.w3.org/2000/svg", "svg");
          svgFile.setAttribute('width', '600');
          svgFile.setAttribute('height', '600');
          svgFile.setAttribute('viewBox', "0 0 600 600");
          svgFile.setAttribute('xmlns', 'http://www.w3.org/2000/svg')
          const svgns = "http://www.w3.org/2000/svg";
          let newRect = document.createElementNS(svgns, subformat);
          gsap.set(newRect, {
            attr: { x: 0, y: 0, width: "100%", height: '100%', fill: "#5cceee" }
          });          
          svgFile.appendChild(newRect);
          let blob = new Blob([svgFile.outerHTML], {type: 'image/svg+xml'});

          let fileName = 'rectangle.svg';
          formData.append('svg', blob, fileName);
          formData.append('background', false);
          formHeaders = {
            'Content-Disposition': 'attachment; filename=' + fileName,
            'Authorization': 'Token ' + Cookies.get('_udxdid')
          }

          break;
        default:
          console.log("handleLayerAdded error: no format")
          break;
      }
      
      formData.append('file_format', fileFormat);

      if (y_pos) {
        formData.append('y_pos', y_pos);
      }
      if (x_pos) {
        formData.append('x_pos', x_pos);
      }

      for (var pair of formData.entries()) {
        console.log(pair[0]+ ', ' + pair[1]); 
      }

      let fileInput = document.getElementById('fileUpload');
      fileInput.files = null;
      fileInput.value = "";


      console.log('formData', formData, formHeaders)

      if (!newError) {
        setShowLoader(true);
        let api_url = Config.baseURLcms + 'section-component-add/';

        function responseTask(response){
          console.log('handleLayerAdded', response, hasBackground);
          setAcceptValues && setAcceptValues(null);
          setNewComponentID(response.data['component_id']);
          
          var newJSON = {
            'id': response.data['dimension_id'], 
            'is_background': !hasBackground 
          };
          setNewComponentDimension(newJSON);
          console.log('newJSON', response.data)
          if (response.data['format'] === "statePNGButton") {
              // This is a temp workaround. Need to pass new sections to layer settings;
              window.location.reload();
         
          }
         
        }
        apiCall("POST", api_url, formData, responseTask, formHeaders)


       

      } else {
          setFormError(newError);
      }
    } else {
      let newError = {
        "heading": "Note",
        "text": "You current have <strong>" + (layerCount-1) + "</strong> layers, the maximum number of pages permitted in a narrative."
      };
      setFormError(newError);
    }

}

export { handleLayerAddedMain }