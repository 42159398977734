import Icons from '../component/module.icons';

function stripImg(string) {
    var x = string.replace('img/', '');
    // var y = x.replace('.png', "");
    return x;
  }
  
  function getFileType(string) {
    let fileType;
    var ext = string.split('.').pop();
    switch (ext) {
      case "png":
        fileType = "img";
        break;
      case "jpg":
        fileType = "img";
        break;
      case "jpeg":
        fileType = "img";
        break;
      case "webp":
        fileType = "img";
        break;
      case "mp4":
        fileType = "vid";
        break;
      case "svg":
        fileType = "svg";
        break;
      default:
        break;
    }
    return fileType;
  }
  
  
  function fileNameType(string, layerName) {
    let x0 = string.replace('img/', '');
    let x1 = x0.replace('svg/', '');
    let x = x1.replace('video/', '');
    var ext = string.split('.').pop();
    let x2;
    if (layerName) {
      x2 = layerName;
    } else {
      x2 = x.replace('.'+ext, '');
    }
  
    // console.log('fileNameType', ext, x2, string);
    let icon;
    switch (ext) {
      case "png":
        icon = <Icons.Image className="icon" />
        break;
      case "jpg":
        icon = <Icons.Image className="icon" />
        break;
      case "jpeg":
        icon = <Icons.Image className="icon" />
        break;
      case "webp":
        icon = <Icons.Image className="icon" />
        break;
      case "svg":
        icon = <svg className="icon" />
        break;
      case "mp4":
        icon = <Icons.Video className="icon" />
        break;
      default:
        icon = <Icons.Wysiwyg className="icon" />
        break;
    }
    if (x2.length > 18) {
      x2 = x2.substring(0,18) + ' ...'
    }
    let result = [icon, x2];
    return result;
  }
  
  function toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  function round30(x) {
    let y = Math.ceil(x / 30) * 30;
    // console.log('y', y);
    return y;
  }

  function humanizeFontWeight(weight) {
    let r;
    switch (weight) {
      case 'normal':
        r = 'Regular'
        break;
      default:
        r = weight
        break;
    }
    return r
  }

  

  export {stripImg, getFileType, fileNameType, toTitleCase, round30, humanizeFontWeight}