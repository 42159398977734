import React, { useState, useEffect, useRef } from "react";
import imagePlaceholder from '../../static/img/image.png'
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/mousewheel';
import 'swiper/css/effect-fade';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

function Timeline(props) {
    
  useEffect(() => {
    // console.log('timeline', props)
  }, [props])

    function containsObject(objID, list) {
      if (list)
      {
        var i;
        for (i = 0; i < list.length; i++) {
            if (list[i].display === objID) {
              if (list[i].width) {
                return (list[i].width * 0.075) + 'px';
              } else {
                return 'auto';
              }
                
            }
        }
      }

      return '155px';
  }

  function getSectionBackgroundImage(objID, list) {
    if (list) {
    var i;
    for (i = 0; i < list.length; i++) {
      // console.log('list[i]', list[i]);
        if (list[i].display === objID) {
            if (list[i].screenshot) {
              return list[i].screenshot
            } else {
              return list[i].image
            }
            
        }
    }

    return imagePlaceholder;
    }
  }
    

    return(
        <div className="fieldRow" id="canvasRow">
            
                      
        {props.sections && (props.sections.length > 0) ?
          (<Swiper
              className="canvasSwiper"
              spaceBetween={0}
              slidesPerView={"auto"}
              onSwiper={(swiper) => props.setSwiperInst(swiper)}
          >
          {props.sections
            .sort((a, b) => a.ordering - b.ordering)
            .map((obj, i) => 
            <SwiperSlide 
              key={'swiper_' + i}
              onClick={(e) => props.handlePageClick(obj)}
              className={"canvasFrame aspectRatio_" + (props.aspectRatio) + ((obj&& (obj.id === props.sectionId)) ? " on" : " off")}
              data-id={obj.id}
              data-ordering={obj.ordering}
              style={{
                'width': (obj && obj.widths ? containsObject(props.aspectRatio, obj.widths): 'auto')
              }}
            > 
              <div>
              {(obj && obj.nav)?(<div className="cueLine"><div className="cueLineTitle">{(obj.nav.heading && obj.nav.heading.length > 19)?(obj.nav.heading.substring(0,19) + '...'):obj.nav.heading}</div></div>):null}
              
              <div className="canvasThumbBorder">
              <img className="backgroundThumb" 
                src={getSectionBackgroundImage(props.aspectRatio, obj.widths) + "?t=" + obj.screenshot_timestamp}
                alt="" />
              </div>
              </div>
  
            </SwiperSlide>
            
          )}

          </Swiper>) : null}
          
      </div>
    )
}

export default Timeline;

// {console.log('props.sections', props.sections)}