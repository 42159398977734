function Help(props) {

    return ( 
        <span id="helpHover" className="helpHover">
            <span className="helpHoverIn">
                <span className="helpHoverText">
                    {props.text}
                </span>
                <span className="helpHoverCaret"></span>
            </span>
        </span>
    )

}

export default Help;