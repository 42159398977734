function speedTest() {    
    var userImageLink = "https://present.udxd.co/media/core/10mb.jpg";
    var startTime, downloadSize;

    return new Promise((resolve, reject) => {
        // The size in bytes
        // downloadSize = 23546233;
        downloadSize = 10174706;
        let downloadImgSrc = new Image();
    
        startTime = new Date().getTime();
        downloadImgSrc.src = userImageLink;
    
        downloadImgSrc.onload = function () {
            let endTime = new Date().getTime();
            if (endTime) {
                var timeDuration = (endTime - startTime) / 1000;
                var loadedBits = downloadSize * 8;
                var bps = (loadedBits / timeDuration).toFixed(2);
                var speedInKbps = (bps / 1024).toFixed(2);
                var speedInMbps = (speedInKbps / 1024).toFixed(2);
                resolve(speedInMbps);
              } else {
                reject("Error getting image");
              }
            return 
        };
        
        
      });
 

      
    }

export default speedTest;