import React, { useState, useEffect, useRef } from "react";
import { apiCall } from "./narrativeUtils/fetch";
import Config from "../Config.json";
import { utcToZonedTime, format, zonedTimeToUtc } from 'date-fns-tz';
import Modal from './module.modal';

function Version(props) {

    const [changes, setChanges] = useState();
    const [showWarning, setShowWarning] = useState(false);
    const [changeArray, setChangeArray] = useState(null);

    function fetchChanges() {
        const get_url = Config.baseURLcms + "versions/" + props.contentTypeId + "/" + props.obj.id + "/";

        function responseTask(response) {
            const assetJSON = response.data;
            if (assetJSON.length > 0) {
                setChanges(assetJSON);
            } else {
                setChanges(null)
            }
            
            console.log('changes', assetJSON)
        }
        apiCall("get", get_url, {}, responseTask)
        }

    useEffect(() => {
        if (props.obj.id) {
            // NEED TO TURN THIS BACK ON TO MAKE START
            // fetchChanges();
        }
    }, [props.obj.id])

    // useEffect(() => {
    //     if (props.location === "page") {
    //         const get_url = Config.baseURLcms + "versions/" + props.user.username + "/";

    //         function responseTask(response) {
    //             const assetJSON = response.data;
    //             if (assetJSON.length > 0) {
    //                 setChanges(assetJSON); 
    //             }
    //             // setChanges(assetJSON);
    //             // console.log('changes', assetJSON)
    //         }
    //         apiCall("get", get_url, {}, responseTask)    
    //     }
    //     console.log(props.location, props.obj.id, props.user.username)

    // }, [props.location, props.obj])

    function onVersionChange(objId, newObj, changeId) {
        setChangeArray([objId, newObj, changeId])
        setShowWarning(true)
    }

    function handleVersionChange(vals) {
        props.setObj(vals[0], vals[1])
        props.setForceVersion(vals[2]);
        setShowWarning(false)
        // setTimeout(() => {
        //     props.saveObj();
        // }, 500);
        
     
    }

    return (
        <div className="row verionRow">

            {(showWarning)&&(
            <Modal heading={"Caution"} text={"<p>Do you want to replace the current page with an earlier version?</p>"} onBtn1Click={setShowWarning} onBtn1={false} btn1Text={"Cancel"} onBtn2Click={handleVersionChange} onBtn2={changeArray} btn2Text={"Continue"} />
          )}

          
            <div className="groupLabel"><p>Version History</p></div>
            {changes && changes.map((obj, i) => 
                
                <span onClick={() => onVersionChange(props.obj.id, obj.change, obj.id)} className="ui version" key={'version_' + i}>
                    <p>{format(new Date(zonedTimeToUtc(obj.timestamp, Intl.DateTimeFormat().resolvedOptions().timeZone)), "MMM d, hh:mm a")}</p>
                    <p className="versionUser">{obj.username}</p>
                </span>
           
            )}
            {!changes && <p className="versionUser">—</p>}
        </div>
    )

}

export default Version;