import React, { useState, useEffect, useRef} from "react";
import axios from 'axios';
import {
  useNavigate,
  NavLink,
  useSearchParams
} from "react-router-dom";
import gsap from "gsap";
import Loader from "../static/svg/loader.svg";
import Config from "../Config.json"
import Cookies from 'js-cookie';
import Modal from './module.modal';
// Icons
import {ReactComponent as Add} from "../static/icons/library_add.svg" 
import {ReactComponent as ArrowBack} from "../static/icons/arrow_back.svg";
import {ReactComponent as Recent} from "../static/icons/schedule.svg";
import {ReactComponent as Draft} from "../static/icons/draft.svg";
import {ReactComponent as TipsUpdates} from "../static/icons/tips_and_updates.svg";
import {ReactComponent as Delete} from "../static/icons/delete.svg";
import {ReactComponent as Group} from "../static/icons/group.svg";
import {ReactComponent as Cloud} from "../static/icons/cloud.svg";
import {ReactComponent as Download} from "../static/icons/download.svg";
import {ReactComponent as OpenLink} from "../static/svg/openLink-w.svg";
import {ReactComponent as Lock} from "../static/svg/padlock.svg";
import HelpPDF from "../static/pdf/380S-CMSoverview-2023.07.12.pdf";

function Narratives(props) {
  
    const [search, setSearch] = useSearchParams();
    const navigate = useNavigate();
    const formInput = useRef();
    
    const [narratives, setNarratives] = useState([]);
    const [showLoader, setShowLoader] = useState(true);
    const [fetchPath, setFetchPage] = useState();
    const [nextPage, setNextPage] = useState();
    const [prevPage, setPrevPage] = useState();
    const [objCount, setObjCount] = useState(1);
    const [showContextMenu, setShowContextMenu] = useState();
    const [status, setStatus] = useState();
    const [showNarrativeForm, setShowNarrativeForm] = useState(false);
    const [formError, setFormError] = useState();

    function fetchNarratives(path) {
      // console.log('fetch')
      let statusPath;
 
      if (status) {
        statusPath = path + '&status=' + status;
      } else {
        statusPath = path + '&status=live,draft,new';
      }
      // console.log('statusPath', statusPath)
      try {
          axios({
              method: 'get',
              url: statusPath,
              headers: {
                Authorization: 'Token ' + Cookies.get('_udxdid')
              },
          })
          .then(response => {
              const assetJSON = response.data;
              // console.log(status)
              if (status) {
                if (status === 'draft') {
                  setNarratives(assetJSON.results.filter(a => a.status === status || a.status === 'new'));
                } else {
                  setNarratives(assetJSON.results.filter(a => a.status === status));
                }
                
              } else {
                setNarratives(assetJSON.results.filter(a => a.status !== 'deleted'));
              }
              setShowLoader(false);
              // console.log('narratives', assetJSON)
              setNextPage(assetJSON.next);
              setPrevPage(assetJSON.previous);
              setObjCount(assetJSON.count)
          });
      } catch (error) {
          console.error(error);
      }
    };
    
  useEffect(() => {        
      // fetchNarratives()
      setFetchPage(Config.baseURL + '/api/cms/narratives/?page=1');

      props.setTools('search');


      let statusParam = search.get('s');
      if (statusParam) {
        setStatus(statusParam);
      }
      

  }, []);


  useEffect(() => {        
    // fetchNarratives()
    if (fetchPath) {
      setShowLoader(true);
      fetchNarratives(fetchPath)
    }
}, [fetchPath, status]);

useEffect(() => {
  if (status) {
    setSearch({'s': status})
  } else {
    setSearch()
  }
  
}, [status])

  useEffect(() => {
    // console.log('narratives', narratives);
  }, [narratives]);


  function onNewNarrativeForm(){
    let narrativeCount = narratives.length;
    if (narrativeCount < Config.validations.maxPresentations) {

     setShowNarrativeForm(true)
    } else {
      let newError = {
        "heading": "Note",
        "text": "You current have <strong>" + narrativeCount + "</strong> presentations, the maximum number of presentations permitted for your organization."
      };
      setFormError(newError);
    }
    


  }

  function handleAddNarrative() {

    let narrativeCount = narratives.length;
    if (narrativeCount < Config.validations.maxPresentations) {

      var dataJSON = {
        "title": formInput.current,
        "username": props.user.username
      }
      console.log('data', dataJSON)
      if (formInput.current) {
        axios({
          method: 'post',
          url: Config.baseURL + '/api/cms/narrative-add/',
          data: dataJSON,
          headers: {
            Authorization: 'Token ' + Cookies.get('_udxdid')
          },
      })
      .then(response => {
          // fetchNarratives();
          // setFetchPage(Config.baseURL + '/api/cms/narratives/?page=1');
          console.log('response', response.data)
          response && navigate("/narratives/" + response.data )
          formInput.current = null;

      })
      .catch(error => {
          console.log('error', error.response);
      });

      }
    } else {
      let newError = {
        "heading": "Note",
        "text": "You current have <strong>" + narrativeCount + "</strong> presentations, the maximum number of presentations permitted for your organization."
      };
      setFormError(newError);
    }
    


  }

  function onNarrativeContext(e, objId) {
    // console.log('props', props)
    e.preventDefault();
    setTimeout(() => {
      setShowContextMenu(objId);
      // console.log('e', e.pageY, e.target, e)
      gsap.to(".contextMenu", {zIndex: 3, x: e.pageX, y: e.pageY - 20, duration: 0});
      gsap.to(".contextMenu", {opacity: 1, duration: 0.3}, "-=0.3");
      let listener = window.addEventListener('click', function(){
        setShowContextMenu(false);
        gsap.to(".contextMenu", {opacity: 0, zIndex: -1, duration: 0});
      })
      window.removeEventListener('click', listener); 
    }, 100);
    
  }

  function handleNarrativeSave(objId, field, value) {
    setShowLoader(true)
    const api_url = Config.baseURL + '/api/cms/narrative-settings/' + objId + "/";
    var dataJSON = {};
    if (field !== 'title') {
      dataJSON['title'] = narratives.find(a => a.id === objId).title
    }
    dataJSON[field] = value;

    console.log('handleNarrativeSave', dataJSON)
    
    axios({
        method: 'POST',
        url: api_url,
        data: dataJSON,
        headers: {
          Authorization: 'Token ' + Cookies.get('_udxdid')
        },
    })
    .then(response => {
      fetchNarratives(Config.baseURL + '/api/cms/narratives/?page=1');
      setFetchPage(Config.baseURL + '/api/cms/narratives/?page=1');
      console.log('response, del', response.data)
    })
    .catch(error => {
        console.log('error', error.response);
    });
  }

  function trash(objId) {
    setFormError(null);
    setShowLoader(true);
    axios({
        method: 'post',
        url: Config.baseURL + '/api/cms/narrative-delete/',
        data: {"id": objId, "trash": true},
        headers: {
          Authorization: 'Token ' + Cookies.get('_udxdid')
        },
    })
    .then(response => {
        fetchNarratives(Config.baseURL + '/api/cms/narratives/?page=1');
        setFetchPage(Config.baseURL + '/api/cms/narratives/?page=1');
        console.log('response, del', response.data)
        // setFormError(null)
        showLoader(false);
    })
    .catch(error => {
      console.log('error', error.response);
    });
  }


  function handleNarrativeContextBtn(objId, action) {
    
    switch (action) {
      case "lock":
        setShowLoader(true);
        axios({
            method: 'post',
            url: Config.baseURL + '/api/cms/narrative-lock/',
            data: {"id": objId, "username": props.user.username, "lock": true},
            headers: {
              Authorization: 'Token ' + Cookies.get('_udxdid')
            },
        })
        .then(response => {
            fetchNarratives(Config.baseURL + '/api/cms/narratives/?page=1');
            setFetchPage(Config.baseURL + '/api/cms/narratives/?page=1');
            console.log('response, dup', response.data)
        })
        .catch(error => {
            console.log('error', error.response);
        });
        break;
      case "unlock":
          setShowLoader(true);
          axios({
              method: 'post',
              url: Config.baseURL + '/api/cms/narrative-lock/',
              data: {"id": objId, "username": props.user.username, "lock": false},
              headers: {
                Authorization: 'Token ' + Cookies.get('_udxdid')
              },
          })
          .then(response => {
              fetchNarratives(Config.baseURL + '/api/cms/narratives/?page=1');
              setFetchPage(Config.baseURL + '/api/cms/narratives/?page=1');
              console.log('response, dup', response.data)
          })
          .catch(error => {
              console.log('error', error.response);
          });
          break;
      case "dup":

      let narrativeCount = narratives.length;
      if (narrativeCount < Config.validations.maxPresentations) {

        setShowLoader(true);
        axios({
            method: 'post',
            url: Config.baseURL + '/api/cms/narrative-duplicate/',
            data: {"id": objId, "username": props.user.username},
            headers: {
              Authorization: 'Token ' + Cookies.get('_udxdid')
            },
        })
        .then(response => {
           
            console.log('response, dup', response.data)

            let logId = response.data['log_id']

            setInterval(() => {
              try {
                axios({
                    method: "get",
                    url: Config.baseURL + "/api/cms/narrative-duplicate-status/" + logId,
                    headers: {
                      Authorization: 'Token ' + Cookies.get('_udxdid')
                    },
                }).then((response) => {
                
                    if (response.data['complete']) {
                      fetchNarratives(Config.baseURL + '/api/cms/narratives/?page=1');
                      setFetchPage(Config.baseURL + '/api/cms/narratives/?page=1');
                      console.log('duplicate ready')
                    } else {
                      console.log('still duplicating', logId)
                    }
                    
                    
                   
                   
                    
                });
            } catch (error) {
                console.error(error);
            }
            }, 10000);


        })
        .catch(error => {
            console.log('error', error.response);
        });

      } else {
        let newError = {
          "heading": "Note",
          "text": "You current have <strong>" + narrativeCount + "</strong> presentations, the maximum number of presentations permitted for your organization."
        };
        setFormError(newError);

      }
        break;
      case "del": 
        setShowLoader(true);
        axios({
            method: 'post',
            url: Config.baseURL + '/api/cms/narrative-delete/',
            data: {"id": objId},
            headers: {
              Authorization: 'Token ' + Cookies.get('_udxdid')
            },
        })
        .then(response => {
            fetchNarratives(Config.baseURL + '/api/cms/narratives/?page=1');
            setFetchPage(Config.baseURL + '/api/cms/narratives/?page=1');
            console.log('response, del', response.data)
        })
        .catch(error => {
            console.log('error', error.response);
        });
        break;
      case "trash":
        let newError = {
          "heading": "Caution",
          "text": "Are you sure you want to delete this narrative?<br />This action cannot be undone.",
          "btn2Text": "Continue",
          "onBtn2Click": trash,
          "onBtn2": objId
        };
        setFormError(newError);
        console.log('handleNarrativeContextBtn', action, objId, newError)
        break;

      case "act":
        setShowLoader(true);
      handleNarrativeSave(objId, 'status', 'draft')
        break;
    
      default:
        break;
    }

    setShowContextMenu(false);
    gsap.to(".contextMenu", {opacity: 0, zIndex: -1, duration: 0});


  }

  async function onDownload(){
    let result = await navigator.userAgentData.getHighEntropyValues(['architecture']);
    let build;
    let baseUrl = "https://present.udxd.co/download/";
    if (result.platform === "macOS") {
      if (result.architecture === "arm") {
        build = 'PresentationDesktop-arm64-mac.dmg'
      } else {
        build = 'PresentationDesktop-mac.dmg'
      }
    } else {
      build = 'Presentation-Desktop-Setup-beta.exe'
    }
    console.log('result', result, build);
    return window.open(baseUrl + build, '_blank');
    
  }



  let activeClassName = "on";

  return (

      <section className="main" id="narrativesPage">
       {(showLoader)?(<div id="loading"><img src={Loader} alt="" /></div>):null}

       {(formError)&&<Modal heading={formError.heading} text={formError.text} onBtn1Click={setFormError} onBtn1={null} btn1Text={"Cancel"} onBtn2Click={formError.onBtn2Click} onBtn2={formError.onBtn2} btn2Text={formError.btn2Text} />}

       {showNarrativeForm && <Modal heading={"New Presentation"} formInput={<div className="settingsRow"><input type="text" autoComplete="off" id="narrativeTitle" required onChange={(e) => {formInput.current = e.target.value}} placeholder="Add a Title" /></div>} onBtn1Click={setShowNarrativeForm} onBtn1={false} btn1Text={"Cancel"} onBtn2Click={handleAddNarrative} onBtn2={null} btn2Text={"Create"} />}

       {showContextMenu !== false && (status !== 'deleted') && <div className="contextMenu">
          {(narratives.find(a => a.id === showContextMenu) && narratives.find(a => a.id === showContextMenu).locked) ? <span className="contextBtn" onClick={() => handleNarrativeContextBtn(showContextMenu, "unlock")}>Unlock</span> : <span className="contextBtn" onClick={() => handleNarrativeContextBtn(showContextMenu, "lock")}>Lock</span>}
          <span className="break"></span>
          <span className="contextBtn" onClick={() => handleNarrativeContextBtn(showContextMenu, "dup")}>Duplicate</span>
          <span className="break"></span>
          <span className="contextBtn" onClick={() => handleNarrativeContextBtn(showContextMenu, "del")}>Delete</span>
        </div>}

        {showContextMenu !== false && (status === 'deleted') && <div className="contextMenu">
          <span className="contextBtn" onClick={() => handleNarrativeContextBtn(showContextMenu, "act")}>Return to Draft</span>
          <span className="break"></span>
          <span className="contextBtn" style={{display: "none"}} onClick={() => handleNarrativeContextBtn(showContextMenu, "trash")}>Delete Permanently</span>
        </div>}


        
        <div className="col appGrid">
          <nav className="leftNav homeLeftNav">
              <div className="leftNavGroup">
                <div className="groupLabel">FILES</div>
                <div onClick={() => setStatus(null)} className="homeBtn ui">
                  <div className="iconField"><Recent className="icon" />Recent</div>
                </div>
                <div onClick={() => setStatus("live")} className="homeBtn ui">
                  <div className="iconField"><Cloud className="icon" />Live</div>
                </div>
                <div className="homeBtn ui">
                  <div onClick={() => setStatus("draft")} className="iconField"><Draft className="icon" />Drafts</div>
                </div>
                <div onClick={() => setStatus("deleted")} className="homeBtn ui">
                  <div className="iconField"><Delete className="icon" />Deleted</div>
                </div>
              </div>
              <div className="leftNavGroup" style={{display: "none"}}>
                <div className="groupLabel">SHARED</div>
                <div className="homeBtn ui">
                <div className="iconField"><Group className="icon" />Shared With You</div>
                </div>
              </div>
              <div className="leftNavGroup">
                <div className="groupLabel">HELP</div>
                <div className="homeBtn ui">
                
                <a href={"/learn"} className="iconField"><TipsUpdates className="icon" />Learn</a>
  
              </div>
              </div>
              <div className="leftNavGroup">
              <div className="groupLabel">DOWNLOADS</div>
                <div className="homeBtn ui">
                  <div className="iconField" onClick={() => onDownload()}>Desktop <Download className="icon" /></div>
                </div>
               
            </div>
          </nav>
          <div className="core">
              <div className="row">
                <div className="iconField ui addNew" onClick={() => onNewNarrativeForm()}>
                  <Add className="icon" /> New Presentation
                </div>
              </div>
              <div className="grid">
              {(narratives.map((narrative, i) =>
                <NavLink 
                  key={"narrative_" + narrative.id}
                  to={((narrative.status !== 'deleted' && !narrative.locked) ?('/narratives/' + narrative.id):"#")}
                  onContextMenu={(e) => onNarrativeContext(e, narrative.id)}
                  // onClick={() => handlePageChange(route.path)}
                  className={({ isActive }) =>
                      ("gridItem" + (isActive ? " " + activeClassName : "") + " " + narrative.status + (narrative.locked && " locked"))
                    }
                >
                    <div className="gridThumb">
         
                      {narrative && narrative.screenshot && <img src={Config.mediaURL + narrative.screenshot} alt="narrative thumb" />}
                    </div>
                    
                    <span className="gridHeading">{narrative.title} {narrative.status === 'new' ? <span className="newIcon"><svg className="iconSm" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><circle cx="8" cy="8" r="8" /></svg></span>:""} {narrative.locked && <span className="lock"><Lock className="icon" /></span>}</span>
                    
                </NavLink>
        
              ))}
              </div>
              <div className="pageNav">
                    <div className={"prevPage iconField" + (!prevPage ? " disabled" : " ui")} onClick={() => prevPage && setFetchPage(prevPage)}><ArrowBack className="icon" />Previous</div>
                    <div className={"nextPage iconField" + (!nextPage ? " disabled" : " ui")} onClick={() => nextPage && setFetchPage(nextPage)}>Next<ArrowBack className="icon flip" /></div>
                    
              </div>
          </div>      
        </div>
      </section>

   
  )

}
export default Narratives;


// <div className="homeBtn ui">
// <div className="iconField">iPad Controller <OpenLink className="icon2" /></div>
// </div>
// <div className="homeBtn ui">
// <div className="iconField">iPad <OpenLink className="icon2" /></div>
// </div>