import React, { useState, useEffect, useRef } from "react";
import { handleZoom } from "../narrativeUtils/canvas"
import Config from "../../Config.json";
import MenuList from '../module.menulist';
import Modal from '../module.modal';
import Icons from '../module.icons';
import Cookies from 'js-cookie';
import gsap from "gsap";
import axios from "axios";
import {getFileType} from "../../utils/helpers";
import { apiCall } from "../narrativeUtils/fetch";
import { handleLayerAddedMain } from "../narrativeUtils/onLayerAdded";
import {ReactComponent as AutoRenew} from "../../static/icons/autorenew.svg"
import {ReactComponent as OpenLink} from "../../static/svg/openLink-w.svg";

function NarrativeTools(props) {

  const escListener = useRef();
  const clickListener = useRef();
  const textSheild = useRef();
  const layerFormat = React.useRef();
  const [acceptValues, setAcceptValues] = useState('init');
  const [activeBtn, setActiveBtn] = useState();
  const [narrativeUpdating, setNarrativeUpdating] = useState(false);

  function handleLayerAdded( format, 
    subformat, 
    y_pos, 
    x_pos) {

    let files = document.getElementById('fileUpload').files;

    handleLayerAddedMain(
      format, 
      subformat, 
      y_pos, 
      x_pos, 
      Config, 
      files,
      props.activeSectionObj, 
      props.unSavedChanges, 
      props.sectionId, 
      layerFormat, 
      props.aspectRatio, 
      props.newMedia, 
      props.setNewMedia, 
      setAcceptValues,
      props.setFormError,
      props.setShowLoader,
      props.setNewComponentID,
      props.setNewComponentDimension
    )

  }


  function handleMediaChoice(isNormal) {
        setAcceptValues(null);
        let fileInput = document.getElementById('fileUpload');
        fileInput.files = null;
        fileInput.value = "";

        function handleMediaChoiceValue(isNormal, callback) {
          if (isNormal) {
            layerFormat.current = "normal";
            setAcceptValues("image/png, image/jpeg, image/webp, video/mp4, image/svg+xml");
          } else {
            layerFormat.current = "statePNGButton";
            setAcceptValues("image/png, image/jpeg, image/webp, image/svg+xml");
          }
          callback();
        }
  
        function setupFileUpload() {
          var fileElem = document.getElementById('fileUpload');
  
          setTimeout(() => {
            fileElem.click();
          }, 150);
        }
  
        handleMediaChoiceValue(isNormal, setupFileUpload);
  
  }

  

    function handleShowFullCanvas(e) {
        if (props.showFullCanvas) {
            props.setShowFullCanvas(false);
        } else {
            props.setShowFullCanvas(true);
        }
        
      }
  
      function handleAspectRatio(index) {
        props.setAspectRatios(index);
        
    }

    function handleTextClick(e) {
      function calcLeft(e){
        let r = e.clientX - e.target.getBoundingClientRect().x;
        return parseInt(r/props.zoomRatio)
      }
  
      function calcTop(e){
        let r = e.clientY - e.target.getBoundingClientRect().y
        return parseInt(r/props.zoomRatio)
      }
      // console.log(calcTop(e), calcLeft(e), e.target.getBoundingClientRect().y)
      handleLayerAdded("text", null, calcTop(e), calcLeft(e))
      setActiveBtn(null);
      textSheild.current.removeEventListener('click', clickListener.current);
      window.removeEventListener('keydown', escListener.current)
      textSheild.current.remove();
    }

    function handleEscClick(e) {

      if(e.key === "Escape") {
        setActiveBtn(null);
        window.removeEventListener('keyPress', escListener.current);
        textSheild.current.removeEventListener('click', clickListener.current);
        textSheild.current.remove();
      }
    }
  
    function addText() {
      if (activeBtn === 'text') {
        setActiveBtn(null);
        textSheild.current.removeEventListener('click', clickListener.current);
        window.removeEventListener('keydown', escListener.current)
        textSheild.current.remove();
      } else {
        setActiveBtn('text');
        let target = document.getElementById('canvasForm');
        textSheild.current = document.createElement('span');
        textSheild.current.classList.add('textSheild');
        target.prepend(textSheild.current);
        clickListener.current = textSheild.current.addEventListener('click', function(e){handleTextClick(e)})
        escListener.current = window.addEventListener('keydown', function(e){handleEscClick(e)})
      }
    }

    function updateNarrativePDF() {
      if (module) {
        // setShowLoader(true);
        let completed;
        let startTimeStamp;
        // if (props.activeSectionObj).activeSectionObj
        // console.log('activeSectionObj', props.activeSectionObj)

        if (props.activeSectionObj) {
          setNarrativeUpdating(props.activeSectionObj.screenshot_timestamp);
          startTimeStamp = props.activeSectionObj.screenshot_timestamp;
          console.log('activeSectionObj.screenshot_timestamp',  props.activeSectionObj.screenshot_timestamp)
        } else {
          setNarrativeUpdating(props.narrative.modified);
          startTimeStamp = props.narrative.modified
          console.log('narrative.modified',  props.narrative.modified)
        }

        axios({
          method: 'POST',
          url:  Config.baseURL + "/api/cms/generate-narrative-pdf/",
          data: {id:  props.narrative.id},
          headers: {
            Authorization: 'Token ' + Cookies.get('_udxdid')
          },
        })
        .then(response => {
            console.log('generate-narrative pdf', response);

            let url;
            if (props.activeSectionObj) {
              url = Config.baseURL + "/api/cms/section/" + props.activeSectionObj.id
            } else {
              url = Config.baseURL + "/api/cms/narrative-landing/" + props.narrative.id;
            }
            
            let interval = setInterval(() => {
              try {
                axios({
                    method: "get",
                    url: url,
                    headers: {
                      Authorization: 'Token ' + Cookies.get('_udxdid')
                    },
                }).then((response) => {
                    const assetJSON = response.data;
                    let timeStamp;
                    if (props.activeSectionObj) {
                      timeStamp = assetJSON.screenshot_timestamp
                    } else {
                      timeStamp = assetJSON.modified;
                    }
                    

                    if (timeStamp > startTimeStamp) {
                      props.setPublishOn(false);
                      setNarrativeUpdating(false);
                      console.log('pdf ready', assetJSON.modified)
              
                      // Need to fix this so that it updates the thumbnails properly on the json fetch
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                      
                 
                      // props.fetchJSON();
                      // clearInterval(interval);
                    

                      
                    } else {
                      console.log('still generating pdf', assetJSON.modified)
                    }
                    
                    
                   
                   
                    
                });
            } catch (error) {
                console.error(error);
            }
            }, 10000);
      
            
            
        })
        .catch(error => {
            console.log('error', error.response);
        });
        }
      
    }

    return (
        <div id="narrativeTools">

          {props.sectionId && <div className="iconField ui" onClick={() => handleMediaChoice(true)} ><Icons.PhotoAdd className="icon ui" title="Add Layer" /><div className="iconLabel">Add Layer</div></div>}
          
          {props.sectionId && <div className={"iconField ui" + ((activeBtn === 'text') ? " on": "")} onClick={() => addText()} ><Icons.Wysiwyg className="icon ui" title="wysiwyg editor" /><div className="iconLabel">Add Text</div></div>}


          {props.sectionId && <div className="iconField ui" onClick={() => handleMediaChoice(false)}> <Icons.StateBtnAdd className="icon ui" title="Add Tab Button" /><div className="iconLabel">Add Tab Button</div></div>}

          {props.sectionId && props.user.is_staff && <div className="iconField ui" onClick={() => handleLayerAdded("svg", "rect")} ><Icons.Rectangle className="icon ui" title="add rectangle" /><div className="iconLabel">Add Shape</div></div>}

          {props.sectionId && <input type="file" id="fileUpload" accept={acceptValues} onChange={() => handleLayerAdded('media', null)} multiple={false} />}
         
          <h1>{props.narrative && props.narrative.title}</h1>

          {props.narrative && <a href={ Config.previewURL + "/narrative/" + props.narrative.id + "?u=" + props.user.link_token + "&slide=" + props.sectionId + "&nav=closed"} className="previewBtn noSubEvents" target="_blank" rel="noreferrer" title="preview" onMouseEnter={(e) =>props.onMouseEnter(e, "Preview")} 
          onMouseLeave={() => props.onMouseLeave() }>
          <OpenLink className="icon" /></a>}

          <div className={"iconField ui publishBtn" + (narrativeUpdating ? " rotate":"") + (props.publishOn ? " ready":"")}  onClick={() => updateNarrativePDF()} ><AutoRenew className="icon ui" title="Add Layer" /><div className="iconLabel">Publish</div></div>

          {props.sectionId && (
            <div className="displayBtns">
            <div
            onClick={(e) => handleShowFullCanvas(e) }
            onMouseEnter={(e) =>props.onMouseEnter(e, "Remove frame")} 
            onMouseLeave={() => props.onMouseLeave() }
            className={
                  "displayName noSubEvents" + (props.showFullCanvas ? " on" : " off")
              }
          >
            <Icons.CropFree className="icon ui" title="Remove Frame" />
          </div>
          <div
          onClick={(e) => handleAspectRatio(1) }
          onMouseEnter={(e) =>props.onMouseEnter(e, "Desktop View")} 
          onMouseLeave={() => props.onMouseLeave() }
          className={
              "displayName noSubEvents" + (props.aspectRatio === 1 ? " on" : " off")
          }
      >
        <Icons.Desktop className="icon ui" title="HD 16:9" />
      </div>
        <div className="displayFormatBtns">
          <div
              onClick={(e) => handleAspectRatio(2) }
              onMouseEnter={(e) =>props.onMouseEnter(e, "Tablet View")} 
              onMouseLeave={() => props.onMouseLeave() }
              className={
                  "displayName noSubEvents" + (props.aspectRatio === 2 ? " on" : " off")
              }
          >
            <Icons.Tablet className="icon ui" title="Tablet 4:3" />
          </div>
    
      
        </div>
            
  

        </div>
          )}

          <span className="zoomBtns">
            {props.activeSectionObj && <div className="zoom ui" onClick={() => handleZoom(50, 'in', props.canvasElem, props.aspectRatio, props.setZoomNumber, props.zoomNumber, props.setZoomRatio) }>+</div>}
            {props.activeSectionObj && <div className="zoom ui" onClick={() => handleZoom(-50, 'out',  props.canvasElem, props.aspectRatio, props.setZoomNumber, props.zoomNumber, props.setZoomRatio)}>–</div>}
          </span>

        </div>
    )
}

export default NarrativeTools;

