import axios from "axios";
import Cookies from 'js-cookie';
import Config from "../../Config.json";
let base_url = Config.baseURL + '/api/cms/';

function fetchJSONMain(hasNewObjId, id, setNarrative, setNarrativeHeadings, setSections, sectionId, setActiveSectionObj, setSectionId, setShowLoader, setShowFullCanvas, swiperInst, setFormError, pageId, setSection, setUpdateFetch, updateFetch) {
  console.log('fetch', hasNewObjId, id, sectionId, pageId)
    try {
        setFormError(null);
        const get_url = base_url + "narrative-update/" + id;
        axios({
            method: "get",
            url: get_url,
            headers: {
              Authorization: 'Token ' + Cookies.get('_udxdid')
            },
        }).then((response) => {
            const assetJSON = response.data;
            setNarrative(assetJSON);
            setNarrativeHeadings(assetJSON.narrativeheadings);
            
            let newSections = [ ...assetJSON.cms_sections ];
            setSections(newSections)

            console.log('fetchJSONMain: sections', newSections)

            if (newSections.length > 0) {
             
              if (sectionId && !hasNewObjId) {
                setSectionId(sectionId)
                console.log('skipped assigning new section id', sectionId)
              } else {
                if (hasNewObjId > 0) {
                
                  setSectionId(hasNewObjId);
                  console.log('hasNewObjId', hasNewObjId)
                  
                } else {
                  if (!isNaN(pageId)) {
                    // console.log('searchParams', pageId, reordered.find(a => a.id === pageId), reordered)
                    setSectionId(pageId)
                    console.log('setting section pageId', pageId)

                  } else {
                    setSectionId(newSections[0].id)
                    console.log('setting section id', newSections[0].id)
                  }
                }
                // console.log('reordered', reordered)
               
            }
            
            setShowLoader(false);
            setShowFullCanvas(false);
            if (swiperInst) {
              swiperInst.update();  
            }



          }
            
        });
    } catch (error) {
        console.error(error);
    }
}

function apiCall(method, url, data, responseTask, formHeaders, setFormError, setShowLoader) {
  let headers;
  if (formHeaders) {
    headers = formHeaders
  } else {
    headers = {
      Authorization: 'Token ' + Cookies.get('_udxdid')
    }
  }
  axios({
    method: method,
    url: url,
    data: data,
    headers: headers,
  })
  .then(response => {
    responseTask && responseTask(response)
  })
  .catch(error => {
    console.log('error', error);
    if (error.response) {
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
    } else if (error.request) {
    console.log(error.request);
    } else {
    console.log('Error', error.message);
    }
    console.log(error.config);

    let newError = {
      "heading": "Error",
      "text": error.message,
      // "btn2Text": "Cancel",
      // "onBtn2Click": setFormError,
      // "onBtn2": null
    };
    setFormError(newError);
    setShowLoader(false);
});
  
}


export {fetchJSONMain, apiCall}