function showSettingsMain(objID, isSave, event, multiSelectIds, setMultiSelectIds, setActiveSettingId, componentDimensions, setComponentDimensions, setUnSavedChanges, setTriggerSaveForm) {
    // console.log(event)
      if (event && event.shiftKey) {
        let otherSelects = [ ...multiSelectIds ]
        let otherSelectsIndex = otherSelects.findIndex(a => a === objID);
        // let card = document.getElementById('card_' + objID);
        if (otherSelectsIndex > -1) {
          otherSelects.splice(otherSelectsIndex, 1)
        } else {
          otherSelects.push(objID)
        }
   
        setMultiSelectIds(otherSelects);
  
      } else {

        setMultiSelectIds([objID]);
      
        setActiveSettingId(objID);
        if (isSave) { 
          function getValue(inputId) {
            let elem = document.getElementById(inputId);
            let result;
            if (elem) {
              result = elem.value;
            } else {
              result = null;
            }
            return result
          }
          var width = getValue('width_dim_' + objID);
          var height = getValue('height_dim_' + objID);
          var x_pos = getValue('x_pos_dim_' + objID);
          var y_pos = getValue('y_pos_dim_' + objID);
          // var component_ordering = document.getElementById('ordering_dim_' + objID).value;
          var component_link = getValue('link_' + objID);
          var component_link_heading = getValue('link_heading_' + objID);
          var component_state_heading = getValue('state_heading_' + objID);
 
          console.log('showSettings', objID, component_state_heading);

          var newComponents = [...componentDimensions];

          var newIndex = newComponents.findIndex(item => item.id === objID);
          var newObj = newComponents[newIndex];

          if (!width) {
            var width = newComponents[newIndex].width
          }
          if (!height) {
            var height = newComponents[newIndex].height
          }
          if (!x_pos) {
            var x_pos = newComponents[newIndex].x_pos
          }
          if (!y_pos) {
            var y_pos = newComponents[newIndex].y_pos
          }
          if (!component_ordering) {
            var component_ordering = newComponents[newIndex].component_ordering
          }
          if (!component_link) {
            var component_link = newComponents[newIndex].component_link
          }
          if (!component_link_heading) {
            var component_link_heading = newComponents[newIndex].component_link_heading
          }
          if (!component_state_heading) {
            var component_state_heading = newComponents[newIndex].component_state_heading
          }
        
          newObj['width'] = parseInt(width);
          newObj['height'] = parseInt(height);
          newObj['x_pos'] = parseInt(x_pos);
          newObj['y_pos'] = parseInt(y_pos);
          newObj['component_ordering'] = parseInt(component_ordering);
          newObj['component_link'] = component_link
          newObj['component_link_heading'] = component_link_heading
          newObj['component_state_heading'] = component_state_heading

          // console.log('component_link_heading', component_link_heading, newComponents);
          setComponentDimensions(newComponents);
          setUnSavedChanges(true);
          setTriggerSaveForm(true);
        } else {
          // setShowManifest(true);
        }
      }

      
  }

  export default showSettingsMain;