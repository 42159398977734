import React, { useState, useEffect } from "react";
import {
  Link,
  useParams
} from "react-router-dom";
import axios from "axios";
import Cookies from 'js-cookie';
import Modal from './module.modal';
import Config from "../Config.json"
import {ReactComponent as Logo} from "../static/svg/logo.svg" 

function Login() {

    const [formError, setFormError] = useState();

    function postLogin() {

        var username = document.getElementById('username');
        var password = document.getElementById('password');
        console.log('username', username.value, password.value);
        if (username.value && password.value) {
          axios({
            method: 'post',
            baseURL: Config.baseURL,
            url: '/api-token-auth/',
            data: {
              'username': username.value, 
              'password': password.value
            }
            })
            .then(response => {

                console.log('response', response);
                Cookies.set('_udxdid', response['data'].token);
                axios({
                    method: 'get',
                    baseURL: Config.originURL,
                    url: '/auth/users/me/',
                    headers: {
                        Authorization: 'Token ' + Cookies.get('_udxdid')
                    }
                    })
                    .then(response => {
                      console.log('response', response)
                      window.location.href = "/";
                    });
                          
            })
            .catch(function (error) {
              if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              } else if (error.request) {
              console.log(error.request);
              } else {
              console.log('Error', error.message);
              
              }
              console.log(error.config);


              let newError = {
                "heading": "Error",
                "text": error.response.data['non_field_errors'],
                // "btn2Text": "Cancel",
                // "onBtn2Click": setFormError,
                // "onBtn2": null
              };
              setFormError(newError);


          });
        }
		
    }


    function submitForm(e) {
      if (e.key === "Enter") {
        console.log('enter')
        postLogin()
      }
    
    }

    useEffect(() => {
    //   fetchJSON();
    }, []);
   

    return (
      <section className="main pageItem-enter-active" id="loginFormPage" onKeyUp={(e) => submitForm(e)}>

      {(formError)&&<Modal heading={formError.heading} text={formError.text} onBtn1Click={setFormError} onBtn1={null} btn1Text={"OK"} onBtn2Click={formError.onBtn2Click} onBtn2={formError.onBtn2} btn2Text={formError.btn2Text} />}
      
          <div className="loginForm">
            <div id="logInLogo">
              <Logo />
              <h1>Create + Present</h1>
            </div>
            <div className="formField">
                <input autoComplete="off" type="text" name="username" id="username" required placeholder="username" />
            </div>

            <div className="formField">
                <input type="password" name="password" id="password" required placeholder="password" />
            </div>
            <div className="formField">
                <input type="submit" className="btn1 submitBtn" value="Sign In" onClick={()=>postLogin()} id="loginSubmit" />
               
            </div>

          </div>
          <div className="productContact">
            <h2>For information on UDXD Create+Present, and how to sign up, please contact us at <a href="mailto:info@udxd.co"  target="_blank" rel="noreferrer">info@udxd.co</a></h2>
            <p>To learn more about UDXD please visit <a href="https://www.udxd.co" target="_blank" rel="noreferrer">www.udxd.co</a></p>
          </div>

      </section>
    );
}

export default Login;

// <a href="/reset-password/" className="reset">Reset Password</a>