function handleMultiSelect(field, value, componentDimensions, multiSelectIds, setComponentDimensions) {
   
    if (field === "align") {
      let newObjs = [ ...componentDimensions ];
      let left;
      let center_v;
      let center_w;
      let height;
      let top;
      let bottom;

      switch (value) {
        case "right":
          let right;
          let rightObj = componentDimensions.filter(a => multiSelectIds.includes(a.id)).sort((a, b) => (a.x_pos + a.width) > (b.x_pos + b.width))[0]
          let objR = rightObj.x_pos + rightObj.width;
          right = objR
          multiSelectIds.forEach((obj0) => {
            let obj = newObjs.find(a => a.id === obj0)
            obj.x_pos = right - obj.width
            // console.log(obj, obj.x_pos, obj.width)
          })
          setComponentDimensions(newObjs)
          // console.log('right', right)
          break;
        case "left":

          function setLeft(){
            let leftObj = componentDimensions.filter(a => multiSelectIds.includes(a.id)).sort((a, b) => (a.x_pos) - (b.x_pos))[0]
            let left = leftObj.x_pos
           
            multiSelectIds.forEach((obj0) => {
              let obj = newObjs.find(a => a.id === obj0)
              obj.x_pos = left
              console.log("setLeft", obj, obj.x_pos, obj.width)
            })
            setComponentDimensions(newObjs)
          }
          setLeft()
          break;
        case "center_w":
          let centerObj = componentDimensions.filter(a => multiSelectIds.includes(a.id)).sort((a, b) => (b.width) - (a.width))[0]
          center_w = centerObj.x_pos + centerObj.width/2
          multiSelectIds.forEach((obj0) => {
            let obj = newObjs.find(a => a.id === obj0)
            obj.x_pos = center_w - obj.width/2
            // console.log(obj, obj.x_pos, obj.width)
          })
          setComponentDimensions(newObjs)
            break;
         case "top":
          let topObj = componentDimensions.filter(a => multiSelectIds.includes(a.id)).sort((a, b) => (a.y_pos) - (b.y_pos))[0]
          top = topObj.y_pos


          multiSelectIds.forEach((obj0) => {
            let obj = newObjs.find(a => a.id === obj0)
            obj.y_pos = top
            // console.log(obj, obj.x_pos, obj.width)
          })
          setComponentDimensions(newObjs)
          
            break;
          case "center_v":
            let centerVObj = componentDimensions.filter(a => multiSelectIds.includes(a.id)).sort((a, b) => (b.height) - (a.height))[0]
             console.log(centerVObj)

            center_v = centerVObj.y_pos + centerVObj.height/2
          multiSelectIds.forEach((obj0) => {
            let obj = newObjs.find(a => a.id === obj0)
            obj.y_pos = center_v - obj.height/2
           
          })
          setComponentDimensions(newObjs)
            break;

          case "bottom":
            let bottomObj = componentDimensions.filter(a => multiSelectIds.includes(a.id)).sort((a, b) => (b.y_pos + b.height ) - (a.y_pos + a.height))[0]
            bottom = bottomObj.y_pos + bottomObj.height
          multiSelectIds.forEach((obj0) => {
            let obj = newObjs.find(a => a.id === obj0)
            obj.y_pos = bottom - obj.height
            // console.log(obj, obj.x_pos, obj.width)
          })
          setComponentDimensions(newObjs)
            break;
        default:
          break;
      }
    }
  }

  export default handleMultiSelect;