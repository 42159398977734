import React, { useState, useEffect, useRef } from "react";
import gsap from "gsap";

import {ReactComponent as ArrowRight} from "../static/icons/arrow_right.svg";


function Modal(props) {

    return (
        <div className={"modalContainer" + ((props.btn3Text)?" threeBtn":"")}>
            <div className="modal">
                <span className="textContainer">
                    <h3>{props.heading}</h3>
                    {props.text && <p><span  dangerouslySetInnerHTML={{ __html: props.text }} /></p>}
                    {props.choices && props.choices}
                    {props.formInput && props.formInput}
                </span> 
              
                <div className={"fullRow errorRow" + ((!props.btn2Text)?" oneBtn":"")}>
                    {props.btn3Text ? <React.Fragment>
                        {props.btn2Text && <span className="saveContinue" onClick={() => props.onBtn2Click(props.onBtn2)}>{props.btn2Text}</span>}
                        {props.btn3Text && <span onClick={() => props.onBtn3Click(props.onBtn3)}>{props.btn3Text}</span>}
                        <span className="cancel" onClick={() => props.onBtn1Click(props.onBtn1)}>{props.btn1Text}</span> 
                       
                        
                        </React.Fragment>:
                    <React.Fragment>
                    <span onClick={() => props.onBtn1Click(props.onBtn1)}>{props.btn1Text}</span> 
                    {props.btn2Text && <span onClick={() => props.onBtn2Click(props.onBtn2)}>{props.btn2Text}</span>}
                   
                    </React.Fragment>}
                   
                </div>
            </div>
        </div>
    )

}

export default Modal;