import Loader from "../static/svg/loader.svg";
import imagePlaceholder from '../static/img/image.png'
import CloseDelete from "../static/svg/close-delete.svg";
import OpenLink from "../static/svg/openLink-w.svg";
import {ReactComponent as Cancel} from "../static/svg/cancel_icon.svg";
import {ReactComponent as ArrowBack} from "../static/icons/arrow_back.svg";
import {ReactComponent as NoteAdd} from "../static/icons/note_add.svg";
import {ReactComponent as PhotoAdd} from "../static/icons/add_photo.svg";
import {ReactComponent as StateBtnAdd} from "../static/icons/call_to_action.svg";
import {ReactComponent as Image} from "../static/icons/image.svg"
import {ReactComponent as Video} from "../static/icons/video.svg"
import {ReactComponent as Svg} from "../static/icons/svg.svg"
import {ReactComponent as Delete} from "../static/icons/delete.svg"
import {ReactComponent as Wysiwyg} from "../static/icons/wysiwyg.svg"
import {ReactComponent as Visible} from "../static/icons/visibility.svg"
import {ReactComponent as VisibleOff} from "../static/icons/visibility_off.svg"
import {ReactComponent as Lock} from "../static/icons/lock.svg"
import {ReactComponent as LockOpen} from "../static/icons/lock_open.svg"
import {ReactComponent as AlignLeft} from "../static/icons/format_align_left.svg"
import {ReactComponent as AlignRight} from "../static/icons/format_align_right.svg"
import {ReactComponent as AlignCenter} from "../static/icons/format_align_center.svg"
import {ReactComponent as Desktop} from "../static/icons/desktop.svg"
import {ReactComponent as Hd} from "../static/icons/hd.svg"
import {ReactComponent as Tablet} from "../static/icons/tablet.svg"
import {ReactComponent as CropFree} from "../static/icons/crop_free.svg"
import {ReactComponent as AlignHoriCenter} from "../static/icons/align_horizontal_center.svg"
import {ReactComponent as AlignHoriLeft} from "../static/icons/align_horizontal_left.svg"
import {ReactComponent as AlignHoriRight} from "../static/icons/align_horizontal_right.svg"
import {ReactComponent as AlignVertCenter} from "../static/icons/align_vertical_center.svg"
import {ReactComponent as AlignVertBottom} from "../static/icons/align_vertical_bottom.svg"
import {ReactComponent as AlignVertTop} from "../static/icons/align_vertical_top.svg"
import {ReactComponent as Sync} from "../static/icons/sync.svg"
import {ReactComponent as AddBox} from "../static/icons/add_box.svg"
import {ReactComponent as AddLink} from "../static/icons/add_link.svg"
import {ReactComponent as Linked} from "../static/icons/link.svg"
import {ReactComponent as LinkedOff} from "../static/icons/link_off.svg"
import {ReactComponent as Rectangle} from "../static/icons/shapes/rectangle.svg"
import {ReactComponent as Line} from "../static/icons/shapes/line.svg"
import {ReactComponent as Circle} from "../static/icons/shapes/circle.svg"
import {ReactComponent as LineHeight} from "../static/icons/format_line_spacing.svg"
import {ReactComponent as LetterSpacing} from "../static/icons/format_letter_spacing.svg"
import {ReactComponent as Undo} from "../static/icons/undo.svg"
import {ReactComponent as Redo} from "../static/icons/undo.svg"

const icons = {
    "loader" : Loader,
    "imagePlaceholder": imagePlaceholder,
    "ArrowBack": ArrowBack,
    "Cancel": Cancel,
    "NoteAdd": NoteAdd,
    "PhotoAdd": PhotoAdd,
    "StateBtnAdd": StateBtnAdd,
    "Image": Image,
    "Video": Video,
    "Svg": Svg,
    "Delete": Delete,
    "Wysiwyg": Wysiwyg,
    "Visible": Visible,
    "VisibleOff": VisibleOff,
    "Lock": Lock,
    "LockOpen":LockOpen,
    "AlignLeft": AlignLeft,
    "AlignRight": AlignRight,
    "AlignCenter": AlignCenter,
    "Desktop": Desktop,
    "Hd": Hd,
    "Tablet": Tablet,
    "CropFree": CropFree,
    "AlignHoriCenter": AlignHoriCenter,
    "AlignHoriLeft": AlignHoriLeft,
    "AlignHoriRight": AlignHoriRight,
    "AlignVertCenter": AlignVertCenter,
    "AlignVertBottom": AlignVertBottom,
    "AlignVertTop": AlignVertTop,
    "Sync": Sync,
    "AddBox": AddBox,
    "AddLink":AddLink,
    "Linked":Linked,
    "LinkedOff":LinkedOff,
    "Rectangle": Rectangle,
    "Line": Line,
    "Circle": Circle,
    "LineHeight": LineHeight,
    "LetterSpacing": LetterSpacing,
    "Undo": Undo,
    "Redo": Redo
}
export default icons;