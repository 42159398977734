import React, { useState, useEffect, useRef } from "react";
import {fileNameType} from "../../utils/helpers";
import Config from "../../Config.json";
import Modal from './../module.modal';
import Icons from '../module.icons';
import Versions from '../module.versions';
import { apiCall } from "./../narrativeUtils/fetch";

function PageSettings(props) {
  const [showGenerateScreenshot, setShowGenerateScreenshot] = useState(true);
  const [active, setActive] = useState();

  function handleSectionScreenShot(e) {
    props.setShowLoader(true);
    const api_url = Config.baseURLcms + 'section-screenshot/';
    setShowGenerateScreenshot(false);
    var dataJSON = {
      'id': props.activeSectionObj.id,
    }
    function responseTask(response){
     
      // console.log('handleSectionScreenShot', response.data)
      props.setSectionId(response.data);
      setShowGenerateScreenshot(true);
      props.setShowLoader(false);
      props.fetchJSON(false);
    }
    apiCall('POST', api_url, dataJSON, responseTask)

  }

  function onSetSections(objId, newObj0) {
    let newSections = [ ...props.sections ];
    let objIndex = newSections.findIndex(section => section.id === objId)
    newSections[objIndex] = newObj0
    
    props.setSections(newSections)
    props.setSectionChanges(newSections);
    props.setUnSavedChanges(true);
    // props.handleLayerSave();

   

    // console.log('newSections', newSections)
  }

  function handleSectionActive() {
    props.handleSectionActive(!active)
    setActive(!active)
  }

  useEffect(() => {
    // console.log('page setting props', props.activeSectionObj)
    if (props.activeSectionObj) {
      setActive(props.activeSectionObj['active'])
      // console.log('active', props.activeSectionObj['active'])
    }
  }, [props.activeSectionObj])

    
    return(
       <div className="rightNav settingsOn" id="manifestColumn">
        <div className={"mediaSettings showSettings on"}>

          <div className="settingsGroup">
            <div className="groupLabel"><p>Page Settings</p></div>
            {props.activeSectionObj &&(
              <div className="settingsRow">
              <div className="sectionHeading" id={"section_" + props.activeSectionObj.id} key={'sectionHeading_' + props.activeSectionObj.id}>
              <div className="fieldRow">
                  <div className="errors"></div>
                    
                    <div className="canvasLabel">Title</div>
                    <input type="text" autoComplete="off" id="sectionTitle" key={props.activeSectionObj.title} defaultValue={props.activeSectionObj.title} onKeyUp={(e) => props.handleOnKeyUp(e, 'narrativeHeading', props.activeSectionObj.nav.id)} />
                 
              </div>
              
              </div>
              </div>
              )}

            <div className="settingsRow">
      
              <div className="canvasLabel">Background</div>
              {props.componentDimensions
                .filter(obj0 => obj0.background === true)
                .map((dimension, i) => 
                <div 
                  id={"settings_" + dimension.id}  
                  onClick={(e) => props.showSettings(dimension.id, false, null)}
                  key={'settingFile_' + i}>
                    <div className="settingsRow bkgFile" onClick={() => props.showSettings(dimension.id, false, null)}>
                      <div className="iconField">{fileNameType(dimension.name, dimension.layer_name).map((obj2, j) => (<span key={"fileName_" + j}>{obj2}</span>))}</div>
                      
                    </div>
                    
                </div>
                )}

            </div>
            
            <div className="settingsRow">
                <span>{props.activeSectionObj.screenshot_for_pdf && <a href={props.activeSectionObj.screenshot_for_pdf} rel="noreferrer" target="_blank">Screenshot</a>}</span>
              {showGenerateScreenshot && <span className="canvasLabel iconField ui" onClick={(e) => handleSectionScreenShot(e)}>Update Thumbnail <Icons.Sync className="icon" /></span>}

            </div>
              
            <div className="settingsRow">
            <span className="canvasLabel">Tablet Scroll Offset</span>
            <input type="text" onChange={(e) => props.handleSectionScrollOffset(e)} defaultValue={props.activeSectionObj.scroll_offset} key={props.activeSectionObj.scroll_offset + props.activeSectionObj.id} id={'section_scroll_offset_' + props.activeSectionObj.id} />
          </div>

            <div className={"settingsRow halfRow"}>
            <span className="canvasLabel">Visibility</span>
            <div className="iconField settingsRow ui" onClick={() => handleSectionActive()}>{(active) ? <Icons.Visible className="icon" /> : <Icons.VisibleOff className="icon" />}
            </div>
            
            </div>
      
          </div>

          <div className="settingsRow sectionSave" id="saveRow">
          <div className={"closeFormBtn btn" + ((props.unSavedChanges) ? (" unsaved") : (" saved"))} onClick={() => props.handleSectionOnlySave()}>Save</div> 
            <div className="iconField ui rightNavDeleteBtn" onClick={() => props.setShowDeleteSection(props.activeSectionObj.id)}><Icons.Delete className="icon"/> <div className="canvasLabel">Delete Page</div></div>
          </div>
          
          <div className="settingsGroup">
            <Versions 
              contentTypeId={14}
              location={"page"}
              obj={props.activeSectionObj}
              setObj={onSetSections} 
              user={props.user}
              saveObj={props.handleSectionOnlySave}
              setUnSavedChanges={props.setUnSavedChanges}
              setSectionChanges={props.setSectionChanges}
              setForceVersion={props.setForceVersion}
            />

          </div>

          
        </div>                         
      </div>
    )
}

export default PageSettings;