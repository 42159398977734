function handleZoom(val, dir, canvasElem, aspectRatio, setZoomNumber, zoomNumber, setZoomRatio) {

    let elem = document.getElementById('canvasForm');
    // console.log('elem', elem)
    let cHeight = elem.offsetHeight;
    let cWidth = elem.offsetWidth;
    let nHeight = cHeight + val;
    let ar = cWidth/cHeight;
    let nWidth = Math.round(nHeight*ar);
    let ratio;
    // console.log('nHeight', nHeight, nWidth)
    elem.style.height = nHeight + "px";
    elem.style.width = nWidth + "px";
  
    if (aspectRatio === 1) {
      ratio = nHeight/1080
    }
    if (aspectRatio === 2) {
      ratio = nHeight/820
    }
  
    (dir === 'in') && setZoomNumber(zoomNumber + 1);
    if (dir === 'out') {
        let r = zoomNumber - 1
        if (r === 0) {
          r = 1
        }
      setZoomNumber(r);
    }
  
    setZoomRatio(ratio)
    
  }

  export {handleZoom} ;