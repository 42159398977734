import { apiCall } from "../narrativeUtils/fetch";
import Config from "../../Config.json";

function addComponent(componentId, sectionId, fetchJSON, setFormError, setShowLoader, componentVersion){
  const api_url = Config.baseURLcms + 'component/' + componentId + "/";
  var dataJSON = {
    'section_id': sectionId,
    'version_obj': componentVersion
  }
  function responseTask(response){
    fetchJSON(response.data)
  }
  apiCall('POST', api_url, dataJSON, responseTask, null, setFormError, setShowLoader)
}

function delComponent(componentId, sectionId, fetchJSON, setFormError, setShowLoader){
  const api_url = Config.baseURLcms + 'component/' + componentId + "/";
  var dataJSON = {
    'section_delete': sectionId
  }
  function responseTask(response){
    fetchJSON(response.data)
  }
  apiCall('POST', api_url, dataJSON, responseTask, null, setFormError, setShowLoader)
}


function updateVersionHistory(
    obj, 
    source, 
    undoRedo, 
    versionCount,
    setVersionCount, 
    versionNumber
    ) {

    if (!undoRedo.current && obj) {
      let versionStore = localStorage.getItem('versionHistory');
      let versionHistory;
      
      if (versionStore) {
        versionHistory = JSON.parse(versionStore);
      }

      console.log('versionHistory.length > 0', versionHistory, obj)

      if (versionHistory && versionHistory.length > 0) {

        // let objCopy = Object.assign({}, obj);
        let objCopy = JSON.stringify(obj);
        let newVersion = {
          'version': versionCount,
          'type': source,
          'id': obj.id, 
          'data': objCopy
        }
        let versionObjs = versionHistory.filter(a => 
            a.id === obj.id && 
            a.type === source && 
            a.data === objCopy)

          console.log('versionObjs', versionObjs, source)

        if (
            versionHistory 
            && versionObjs.length === 0
            ) {
          let existingVersions = [...versionHistory];
          console.log('change occurred', newVersion)

          existingVersions.push(newVersion)

          if (versionHistory.length > 19) {
            existingVersions.shift()
          }
          // versionHistory = existingVersions;
          // setVersionHistory(existingVersions)
          localStorage.setItem('versionHistory', JSON.stringify(existingVersions))

          let newVersionCount = versionCount;
          setVersionCount(newVersionCount+=1)
          versionNumber.current = versionNumber.current + 1

          

        } else {
          console.log('no change')
        }

      } else {

        // let objCopy = Object.assign({}, obj);
        let objCopy = JSON.stringify(obj);
        let newVersion = {
          'version': versionCount,
          'type': source,
          'id': obj.id,
          'data': objCopy
        }
        // setVersionHistory([newVersion]);
        localStorage.setItem('versionHistory', JSON.stringify([newVersion]));
        let newVersionCount = 0;
        setVersionCount(newVersionCount+=1)
        versionNumber.current = 0       
        console.log('first version', [newVersion])
        
      }
      undoRedo.current = false;

      let h = localStorage.getItem('versionHistory')
      let objs = []
      let hData = JSON.parse(h);

      console.log('hData', hData)
      hData.forEach(a => function(){
        console.log('hData a', a)
        let dObj = JSON.parse(a['data'])
        objs.push(dObj)
      })

      for(let i = 0; i < hData.length; i++) {
        let obj = hData[i];
  
        let dObj = JSON.parse(obj['data'])
        objs.push(dObj)
   
      }

      console.log('versionHistory Updates', objs, versionNumber.current)
      
    } else {
      undoRedo.current = false;
    }

  }


  function onUndoRedo(
    undoRedo,
    sections, 
    setActiveSectionObj, 
    versionNumber, 
    metaKey, ctrlKey, shiftKey, key, 
    versionCount, 
    setUnSavedLayerIds, 
    activeSectionObj,
    setShowLoader,
    fetchJSON,
    setFormError,
    setUnSavedChanges
    ) 
    {
      
    undoRedo.current = true;

      // let versions = JSON.parse(localStorage.getItem('layerVersions'));
      function processVersion(versionNo) {
        console.log('processVersion', versionNo, activeSectionObj.components)
        
        let versionObj;
        let versionHistory = JSON.parse(localStorage.getItem('versionHistory'));
        if (versionHistory) {
          versionObj = versionHistory.find((a) => a.version === versionNo);
          console.log("versionObj", versionObj)
        }
        // console.log('versionObj 0', versionNo, versionObj, versions)
        if (versionObj) {
          console.log('versionObj', versionObj, versionHistory)
          if (versionObj['type'] === "page") {
            let newObjs = [ ...sections ]
            let objIndex = newObjs.findIndex((a) => a.id === versionObj.id);
            newObjs[objIndex] = JSON.parse(versionObj['data']);
            
            

            newObjs[objIndex].components.forEach((a, i0) => {
              let componentIndex = activeSectionObj.components.findIndex(i => i.id === a.id);
              if (componentIndex > -1) {
                let ids = []
                a.dimensions.forEach((b, i) => {
                  ids.push(b.id)
                 
                })
                setUnSavedLayerIds(ids)
                if (i0 === newObjs[objIndex].components.length -1) {
                  setActiveSectionObj(newObjs[objIndex])
                }
                
                // console.log('layerid', ids)
                console.log('component exists:', a.id, componentIndex)
              } else {
                console.log('no component', a.id, componentIndex)
                setShowLoader(true);
                let versionObj2 = versionHistory.find((a) => a.version === (versionNo + 1));
                if (!versionObj2) {
                  versionObj2 = versionHistory.find((a) => a.version === versionNo);
                }
                let data2 = JSON.parse(versionObj2['data']);
                let component2 = data2.components.find(a2 => a2.id === a.id)
                addComponent(a.id, activeSectionObj.id, fetchJSON, setFormError, setShowLoader, component2)
                // console.log('componentToRedo, versionNo', component2, versionNo)
              }

             
              
            });
              // setActiveSettingId(versionObj.id)
            // console.log('undo/redo updates', newObjs, newObjs[objIndex])
          }
    
          versionNumber.current = versionNo
          setUnSavedChanges(true);
        } else {
          console.log('no version found', versionNo)

          versionNumber.current = -1;
        }
        
      }
      

      // UNDO
      if ((metaKey || ctrlKey) && !shiftKey && key === 'z') {
        
        console.log('undo', versionCount, versionNumber.current)
        let getVersionNo;
        if (versionNumber.current > 0) {
          getVersionNo = versionNumber.current - 1;
        } else {
          getVersionNo = 0;
        }


        console.log('getVersionNo', getVersionNo)
        processVersion(getVersionNo);

        
      }

      // REDO
      if ((metaKey || ctrlKey) && shiftKey && key === 'z') {
        console.log('redo', versionCount, versionNumber.current)
        let getVersionNo;
        if (versionNumber.current > -1) {
          getVersionNo = versionNumber.current + 1
        }
        
        // setVersionNumber(getVersionNo)
        if (getVersionNo > 0) {
          processVersion(getVersionNo)
        }
        
      }
  }

  export { updateVersionHistory, onUndoRedo }

  