import React, { useState, useEffect, useRef } from "react";
import { Fragment } from "react/cjs/react.production.min";
import { Rnd } from "react-rnd";
import gsap from "gsap";
import { Draggable } from "gsap/all";
import Config from "../Config.json"
import {ReactComponent as ArrowRight} from "../static/icons/arrow_right.svg";
import {ReactComponent as Image} from "../static/icons/image.svg";
import {ReactComponent as Video} from "../static/icons/video.svg";
import {ReactComponent as Svg} from "../static/icons/svg.svg";
import {ReactComponent as Draft} from "../static/icons/draft.svg" 
import { el } from "date-fns/locale";
gsap.registerPlugin(Draggable)
// gsap.registerPlugin(InertiaPlugin);

function MenuList(props) {

  const rnd = useRef();
  const draggingId = useRef();
  const scrollingContainer = useRef();
  const scrollScreen = useRef();
  const scrollBar = useRef();
  const handle = useRef();
  const isDragging = useRef(false);
  const originY = useRef();
  const originYpx = useRef();
  const dirRefY = useRef();
  const dirRefYprev = useRef();
  const elevatingSubs = useRef(false);
  const [parentOpenId, setParentOpenId] = useState();
  const [subPagesClosed, setSubPagesClosed] = useState(true);
  const [showContextMenu, setShowContextMenu] = useState();
  const [tempAfterObjs, setTempAfterObjs] = useState([]);
  const [rows, setRows] = useState([]);
  const [dir, setDir] = useState();
  const [dragOrder, setDragOrder] = useState();
  const [scrollY, setScrollY] = useState();

  function round30(x) {
      let y = Math.ceil(x / 30) * 30;
      return y;
  }


function fileNameType(string, layerName) {
  let result = [];
  if (string.length > 0) {
      // console.log('string', string)
      let x0 = string.replace('gallery/', '');
      var ext = string.split('.').pop();
      let x2;
      if (layerName) {
        x2 = layerName;
      } else {
        x2 = x0.replace('.'+ ext, '');
      }
    
      // console.log('fileNameType', ext, x2, string);
      let icon;
      switch (ext) {
        case "png":
          icon = <Image className="icon" />
          break;
        case "jpg":
          icon = <Image className="icon" />
          break;
        case "jpeg":
          icon = <Image className="icon" />
          break;
        case "webp":
          icon = <Image className="icon" />
          break;
        case "svg":
          icon = <Svg className="icon" />
          break;
        case "mp4":
          icon = <Video className="icon" />
          break;
        default:
          icon = <Draft className="icon" />
          break;
      }
      if (x2.length > 18) {
        x2 = x2.substring(0,18) + ' ...'
      }
      result = [icon, x2];
  }
 
  return result;
}

  function handleElevateSub(newArray, yObjIndex, headingIndex, yObj, newOrdering, nextNumber, arrayLength) {

      console.log('handleElevateSub', newArray, yObjIndex, headingIndex, yObj, newOrdering, nextNumber, arrayLength)

      newArray.splice(yObjIndex, 1)
      let newArrayReordered = [];
      newArray.forEach((a, i) => {
        let newObj = a;
        newObj['ordering'] = i;
        newArrayReordered.push(newObj)

        if (i === newArray.length - 1) {
          props.baseObjs[headingIndex][props.subField] = newArrayReordered;
        }
      });
  
  
      let newHeadings = [ ...props.baseObjs ];
      let newElevatedSub = yObj;
      let newElevatedSubOrdering;
      newElevatedSub[props.parentField] = null;
      delete newElevatedSub[props.parentField];
      
      // console.log('newHeadings', newHeadings, newElevatedSub, newElevatedSub['ordering'])
      if (newOrdering <= 0)  {
        console.log('elevate before \u25B2')
        if (props.baseObjs[headingIndex - 1]) {
          newElevatedSubOrdering = props.baseObjs[headingIndex - 1]['ordering'];
        } else {
          newElevatedSubOrdering = 0
        }
        
      } else if (newOrdering >= nextNumber)  {
        console.log('elevate after \u25BC')
        if (props.baseObjs[headingIndex + 1]) {
          newElevatedSubOrdering = props.baseObjs[headingIndex + 1]['ordering'];
          console.log('newElevatedSubOrdering', newElevatedSubOrdering)
          // narrativeHeadings[headingIndex + 1]['ordering'] = narrativeHeadings[headingIndex + 1]['ordering'] - array.length + 1
        } else {
          newElevatedSubOrdering = arrayLength
        }
      } else {
        console.log('elevate error')
      }
  
  
      newElevatedSub['ordering'] = newElevatedSubOrdering;
      newElevatedSub[props.subField] = [];
      newHeadings.push(newElevatedSub);
  
      // console.log('newElSub', newElevatedSubOrdering)
      
      handleSubHeadings(props.baseObjs[headingIndex], headingIndex, true)
  
      reOrder(newHeadings, newElevatedSub, null, true, newElevatedSub['ordering'])
  }
    
    

  function elevateSub(array, obj, d, setObj, headingIndex) {

      let newArray = [ ...array ];   
      let yObj = newArray.find(obj0 => obj0.id === obj.id);
      let yObjIndex = newArray.findIndex(obj0 => obj0.id === obj.id);

      // console.log('yObj, props.elevateSub', yObj, props.elevateSubs)
      let yPosArray = [];
      let trueYpos = 0;
      rows.forEach((obj0, i) => {
        trueYpos+=obj0.height
        let obj = {};
        obj['id'] = obj0.id;
        obj['isOpen'] = obj0.isOpen;
        obj['startY'] = trueYpos;
        obj['endY'] = obj0.height;
       
        yPosArray.push(obj);
      });
      let overElem;
      try {
        overElem = yPosArray.filter(function(a){
          return d.y >= a.startY && d.y <= a.endY && a.isOpen
        });
      } catch {
        overElem = null;
      }

      // console.log('elevateSub over', overElem)

      if (yObj && props.elevateSubs) {
        
        if (parseInt(round30(d.y)/30) !== yObj.ordering) {
          let yObj_posY;
          if (d.y/30 === 0) {
            yObj_posY = d.y;
          } else {
            yObj_posY = round30(d.y);
          }
          let nextNumber;
          let nextObj;
          let newOrdering;
          let runElevate = false;
          // let next = props.baseObjs[headingIndex + 1];
          // let prev = props.baseObjs[headingIndex - 1];
        
          let dropOrder = yObj_posY/30;
          // if (yObj_posY/30 > 1)

          console.log('dropOrder', dropOrder, newArray.length, overElem.length)
          
          if (!elevatingSubs.current && overElem.length === 0) {
            if (dropOrder >= newArray.length) {
              nextNumber = newArray.length;
              newOrdering = newArray.length;
              console.log('dropOrder after subs', newOrdering)
              runElevate = true;
            } else if (dropOrder < 0) {
              nextObj = props.baseObjs[headingIndex - 1];
              if (nextObj){

                nextNumber = nextObj['ordering'] - 1;
                newOrdering = nextNumber - 1;
                console.log('dropOrder before subs', newOrdering)
                runElevate = true;
              } else {
                nextNumber = 0;
                newOrdering = 0;
                console.log('dropOrder at zero', newOrdering)
                runElevate = true;

              }
            } else {
              return false;
            }
          }
         

          
          if (runElevate) {
            elevatingSubs.current = true;
            console.log('elevateSub: run elevate');
            handleElevateSub(newArray, yObjIndex, headingIndex, yObj, newOrdering, nextNumber, array.length);

            return true
          } else {
            elevatingSubs.current = false;
            console.log('elevateSub: run reorder');
            return false
          }
  
          
        }
    
      }
    
    }
    
    
    
     
      function reOrderForOpen(array, openBaseObj, setObj, height) {
          
          let newArray = [ ...array ];  
          
          let yObj = newArray.find(obj0 => obj0.id === openBaseObj.id);
      
          if (yObj) {
            if (height) {
                let afterNumberCopy = [];
                let afterNumber = newArray.filter(obj0 => ((obj0.ordering > yObj.ordering)&&(obj0.id !== yObj.id)));
                afterNumber.map(obj => afterNumberCopy.push({id: obj.id, ordering: obj.ordering}));
          
                afterNumber.forEach((obj, i) => {
                  obj.ordering = (height/30)+1+i
                });
                let reordered = newArray.sort((a, b) => a.ordering - b.ordering);
                setObj(reordered);
                
                // tempAfterObjs.current = reordered
                console.log('reOrderForOpen, reordered', reordered)
                setTempAfterObjs(afterNumber)
                
            } else {
                tempAfterObjs.forEach((z) => {
                  let match = newArray.findIndex(a => a.id === z.id);
                  z['ordering'] = match
                });
                
                let reordered = newArray.sort((a, b) => a.ordering - b.ordering);
                setObj(reordered);


                // let resetArray = []
                // tempAfterObjs.current.forEach((obj, i) => {
                
                // });
                
                // tempAfterObjs.current = []
                setTempAfterObjs([])
                // console.log('props.baseObjs', reordered)
            }
              
          
          }

      }
    
    
      function handleSubHeadings(obj, i, runReOrder){
        let rowsOpen = rows.find(a => a['isOpen']);
        let row = rows[i];

        if (rowsOpen && !row['isOpen']) {
          rowsOpen['height'] = 30;
          rowsOpen['isOpen'] = false
          reOrderForOpen(props.baseObjs, obj, props.setBaseObjs, null)
          setSubPagesClosed(true);
          setParentOpenId(null);
        } else {

        if (row['isOpen']){
          row['height'] = 30;
          row['isOpen'] = false
          setSubPagesClosed(true);
          setParentOpenId(null);
          reOrderForOpen(props.baseObjs, obj, props.setBaseObjs, null)
          
        } else {
          row['height'] = ((obj[props.subObjsField].length-1) * 30) + ((i+1) * 30);
            row['isOpen'] = true
      
            runReOrder && reOrderForOpen(props.baseObjs, obj, props.setBaseObjs, row['height'])
            setSubPagesClosed(false);
            setParentOpenId(obj.id);
        }

        }


        
        isDragging.current = false;
        // console.log('handleSubHeadings recd', obj, i, rows, row)
      }

  function reOrder(array, yObj, d, saveObj, ordering) {
  
      let reordered;
      let newArray = [ ...array ];   
    
      console.log('reOrder received', array, yObj, saveObj, ordering)
     
      if (yObj) {
        let orderingPassed = false;
        if (d) {
          if (parseInt(round30(d.y)/30) !== yObj.ordering) {
            orderingPassed = true
          }
        }
        // console.log('orderingPassed', orderingPassed, ordering, yObj.ordering, parseInt(d.y/30) )
        if (orderingPassed || (ordering > -1)) {
    
    
          let continueOrder = true;
          let newOrdering;
          let lastNumber = newArray.length - 1;
    
          if (orderingPassed) {
            let yObj_posY;
            if (d.y/30 === 0) {
              yObj_posY = d.y;
            } else {
              yObj_posY = round30(d.y);
            }
            newOrdering = parseInt((yObj_posY/30));
          } 
          if (!orderingPassed) {
            newOrdering = ordering;
            // elevatingSubs = false;

            elevatingSubs.current = false;
          }

          if (newOrdering > lastNumber) {
            newOrdering = lastNumber
          }
          // console.log('newOrdering', newOrdering)
          if (newOrdering < 0 || !newOrdering) {
            newOrdering = 0
          }
          
          // Continue Reorder Unless Sub Goes Above Group
          if (continueOrder) {
    
            let beforeNumber = [];
            let afterNumber = [];
            let sameNumber;
            if (newOrdering === 0) {
              console.log('%cFirst', 'color: red; font-weight: bold;');
              afterNumber = newArray.filter(obj0 => ((obj0.ordering > newOrdering)&&(obj0.id !== yObj.id)));
            } else if (newOrdering === (lastNumber)) {
              console.log('%cLast', 'color: green; font-weight: bold;');
              beforeNumber = newArray.filter(obj0 => ((obj0.ordering < newOrdering)&&(obj0.id !== yObj.id))); 
              newOrdering = newArray.length - 1
            } else {
              console.log('%cMiddle', 'color: gold; font-weight: bold;');
              beforeNumber = newArray.filter(obj0 => ((obj0.ordering < newOrdering)&&(obj0.id !== yObj.id)));
              afterNumber = newArray.filter(obj0 => ((obj0.ordering > newOrdering)&&(obj0.id !== yObj.id)));
            }

            sameNumber = newArray.find(obj0 => ((obj0.ordering === newOrdering)&&(obj0.id !== yObj.id)));

            // console.log('same', sameNumber, newArray, yObj, originY)
            if (sameNumber) {
            
              if (newOrdering > originY.current) {
                sameNumber && (sameNumber['ordering']=newOrdering-1);
              } else if (newOrdering < originY.current && newOrdering >= 0) {
                sameNumber && (sameNumber['ordering']=newOrdering+1);
              } else if (newOrdering === originY.current){
                sameNumber && (sameNumber['ordering']=newOrdering);
              } else {
                // pass 
              }
            } else {
              sameNumber = null
            }
            
            console.log('1. newOrdering', newOrdering, originY.current)
            console.log('2. beforeNumber', beforeNumber)
            console.log('3. sameNumber', sameNumber)
            console.log('4. afterNumber', afterNumber)

      
              beforeNumber.forEach((obj, i) => {
                obj.ordering = i
              });
              
              afterNumber.forEach((obj, i) => {
                obj.ordering = newOrdering+i+1
              });
      
         
      
              yObj.ordering = newOrdering;
              
              // originY = null;
              originY.current = null
              reordered = newArray.sort((a, b) => a.ordering - b.ordering);
      
              // SAVE CHANGES
      
              if (saveObj) {
                let reorderedCopy = [];
      
                reordered.forEach((obj, i) => {
                  obj.ordering = i
                  reorderedCopy.push(obj);
                })
                // console.log('onDragStop, setObj', yObj.ordering, reorderedCopy)
          
                let narrativeHeadingObjs = [];
                reorderedCopy.forEach((obj, i) => {
                  var narrativeHeadingObj = {}
                  narrativeHeadingObj['id'] = obj.id;
                  narrativeHeadingObj[props.subField] = obj.subs
                  narrativeHeadingObj[props.parentField] = null
                  narrativeHeadingObj['ordering'] = i;
                  narrativeHeadingObjs.push(narrativeHeadingObj);
                  // console.log('narrativeHeadingObj ready', narrativeHeadingObj)
                  if (i === reorderedCopy.length - 1) {
                    props.handleSave(narrativeHeadingObjs, false);
                  }
                })
                props.setBaseObjs(reorderedCopy);
                console.log('setObj: reorderedCopy sent & saved:', reorderedCopy)

                if (parentOpenId) {
                  setTimeout(() => {
                    let obj = props.baseObjs.findIndex(a => a.id === parentOpenId);
                    let rowsOpen = rows.find(a => a['isOpen']);
                    if (rowsOpen) {
                      rowsOpen['height'] = 30;
                      rowsOpen['isOpen'] = false
                      reOrderForOpen(props.baseObjs, obj, props.setBaseObjs, null)
                      setSubPagesClosed(true);
                      setParentOpenId(null);
                    }
        
                  }, 100);
                  
                }
                
      
              } else {
                console.log('setObj: sub reordered received', yObj, yObj[props.parentField])
                if (yObj[props.parentField]) {  
                  let array = [ ...props.baseObjs]
                  let heading = array.find(a => a.id === yObj[props.parentField])
                  heading[props.subField] = reordered
                  props.setBaseObjs(array)

                  // console.log('subs added', reordered)
                  let narrativeHeadingObjs = [];
                  reordered.forEach((obj, i) => {
                    var narrativeHeadingObj = {}
                    narrativeHeadingObj['id'] = obj.id;
                    narrativeHeadingObj['ordering'] = i;
                    narrativeHeadingObj[props.parentField] = obj[props.parentField];
                    narrativeHeadingObjs.push(narrativeHeadingObj);
                    if (i === reordered.length - 1) {
                        console.log('setObj: sub reordered sent', reordered)
                        props.handleSubSave(narrativeHeadingObjs, false);
                    }
                  })
                
      
                  
                }
                
      
              
              }
          
          } 
          
        }

       
        
      }
      
     
      isDragging.current = false;
 
      return reordered
    }
    

  function nestParent(y, objId, subsL) {
      if (y) {
          let yPosArray = [];
          let trueYpos = 0;
          rows.forEach((obj0, i) => {
            let obj = {};
            obj['id'] = obj0.id;
            obj['isOpen'] = obj0.isOpen;
            obj['startY'] = trueYpos;
            obj['endY'] = obj0.height;
            trueYpos+=obj0.height
            yPosArray.push(obj);
          });
          let overElem;
          try {
            overElem = yPosArray.filter(function(a){
              return y >= a.startY && y <= a.endY && a.isOpen
            });
          } catch {
            overElem = null;
          }
          console.log('yPosArray', yPosArray, overElem, objId, y)
          if (overElem.length > 0 && subsL === 0) {
          
            let newArray = [ ...props.baseObjs ];
            let objIndex = newArray.findIndex(a => a.id === objId);
            let objCopy = props.baseObjs[objIndex];
            newArray.splice(objIndex, 1)
            let targetIndex = newArray.findIndex(a => a.id === overElem[0].id);
            
            let newArrayReordered = [];
            
            newArray[targetIndex].subs && newArray[targetIndex].subs.forEach((a, i) => {
              newArrayReordered.push({
                'id': a.id,
                'heading': a.heading,
                'narrative': a.narrative,
                'page': a.page,
                'parent': overElem[0].id,
                'ordering': i+1
              })
            
            });
            
            let newObj = {
              'id': objCopy.id,
              'heading': objCopy.heading,
              'narrative': objCopy.narrative,
              'page': objCopy.page,
              'parent': overElem[0].id,
              'ordering': 0
            }
            newArrayReordered.push(newObj)
            newArray[targetIndex]['subs'] = newArrayReordered;
            props.setBaseObjs(newArray);
    
            console.log('overElem', overElem[0], newArray, newObj)

            setSubPagesClosed(true);
            setParentOpenId(null);
            
            reOrder(newArray, newObj, null, true, 0);
            
            return true;
    
          } else {
            return false
          }
      } else {
        return false
      }
    }

    function handleOnKeyUp(e, field, group, objId) {
      if (group === "file") {
        if (e.key === "Enter") {
          // handleModuleSave(field, e.target.value);
          console.log(field, e.target.value, objId)
          }
      }
      if (group === "category") {
        if (e.key === "Enter") {
          console.log(field, e.target.value, objId)
          // handleCategorySave(field, e.target.value, objId);
          }
      }
     
  
    }

  function onNarrativeHeadingContext(e, objId) {
    if (props.handleSave) {
      e.preventDefault();
      setTimeout(() => {
        setShowContextMenu(objId);
        // console.log('e', e.pageY, e.target, e)
        gsap.to(".contextMenu", {zIndex: 3, x: e.pageX, y: e.pageY - 120, duration: 0});
        gsap.to(".contextMenu", {opacity: 1, duration: 0.3}, "-=0.3");
        let listener = window.addEventListener('click', function(){
          setShowContextMenu(false);
          gsap.to(".contextMenu", {opacity: 0, zIndex: -1, duration: 0});
        })
        window.removeEventListener('click', listener); 
      }, 100);
    }
   
    
  }

  function handleNarrativeHeadingDelete(objId) {
    setShowContextMenu(false);
    gsap.to(".contextMenu", {opacity: 0, zIndex: -1, duration: 0});
    props.deleteParent(objId)
    
  }

  function makeIntoGroup() {
    setShowContextMenu(false);
    gsap.to(".contextMenu", {opacity: 0, zIndex: -1, duration: 0});
    props.convertToGroup()
  }
  function unGroup() {
    setShowContextMenu(false);
    gsap.to(".contextMenu", {opacity: 0, zIndex: -1, duration: 0});
    props.convertToGroup('ungroup', showContextMenu)
  }

  useEffect(() => {
    Draggable.create(handle.current, {
      type:"y",
      bounds: scrollBar.current,
      edgeResistance: 1,
      onDragStart: function() {
        // console.log("drag start");
      },
      onDrag: function(e) {
        let offset = "-" + scrollBar.current.offsetHeight * 0.4 * (e.y-100)/scrollBar.current.offsetHeight;
        gsap.to(scrollingContainer.current, {y: offset, duration: 0.2})
        setScrollY(offset);
      },
      onDragEnd: function(e) {
        console.log("drag end", (e.y-100)/scrollBar.current.offsetHeight, e);
      }
    });
    return ()=>{
      rnd.current=undefined
    }
  }, [])


  useEffect(() => {
    if (tempAfterObjs) {
      // console.log('tempAfterObjs', tempAfterObjs)
     
    }
  }, [tempAfterObjs])

  useEffect(() => {
    
    if (props.baseObjs && props.subObjsField) {
      let array;
      array = [...rows]
      props.baseObjs.forEach((obj, i) => {
        let exists = array[i];
        if (exists) {
            array[i].id = obj.id
            array[i].ordering = i
            if (array[i].isOpen) {
              array[i].height = ((obj[props.subObjsField].length-1) * 30) + ((i+1) * 30);
              
            } else {
            array[i].height = 30
            }
           
        } else {
            let newObj = {}
            newObj['id']= obj.id
            newObj['height'] = 30
            newObj['isOpen'] = false
            newObj['ordering'] = i
            newObj['subs'] = obj[props.subObjsField]
            array.push(newObj)
        }
      });
      // console.log('array', array, tempAfterObjs)
      setRows(array)
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  
  }, [props.baseObjs])

  useEffect(() => {
    // console.log('dragOrder', dragOrder)
  }, [dragOrder])

  function measureHeight() {
    let h = 0;
    rows.forEach(a => h+=a.height)
    let result = h + 30;
    let opacity = 0;
    if (result > (window.innerHeight * 0.8)) {
      opacity = 1;
    } else {
      gsap.to(scrollingContainer.current, {y: 0, duration: 0.2})
    }
    gsap.to(scrollBar.current, {height: window.innerHeight * 0.8, opacity: opacity, duration: 0.1})
    return result
  }

  function decideDragging(obj) {
    if (props.disableDragging) {
      return true
    } else {
      return subPagesClosed?false:(parentOpenId && (parentOpenId === obj.id))
    }
  }

    return (
        <div className="menuContainer">
            <div className="scrollScreen" ref={scrollScreen}></div>    
            {showContextMenu !== false && <div className="contextMenu">

              {props.parentField && (props.baseObjs.find(a => a.id === showContextMenu) && props.baseObjs.find(a => a.id === showContextMenu)[props.subObjsField].length === 0) && (props.multiSelectPageIds && props.multiSelectPageIds.length > 1)&& <Fragment><span className="contextBtn" onClick={() => makeIntoGroup()}>Group</span><span className="break"></span></Fragment>}
              {props.parentField && (props.baseObjs.find(a => a.id === showContextMenu) && props.baseObjs.find(a => a.id === showContextMenu)[props.subObjsField].length > 0) && <Fragment>
                <span className="contextBtn" onClick={() => props.setEditPageForm(showContextMenu)}>Rename</span>
                <span className="break"></span>
                <span className="contextBtn" onClick={() => unGroup()}>Ungroup</span>
                <span className="break"></span>
              </Fragment>}

              <span className="contextBtn" onClick={() => handleNarrativeHeadingDelete(showContextMenu)}>Delete</span>
            </div>}
            
            <span className="scrollingContainer" ref={scrollingContainer}>
            <div className="pages fieldRow">
                <div className="pageHeadings" style={{top: props.offsetTop }}>
                  
                 {props.baseObjs && props.baseObjs
                  .sort((a, b) => a.ordering - b.ordering)
                  .map((obj, i) => (
                    <div
                      className={"mediaFile ui pageBtn" + ((draggingId.current === "pageHeading_" + obj.id)?" active":"") + ((parentOpenId === obj.id)?" open":"") + (props.multiSelectPageIds && props.multiSelectPageIds.includes(obj.id) ?" active":"")+ ((obj.page && (props.sectionId === obj.page) && isDragging.current === false )?" selected":"")}
                      key={'pageBtn0_' + i}
                      id={"pageHeading_" + obj.id}
                      >

                      <span className={"pageBtnContainer"}>
                        <span className={"pageHeading" + (props.showPageCount?"":" noPageCount")}>
                          {props.showPageCount && <span className="pageNumber">Page {i+1}</span>}
                          <span className="navHeading" data-ordering={obj.ordering}>{obj[props.headingField] && obj[props.headingField].slice(0,20)}{obj[props.headingField] && obj[props.headingField].length > 22 && " ..."}</span>
                        </span>
                        <span className="groupFiles" 
                        id={"groupFiles_" + obj.id}
                        >
                        {obj[props.subObjsField] && obj[props.subObjsField]
                          .sort((a, b) => a.ordering - b.ordering)
                          .map((sub, j) => (
                            <div
                              className={"mediaFile ui pageBtn" + ((draggingId.current === "pageHeading_" + sub.id)?" active":"")+ ((sub.page && (props.sectionId === sub.page))?" selected":"")}
                              key={'subPages_' + j}
                              id={"pageHeading_" + sub.id}
                              >
                              <span className="groupFilesIn">
                                {props.showPageCount && <span className="pageNumber">Sub {j+1}</span>}

                                {props.subIsMedia &&  <span className="layerIconContainer">
                                {fileNameType(sub[props.subField], null).map((obj2, j) => (
                                <span key={"fileName_" + j}>{obj2}</span>
                                ))}
                                </span>
                                
                                }
                                {!props.subIsMedia && 
                                <span className="navHeading" data-ordering={sub.ordering}>{sub.heading && sub.heading.slice(0,22)}{sub.heading && sub.heading.length > 24 && " ..."}</span>}
                              </span>

                              </div>
                            ))}
                        </span>

                        </span>
                      </div>
                    
            
                  ))}
                  </div>

            </div>
        
        
            <div className="pages fieldRow pagesContainer">
                <div className="draggablePages dragBounds" style={{top: props.offsetTop, height: measureHeight() }}>
              
                {rows && props.baseObjs
                    .sort((a, b) => a.ordering - b.ordering)
                    .map((obj, i) => (
                    <Rnd
                        ref={rnd}
                        className={"mediaFile ui pageBtn"}
                        key={'pageBtn_' + i}
                        id={"page_" + i}
                        style={{'cursor': "default"}}
                        lockAspectRatio={true}
                        enableResizing={false}
                        dragAxis={'y'}
                        dragGrid={[1,30]}
                        bounds={'parent'}
                        // disableDragging={subPagesClosed?false:(parentOpenId && (parentOpenId === obj.id))}
                        disableDragging={decideDragging(obj)}
                        default={{
                        x: 0,
                        y: (i*30),
                        height: 30
                        }}
                        position={{
                        x: 0,
                        y: (tempAfterObjs.length > 0 && (tempAfterObjs.findIndex(a => a.id === obj.id) > -1)) ? tempAfterObjs.find(a => a.id === obj.id).ordering * 30 : obj.ordering * 30
                        }}
                        onDragStart={(e, d) => { 
                        
                          originY.current = parseInt(round30(d.y)/30)
                          dirRefY.current = parseInt(round30(d.y)/30)
                          originYpx.current = d.y
                         

                        }}
                        onDrag={(e, d) => {
                            let currOrdering = parseInt(d.y/30);
                            if (currOrdering !== originY.current) {
                              dirRefYprev.current = currOrdering
                              setDragOrder(currOrdering);
                            }
                            let newDir;

                            if (originY.current > currOrdering) {
                              newDir = 'up';
                              setDir('up');
                            } else {
                              newDir = 'down';
                              setDir('down');
                            }
                            
                            console.log('onDrag Sub', originY.current, currOrdering, newDir, d.lastY/30)

                              setTimeout(() => {
                              
                              isDragging.current = true;
                              draggingId.current = obj.id
                              }, 200);
                  
                        }}
                        onDragStop={(e, d) => {
                        if (isDragging.current === true) {
                            let isNesting = nestParent(d.y, obj.id, obj[props.subObjsField].length);
                            console.log('isNesting', isNesting)
                            if (isNesting === false) {
                          
                                  // console.log('reorder, isNesting false', originYpx.current - d.y)
                                  reOrder(props.baseObjs, obj, d, true, null)
                                 
                                 
                                  
                                
                            }
                            
                           
                        }
                        isDragging.current = false;
                        draggingId.current = null;
                        setDir(null);
                        
                        }}
                        >
                        <div className={"pageBtnContainer" + ((parentOpenId === obj.id)?" open":"")}>
                
                        <span 
                            className="groupHeading ArrowRight"
                            // data={obj[props.subObjsField]}
                            >
                            {obj[props.subObjsField] ? <ArrowRight 
                            className={"iconLg" + (obj[props.subObjsField] && obj[props.subObjsField].length === 0 ? " off":"")}
                            onClick={(e) => {
                                console.log('onclick', isDragging.current)
                                if (obj[props.subObjsField] && obj[props.subObjsField].length > 0) { 
                                 
                                    handleSubHeadings(obj, i, true)
                                } 
                            }}  
                            /> : <span /> }
                            <span 
                            className={"pageLink dragLine " + (dir === 'up'?'top':'') + (dir === 'down'?'bottom':'')}
                            onClick={(e) => {
                                if (!isDragging.current && props.handleParentClick) {
                                    props.handleParentClick(obj, e);
                                   
                                }
                              
                            }}
                            onContextMenu={(e) => onNarrativeHeadingContext(e, obj.id)}
                            >
                            
                            </span>

              
                            
                        </span>
           
                        {obj[props.subObjsField] && <span className="groupFiles">
                            {obj[props.subObjsField]
                            .sort((a, b) => a.ordering - b.ordering)
                            .map((sub, j) => (
                            <Rnd
                                className={"mediaFile ui pageBtn"}
                                key={'subPageBtn_' + j}
                                id={"subPage_" + j}
                                style={{'cursor': "default"}}
                                lockAspectRatio={true}
                                enableResizing={false}
                                dragAxis={'y'}
                                dragGrid={[1,30]}
                                bounds={'.dragBounds'}
                                disableDragging={props.disableDragging?true:false}
                                onClick={() => {
                                  
                                    if (!isDragging.current) {
                                      if (sub.fileType === 'pdf' && sub.file) {
                                        window.open(Config.mediaURL + sub.file);
                                      } else {
                                        props.handlePageClick && props.handlePageClick(sub)
                                       
                                      }
                                      console.log('onClick', sub)
                                    }
                                }}
                                default={{
                                x: 0,
                                y: (j*30),
                                height: 30
                                }}
                                position={{
                                x: 0,
                                y: sub.ordering * 30
                                }}
                                onDragStart={(e, d) => { 
                                  originY.current = parseInt(round30(d.y)/30)
                                  dirRefY.current = parseInt(round30(d.y)/30)
                                  originYpx.current = d.y
                                }}
                                onDrag={(e, d) => {
                                  let currOrdering = parseInt(d.y/30);
                                  if (currOrdering !== originY.current) {
                                    dirRefYprev.current = currOrdering
                                    setDragOrder(currOrdering);
                                  }
                                  let newDir;
      
                                  if (originY.current > currOrdering) {
                                    newDir = 'up';
                                    setDir('up');
                                  } else {
                                    newDir = 'down';
                                    setDir('down');
                                  }
                                  
                                  console.log('onDrag Sub', originY.current, currOrdering, newDir, d.lastY/30)
      
                                    setTimeout(() => {
                                    
                                    isDragging.current = true;
                                    draggingId.current = obj.id
                                    }, 200);
                                  
                                }}
                                onDragStop={(e, d) => {
                                       
                                    let elevateProcess = elevateSub(obj[props.subObjsField], sub, d, null, i);
                                    
                                    if (!elevateProcess && isDragging.current) 
                                        {
                                          
                                          console.log('run reorder: sent', obj[props.subObjsField], sub, d, false, null)
                                          reOrder(obj[props.subObjsField], sub, d, false, null)
                                         
                                          
                                        }
                  
                                        draggingId.current = null;
                                        isDragging.current = false;
                                        setDir(null);
                                        
                                  }}
                                 
                                >
                                <span className="groupFilesIn">
                                <span className={"pageLink dragLine " + (dir === 'up'?'top':'') + (dir === 'down'?'bottom':'')}></span>
                                </span>

                                </Rnd>
                            ))}
                        </span>}
               
                        
                        </div>
                    
                        </Rnd>
                    ))}
               
                </div>
            </div>
            </span>
                               
            <div className="scrollBar" ref={scrollBar}>
                <div className="scrollHandle" id="scrollHandle" ref={handle}></div>
            </div>
        </div>                 
    );
}

export default MenuList;