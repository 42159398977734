import React, { useState, useEffect, useRef } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
  Navigate,
  useNavigate,
  useSearchParams
} from "react-router-dom";
import axios from "axios";
import Cookies from 'js-cookie';
import gsap from "gsap";
import Config from "./Config.json";
import Package from '../package.json';
import speedTest from "./utils/speedtest";
import Login from './component/app.login';
import Canvas from './component/app.narrativeDetail';
import Narrative from './component/app.narrative';
import Gallery from './component/app.gallery';
import Narratives from './component/app.narratives';
import Documents from './component/app.documents';
import Mapbox from './component/app.mapbox';
import Reset from './component/app.reset';
import Learn from './component/app.learn';
import Help from './component/module.help';
import Modal from './component/module.modal';
import Loader from "./static/svg/loader.svg";
import LeftArrow from "./static/svg/leftarrow.svg";

// Icons
import {ReactComponent as Search} from "./static/icons/search.svg" 
import {ReactComponent as Home} from "./static/icons/home.svg" 
import {ReactComponent as Info} from "./static/icons/info.svg" 
import {ReactComponent as Logo} from "./static/svg/logo.svg" 


import './static/css/app.css';
// import logo from './logo.svg';

const routes = [
  // { path: '', name: 'BPS 3.0 Admin', Component: Narratives },
  { path: '/narratives/:id', name: '', Component: Canvas },
]

var base_url = Config.baseURL

function App() {
  // const helpHover = useRef();
  const [isUser, setIsUser] = useState(true);
  const [user, setUser] = useState(true);
  const [passToken, setPassToken] = useState(null);
  const [isDark, setIsDark] = useState(true);
  const [username, setUsername] = useState(null);
  const [showSettings, setShowSettings] = useState(false);
  const [userId, setUserId] = useState(null);
  const [token, setToken] = useState(null);
  const [tools, setTools] = useState('search');
  const [showInfo, setShowInfo] = useState(false);
  const [hoverText, setHoverText] = useState(false);
  const [speed, setSpeed] = useState();
  const [formError, setFormError] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [speedClocked, setSpeedClocked] = useState(false);

  function handleShowSettings() {
    if (showSettings) {
      setShowSettings(false) 
    } else {
      setShowSettings(true) 
    }
  }


  function handleDarkMode() {
    let darkModeVal;
    if (isDark) {
      darkModeVal = false;
    } else {
      darkModeVal = true;
    }
    setIsDark(darkModeVal);
    setShowSettings(false);

    let formData = {};
    formData['id'] = userId;
    // formData['user'] = userId;
    formData['dark_mode'] = darkModeVal;

    try {
      // console.log('try prefs', userId, isDark)
      const url = base_url + "/api/user-setting/" + userId + '/';
      axios({
          method: "post",
          url: url,
          data: formData,
          headers: {
            Authorization: 'Token ' + token
          },
      }).then((response) => {
        console.log('response, isDark', response)
    
      });
    } catch (error) {
        console.error(error);
        console.log('error prefs', userId, isDark)
    }

  }

  function handleLogOut() {
    var cookie = Cookies.get('_udxdid');
    if (cookie) {
      axios({
        method: 'post',
        baseURL: base_url,
        url: '/token/logout/',
        data: {
          'username': username,
          'token': cookie
        },
        headers: {
            Authorization: 'Token ' + cookie
        }

        })
        .then(response => {
          console.log('response', response, response.data['username'])
          Cookies.remove('_udxdid');
          setUsername(null);
          setIsUser(false);
          setToken(null);
          setPassToken(null);
        })
        .catch(function (error) {
          if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          } else if (error.request) {
          console.log(error.request);
          } else {
          console.log('Error', error.message);
          }
          console.log(error.config);
      });
    }
  }

  useEffect(() => {
    
    var cookie = Cookies.get('_udxdid');
    if (cookie) {      
      axios({
        method: 'get',
        baseURL: base_url,
        url: '/auth/users/me/',
        headers: {
            Authorization: 'Token ' + cookie
        }
        })
        .then(response => {
          // console.log('response', response, response.data['username']);
          setShowLoader(true);
          !speedClocked && speedTest().then(speedInMbps => {
            setShowLoader(false);
            setSpeedClocked(true);
            // console.log('speedtest')
            if (speedInMbps < 60) {
              
              let newError = {
                "heading": "Warning",
                "text": "Your download speed was clocked at <strong>" + speedInMbps + "mb/s</strong>.<br/>This is slower than our recommended standard of 60mb/s. As a result you may experience issues when editing large presentations and files in this app."
              };
              setFormError(newError);
            }

          });

          if (response.data['username']) {
            setUsername(response.data['username']);
            setUserId(response.data['id']);
            setIsUser(true);
            setToken(cookie);
            setPassToken(cookie);
          } else {
            setUsername(null);
            setUserId(null);
            setIsUser(false);
          }
          if (response) {
            axios({
              method: 'get',
              baseURL: base_url,
              url: '/api/user-setting/' + response.data['id'],
              headers: {
                Authorization: 'Token ' + cookie
              },
              })
              .then(response => {
                // console.log('user response', response.data)
                setUser(response.data);
              })
              .catch(function (error) {
                if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                } else if (error.request) {
                console.log(error.request);
                } else {
                console.log('Error', error.message);
                }
                console.log(error.config);
                setUser(null);
            });
          }
          
          
        })
        .catch(function (error) {
          if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          } else if (error.request) {
          console.log(error.request);
          } else {
          console.log('Error', error.message);
          }
          console.log(error.config);
          setUsername(null);
          setIsUser(false);
      });
    } else {
      setIsUser(false);
    }
   
  }, []);

  useEffect(() => {
    if (userId && token) {
      try {
        const url = base_url + "/api/user-setting/" + userId + "/";
        axios({
            method: "get",
            url: url,
            headers: {
              Authorization: 'Token ' + token
            },
        }).then((response) => {
          // console.log('user-settings', response, response.data['dark_mode'])
          setIsDark(response.data['dark_mode']);
        });
      } catch (error) {
          console.error(error);
          console.log('error prefs', userId, isDark)
      }
    }
  }, [userId, token])

  function onMouseEnter(e, text) {
    let target = document.getElementById("helpHover");
    setHoverText(text);
   
    function calcLeft(){
      let r = (e.target.getBoundingClientRect().x + e.target.getBoundingClientRect().width/2) - (text.length*8.5)/2; 
      if ((r + (text.length*9)) > window.innerWidth ) {
        r = window.innerWidth - (text.length*7)
      }
      return r
    }

    function calcTop(){
      let r = e.target.getBoundingClientRect().y - 45
      if ((r + 46) > window.innerHeight) {
        r = window.innerHeight - 46
      } else if ((r + 46) < 80) {
        r = e.target.getBoundingClientRect().y + e.target.getBoundingClientRect().height + 8
        document.querySelectorAll(".helpHoverCaret")[0].classList.add('reverse');
      } else {
        document.querySelectorAll(".helpHoverCaret")[0].classList.remove('reverse');
      }
      return r
    }
    gsap.to(target, {zIndex: 11, x: calcLeft(), y: calcTop(), duration: 0});
    gsap.to(target, {opacity: 1, duration: 0.3});
    
  }

  function onMouseLeave() {
    let target = document.getElementById("helpHover");
    setHoverText(null);
    gsap.to(target, {opacity: 0, duration: 0.3});
    gsap.to(target, {zIndex: -1, duration: 0});
   
  }


  return (

      <div className={"app" + ((isDark)?(" dark"):(" light"))}>

      {(showLoader)?(<div id="loading"><img src={Loader} alt="" /></div>):null}

      {(formError)&&<Modal heading={formError.heading} text={formError.text} onBtn1Click={setFormError} onBtn1={null} btn1Text={"Close"} onBtn2Click={null} onBtn2={null} btn2Text={null} />}

      {showInfo && <Modal heading={"UDXD: Create + Present"} text={"<span class='betaText'>BETA</span><br/>Version: " + Package.version + "<br/>Build: " + Config.buildMajor + "." + Config.buildMinor + "." + Config.buildRevision} onBtn1Click={setShowInfo} onBtn1={false} btn1Text={"Ok"}  />}

      <Help text={hoverText} />

        {username && <header>
          <nav>
             <NavLink
              className={"menuLink"}
              to={"/"}
              // onClick={() => handlePageChange(route.path)}
             
            >
              <Home className="icon" />
              
            </NavLink>
            
          </nav>
          <div className="tools">
          {(tools === "search") && <a href="/" id="logo"><Logo className="logo" /></a>}
          
        

          </div>
          <div id="showSettings">
                  
         <div className="ui" onClick={()=>handleShowSettings()}>
            <span className="item username">{username && username.slice(0,1)}</span>
        
          </div>
      </div>
          {(showSettings) ? (
            <div className="userBtns">
              <span className="userBtn lower">{username}</span>
              <div className="userBtn mode ui" onClick={() => console.log('mode')}>{(isDark)?(
                <span className="iconField">
                <svg xmlns="http://www.w3.org/2000/svg" width="29.944" height="29.944" viewBox="0 0 29.944 29.944">
                <path id="Icon_feather-moon" data-name="Icon feather-moon" d="M31.5,19.185A13.5,13.5,0,1,1,16.815,4.5,10.5,10.5,0,0,0,31.5,19.185Z" transform="translate(-3.056 -3)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
              </svg>
              Dark Mode
                </span>
              ):(
                <svg id="Icon_feather-sun" data-name="Icon feather-sun" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                  <path id="Path_115" data-name="Path 115" d="M25.5,18A7.5,7.5,0,1,1,18,10.5,7.5,7.5,0,0,1,25.5,18Z" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                  <path id="Path_116" data-name="Path 116" d="M18,1.5v3" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                  <path id="Path_117" data-name="Path 117" d="M18,31.5v3" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                  <path id="Path_118" data-name="Path 118" d="M6.33,6.33,8.46,8.46" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                  <path id="Path_119" data-name="Path 119" d="M27.54,27.54l2.13,2.13" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                  <path id="Path_120" data-name="Path 120" d="M1.5,18h3" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                  <path id="Path_121" data-name="Path 121" d="M31.5,18h3" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                  <path id="Path_122" data-name="Path 122" d="M6.33,29.67l2.13-2.13" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                  <path id="Path_123" data-name="Path 123" d="M27.54,8.46l2.13-2.13" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                </svg>

              )}</div>
              {<div className="userBtn" onClick={() => handleLogOut()}>Log Out</div>}
              </div>
            ) : null}
           
        </header>}

        <Routes> 
          <Route index exact path="/" element={(isUser)?(<Narratives user={user} setTools={setTools} status={'active'} />):(<Navigate to="/login" />)} />
          <Route path="narratives">
            <Route index={true} element={(isUser)?(<Narratives user={user} setTools={setTools} status={'active'} onMouseEnter={onMouseEnter} />):(<Navigate to="/login" />)} />
            <Route path="deleted/" element={(isUser)?(<Narratives user={user} setTools={setTools} status={'deleted'} />):(<Navigate to="/login" />)} />
            <Route path=":id" element={(isUser)?(<Narrative user={user} setTools={setTools} onMouseEnter={onMouseEnter} />):(<Navigate to="/login" />)} />
            <Route path=":id/pages" element={(isUser)?(<Canvas user={user} setTools={setTools} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />):(<Navigate to="/login" />)} />
          </Route>
          <Route path="modules">
            <Route path="gallery/:id" element={(isUser)?(<Gallery user={user} setTools={setTools}/>):(<Navigate to="/login" />)} />
            <Route path="document/:id" element={(isUser)?(<Documents user={user} setTools={setTools}/>):(<Navigate to="/login" />)} />
            <Route path="mapbox/:id" element={(isUser)?(<Mapbox user={user} setTools={setTools}/>):(<Navigate to="/login" />)} />
          </Route>
          <Route index exact path="/login" element={<Login setUser={setUser} />} />
          <Route index exact path="/reset-password" element={<Reset user={user} />} />
          <Route path="learn/" element={(isUser)?(<Learn user={user} setTools={setTools}/>):(<Navigate to="/login" />)} />
        </Routes>

      

        <span className="beta">BETA</span>
          <span id="version" className="ui" onClick={() => setShowInfo(!showInfo)}>
              <span className="iconField"><Info className="iconMd fillWhite" /></span>
          </span>
      </div>
    

    );

}

export default App;
// 
// {(tools === "search") && <div className="siteFilter ui searchField">
              
// <span>
//   <Search className="icon" />
//   <input type="text" className="search" placeholder="Search ..." />
// </span>
// </div>}