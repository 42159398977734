import React, { useState, useEffect, useRef, useCallback } from "react";
import { Rnd } from "react-rnd";
import Config from "../../Config.json";
import {animDropDown, processCSS, handleInputFocus, processHtmlText} from "../../utils/form.tools";
import { el } from "date-fns/locale";

function Frame(props) {
    const selectDrag = useRef(false);
    const isDragging = useRef(false);
    const selectCleared = useRef(false);
    const textEditDiv = useRef();
    const mouseDownListener = useRef();
    // const mouseMoveListener = useRef();
    const mouseUpListener = useRef();
    const selectionListener = useRef();
    const escListener = useRef();
    const editorOn = useRef(null);
    const tempTextObj = useRef();

    const [isSelecting, setIsSelecting] = useState(false);
    const [zoomRatios, setZoomRatios] = useState([]);
    // const [selectionObj, setSelectionObj] = useState();

    useEffect(() => {
      // console.log('frame props', props)
      if (props.zoomRatio && props.aspectRatio) {
         let ratios = [...zoomRatios]  
         if (ratios.length === 0) {
            ratios[0] = props.zoomRatio
            ratios[1] = props.zoomRatio
         } else {
          if (props.aspectRatio === 1) {
            ratios[0] = props.zoomRatio
          }
          if (props.aspectRatio === 2) {
            ratios[1] = props.zoomRatio
          }
         }
         setZoomRatios(ratios);
      }
    }, [props.zoomRatio, props.aspectRatio])

    function mouseMoveListener() {
      if (!selectDrag.current) {
        selectDrag.current = true
        
      }
      console.log('mousemove')
    }
    
    mouseDownListener.current = () => {
      selectDrag.current = false
    }

    async function clearSelected() {
      let selectionObj = window.getSelection(); 
      if (selectionObj) {
        
        do {
        
          let selects = document.querySelectorAll('.selection');
          // console.log('selects', selects.length)
          selects && selects.forEach((node, i) => {
  
            if (!node.classList.contains('stl')) {
              let text = node.innerHTML;
              node.insertAdjacentHTML('beforebegin', text);
              node.remove();
            } 
            node.classList.remove('selection');
            if (i === (selects.length - 1)) {
              selectCleared.current = true;
            }
          })

    
          let cleanUps = document.querySelectorAll('.stl');
          cleanUps && cleanUps.forEach((node, i) => {
            if (node.innerHTML.trim() === "") {
              console.log('empty node', node)
              node.remove();
            } 
      
          })

          selectDrag.current = false;
          setIsSelecting(false);
      
          if (selectCleared.current === true) {
            props.setSelection(false);
            console.log('clearSelected CLEARED')
            return true
          } else {
            // console.log('clearSelected false 1')
            return false
          }
        } while (selectCleared.current === false) 

       
       
      } else {
        props.setSelection(false);
        // console.log('clearSelected failed: no selection obj.')
        return false
      }
    }



    function processObjSelection(selectionObj) {
      // console.log('selectionObj step 1')
      
        let newComponents = [ ...props.componentDimensions ];
        let newComponent = newComponents.find(obj => obj.id === props.activeSettingId)

        // console.log('processSelection, step1', selectionObj, newComponent, selectDrag.current)
        if (newComponent && !selectDrag.current) {
          if (newComponent.html_text && newComponent.html_text['text']) {

            // console.log('processSelection, step2', newComponent.html_text)
            let text = newComponent.html_text['text'];

            if (selectionObj && text && /\S/.test(selectionObj.toString())) {

              // console.log('processSelection, step3', text, /\S/.test(selectionObj.toString()))
              let range = selectionObj.getRangeAt(0)
              let tempHtml = document.createElement('div');
              tempHtml.append(range.cloneContents())
              let match;

              if (tempHtml.innerHTML === selectionObj.anchorNode.parentElement.innerHTML) {
                match = true;
              } else {
                match = false;
              }
              setIsSelecting(false);
              // console.log('match', match, tempHtml)

              function createSpan() {
                  
                  var span = document.createElement("span");
                  span.classList.add('selection')  
                  span.innerHTML = tempHtml.innerHTML;

                  if (
                    selectionObj.focusNode.parentElement 
                    && selectionObj.focusNode.parentElement.classList.contains('stl') 
                    && match) {
                      selectionObj.focusNode.parentElement.classList.add('selection');
                      // console.log('match', selectionObj.focusNode.parentElement)
                    
                    } 
                    else {
                      let range = selectionObj.getRangeAt(0)
                      range.deleteContents();
                      range.insertNode(span);
                      // console.log('new span', span, range)
                      
                  } 
                  setTimeout(() => {
                    props.setSelection(true);
                  }, 100);

                  // console.log('mousemove cancelled')
                }

                if (tempHtml.innerHTML.trim() !== "") {
                  createSpan();
                  
                }

              }
          
          } 
        
        
        }
    }
    
    
    mouseUpListener.current = () => {
      console.log('mouseUpListener on')
      clearSelected();
    }
    

    function onSelectStart() {
      
      if (textEditDiv.current && !selectDrag.current) {


        clearSelected().then(function() {
          let selection =  window.getSelection(); 

          let hasSelection = false;
          if (/\S/.test(selection.toString())) {
            hasSelection = true
          }
    
          // console.log('mouseup: selectDrag.current: ' + selectDrag.current + " | hasSelection: " + hasSelection)
    
    
          if (selection && /\S/.test(selection.toString())) {
            
            // console.log('selection detected',  /\S/.test(selection.toString()))
    
            // setSelectionObj(selection);
            processObjSelection(selection)
            selectDrag.current = false;
    
    
    
          }

        })


        
      }
    }


  useEffect(() => {
    // console.log('isSelecting: ', isSelecting)
    if(textEditDiv.current && isSelecting) {
      console.log('isSelecting', isSelecting)
      textEditDiv.current.addEventListener('mousedown', mouseDownListener.current)
      textEditDiv.current.addEventListener('mousemove', mouseMoveListener)
      textEditDiv.current.addEventListener('mouseup',  mouseUpListener.current)

    }

 
  }, [isSelecting, textEditDiv.current])
    


    function handleCanvasClick(e) {
        let classes = e.target.classList;
        
        if (classes.contains("clearBtn")) {

          
          clearSelected().then(function(){

            // console.log('handleCanvasClick')
            
            props.setActiveSettingId(null);
            editorOn.current = false;
            props.setDropDown(null);
            props.setSelection(false);
  
            if (isSelecting) {
              setIsSelecting(false);
            }
            
          });
        
        }
    }

    function handleText(e) {
      if (editorOn.current) {
        let newComponents = [ ...props.componentDimensions ];
        let newComponent = newComponents.find(obj => obj.id === props.activeSettingId)

        let lines = 1
        if (e.target.innerHTML.split('\n')) {
          lines = e.target.innerHTML.split('\n').length;
        }
  
        let newText = e.target.innerHTML;
        let patt0 = new RegExp("<br>", "g")
        let patt1 = new RegExp("&nbsp;", "g")
        newText=newText.replace(patt0,"").replace(patt1,"");
        newComponent['html_text']['text'] = newText;
          
        props.setComponentDimensions(newComponents)
        console.log('newComponents', newComponents, newText)

      }

        
          
      }


      function handleEscClick(e) {
       
        if(e.key === "Escape") {
          clearSelected().then(function () {

            console.log('handleEscClick')

            props.setActiveSettingId(null);
            editorOn.current = false;
            props.setDropDown(null);
            selectDrag.current = false;
            // window.removeEventListener('keyPress', escListener.current);
            props.setSelection(false);
            setIsSelecting(false);
            // textEditDiv.current.removeEventListener('click', selectListener.current);
          });
         
        }
      }
    

      function makeTextEditable(e){
        if (isSelecting !== true) {
          

          e.target.contentEditable = true
          escListener.current = window.addEventListener('keydown', function(e){
            handleEscClick(e)
          })
          textEditDiv.current = e.target;
          
        }
        
       
        
        
      }   

      function apiToZoom(value, isLocked) {
        let result;
        if (props.aspectRatio === 1){
          result = value * props.zoomRatio 
        } else {
          if (isLocked) {
            result = value * zoomRatios[0]
          } else {
            result = value * zoomRatios[1] 
          } 
        }
        return result
      }


      function zoomToApi(value, isLocked){
        let result;
        if (props.aspectRatio === 1){
          result = value/props.zoomRatio 
        } else {
          if (isLocked) {
            result = value/zoomRatios[0]
          } else {
            result = value/zoomRatios[1] 
          } 
        }
        return parseInt(result)
      }

    return(
        <div className="canvasContainer clearBtn" onClick={(e) => handleCanvasClick(e)}>
        <div className="canvasPage clearBtn">

          <div className={(props.activeSettingId)?("canvasFormWrap inFocus clearBtn"):("canvasFormWrap clearBtn offFocus")}>
            <div className={"canvasForm aspectRatio_" + (props.aspectRatio) + ((props.showFullCanvas) ? " showFull":"")} id={"canvasForm"} >
              <div 
              className="clipPath" 
              style={{
                // "left": ((activeSectionObj && activeSectionObj.scroll_offset)?("-" + activeSectionObj.scroll_offset):0),
                "position": "relative"
              }}
              id="canvasPath"
              >
                {props.componentDimensions && props.componentDimensions
                  .sort((a, b) => a.component_ordering - b.component_ordering)
                  .filter(file => (file.active === true))
                  .map((dimension, i) => 
                (dimension) ?
                  (<Rnd
                    
                    onClick={(e) => {
                        if (!isDragging.current && (props.activeSettingId !== dimension.id)) {
                        
                          // showSettings(dimension.id, i, false, e)
                        }
                        if (dimension.background||dimension.change_lock) {
               
                          props.showSettings(dimension.id, false, e)
                         }

                        
                    }}
                    onDoubleClick={(e) => {
                      if ((dimension.file_format === "text")&&(dimension.html_text)) {
                        editorOn.current = dimension.id;
                        tempTextObj.current = dimension.html_text['text'];
                        let htmlElem = document.getElementById("dim_text_" + dimension.id)
                        const length = htmlElem.innerText.length;

                        if (htmlElem) {
                          var selectedText = window.getSelection();
                          // var selectedRange = document.createRange();
                          // if (htmlElem.childNodes[0] && length) {
                          //   selectedRange.setStart(htmlElem.childNodes[0], length);
                            
                          // }
                          
                          // selectedRange.collapse(true);
                          //   selectedText.removeAllRanges();
                          //   selectedText.addRange(selectedRange);
                          htmlElem.focus(); 
                        }
                        props.showSettings(dimension.id, false, e)
                      }
                    }}
                    id={"canvasForm1_draggable_" + dimension.id}
                    position={{
                      x: apiToZoom(dimension.x_pos, dimension.is_locked), 
                      y: apiToZoom(dimension.y_pos, dimension.is_locked)
                    }}
                    size={{
                      width: apiToZoom(dimension.width, dimension.is_locked),  
                      height: apiToZoom(dimension.height, dimension.is_locked)
                    }}
                    key={'draggable_' + dimension.id}
                    lockAspectRatio={(dimension.file_format === "text")?false:((props.lockAr)?true:false)}
                    enableResizing={(dimension.background)?(false):(true)}
                    disableDragging={(dimension.background||editorOn.current||dimension.change_lock)?(true):(false)}
                    onDragStart={(e) => {
                      props.showSettings(dimension.id, false, e)
          
                    }}
                    onDrag={(e, d) => {
                      isDragging.current = true;
                    }}
                    onDragStop = {(e, d) => { 
                      var newComponents = [ ...props.componentDimensions ];
                      var newObj = newComponents.find(obj => (obj.id === dimension.id));
                      if ((newObj['x_pos'] !== zoomToApi(d.x, dimension.is_locked))&&(newObj['y_pos']!==zoomToApi(d.y, dimension.is_locked))) {
                        newObj['x_pos'] = zoomToApi(d.x, dimension.is_locked);
                        newObj['y_pos'] = zoomToApi(d.y, dimension.is_locked);
                        setTimeout(() => {
                          if (newObj) {
                            document.getElementById('x_pos_dim_' + dimension.id).value = newObj['x_pos'];
                            document.getElementById('y_pos_dim_' + dimension.id).value = newObj['y_pos'];
                          }
                        }, 100);
                        props.setComponentDimensions(newComponents);
                        props.setUnSavedChanges(true);
                      }
                     
                      setTimeout(() => {
                        isDragging.current = false;
                      }, 300);
                    }}
                    onResizeStop={(e, direction, ref, delta, position) => {

                      var newComponents = [ ...props.componentDimensions ];
                      var newObj = newComponents.find(obj => (obj.id === dimension.id));
                      if (newObj) {
                        newObj['width'] = zoomToApi(ref.offsetWidth, dimension.is_locked);
                        newObj['height'] = zoomToApi(ref.offsetHeight, dimension.is_locked);
                        newObj['x_pos'] = zoomToApi(position.x, dimension.is_locked);
                        newObj['y_pos'] = zoomToApi(position.y, dimension.is_locked);
                        
                        setTimeout(() => {
                          if (newObj) {
                            if (document.getElementById('x_pos_dim_' + dimension.id)){
                              document.getElementById('x_pos_dim_' + dimension.id).value = newObj['x_pos']
                            }
                            if (document.getElementById('y_pos_dim_' + dimension.id)) {
                              document.getElementById('y_pos_dim_' + dimension.id).value = newObj['y_pos'];
                            }
                            if (document.getElementById('width_dim_' + dimension.id)){
                              document.getElementById('width_dim_' + dimension.id).value = newObj['width'];
                            }
                            if (document.getElementById('height_dim_' + dimension.id)){
                              document.getElementById('height_dim_' + dimension.id).value = newObj['height'];
                            }
                            
                            
                          }
                        }, 100);
                        props.setComponentDimensions(newComponents);
                        props.setUnSavedChanges(true);
                      }
                      
                    }}
                    >
                    <div className={
                      "mediaThumb draggable" + 
                      ((props.activeSettingId === dimension.id)?" focus":"") + 
                      ((props.multiSelectIds.includes(dimension.id) && (props.multiSelectIds.length > 1)) ? 
                      " focus multi":"") + (((dimension.html_text && editorOn.current && dimension.file_format === "text" && props.activeSettingId === dimension.id)?" text"
                      :"")) +
                      (dimension.background ? " bkgLayer":"")
                      
                      } 
                      id={"card_" + dimension.id}>
                      <div 
                        className="canvas-card"
                        id={"cardInner_" + dimension.id}
                        data-ordering={dimension.component_ordering}
                        >
                    
                        <span className="resizer topLeft"></span>
                            <span className="resizer topRight"></span>
                            <span className="resizer bottomRight"></span>
                            <span className="resizer bottomLeft"></span>
                          <div className="canvas-card-inner">
                            
                            <div className="draggableImage canvas-card-front editorLayer">
                   
                                
                                { (dimension.file_format === 'img')
                                  && dimension.componentdimensionstateimages.filter(cdsi => parseInt(cdsi.state_id) === props.activeStateId).map((obj, i) => (
                                    <img
                                    key={'stateImg'+i}
                                    draggable="false"
                                    className={"imgDefault"}
                                    src={obj.image_path}
                                    alt=""
                                  />
                                  ))

                                }

                              
                                {(
                                ((dimension.file_format === 'img')
                                  && (dimension.componentdimensionstateimages.filter(cdsi => parseInt(cdsi.state_id) === props.activeStateId).length < 1))
                                ||(dimension.file_format === 'statePNGButton')) && (
                                  <img
                                    draggable="false"
                                    className={"imgDefault" + ((props.activeStateId === dimension.component_id)?" on":"")}
                                    src={(props.activeStateId === dimension.component_id)?(dimension.image_on_path?dimension.image_on_path:dimension.image_path):(dimension.image_path)}
                                    alt=""
                                  />
                                )}
                                {(dimension.file_format === 'svg') && (
                                  <img
                                  draggable="false"
                                  className="imgDefault"
                                  src={dimension.svg_path}
                                  alt=""
                                  />
                                  )}

                                {(dimension.file_format === 'vid') && (
                                  <video 
                                  draggable="false" 
                                  className="vidDefault"
                                  width={apiToZoom(dimension.width, dimension.is_locked)}
                                  height={apiToZoom(dimension.height, dimension.is_locked)}
                                  playsInline
                                  >
                                    <source src={dimension.video_path} type="video/mp4" />
                                  </video>
                                  )}
                                
                                  {((dimension.file_format === "text")&&(dimension.html_text)) && 
                                  <span 
                                    name="textValue" 
                                    className={"canvasText" + (editorOn.current?"":" readOnly")} contentEditable={editorOn.current?true:false} suppressContentEditableWarning={true} 
                                    onKeyDown={(e) => handleText(e)} 
                                    // onSelect={() => onSelectStart()}
                                    onDoubleClick={(e) => makeTextEditable(e)} 
                                    id={"dim_text_" + dimension.id} 
                                    // onChange={(e) => handleTextSelect(e)} 
                                    style={processCSS(dimension.html_text.css, props.zoomRatio)} dangerouslySetInnerHTML={{__html:(editorOn.current === dimension.id)?processHtmlText(tempTextObj.current):processHtmlText(dimension.html_text['text'], props.zoomRatio)}}>
                                    </span>
                                  }
                              
                            </div>
                    
                          </div>
                        </div>
                    </div>
                  </Rnd>):null
                
                )}

                {!props.activeSectionObj && props.narrative && <img src={Config.mediaURL + props.narrative.screenshot} className="screenshot" alt="" />}
              </div>
              
            </div>
            
          </div>
                    
          

          </div>
        </div>
    )
}

export default Frame;

