import gsap from "gsap";

function animDropDown(open, activeSettingId, dropDown){
    // gsap.to(".dropDownChoices", {height: 0, duration: 0});
    // gsap.to(".dropDownField", {opacity: 0, scale: 0, zIndex: -1, duration: 0.1});

    if (open) {
        console.log(open, activeSettingId, dropDown)
      let activeSettingIdVal;
      if (!activeSettingId) {
        activeSettingIdVal = 'new';
      } else {
        activeSettingIdVal = activeSettingId;
      }
      console.log('dropDown', dropDown, 'settingsRow_' + dropDown, document.getElementById('settingsRow_' + dropDown), activeSettingIdVal)
      let parent = document.getElementById('settingsRow_' + dropDown).offsetLeft;
      let dropDownElem;
      dropDownElem = document.getElementById("inputField_" + dropDown + "_" + activeSettingIdVal ).offsetLeft;
      let offsetLeft = parent - dropDownElem;
      var tl = gsap.timeline();
      tl.to("#inputField_" + dropDown + "_" + activeSettingIdVal + " .dropDownChoices", {height: "auto", duration: 0});
      tl.to("#inputField_" + dropDown + "_" + activeSettingIdVal, {zIndex: 3, x: offsetLeft, height: "auto", duration: 0}, "<");
      tl.to("#inputField_" + dropDown + "_" + activeSettingIdVal, {scale: 1, opacity: 1, duration: 0.1});
    } else {
      gsap.to(".dropDownChoices", {height: 0, duration: 0});
      gsap.to(".dropDownField", {opacity: 0, scale: 0, zIndex: -1, duration: 0.1});
    }
  }
  function processCSS(objCss, zoomRatio) {
    let array = Object.entries(objCss);
    let reactCss = {};

    // console.log('objcss', objCss, zoomRatio)

    array.forEach((obj, i) => {
      switch (obj[0]) {
        case 'font-family':
          reactCss['fontFamily'] = obj[1];
          break;
        case 'font-weight':
          reactCss['fontWeight'] = obj[1];
          break;
        case 'font-size':
          let s = obj[1];
     
          if (s) {
            if (typeof s === 'string') {
              let s0 = s.replace('px', '');
              s = parseInt(s0) * zoomRatio;
              // console.log('s 1', s)
            } else {
              s = s * zoomRatio;
              // console.log('s 2', s)
            }
           
            // console.log('s', obj[1])
            reactCss['fontSize'] = s + "px";
          }
           
          break;
        case 'text-align':
          reactCss['textAlign'] = obj[1];
          break;
        case 'color':
          reactCss['color'] = obj[1];
          break;
        case 'letter-spacing':
          reactCss['letterSpacing'] = obj[1];
          break;
        case 'line-height':
          reactCss['lineHeight'] = obj[1];
          break;
        default:
          break;
      }
    })
    
    return reactCss
  }

  function processHtmlText(objText, zoomRatio) {
    let div = document.createElement('div');
    div.innerHTML = objText;
    let spans = div.querySelectorAll('.stl');
    let result;
    if (spans) {     
      for (let i = 0; i < spans.length; i++) {
        let propertyVal = spans[i].style.getPropertyValue('font-size');
        if (propertyVal) {
          let s0 = propertyVal.replace('px', '');
          let s = parseInt(s0) * zoomRatio;
          let sVal = s.toFixed(3) + "px"
          spans[i].style.fontSize = sVal;
        } 
      }
      result = div.innerHTML;
    } else {
      result = objText;
    }
    return result
  }

  function handleInputDropDownFocus(target){
    var dropdown_id = target.id;

    // console.log('handleInputDropDownFocus', target, dropdown_id)
    var dropdown = document.getElementById(dropdown_id + '_dd');

    // console.log(target.classList, dropdown)

    if (target.classList.contains('on')) {
      target.classList.remove('on');
      if (dropdown) {
        dropdown.classList.add('off');
      }
    } else {
      var onInputs = document.querySelectorAll('.valueInput.on');
      var onDropDowns = document.querySelectorAll('.inputDropDown');
      var i;
      for (i = 0; i < onInputs.length; i++) {
        onInputs[i].classList.remove('on');
      }
      var j;
      for (j = 0; j < onDropDowns.length; j++) {
        onDropDowns[j].classList.add('off');
      }
      
      target.classList.add('on');
      if (dropdown) {
        dropdown.classList.remove('off');
      }
      
    }
  }


  function handleInputFocus(e){

    var target = e.target;
    var existingVal = target.value;

    if (!existingVal) {
      var val = target.placeholder;
      target.value = val;
    }
    // console.log('handleInputFocus', target, val, e)
    target.select();
    handleInputDropDownFocus(target);
    
  }

export {
    animDropDown,
    processCSS,
    handleInputFocus,
    processHtmlText
    }