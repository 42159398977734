import React, { useState, useEffect, useRef } from "react";
import {
    NavLink,
  useNavigate,
  useParams
} from "react-router-dom";
import axios from "axios";
import { Rnd } from "react-rnd";
import Cookies from 'js-cookie';
import gsap from "gsap";
import { SketchPicker } from 'react-color';
import Config from "../Config.json"
import Loader from "../static/svg/loader.svg";
import imagePlaceholder from '../static/img/image.png'
import Modal from './module.modal';
import MenuList from './module.menulist';
// Icons
import OpenLink from "../static/svg/openLink-w.svg";
import {ReactComponent as Edit} from "../static/icons/edit.svg" 
import {ReactComponent as ArrowRight} from "../static/icons/arrow_right.svg" 
import {ReactComponent as ArrowBack} from "../static/icons/arrow_back.svg";
import {ReactComponent as Close} from "../static/svg/close.svg"
import {ReactComponent as NoteAdd} from "../static/icons/note_add.svg";
import {ReactComponent as PhotoAdd} from "../static/icons/add_photo.svg";
import {ReactComponent as Image} from "../static/icons/image.svg"
import {ReactComponent as Video} from "../static/icons/video.svg"
import {ReactComponent as Svg} from "../static/icons/svg.svg"
import {ReactComponent as Delete} from "../static/icons/delete.svg"
import {ReactComponent as AddBox} from "../static/icons/add_box.svg"

function Module(props) {
  const navigate = useNavigate();
  let { id } = useParams();
  const originY = React.useRef();
  const originYpx = React.useRef();
  const isDragging = React.useRef();
  const rnds = React.useRef([]);
  const tempAfterObjs = React.useRef(null);
  const layerFormat = React.useRef();
  const colorPicker = useRef();
  const [module, setModule] = useState(null);
  const [moduleObj, setModuleObj] = useState(null);
  const [modules, setModules] = useState([]);
  const [categories, setCategories] = useState();
  const [files, setFiles] = useState([]);
  const [showError, setShowError] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [animOpen, setAnimOpen] = useState(false);
  const [categoryDrags, setCategoryDrags] = useState(true);
  const [acceptValues, setAcceptValues] = useState('init');
  const [dropDown, setDropDown] = useState();
  const [editField, setEditField] = useState(null);
  const [showAnimDropDown, setShowAnimDropDown] = useState(false);
  const [showCategoryForm, setShowCategoryForm] = useState(false);
  const [activeCategory, setActiveCategory] = useState();
  const [formError, setFormError] = useState();
  const [openParentId, setOpenParentId] = useState(null);
  const [assignCatId, setAssignCatId] = useState();

  const fileTypes = ['image', 'video','vr']
  const rotations = ['horizontal', 'vertical','360']

  useEffect(() => {
    props.setTools("canvas")
    fetchJSON(null);
    
    // login();
  }, []);

  useEffect(() => {

    if (moduleObj) {
      animModalIn(moduleObj.id)
      if (assignCatId) {
        handleModuleSave('filecategory', assignCatId)
      }
     
    }
  }, [moduleObj])

  useEffect(() => {
    module && setCategories(module.all_filecategories)
    module && setFiles(module.files_nocat)
    moduleObj && setModuleObj(module.files.find(obj => obj.id === moduleObj.id))
  }, [module])
  

  useEffect(() => {
    categories && console.log('categories', categories)
    if (categories && (categories.length === 0)) {
      setShowCategoryForm(true)
    } else {
    
      setShowCategoryForm(false)
    }
  }, [categories])

  useEffect(() => {
    if (dropDown) {
      animDropDown(true)
    } else {
      animDropDown(false)
    }
  }, [dropDown])

  function handleBackBtn(){
    navigate('/narratives/' + module.narrative)
    setShowError(false);
  }

  function animDropDown(open){
    if (open) {
      // let parent = document.getElementById('settingsRow_' + dropDown).getBoundingClientRect().x;
      let parent = document.getElementById('settingsRow_' + dropDown).offsetLeft;
      let dropDownElem = document.getElementById("inputField_" + dropDown + "_" + moduleObj.id ).offsetLeft;
      let offsetLeft = parent - dropDownElem;
      // console.log('offsetLeft', offsetLeft);
      // gsap.to(dropDownElem, {y: offsetLeft, duration: 0});
      var tl = gsap.timeline();
      tl.to("#inputField_" + dropDown + "_" + moduleObj.id + " .dropDownChoices", {height: "auto", duration: 0});
      tl.to("#inputField_" + dropDown + "_" + moduleObj.id, {zIndex: 3, x: offsetLeft, height: "auto", duration: 0}, "<");
      tl.to("#inputField_" + dropDown + "_" + moduleObj.id, {scale: 1, opacity: 1, duration: 0.1});
    } else {
      gsap.to(".dropDownChoices", {height: 0, duration: 0});
      gsap.to(".dropDownField", {opacity: 0, scale: 0, zIndex: -1, duration: 0.1});
    }
  }

  function getModelFileType(string) {
    let fileType;
    var ext = string.split('.').pop();
    switch (ext) {
      case "png":
        fileType = "image";
        break;
      case "jpg":
        fileType = "image";
        break;
      case "jpeg":
        fileType = "image";
        break;
      case "webp":
        fileType = "image";
        break;
      case "mp4":
        fileType = "video";
        break;
      case "svg":
        fileType = "image";
        break;
      default:
        break;
    }
    return fileType;
  }

  function getFileType(string) {
    let fileType;
    var ext = string.split('.').pop();
    switch (ext) {
      case "png":
        fileType = "img";
        break;
      case "jpg":
        fileType = "img";
        break;
      case "jpeg":
        fileType = "img";
        break;
      case "webp":
        fileType = "img";
        break;
      case "mp4":
        fileType = "vid";
        break;
      case "svg":
        fileType = "svg";
        break;
      default:
        break;
    }
    return fileType;
  }
  

  function fileNameType(string, layerName) {
    let x0 = string.replace('gallery/', '');
    var ext = string.split('.').pop();
    let x2;
    if (layerName) {
      x2 = layerName;
    } else {
      x2 = x0.replace('.'+ ext, '');
    }
  
    // console.log('fileNameType', ext, x2, string);
    let icon;
    switch (ext) {
      case "png":
        icon = <Image className="icon" />
        break;
      case "jpg":
        icon = <Image className="icon" />
        break;
      case "jpeg":
        icon = <Image className="icon" />
        break;
      case "webp":
        icon = <Image className="icon" />
        break;
      case "svg":
        icon = <Svg className="icon" />
        break;
      case "mp4":
        icon = <Video className="icon" />
        break;
      default:
        break;
    }
    if (x2.length > 18) {
      x2 = x2.substring(0,18) + ' ...'
    }
    let result = [icon, x2];
    return result;
  }

  let moduleObjs = [];

  function animColorPicker(open, id){
    if (open !== id) {
      var tl = gsap.timeline();
      tl.to("#" + id, {height: "auto", duration: 0});
      tl.to("#" + id, {zIndex: 3, height: "auto", duration: 0}, "<");
      tl.to("#" + id, {scale: 1, opacity: 1, duration: 0.1});
      setAnimOpen(id);
    } else {
      gsap.to("#" + id, {height: 0, duration: 0});
      gsap.to("#" + id, {opacity: 0, scale: 0, zIndex: -1, duration: 0.1});
      setAnimOpen(null);
    }
  }

  // function fetchPage(path) {
  //   try {
  //     axios({
  //         method: "get",
  //         url: path,
  //         // headers: {
  //         //   Authorization: 'Token ' + Cookies.get('ud_token')
  //         // },
  //     }).then((response) => {
  //         const assetJSON = response.data;
  //         console.log('fetchPage', assetJSON);
  //         let modulesNew = [ ...modules ];
  //         modulesNew.push(assetJSON);
  //         setModules(modulesNew);
  //         console.log('module', assetJSON, 'modules', modulesNew);
  //     });
  //   } catch (error) {
  //       console.error(error);
  //   } 
    
  // }
  
  function getModules(paths) {
    let moduleObjs = [];
    for(var i = 0; i < paths.length; i++) {            
      try {
        axios({
            method: "get",
            url: paths[i],
            headers: {
              'Authorization': 'Token ' + Cookies.get('_udxdid')
            },
        }).then((response) => {
            const objJSON = response.data;
            moduleObjs.push(objJSON);

            if (paths.length === moduleObjs.length) {
              setModules(moduleObjs);
              console.log('modules', moduleObjs, paths.length)
            }
           
        });
      } catch (error) {
          console.error(error);
      }  
    }
   
    return paths.length === moduleObjs.length;
  }

  function fetchJSON(objId) {
 
    try {
        axios({
            method: "get",
            url: Config.baseURL + "/api/cms/gallery/" + id,
            headers: {
              'Authorization': 'Token ' + Cookies.get('_udxdid')
            },
        }).then((response) => {
            const assetJSON = response.data;
            setModule(assetJSON);
            setShowLoader(false);
            if (objId) {
              setModuleObj(assetJSON.files.find(obj => obj.id === objId))
              // console.log('objId', objId)
            }
            if (activeCategory) {
              setActiveCategory(assetJSON.categories.find(obj => obj.id === activeCategory.id))
            }
            // async function modulesLoaded(){
            //   await getModules(assetJSON.modules);
            //   setShowLoader(false);
            // };
            // modulesLoaded();
           
            
        });
    } catch (error) {
        console.error(error);
    }
}

    function handleAddFile(catId) {
      setShowLoader(true)
      const api_url = Config.baseURL + '/api/cms/gallery/' + id + "/";
      var dataJSON = {};
      dataJSON['status'] = "new"
      axios({
          method: 'POST',
          url: api_url,
          data: dataJSON,
          headers: {
            'Authorization': 'Token ' + Cookies.get('_udxdid')
          },
      })
      .then(response => {
          console.log('add file response', response.data['id'])
          fetchJSON(response.data['id']);
          if (catId){
            console.log('catId', catId)
            setAssignCatId(catId);
            
          }
      })
      .catch(error => {
          console.log('error', error.response);
      });
    }

    function handleAddCategory(name) {
      setShowLoader(true)
      const api_url = Config.baseURL + '/api/cms/gallery/' + id + "/";
      var dataJSON = {};
      dataJSON['new-category'] = name
      axios({
          method: 'POST',
          url: api_url,
          data: dataJSON,
          headers: {
            'Authorization': 'Token ' + Cookies.get('_udxdid')
          },
      })
      .then(response => {
          fetchJSON(response.data['id']);
          setShowCategoryForm(false);
          handleAddFile(response.data['id']);

      })
      .catch(error => {
          console.log('error', error.response);
      });
    }

    function handleGalleryMedia(e, fileName, objId){
   
      var fileList = e.target.files;
      var arrayLength = fileList.length;
      const api_url = Config.baseURL + '/api/cms/gallery/' + id.replace('gallery-', '') + "/";
    
      for (var i = 0; i < arrayLength; i++) {
    
        // File Checks
        let sizeMB = parseFloat(fileList[i].size / (1024 ** 2)).toFixed(2);
    
        if (sizeMB > Config.validations.maxFileSize) {
    
            let newError = {
              "heading": "Error",
              "text": "The selected file is <strong>" + sizeMB + "MB</strong>.<br/>This file exceeds maximum upload size of <strong>10MB</strong>.<br/><br/>Please select a new file that is less than 10MB."
            };
            setFormError(newError);
    
        } else {
    
            setShowLoader(true)
            var formData = new FormData();
            formData.append(fileName, fileList[i], fileList[i].name) 
            formData.append('gallery', id.replace('gallery-', ''))
          
            console.log(fileName, fileList[i], fileList[i].name)
    
    
            axios({
                method: 'POST',
                url: api_url,
                data: formData,
                headers: {
                  'Authorization': 'Token ' + Cookies.get('_udxdid')
                },
            })
            .then(response => {
                console.log('handleGalleryMedia', response);
                fetchJSON();
            })
            .catch(error => {
                console.log('error', error.response);
            });
    
          }
        
    
      } 
    }
    

  

    function handleCategorySave(field, value, objId) {
      // setShowLoader(true)
      const api_url = Config.baseURL + '/api/cms/gallery-category/' + objId + "/";
      var dataJSON = {};
      dataJSON['gallery'] = id.replace('gallery-', '')
      dataJSON[field] = value;
      console.log('handleCategorySave', dataJSON)
      axios({
          method: 'POST',
          url: api_url,
          data: dataJSON,
          headers: {
            'Authorization': 'Token ' + Cookies.get('_udxdid')
          },
      })
      .then(response => {
          console.log('handleCategorySave', response);
          let d = document.getElementById('pageBtnContainer_'+ response.data['id']);
          d && d.classList.remove('open')
          setDropDown(null);
          setEditField(null);
          fetchJSON(null);
         
      })
      .catch(error => {
          console.log('error', error.response);
      });
    }

    function handleGalleryCategoryDelete(objId) {
      handleCategorySave('delete', true, objId)
    }

  function handleModuleSave(field, value) {
    // setShowLoader(true)
    const api_url = Config.baseURL + '/api/cms/gallery-file/' + moduleObj.id + "/";
    var dataJSON = {};
    dataJSON['gallery'] = id.replace('gallery-', '')
    // let category = categories.find(obj => obj.id === moduleObj.filecategory);
    // let index;
    // if (category) {
    //   index = category.categoryfiles.findIndex(obj => obj.id === moduleObj.id);
     
    // }
    // if (index > -1) {
    //   dataJSON['ordering'] = index
     
    // }

    if (field === 'end_pos' && value === "") {
      value = null
    }
    
    dataJSON[field] = value;

   

    console.log('handleModuleSave', dataJSON)
    axios({
        method: 'POST',
        url: api_url,
        data: dataJSON,
        headers: {
          'Authorization': 'Token ' + Cookies.get('_udxdid')
        },
    })
    .then(response => {
        console.log('handleModuleSave', response);
        setDropDown(null);
        fetchJSON(null);
        setAssignCatId(null)
    })
    .catch(error => {
        console.log('error', error.response);
    });
  }

  

  function handleModuleMedia(e, fileName){
   
    var fileList = e.target.files;
    var arrayLength = fileList.length;
    const api_url = Config.baseURL + '/api/cms/gallery-file/' + moduleObj.id + "/";

    for (var i = 0; i < arrayLength; i++) {

      // File Checks
      let sizeMB = parseFloat(fileList[i].size / (1024 ** 2)).toFixed(2);

      if (sizeMB > Config.validations.maxFileSize) {

          let newError = {
            "heading": "Error",
            "text": "The selected file is <strong>" + sizeMB + "MB</strong>.<br/>This file exceeds maximum upload size of <strong>10MB</strong>.<br/><br/>Please select a new file that is less than 10MB."
          };
          setFormError(newError);

      } else {

          setShowLoader(true)
          var formData = new FormData();
          formData.append(fileName, fileList[i], fileList[i].name) 
          formData.append('gallery', id.replace('gallery-', ''))
          formData.append('active', moduleObj.active)
          if (!moduleObj.file) {
            formData.append('file_type', getModelFileType(fileList[i].name))
          }
          
          console.log('moduleOb', moduleObj, fileName, fileList[i], fileList[i].name)

          axios({
              method: 'POST',
              url: api_url,
              data: formData,
              headers: {
                'Authorization': 'Token ' + Cookies.get('_udxdid')
              },
          })
          .then(response => {
              console.log('handleModuleMedia', response);
              fetchJSON(null);
          })
          .catch(error => {
              console.log('error', error.response);
          });

        }
      

    } 
  }

  

  function handleGalleryReorderSave(obj, reload) {
    console.log('handleGalleryReorderSave', obj, reload);      
    axios({
        method: 'POST',
        url: Config.baseURL + '/api/cms/gallery-reorder/',
        data: {
            'filecategories': obj
          },
          headers: {
            'Authorization': 'Token ' + Cookies.get('_udxdid')
          },
    })
    .then(response => {
        console.log('handleGalleryReorderSave: response', response);
        // isLast && handleReloadSection(response);
        // handleReloadSection(response);
        fetchJSON(null);
    })
    .catch(error => {
        console.log('error', error.response);
    });

  }

  function handleGalleryReorderFileSave(obj, reload) {
    console.log('handleGalleryReorderSave', obj, reload);      
    axios({
        method: 'POST',
        url: Config.baseURL + '/api/cms/gallery-reorder/',
        data: {
            'files': obj
          },
        headers: {
          'Authorization': 'Token ' + Cookies.get('_udxdid')
        },
    })
    .then(response => {
        console.log('handleGalleryReorderSave: response', response, obj);
        // isLast && handleReloadSection(response);
        // handleReloadSection(response);
        // fetchJSON()
        try {
          setOpenParentId(obj[0].filecategory)
          console.log(obj[0].filecategory)
        } catch (error) {
          
        }
       
        fetchJSON(null);
   
        
    })
    .catch(error => {
        console.log('error', error.response);
    });
  }

  function handleThemeCss(field, value){
    let newCss;
    if (module.css) {
      newCss = module.css;
    } else {
      newCss = {};
    }
    if (value) {
      newCss[field] = value;
    } else {
      delete newCss[field]; 
    }
    handleModuleSave('css', newCss);

  }

  function animModalIn(objId){
    let target = "#moduleDetail_" + objId;
    gsap.to(target, {opacity: 1, duration: 0.2})
  }

  function animModalOut(objId){
    let target = "#moduleDetail_" + objId;
    gsap.to(target, {opacity: 0, duration: 0.2, onComplete: setModuleObj(null)});
  }

  function round30(x) {
    let y = Math.ceil(x / 30) * 30;
    // console.log('y', y);
    return y;
}

function reOrderForOpen(array, obj, setObj, height) {
  
  let newArray = [ ...array ];   
  let yObj = newArray.find(obj0 => obj0.id === obj.id);
  if (yObj) {
    if (height) {
      let afterNumberCopy = [];
      let afterNumber = newArray.filter(obj0 => ((obj0.ordering > yObj.ordering)&&(obj0.id !== yObj.id)));
      afterNumber.map(obj => afterNumberCopy.push({id: obj.id, ordering: obj.ordering}));
      tempAfterObjs.current = afterNumberCopy;
      
      afterNumber.forEach((obj, i) => {
        obj.ordering = (height/30)+i+1
      });
      let reordered = newArray.sort((a, b) => a.ordering - b.ordering);
      setObj(reordered);
      
    } else {
      tempAfterObjs.current && tempAfterObjs.current.forEach((z) => {
        let match = newArray.find(a => a.id === z.id)
        match['ordering'] = z.ordering
      });
      
      let reordered = newArray.sort((a, b) => a.ordering - b.ordering);
      setObj(reordered);
    }
  }
}

 
function reOrder(array, obj, d, setObj) {
  let reordered;
  let newArray = [ ...array ];   
  let yObj = newArray.find(obj0 => obj0.id === obj.id);

  if (yObj) {
    
    if (parseInt(round30(d.y)/30) !== yObj.ordering) {
      
      let yObj_posY;
      if (d.y/30 === 0) {
        yObj_posY = d.y;
      } else {
        yObj_posY = round30(d.y);
      }

      let lastNumber = newArray.length - 1;
                        
      let newOrdering = parseInt((yObj_posY/30))-1;
      if (newOrdering > lastNumber) {
        newOrdering = lastNumber
      }
      
      if (newOrdering < 0) {
        newOrdering = 0
      }
      let beforeNumber = [];
      let afterNumber = [];
      let sameNumber;
      if (newOrdering === 0) {
        console.log('%cFirst', 'color: red; font-weight: bold;');
        afterNumber = newArray.filter(obj0 => ((obj0.ordering >= newOrdering)&&(obj0.id !== yObj.id)));
        // sameNumber && (sameNumber['ordering'] = 1);

      } else if (newOrdering === (lastNumber)) {
        console.log('%cLast', 'color: green; font-weight: bold;');
        beforeNumber = newArray.filter(obj0 => ((obj0.ordering <= newOrdering)&&(obj0.id !== yObj.id)));
        // sameNumber = newArray.find(obj0 => ((obj0.ordering === newOrdering)&&(obj0.id !== yObj.id)));
        // sameNumber && (sameNumber['ordering']=newArray.length-1);
        
      } else {
        console.log('%cMiddle', 'color: gold; font-weight: bold;');
        beforeNumber = newArray.filter(obj0 => ((obj0.ordering < newOrdering)&&(obj0.id !== yObj.id)));
        afterNumber = newArray.filter(obj0 => ((obj0.ordering > newOrdering)&&(obj0.id !== yObj.id)));
        sameNumber = newArray.find(obj0 => ((obj0.ordering === newOrdering)&&(obj0.id !== yObj.id)));
        if ((newOrdering > originY.current)) {
          sameNumber && (sameNumber['ordering']=newOrdering-1);
        } else {
          sameNumber && (sameNumber['ordering']=newOrdering+1);
        }

      }
    
      beforeNumber.forEach((obj, i) => {
        obj.ordering = i
      });
      
      afterNumber.forEach((obj, i) => {
        obj.ordering = newOrdering+i+1
      });

      console.log('newOrdering', newOrdering)
      console.log('beforeNumber', beforeNumber)
      console.log('sameNumber', sameNumber)
      console.log('afterNumber', afterNumber)

      yObj.ordering = newOrdering;
      originY.current = null;
      reordered = newArray.sort((a, b) => a.ordering - b.ordering);
      let reorderedCopy = [ ...reordered ];

      reorderedCopy.forEach((obj, i) => {
        obj.ordering = i
      })
      console.log('onDragStop', yObj.ordering, reorderedCopy)

      
    
      setObj(reorderedCopy);

    }

    
  }


  return reordered
}

function handleShowAnimDropDown() {
  if (showAnimDropDown) {
    setShowAnimDropDown(false);
  } else {
    setShowAnimDropDown(true);
  }
}

function handleDropDown(e, field) {
  if (field === dropDown) {
    setDropDown(null); 
  } else {
    setDropDown(field)
  }
}

function handleCategoryMedia(e, fileName, objId){
   
  var fileList = e.target.files;
  var arrayLength = fileList.length;
  const api_url = Config.baseURL + '/api/cms/gallery-category/' + objId + "/";

  for (var i = 0; i < arrayLength; i++) {

    // File Checks
    let sizeMB = parseFloat(fileList[i].size / (1024 ** 2)).toFixed(2);

    if (sizeMB > Config.validations.maxFileSize) {

        let newError = {
          "heading": "Error",
          "text": "The selected file is <strong>" + sizeMB + "MB</strong>.<br/>This file exceeds maximum upload size of <strong>10MB</strong>.<br/><br/>Please select a new file that is less than 10MB."
        };
        setFormError(newError);
        setShowLoader(false);

    } else {

        setShowLoader(true)
        var formData = new FormData();
        formData.append(fileName, fileList[i], fileList[i].name) 
        formData.append('gallery', id.replace('gallery-', ''))
   


        axios({
            method: 'POST',
            url: api_url,
            data: formData,
            headers: {
              'Authorization': 'Token ' + Cookies.get('_udxdid')
            },
        })
        .then(response => {
            console.log('handleCategoryMedia', response);
            fetchJSON();
        })
        .catch(error => {
            console.log('error', error.response);
        });

      }
    

  } 
}

function handleOnKeyUp(e, field, group, objId) {
    if (group === "file") {
      if (e.key === "Enter") {
        handleModuleSave(field, e.target.value);
        }
    }
    if (group === "category") {
      if (e.key === "Enter") {
        handleCategorySave(field, e.target.value, objId);
        }
    }
   

}

function handleActiveCategory(obj) {
  if (activeCategory && (activeCategory.id === obj.id)) {
    setActiveCategory(null)
  } else {
    setActiveCategory(obj)
  }
  
}



    return (
        <section className="main" id="galleryPage">
        {(showLoader)?(<div id="loading"><img src={Loader} alt="" /></div>):null}

        {(formError)&&<Modal heading={formError.heading} text={formError.text} onBtn1Click={setFormError} onBtn1={null} btn1Text={"Close"} onBtn2Click={null} onBtn2={null} btn2Text={null} />}

        {(showError)&&(<div id="errorPopup"><div className="modal"><span><h3>Unsaved Changes</h3><p>Are you sure you want to go back?</p></span> <div className="fullRow errorRow"><span onClick={() => handleBackBtn('cancel')}>Cancel</span> <span onClick={() => handleBackBtn('force')}>Continue</span></div></div></div>)}
        {(showCategoryForm)&&(<div className="modalContainer"><div className="modal"><span><h3>New Category</h3>

        <div className="settingsRow">
          <input type="text" autoComplete="off" id="newCategoryName" autoFocus placeholder="Add Category Name" />
        </div>

        </span> <div className="fullRow errorRow"><span onClick={() => {
          setShowCategoryForm(false)
        }}>Cancel</span> <span onClick={() => handleAddCategory(document.getElementById('newCategoryName').value)}>Continue</span></div></div></div>)}
        
        
        {module && <div className="col appGrid">

        <div id="narrativeTools">
          
  
          <div className="iconField ui"  onClick={() => handleAddFile()} ><PhotoAdd className="icon ui" title="Add Layer" /><div className="iconLabel">Add File</div></div>

          <h1>{module.title}</h1>

          <a href={ Config.previewURL + "/narrative/" + module.narrative + '?u=' + props.user.link_token + "&module=" + module.id} className="previewBtn previewBtnGallery" target="_blank" rel="noreferrer" title="preview">
          <img src={OpenLink} className="icon" alt="" /></a>

          </div>
            <nav className="leftNav">
            
  
              <div className="leftArrow iconField" onClick={() => handleBackBtn()}>
                <ArrowBack className="icon" /> 
  
                <div className="pages">Presentation Overview</div>
              </div>

              <div className="pages fieldRow modulePages">
                  <div  className="sectionRows">
                    <div className="sectionTools"><div className="sectionLabel">Categories</div><AddBox className="icon ui" title="Select Category" onClick={() => setShowCategoryForm(true)} /></div>
                  </div>
                  <div className="pageBtnContainer open">
                    <div className="draggablePages draggableGalleryFiles">
                    
                      <span className="groupFiles">
                          {files &&
                            files
                            .map((obj1, j) => (
                              <Rnd
                                className={"mediaFile ui pageBtn"}
                                key={'catFile_' + j}
                                id={"page_" + obj1.id}
                                style={{'cursor': "default"}}
                                lockAspectRatio={true}
                                enableResizing={false}
                                dragAxis={'y'}
                                default={{
                                  x: 0,
                                  y: j*30,
                                  height: 30
                                }}
                                position={{
                                  x: 0,
                                  y: j*30,
                                }}
                                onClick={(e) =>{
                                  if (isDragging.current === false) {
                                    setTimeout(() => {
                                      e.target.classList.add('dragging');
                                    }, 100);
                
                                  }
                                }}
                                onDragStart={(e, d) => { 
                                  originY.current = parseInt(round30(d.y)/30)
                                  console.log('onDragStart', originY.current, d.y, rnds.current)
                                }}
                                onDrag={(e, d) => {
                                  isDragging.current = true;
                                  setTimeout(() => {
                                    e.target.classList.add('dragging');
                                  }, 100);
                                }}
                                onDragStop={(e, d) => {                          
                                  // setCategories(reordered)
                                  
                                  
                                  reOrder(files, obj1, d, setFiles)

                                  setTimeout(() => {
                                    isDragging.current = false;
                                  }, 200);
                      
                                }}
                                >
                                <span
                                className="layerIconContainer"
                                
                                >
                  
                                  {fileNameType(obj1.file, null).map((obj2, j) => (
                                    <span
                                    key={"fileName_" + j}>
                                    
                                    {obj2}
                          
                                    </span>))}
                                </span>
                              </Rnd>
                            ))
                          }
                        </span>
                
                    </div>
                   </div>                       
                  <div className="groupCategories">

                    {categories &&  <MenuList 
                      baseObjs={categories} 
                      subObjsField={"categoryfiles"}
                      headingField={"name"}
                      subField={"file"}
                      parentField={"filecategory"}
                      linkField={"file"}
                      subIsMedia={true}
                      setBaseObjs={setCategories}
                      handleParentClick={handleActiveCategory}
                      handlePageClick={null}
                      handleSave={handleGalleryReorderSave}
                      handleSubSave={handleGalleryReorderFileSave}
                      deleteParent={handleGalleryCategoryDelete}
                      elevateSubs={false}
                      offsetTop={files ? (files.length * 30 + 180 + "px") : 180 + "px"}
                      showPageCount={false}
                      openParentId={openParentId}
                      />}

                  </div>
           
              </div>
            </nav>
            <div className="core">
  
              <div className="twoCol">
                  <div className="flex">

                    {moduleObj && 
                      <div className="moduleDetail" id={"moduleDetail_" + moduleObj.id}>
                        
                        <div className="moduleContainer">
                          <div className="closeModal ui" onClick={() => {animModalOut(moduleObj.id) }}><Close className="icon" /></div>

                          <span className="file">
                            
                            {(moduleObj.file && getFileType(moduleObj.file) === 'img') && <span className="fileImage">
                            <img src={Config.mediaURL + moduleObj.file} alt="" onClick={() => document.getElementById("inputField_file_" + moduleObj.id).click()} />
                            </span>}

                            {(moduleObj.file && getFileType(moduleObj.file) === 'vid') && <span className="fileVideo">
                              <video width="320" height="240" controls>
                                <source src={Config.mediaURL + moduleObj.file} type="video/mp4" />
                              </video>
                            </span>}

                            {!moduleObj.file && <div className="ui filePlaceholder autoWidth" 
                            onClick={() => document.getElementById("inputField_file_" + moduleObj.id).click()} >Add File</div>}
                          
                            <input type="file" style={{display: "none"}} autoComplete="off" id={"inputField_file_" + moduleObj.id} accept={"image/png, image/jpeg, image/webp, video/mp4"} onChange={(e) => handleModuleMedia(e, 'file')}  />
                          </span>
                          

                          <div className="moduleSettings">
                            <div className="groupLabel"><p>File Settings</p></div>
                            <div className="settingsRow" id="settingsRow_category">
                              <div className="canvasLabel">Category</div>
                              <div className="ui fontBtn" onClick={(e) => handleDropDown(e, 'category')}>{moduleObj.filecategory ?  categories.find(obj => obj.id === moduleObj.filecategory).name : <span className="canvasLabel">Select Category</span>}</div>
                              <span className="inputField dropDownField" id={"inputField_category_" + moduleObj.id}>
                                <span className="dropDown">
                                  <span className="dropDownChoices">
                                    {categories
                                      .sort((a, b) => a.ordering - b.ordering)
                                      .map((obj, i) => (
                                      <span 
                                        key={'fileCategory_'+i} 
                                        className={"dropDownChoice ui"}
                                        onClick={() => handleModuleSave('filecategory', obj.id)}
                                        >{obj.name}</span>
                                    ))}
                                  </span>
                                </span>
                              </span> 
                            </div>

                            <div className="settingsRow" id="settingsRow_fileType">
                              <div className="canvasLabel">File Type</div>
                              <div className="ui fontBtn" onClick={(e) => handleDropDown(e, 'fileType')}>{moduleObj.fileType ?  fileTypes.find(obj => obj === moduleObj.fileType) : <span className="canvasLabel">Select File Type</span>}</div>
                              <span className="inputField dropDownField" id={"inputField_fileType_" + moduleObj.id}>
                                <span className="dropDown">
                                  <span className="dropDownChoices">
                                    {fileTypes
                                      .sort((a, b) => a.ordering - b.ordering)
                                      .map((obj, i) => (
                                      <span 
                                        key={'fileType_'+i} 
                                        className={"dropDownChoice ui"}
                                        onClick={() => handleModuleSave('fileType', obj)}
                                        >{obj}</span>
                                    ))}
                                  </span>
                                </span>
                              </span> 
                            </div>

                            {(moduleObj.fileType === "vr") && <div className="settingsRow" id="settingsRow_rotation">
                              <div className="canvasLabel">Movement</div>
                              <div className="ui fontBtn" onClick={(e) => handleDropDown(e, 'rotation')}>{moduleObj.rotation ?  rotations.find(obj => obj === moduleObj.rotation) : <span className="canvasLabel">Select Rotation</span>}</div>
                              <span className="inputField dropDownField" id={"inputField_rotation_" + moduleObj.id}>
                                <span className="dropDown">
                                  <span className="dropDownChoices">
                                    {rotations
                                      .sort((a, b) => a.ordering - b.ordering)
                                      .map((obj, i) => (
                                      <span 
                                        key={'rotation_'+i} 
                                        className={"dropDownChoice ui"}
                                        onClick={() => handleModuleSave('rotation', obj)}
                                        >{obj}</span>
                                    ))}
                                  </span>
                                </span>
                              </span> 
                            </div>}

                            {(moduleObj.fileType === "vr") && <div className="settingsRow" id="start_pos">
                              <div className="canvasLabel">Start Position</div>
                              <input type="number" autoComplete="off" id="caption" defaultValue={moduleObj.start_pos} onChange={(e) => handleModuleSave('start_pos', e.target.value)} onKeyUp={(e) => handleOnKeyUp(e, 'start_pos', 'file', null)} />
                            </div>}

                            {(moduleObj.fileType === "vr") && <div className="settingsRow" id="end_pos">
                              <div className="canvasLabel">End Position</div>
                              <input type="number" autoComplete="off" id="caption" defaultValue={moduleObj.end_pos} onChange={(e) => handleModuleSave('end_pos', e.target.value)} onKeyUp={(e) => handleOnKeyUp(e, 'end_pos', 'file', null)} />
                            </div>}

                            {(moduleObj.fileType === "vr") &&  <div className="settingsRow">
                            <div className="moduleHeading" id={"inputField_vr_ref" + moduleObj.id}>
                            <div className="fieldRow">
                                <div className="errors"></div>
                        
                                  <div className="canvasLabel">Viewport Base Image</div>
                                  {moduleObj.vr_ref ? <img src={Config.mediaURL + moduleObj.vr_ref} alt="" className="thumbnail" onClick={() => document.getElementById("inputField_vr_ref_" + moduleObj.id).click()} /> : <span className="btn1 autoWidth" onClick={() => document.getElementById("inputField_vr_ref_" + moduleObj.id).click()}>Add Viewport Base Image</span>}
                                  <input type="file" style={{display: "none"}} autoComplete="off" id={"inputField_vr_ref_" + moduleObj.id} accept={"image/png, image/jpeg, image/webp"} onChange={(e) => handleModuleMedia(e, 'vr_ref')}  />

                      
                              
                            </div></div></div>}

                            <div className="settingsRow">
                              <div className="moduleHeading" id={"inputField_caption" + moduleObj.id}>
                              <div className="fieldRow">
                                  <div className="errors"></div>
                          
                                    <div className="canvasLabel">Caption</div>
                                    <input type="text" autoComplete="off" id="caption" defaultValue={moduleObj.caption} onChange={(e) => handleModuleSave('caption', e.target.value)} onKeyUp={(e) => handleOnKeyUp(e, 'caption', 'file', null)} />
                                
                              </div>
                              
                              </div>
                            </div>
                            
                            <div className="settingsRow">
                            <div className="moduleHeading" id={"inputField_thumbnail" + moduleObj.id}>
                            <div className="fieldRow">
                                <div className="errors"></div>
                        
                                  <div className="canvasLabel">Thumbnail</div>
                                  {moduleObj.thumbnail ? <img src={Config.mediaURL + moduleObj.thumbnail} alt="" className="thumbnail" onClick={() => document.getElementById("inputField_thumbnail_" + moduleObj.id).click()} /> : <span className="btn1 autoWidth" onClick={() => document.getElementById("inputField_thumbnail_" + moduleObj.id).click()}>Add Thumbnail</span>}
                                  <input type="file" style={{display: "none"}} autoComplete="off" id={"inputField_thumbnail_" + moduleObj.id} accept={"image/png, image/jpeg, image/webp"} onChange={(e) => handleModuleMedia(e, 'thumbnail')}  />

                      
                              
                            </div>

                            <div className="fieldRow">
                            {(moduleObj.file && getFileType(moduleObj.file) === 'vid') && <div className="ui btn1 autoWidth" onClick={() => document.getElementById("inputField_file_" + moduleObj.id).click()} >Replace Video</div>}
                            </div>

                            
                            
                          
                            </div>

                            <div className="settingsRow deleteRow">             
                              <div className="iconField ui" onClick={() => handleModuleSave('delete', true)}><Delete className="icon"/> <div className="canvasLabel">Delete File</div>
                              </div>
                            </div>        

                          </div>
                            


                          </div>
              
                          
                        </div>
                      </div>
                      
                    }
                    <div className="categoryRows">
                    <div className="categoryRow">
                        <div className="files images">
                    {files && files
                      .sort((a, b) => a.ordering - b.ordering)
                      .map((file, i) => (
                        

                      <div 
                          onClick={() => setModuleObj(file)}
                          key={'gallery_file_' + i} 
                          className={"thumbnail ui"}
                      >
                      {file.thumbnail ? <img src={Config.mediaURL + file.thumbnail} alt="" /> : <figure className="emptyThumbnail ui"/>}
                      {(file.caption) ? 
                          <div 
                          // className={`${styles.caption}`}
                          style={{display: "none"}}
                          >{file.caption}</div> 
                          : null}
                      </div>
                      ))}
                     
                  
                      </div>
                      </div>

                    {categories && categories
                      .sort((a, b) => a.ordering - b.ordering)
                      .map((category, i0) => (
                        <div className="categoryRow" key={"catFiles_" + i0}>
                        <h2>{category.name}</h2>
                        <div className="files images">
                        
                      {category && category.categoryfiles && category.categoryfiles
                      // .filter(a => a.filecategory === file.id)
                      .sort((a, b) => a.ordering - b.ordering)
                      .map((file, i) => (
                      <div 
                          onClick={() => setModuleObj(file)}
                          key={'gallery_file_' + i} 
                          className={"thumbnail ui"}
                      >
                      {file.thumbnail ? <img src={Config.mediaURL + file.thumbnail} alt="" /> : <figure className="emptyThumbnail ui"/>}
                      {(file.caption) ? 
                          <div 
                          // className={`${styles.caption}`}
                          style={{display: "none"}}
                          >{file.caption}</div> 
                          : null}
                      </div>
                      ))}
                      </div>
                      </div>
                      ))}
                    </div>
                
                  </div>
                  
                  {activeCategory && <div className="rightNav settingsOn" id="manifestColumn">
                     <div className={"mediaSettings showSettings on"}>

                     <div className="settingsGroup">
                     <div className="groupLabel"><p>Gallery Settings</p></div>
                       <div className="settingsRow">
                       <div className="moduleHeading">
                       {module && <div className="fieldRow">
                           <div className="errors"></div>
                   
                             <div className="canvasLabel">Default Landing Image</div>
                             {module.default_image ? <img src={Config.mediaURL + module.default_image} alt="" className="thumbnail" onClick={() => document.getElementById("default_image_" + module.id).click()} /> : <span className="btn1 autoWidth" onClick={() => document.getElementById("default_image_" + module.id).click()}>Add Image</span>}
                             <input type="file" style={{display: "none"}} autoComplete="off" id={"default_image_" + module.id} accept={"image/png, image/jpeg, image/webp"} onChange={(e) => handleGalleryMedia(e, 'default_image', module.id)}  />

                 
                         
                       </div>}
                       </div>
                       </div>
                       

                   </div>
                        <div className="settingsGroup">
                          <div className="groupLabel"><p>Category Settings</p></div>
                            <div className="settingsRow">
                             
                                <div className="fieldRow">
                                    <div className="errors"></div>
                                      <div className="canvasLabel">Heading</div>
                                      <input type="text" autoComplete="off" id="narrativeHeading" key={activeCategory.id} defaultValue={activeCategory.name} onKeyUp={(e) => handleOnKeyUp(e, 'name', 'category', activeCategory.id)} />
                                </div>
                        

                        
                            </div>

                            <div className="settingsRow">
                            <div className="moduleHeading" id={"category_thumbnail" + activeCategory.id}>
                            <div className="fieldRow">
                                <div className="errors"></div>
                        
                                  <div className="canvasLabel">Thumbnail</div>
                                  {activeCategory.thumbnail ? <img src={Config.mediaURL + activeCategory.thumbnail} alt="" className="thumbnail" onClick={() => document.getElementById("category_thumbnail_" + activeCategory.id).click()} /> : <span className="btn1 autoWidth" onClick={() => document.getElementById("category_thumbnail_" + activeCategory.id).click()}>Add Thumbnail</span>}
                                  <input type="file" style={{display: "none"}} autoComplete="off" id={"category_thumbnail_" + activeCategory.id} accept={"image/png, image/jpeg, image/webp"} onChange={(e) => handleCategoryMedia(e, 'thumbnail', activeCategory.id)}  />

                      
                              
                            </div>
                            </div>
                            </div>
                            

                        </div>
                      </div>
                  </div>}
                </div>
            </div>     
           
          

         </div>}
        

      </section>
    );
}

export default Module;