import React, { useState, useEffect, useRef } from "react";
import {
    NavLink,
  useNavigate,
  useParams
} from "react-router-dom";
import axios from "axios";
import Cookies from 'js-cookie';
import { Rnd } from "react-rnd";
import gsap from "gsap";
import { ChromePicker } from 'react-color';
import SVG from 'react-inlinesvg';
import Modal from './module.modal';
import Config from "../Config.json"
import Loader from "../static/svg/loader.svg";
import imagePlaceholder from '../static/img/image.png'

// Icons
import OpenLink from "../static/svg/openLink-w.svg";
import {ReactComponent as Close} from "../static/icons/close.svg" 
import {ReactComponent as Edit} from "../static/icons/edit.svg" 
import {ReactComponent as Add} from "../static/icons/library_add.svg" 
import {ReactComponent as WebGL} from "../static/modules/3d.svg" 
import {ReactComponent as Doc} from "../static/modules/doc_library.svg" 
import {ReactComponent as GalleryImage} from "../static/modules/gallery_image.svg" 
import {ReactComponent as GalleryVr} from "../static/modules/gallery_vr.svg" 
import {ReactComponent as GalleryVideo} from "../static/modules/gallery_video.svg" 
import {ReactComponent as Map} from "../static/modules/map.svg" 
import {ReactComponent as Stack} from "../static/modules/stack_typical.svg" 
import {ReactComponent as Image} from "../static/icons/image.svg"
import {ReactComponent as Video} from "../static/icons/video.svg"
import {ReactComponent as Svg} from "../static/icons/svg.svg"
import {ReactComponent as Linked} from "../static/icons/link.svg"
import {ReactComponent as Delete} from "../static/icons/delete.svg";
import {ReactComponent as AutoRenew} from "../static/icons/autorenew.svg"

const fontFamilies = ['CerebriSans', 'Arial', 'Verdana', 'Tahoma', 'Trebuchet', 'Times New Roman', 'Georgia', 'Georgia', 'Garamond', 'Courier New'];

function Narrative(props) {
  const navigate = useNavigate();
  let { id } = useParams();
  const colorPicker = useRef();
  const [narrative, setNarrative] = useState(null);
  const [modules, setModules] = useState([]);
  const [showError, setShowError] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [animOpen, setAnimOpen] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [reloadIframe, setReloadIframe] = useState(0);
  const [dropDown, setDropDown] = useState();
  const [tempColor, setTempColor] = useState();
  const [showModuleForm, setShowModuleForm] = useState(false);
  const [newModule, setNewModule] = useState();
  const [tempModuleTitle, setTempModuleTitle] = useState();
  const [narrativeUpdating, setNarrativeUpdating] = useState(false);

  function animDropDown(open){
    if (open) {
      console.log(open, dropDown)
      // let parent = document.getElementById('settingsRow_' + dropDown).getBoundingClientRect().x;
      let parent = document.getElementById('settingsRow_' + dropDown).offsetLeft;
      let dropDownElem = document.getElementById("inputField_" + dropDown).offsetLeft;
      let offsetLeft = parent - dropDownElem;
      // console.log('offsetLeft', offsetLeft);
      gsap.to(dropDownElem, {y: offsetLeft, duration: 0});
      var tl = gsap.timeline();
      
      tl.to("#inputField_" + dropDown + " .dropDownChoices", {height: "auto", duration: 0});
      tl.to("#inputField_" + dropDown, {zIndex: 3, x: offsetLeft, height: 'auto', duration: 0}, '<');
      tl.to("#inputField_" + dropDown, {scale: 1, opacity: 1, duration: 0.1});
    } else {
      gsap.to(".dropDownChoices", {height: 0, duration: 0});
      gsap.to(".dropDownField", {opacity: 0, scale: 0, zIndex: -1, duration: 0.1});
    }
  }

  function handleDropDown(e, field) {
    if (field === dropDown) {
      setDropDown(null); 
    } else {
      setDropDown(field)
    }
  }


  function fileNameType(string, layerName) {
    let x0 = string.replace('img/', '');
    let x1 = x0.replace('svg/', '');
    let x2 = x1.replace('core/', '');
    let x = x2.replace('narrative/', '');
    var ext = string.split('.').pop();
    let x3;
    if (layerName) {
      x3 = layerName;
    } else {
      x3 = x.replace('.'+ext, '');
    }
  
    // console.log('fileNameType', ext, x2, string);
    let icon;
    switch (ext) {
      case "png":
        icon = <Image className="icon" />
        break;
      case "jpg":
        icon = <Image className="icon" />
        break;
      case "jpeg":
        icon = <Image className="icon" />
        break;
      case "webp":
        icon = <Image className="icon" />
        break;
      case "svg":
        icon = <Svg className="icon" />
        break;
      case "mp4":
        icon = <Video className="icon" />
        break;
      default:
        break;
    }
    if (x3.length > 18) {
      x3 = x3.substring(0,18) + ' ...'
    }
    let result = [icon, x3];
    return result;
  }

  let moduleObjs = [];
  let acceptValues = "image/png, image/jpeg, image/webp, video/mp4, image/svg+xml";

  function animColorPicker(open, id){
    setTempColor(null)
    if (animOpen) {
      gsap.to('.colorPicker', {height: 0, duration: 0});
      gsap.to('.colorPicker', {opacity: 0, scale: 0, zIndex: -1, duration: 0.1});
    }
    if (open !== id) {
      
      var tl = gsap.timeline();
      tl.to("#" + id, {height: "auto", duration: 0});
      tl.to("#" + id, {zIndex: 3, height: "auto", duration: 0}, "<");
      tl.to("#" + id, {scale: 1, zIndex: 3, opacity: 1, duration: 0.1});
      setAnimOpen(id);
    } else {
      gsap.to("#" + id, {height: 0, duration: 0});
      gsap.to("#" + id, {opacity: 0, scale: 0, zIndex: -1, duration: 0.1});
      setAnimOpen(null);
    }
  }

  
  function getModules(paths) {
    let moduleObjs = [];
    for(var i = 0; i < paths.length; i++) {            
      try {
        axios({
            method: "get",
            url: paths[i],
            headers: {
              Authorization: 'Token ' + Cookies.get('_udxdid')
            },
        }).then((response) => {
            const objJSON = response.data;
            moduleObjs.push(objJSON);

            if (paths.length === moduleObjs.length) {
              let exclude = ['webgl', 'stack'];
              setModules(moduleObjs.filter(a => !exclude.includes(a.typeName) ));
              console.log('modules', moduleObjs, paths.length)
            }
           
        });
      } catch (error) {
          console.error(error);
      }  
    }
   
    return paths.length === moduleObjs.length;
  }

  function fetchJSON(hasNewObjId) {
 
    try {
        axios({
            method: "get",
            url: Config.baseURL + "/api/cms/narrative-landing/" + id,
            headers: {
              Authorization: 'Token ' + Cookies.get('_udxdid')
            },
        }).then((response) => {
            const assetJSON = response.data;
            setNarrative(assetJSON);
            async function modulesLoaded(){
              await getModules(assetJSON.modules);
              setShowLoader(false);
              setReloadIframe(reloadIframe + 1)
            };
            modulesLoaded();
           
            
        });
    } catch (error) {
        console.error(error);
    }
}

  function handleBackBtn(step){
    if (step === 'step1') {
      setShowError(true);
    } 
    if (step === 'cancel') {
      setShowError(false);
    } 
  
    if (step === 'force') {
      navigate('/narratives/' + narrative.id)
    } 
  
  
  }

  useEffect(() => {
    if (dropDown) {
      animDropDown(true)
    } else {
      animDropDown(false)
    }
  }, [dropDown])

  useEffect(() => {
    props.setTools("search")
    fetchJSON(false);

    localStorage.removeItem("versionHistory");

  }, []);


  function handleNarrativeSave(field, value) {
    setShowLoader(true)
    const api_url = Config.baseURL + '/api/cms/narrative-settings/' + narrative.id + "/";
    var dataJSON = {};
    if (field !== 'title') {
      dataJSON['title'] = narrative.title
    }
    dataJSON[field] = value;

    console.log('handleNarrativeSave', dataJSON)
    
    axios({
        method: 'POST',
        url: api_url,
        data: dataJSON,
        headers: {
          Authorization: 'Token ' + Cookies.get('_udxdid')
        },
    })
    .then(response => {
        console.log('handleNarrativeSave', response);
        fetchJSON(false);
        setTempColor(null);
    })
    .catch(error => {
        console.log('error', error.response);
    });
  }

  function handleNarrativeMedia(fileName){
    var fileList = document.getElementById(fileName).files;
    var arrayLength = fileList.length;
    for (var i = 0; i < arrayLength; i++) {
      var formData = new FormData();
      formData.append(fileName, fileList[i], fileList[i].name) 
      const api_url = Config.baseURL + '/api/cms/narrative-settings/' + narrative.id + "/";
      formData.append('title', narrative.title)
      formData.append('active', narrative.active)
      axios({
          method: 'POST',
          url: api_url,
          data: formData,
          headers: {
            Authorization: 'Token ' + Cookies.get('_udxdid')
          },
      })
      .then(response => {
          console.log('handleNarrativeMedia', response);
          fetchJSON(false);
      })
      .catch(error => {
          console.log('error', error.response);
      });
      

    } 
  }

  function handleThemeCss(obj, field, value){
    let newCss;
    if (narrative[obj]) {
      newCss = narrative[obj];
    } else {
      newCss = {};
    }
   
    console.log('handleThemeCss', obj, field, value, newCss)
    if (value) {
      newCss[field] = value;
    } else {
      delete newCss[field]; 
    }
    setTimeout(() => {
      handleNarrativeSave(obj, newCss);
      setDropDown(null);
    }, 300);
    

  }

  function copyLink(e, copyText) {

    let t = e.target;
    let tl = gsap.timeline({
      defaults: {
        ease: "power1",
        duration: 0.3
      }
    });

    tl.to(t, {color: "#007AFF"})
    tl.to('.animFill path', {fill: "#007AFF"}, "<")
    tl.to(t, {color: "#ffffff"})
    tl.to('.animFill path', {fill: "#FFFFFF"}, "<")

    navigator.clipboard.writeText(copyText);
    
  }

  function handleStatus(status) {
    handleNarrativeSave('status', status);
   setDropDown(null);
  }

  function handleNewTitle(e, value) {
    if (e.key === "Enter") {
      handleNarrativeSave('title', e.target.value);
    }
  }

  function handleAddNewModule(slug) {
    setShowLoader(true)
    const api_url = Config.baseURL + '/api/cms/' + slug + '-add/' + id + "/";
    var dataJSON = {};
    dataJSON['status'] = "new"
    dataJSON['title'] = tempModuleTitle
    axios({
        method: 'POST',
        url: api_url,
        data: dataJSON,
        headers: {
          Authorization: 'Token ' + Cookies.get('_udxdid')
        }
    })
    .then(response => {
        console.log('add module response', response.data['id'])
        fetchJSON(response.data['id']);
        setTempModuleTitle(null);
        setShowModuleForm(false);
        setTempColor(null);
    })
    .catch(error => {
        console.log('error', error.response);
    });
  } 

  function updateNarrativePDF() {
    if (module) {
      // setShowLoader(true);
      setNarrativeUpdating(narrative.modified);
      console.log('narrative.modified', narrative.modified)
      
      axios({
        method: 'POST',
        url:  Config.baseURL + "/api/cms/generate-narrative-pdf/",
        data: {id: id},
        headers: {
          Authorization: 'Token ' + Cookies.get('_udxdid')
        },
      })
      .then(response => {
          console.log('generate-narrative pdf', response);
          
          setInterval(() => {
            try {
              axios({
                  method: "get",
                  url: Config.baseURL + "/api/cms/narrative-landing/" + id,
                  headers: {
                    Authorization: 'Token ' + Cookies.get('_udxdid')
                  },
              }).then((response) => {
                  const assetJSON = response.data;
                  if (assetJSON.modified !== narrativeUpdating) {
                    setNarrative(assetJSON);
                    setNarrativeUpdating(false);
                    console.log('pdf ready', assetJSON.modified)
                  } else {
                    console.log('still generating pdf', assetJSON.modified)
                  }
                  
                  
                 
                 
                  
              });
          } catch (error) {
              console.error(error);
          }
          }, 10000);

          
      })
      .catch(error => {
          console.log('error', error.response);
      });
      }
    
  }
  
  
  

  const choices = (
    <div className="buttons">
      <div style={{display: "none"}} className={"btn1" + ((modules.findIndex(a => a.typeName === 'mapbox') > -1)?" off":"")}  onClick={() => setShowModuleForm('mapbox')}>
        <div className="iconField"><Map className="iconLg" /> Map</div>
      </div>
      <div className={"btn1"} onClick={() => setShowModuleForm('gallery')}>
        <div className="iconField"><GalleryImage className="iconLg" /> Gallery</div>
      </div>
    </div>
  )

  const titleRow = (<div className="settingsRow"><input type="text" autoComplete="off" required onChange={(e) => {setTempModuleTitle(e.target.value)}} placeholder="Add a Title" /></div>)
    

    return (
        <section className="main">
        {(showLoader)?(<div id="loading"><img src={Loader} alt="" /></div>):null}
        {(showError)&&(<div id="errorPopup"><div className="modal"><span><h3>Unsaved Changes</h3><p>Are you sure you want to go back?</p></span> <div className="fullRow errorRow"><span onClick={() => handleBackBtn('cancel')}>Cancel</span> <span onClick={() => handleBackBtn('force')}>Continue</span></div></div></div>)}
        
        {(showModuleForm === true) && <Modal heading={"Modules"} text={"Add a Module"} choices={choices} onBtn1Click={setShowModuleForm} onBtn1={false} btn1Text={"Cancel"} onBtn2Click={null} onBtn2={null} btn2Text={null} />}

        {(showModuleForm === 'gallery') && <Modal heading={"New Gallery Page"} choices={titleRow} onBtn1Click={setShowModuleForm} onBtn1={false} btn1Text={"Cancel"} onBtn2Click={handleAddNewModule} onBtn2={'gallery'} btn2Text={"Create"} />}

        {(showModuleForm === 'mapbox') && <Modal heading={"New Map Page"} choices={titleRow} onBtn1Click={setShowModuleForm} onBtn1={false} btn1Text={"Cancel"} onBtn2Click={handleAddNewModule} onBtn2={'mapbox'} btn2Text={"Create"} />}
        
        {(showShare)&&(<div className="modalContainer shareModal"><div className="modal">
          <span className="iconField ui closeBtn" onClick={() => setShowShare(false)}><Close className="iconLg fillWhite" /></span>
          <span className="textContainer">
          <h3>Share</h3>
          <span className="textInner">
            <p>Click the link below to copy the share link.</p><p>Anyone with this link can view the presentation, without a username and password.</p>
            <p><span className="copyLink ui iconField" onClick={(e) => copyLink(e, Config.previewURL + "/narrative/" + narrative.id + "?u=" + props.user.link_token)}><Linked className="icon animFill" /> Copy Link</span></p>
    
          </span>
          </span>
          </div></div>)}

        <div id="narrativeOverviewTools">
          <div className={"iconField ui" + (narrativeUpdating ? " rotate":"")}  onClick={() => updateNarrativePDF()} ><AutoRenew className="icon ui" title="Add Layer" /><div className="iconLabel">Update Presentation PDF</div></div>

          {narrative && <a href={ Config.previewURL + "/narrative/" + narrative.id + "?u=" + props.user.link_token + "&nav=closed"} className="previewBtn" target="_blank" rel="noreferrer" title="preview">
          <img src={OpenLink} className="icon" alt="" /></a>}

          
          <div className="ui btn1 shareBtn" onClick={() => setShowShare(true)} >Share</div>

        </div>

        {narrative && <div className="col appGrid">
            <nav className="leftNav">
                  <div className="row narrativeBtn">
                  <NavLink to={'/narratives/' + narrative.id + "/pages"} className="iconField">Edit Presentation <Edit className="icon" /></NavLink>
                </div>
              
                <div className="row">
                  <div className="heading sectionTools">
                  <div className="sectionLabel">Modules</div>
                  <Add onClick={() => setShowModuleForm(true)} className="icon ui" />
                  </div>
                  <div className="moduleGrid">
                  {(modules && modules
                    .sort((a, b) => (a.id.toUpperCase() < b.id.toUpperCase()) ? -1 : (a.id.toUpperCase() > b.id.toUpperCase()) ? 1 : 0)
                    .map((obj, i) =>
                    <NavLink 
                      key={"module_" + obj.id}
                      to={"/modules/" + obj.typeName + "/" + ((obj.typeName === 'document')?obj.id.replace("documents-",""):obj.id.replace(obj.typeName + "-",""))}
                      // onClick={() => handlePageChange(route.path)}
                      className={"gridItem"}
                    >
                      
                      
                      {obj.icon && <SVG src={Config.mediaURL + obj.icon} className="iconLg" />}
                      {!obj.icon && obj.typeName === "document" && <Doc className="iconLg" />}
                      {!obj.icon && obj.typeName === "mapbox" && <Map className="iconLg" />}
                      {!obj.icon && obj.typeName === "gallery" && <GalleryImage className="iconLg" />}
                      {!obj.icon && obj.typeName === "webgl" && <WebGL className="iconLg" />}
                      {!obj.icon && obj.typeName==="stack" && <Stack className="iconLg" />}

                      {obj.typeName === "mapbox" && "Maps"}
                      {obj.typeName === "document" && "Document Library"}
                      {(obj.typeName !== "mapbox" && obj.typeName !== "document") && obj.title}
          
                        
                    </NavLink>
            
                  ))}
                  </div>
                </div>

            </nav>
            <div className="core">
            <div className="heading">
            <h1>{narrative.title}</h1>
          </div>
              <div className="twoCol">
 
                  <div className="card">
                  <iframe key={reloadIframe} src={Config.previewURL + '/narrative/' + id + '?u=' + props.user.link_token} title="narrativePreview" className="narrativePreview" />
                    
                  </div>
                  {console.log('props.user.link_token', props.user)}
                  
                  <div className="rightNav settingsOn" id="manifestColumn">
                    <div className="mediaSettings showSettings on">
                  <div className="settingsGroup">
                      <div className="row">
                          <div className="groupLabel"><p>Presentation Settings</p></div>
                    
                          <div className="settingsRow">
                  
                            <div className="fieldRow">
                                <div className="errors"></div>
                        
                                  <div className="canvasLabel">Title</div>
                                  <input type="text" autoComplete="off" id="sectionTitle" defaultValue={narrative.title} onKeyUp={(e) => handleNewTitle(e, 'narrativeTitle')} />
                              
                            </div>
                          
                  
                          </div>
                    
                      </div>
                      <div className="row settingsGrid">
                      <div className="groupLabel">
                      Status
                      </div>
  
                      <div className="settingsRow" id="settingsRow_status">
                      <div className="ui fontBtn" onClick={(e) => handleDropDown(e, 'status')}>{narrative.status ? narrative.status : <span className="label">Update Status</span>}</div>
                        <span className="inputField dropDownField" id={"inputField_status"}>
                          <span className="dropDown">
                            <span className="dropDownChoices">
                            
                                <span className={"dropDownChoice ui"} onClick={() => handleStatus('live')}>Live</span>
                                <span className={"dropDownChoice ui"} onClick={() => handleStatus('draft')}>Draft</span>
           
                            </span>
                          </span>
                        </span>
                      </div>
  
                    </div>
                    <div className="row settingsGrid">
                        <div className="canvasLabel ui" onClick={() => document.getElementById('screenshot').click()}>Thumbnail</div>
                        <div className="iconField ui" onClick={() => document.getElementById('screenshot').click()}>{narrative.screenshot ? fileNameType(narrative.screenshot, null).map((obj2, j) => (
                          <span
                          
                          key={"fileName_" + j}>{obj2}</span>)) : <span className="canvasLabel">Add File</span>}</div>
                        <input type="file" style={{"display": "none"}} id="screenshot" accept={acceptValues} multiple={false} onChange={() => handleNarrativeMedia("screenshot")} />
                    </div>
                  </div>
                    <div className="settingsGroup">

                    <div className="groupLabel"><p>Theme</p></div>
                    <div className="row settingsGrid">
                        <div className="canvasLabel ui" onClick={() => document.getElementById('menu_logo').click()}>Menu Logo</div>
                        <div className="iconField ui" onClick={() => document.getElementById('menu_logo').click()}>{narrative.menu_logo ? fileNameType(narrative.menu_logo, null).map((obj2, j) => (
                          <span
                          
                          key={"fileName_" + j}>{obj2}</span>)): <span className="canvasLabel">Add File</span>}</div>
                        <input type="file" style={{"display": "none"}} id="menu_logo" accept={acceptValues} multiple={false} onChange={() => handleNarrativeMedia("menu_logo")} />
                    </div>
                    <div className="row settingsGrid">
                        <div className="canvasLabel ui" onClick={() => document.getElementById('menu_background').click()}>Background</div>
                        <div className="iconField ui" onClick={() => document.getElementById('menu_background').click()}>{narrative.menu_background ? fileNameType(narrative.menu_background, null).map((obj2, j) => (
                          <span
                          
                          key={"fileName_" + j}>{obj2}</span>)) : <span className="canvasLabel">Add File</span>}</div>
                        <input type="file" style={{"display": "none"}} id="menu_background" accept={acceptValues} multiple={false} onChange={() => handleNarrativeMedia("menu_background")} />
                    </div>
                    <div className="row settingsGrid">
                          {props.user.role && props.user.role !== 'superuser' ? 
                          <React.Fragment>
                          <div className="canvasLabel" onClick={() => animColorPicker(animOpen, 'colorPicker0')}>Background Color</div>
                          <div className="colorChip" 
                          style={{
                            background: (narrative.theme_css && narrative.theme_css['primary-background']) ? narrative.theme_css['primary-background'] : 'none'
                          }}></div>
                          </React.Fragment>
                          : 
                          <React.Fragment>
                            <div className="canvasLabel ui" onClick={() => animColorPicker(animOpen, 'colorPicker0')}>Background Color</div>
                            <div className="ui colorChip" 
                            style={{
                              background: (narrative.css && narrative.css['primary-background']) ? narrative.css['primary-background'] : 'none'
                            }}
                            onClick={() => animColorPicker(animOpen, 'colorPicker0')}></div>
                        
                            <span 
                            id="colorPicker0"
                            className="colorPicker"
                            >
                            <ChromePicker
                                color={tempColor?tempColor:((narrative.css && narrative.css['primary-background'])?narrative.css['primary-background']:"")}
                                disableAlpha={true}
                                onChange={(color) => {
                                  setTempColor(color.hex)
                                }}
                                onChangeComplete={(color, event) => {
                                  // console.log('color, event', color, event)
                                  
                                  if (tempColor) {
                                    handleThemeCss('css', 'primary-background', tempColor)
                                  }
                                  if (!tempColor && color.hex) {
                                    handleThemeCss('css', 'primary-background', color.hex) 
                                  }
                                }}
                              />
                              
                              </span>
                          </React.Fragment>
                        }
                        
                    </div>
                    <div className="row settingsGrid">

                      {props.user.role && props.user.role !== 'superuser' ? 
                      <React.Fragment>
                        <div className="canvasLabel" onClick={() => animColorPicker(animOpen, 'colorPicker1')}>Primary Color</div>
                        <div className="colorChip" 
                        style={{
                          background: (narrative.theme_css && narrative.theme_css['primary']) ? narrative.theme_css['primary'] : 'none'
                        }}
                        ></div>
                      </React.Fragment>

                      : <React.Fragment>
                      <div className="canvasLabel ui" onClick={() => animColorPicker(animOpen, 'colorPicker1')}>Primary Color</div>
                      <div className="ui colorChip" 
                      style={{
                        background: (narrative.css && narrative.css['primary']) ? narrative.css['primary'] : 'none'
                      }}
                      onClick={() => animColorPicker(animOpen, 'colorPicker1')}></div>
                     
                      <span 
                      id="colorPicker1"
                      className="colorPicker"
                      >
                      <ChromePicker
                          color={tempColor?tempColor:((narrative.theme_css && narrative.theme_css['primary'])?narrative.theme_css['primary']:"")}
                          disableAlpha={true}
                          onChange={(color) => {
                            setTempColor(color.hex)
                          }}
                          onChangeComplete={(color, event) => {
                            

                            if (tempColor) {
                              handleThemeCss('css', 'primary', tempColor) 
                            }
                            if (!tempColor && color.hex) {
                              handleThemeCss('css', 'primary', color.hex) 
                            }
                     
                          }}
                        />
                        </span>
                      </React.Fragment>
                      }
                        
                    </div>
                    <div className="row settingsGrid">
                    {props.user.role && props.user.role !== 'superuser' ? 
                    <React.Fragment>
                      <div className="canvasLabel" onClick={() => animColorPicker(animOpen, 'colorPicker2')}>Secondary Color</div>
                      <div className="colorChip" 
                        style={{
                          background: (narrative.theme_css && narrative.theme_css['secondary'] ) ? narrative.theme_css['secondary'] : 'none'
                        }}
                       ></div>
                       {console.log('narrative', narrative)}
                    </React.Fragment>

                    : <React.Fragment>
                      
                        <div className="canvasLabel ui" onClick={() => animColorPicker(animOpen, 'colorPicker2')}>Secondary Color</div>
                        <div className="ui colorChip" 
                        style={{
                          background: (narrative.theme_css && narrative.theme_css['secondary']) ? narrative.theme_css['secondary'] : 'none'
                        }}
                        onClick={() => animColorPicker(animOpen, 'colorPicker2')}></div>
                        
                        <span 
                        id="colorPicker2"
                        className="colorPicker"
                        >
                        <ChromePicker
                            color={tempColor?tempColor:((narrative.css && narrative.css['secondary'])?narrative.css['secondary']:"")}
                            disableAlpha={true}
                            onChange={(color) => {
                              setTempColor(color.hex)
                            }}
                            onChangeComplete={(color, event) => {
                              console.log('color', color.hex, tempColor)

                              if (tempColor) {
                                handleThemeCss('css', 'secondary', tempColor) 
                              }
                              if (!tempColor && color.hex) {
                                handleThemeCss('css', 'secondary', color.hex) 
                              }
                             
                            }}
                          />
                          </span>
                          </React.Fragment>

                    }

                    </div>
                    
                  


                  </div>
                    
               

                  </div> 
                  
                </div>

                </div>
            </div>     
           
          

         </div>}
        

      </section>
    );
}

export default Narrative;
// <iframe key={reloadIframe} src={Config.previewURL + '/narrative/' + id + '?u=' + props.user.link_token} title="narrativePreview" className="narrativePreview" />