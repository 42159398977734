import React, { useState, useEffect, useRef } from "react";
import {
  NavLink,
  useNavigate,
  useParams,
  useSearchParams
} from "react-router-dom";
import gsap from "gsap";

import LeftNav from './narrativeDetail/module.leftNav';
import Frame from './narrativeDetail/module.frame';
import TabButtons from "./narrativeDetail/module.tabButtons";
import LayerSettings from "./narrativeDetail/module.layerSettings";
import NarrativeTools from "./narrativeDetail/module.narrativeTools";
import Timeline from './narrativeDetail/module.pageTimeline';
import PageSettings from './narrativeDetail/module.pageSettings';

import {animDropDown } from "../utils/form.tools";
import { handlePostSave } from "./narrativeUtils/handlePostSave";
import { fetchJSONMain, apiCall } from "./narrativeUtils/fetch";
import { updateVersionHistory, onUndoRedo } from "./narrativeUtils/versionHistory";

import showSettingsMain from "./narrativeUtils/showSettings";
import Config from "../Config.json";
import Modal from './module.modal';

import Loader from "../static/svg/loader.svg";
// import { version } from "react/cjs/react.production.min";


function Canvas(props) {
  const navigate = useNavigate();
    let { id } = useParams();
    const tempTextObj = useRef();
    const editorOn = useRef(null);
    const formInput = useRef(null);
    const tempNarrativeHeading = useRef();
    const undoRedo = useRef(false);
    const versionNumber = useRef(-1);
    // const versions = useRef();

    // UI 
    const [showFullCanvas, setShowFullCanvas] = useState(true);
    const [aspectRatio, setAspectRatios] = useState(1); 
    const [showLoader, setShowLoader] = useState(true);
    const [showError, setShowError] = useState(false);
    const [triggerSaveForm, setTriggerSaveForm] = useState(false);
    const [showDeleteSection, setShowDeleteSection] = useState(false);
    const [zoomRatio, setZoomRatio] = useState(0.463);
    const [zoomNumber, setZoomNumber] = useState(1);
    const [lockAr, setLockAr] = useState(true);
    const [dropDown, setDropDown] = useState();
    const [showPages, setShowPages] = useState(true);
    const [showNarrativeHeadingForm, setShowNarrativeHeadingForm] = useState(false);
    const [showFormatLockWarning, setShowFormatLockWarning] = useState(false);
    const [multiSelectIds, setMultiSelectIds] = useState([]);
    const [multiSelectPageIds, setMultiSelectPageIds] = useState([]);
    const [formError, setFormError] = useState();
    const [showPageForm, setShowPageForm] = useState(false);
    const [editPageForm, setEditPageForm] = useState(false);
    const [reload, setReload] = useState(false);
    const [pagesPos, setPagesPos] = useState();
    const [swiperInst, setSwiperInst] = useState(null);
    const [acceptValues, setAcceptValues] = useState('init');
    const [animationPresets, setAnimationPresets] = useState([]);
    const [newTab, setNewTab] = useState(null);
    const [publishOn, setPublishOn] = useState(false);
    const [showFrame, setShowFrame] = useState(false);
    const [updateFetch, setUpdateFetch] = useState(0);

    // Active States
    const [searchParams, setSearchParams] = useSearchParams();
    const [sectionId, setSectionId] = useState(null);
    const [activeSectionObj, setActiveSectionObj] = useState(null);
    const [unSavedChanges, setUnSavedChanges] = useState(false);
    const [partnerChanges, setPartnerChanges] = useState([]);
    const [activeSettingId, setActiveSettingId] = useState(null);
    const [activeNarrativeHeading, setActiveNarrativeHeading] = useState();
    const [activeStateId, setActiveStateId] = useState(null);
  
    // Data
    const [narrative, setNarrative] = useState(null);
    const [sections, setSections] = useState(null);
    const [section, setSection] = useState(null);
    const [components, setComponents] = useState([]);
    const [componentDimensions, setComponentDimensions] = useState([]);
    const [narrativeHeadings, setNarrativeHeadings] = useState();

    // Changes
    const [sectionChanges, setSectionChanges] = useState([]);
    const [newMedia, setNewMedia] = useState([]);
    const [newComponentID, setNewComponentID] = useState(null);
    const [newComponentPopUpID, setNewComponentPopUpID] = useState(null);
    const [newComponentDimension, setNewComponentDimension] = useState(null);
    const [unSavedLayerIds, setUnSavedLayerIds] = useState([]);
    const [newAnimObj, setNewAnimObj] = useState({});
    const [newStateAnimObj, setNewStateAnimObj] = useState({});
    const [narrativeChanges, setNarrativeChanges] = useState([]);
    const [selection, setSelection] = useState();
    const [forceVersion, setForceVersion] = useState(null);

    // Versioning
    const [versionCount, setVersionCount] = useState(0);
    const [versionHistory, setVersionHistory] = useState(null);
    // const [versionNumber, setVersionNumber] = useState(-1);


    function stageLayerIds(objId){
      let newIds = [ ...unSavedLayerIds ];
      if (newIds.findIndex(a => a === objId) < 0) {
        newIds.push(objId)
        setUnSavedLayerIds(newIds);
        // console.log('stageLayerIds', newIds)
      }
      
    }

    async function handleLayerSave(menuObj) {
      setPublishOn(true);
      setShowLoader(true);
      setFormError(null);

      await handlePostSave(activeSettingId, setShowLoader, setUnSavedChanges, setNewAnimObj, sectionChanges, sectionId, handleSectionSave, componentDimensions, handleComponentDimensionUpdate, multiSelectIds, handleEditAnimation, newAnimObj, handleAddAnimation, newStateAnimObj, setNewStateAnimObj, unSavedLayerIds, menuObj, setSectionId, setActiveNarrativeHeading, handleCloseSettings, setForceVersion);

      return true


    }

    function updateVersionHistorySub(obj, source){
      updateVersionHistory(
        obj, 
        source, 
        undoRedo,
        versionCount, 
        setVersionCount, 
        versionNumber
        )
    }

    function handleDontSave(menuObj){
      // console.log('dont save')
      setUnSavedChanges(null)
      setUnSavedLayerIds([])
      if (menuObj){
        handleCloseSettings();
        if (menuObj.page) {
          setSectionId(menuObj.page);
          setActiveNarrativeHeading(menuObj);
        } else if (menuObj.page === null){
          setActiveNarrativeHeading(menuObj);
        } else {
          setSectionId(menuObj.id);
        }
    
        // console.log('menuObj', menuObj)
      }
      setTimeout(() => {
        fetchJSON()
      }, 200);
      
    }

    function handleNewComponent() {
      showSettings(newComponentDimension['id'], false, null);
      setNewComponentDimension(null);
      handleLayerSave();
    }

    function fetchPresets(narrativeId) {
      const get_url = Config.baseURLcms + "animation-preset/" + narrativeId;

      function responseTask(response) {
        const assetJSON = response.data;
        setAnimationPresets(assetJSON);
      }
      apiCall("get", get_url, {}, responseTask, null, setFormError)
    }


    function handleComponentDimensions(sectionObj) {

      let activeSection = sectionObj;
  
      setSection(activeSectionObj);
      if (activeSection.components.length > 0) {
        setComponents(activeSection.components)
        var components = activeSection.components;
        var dims = [];
        for(var i = 0; i < components.length; i++) {
          var obj = components[i];
            var dim = obj.dimensions.filter((dimension) => dimension.display === aspectRatio);
            if (dim.length !== 0) {
              // console.log('obj', obj, dim.length);
              dims.push(dim[0]);
            }
            
            if (i === (components.length - 1)) {
              setComponentDimensions(dims);
            }
        }

      } else {
        setComponents([]);
        setComponentDimensions([]);
      }

      console.log('handleComponentDimensions', sectionObj);

    }


    useEffect(() => {

      setVersionCount(0);
      setVersionHistory(null);
      props.setTools("canvas")
      fetchJSON(false);
      fetchPresets(id);
      editorOn.current = false;

      let versionHistory = JSON.parse(localStorage.getItem('versionHistory'));

      if (versionHistory && versionHistory.length > 0) {
        let versionNo = versionHistory[versionHistory.length - 1].version
        setVersionCount(versionHistory.length)
        versionNumber.current = versionNo
        console.log('init version count and number', versionHistory.length, versionNo)
      }
      


    }, []);


    useEffect(() => {
      showNarrativeHeadingForm && document.getElementById('narrativeHeadingNew').focus();
    }, [showNarrativeHeadingForm])



    useEffect(() => {
      function loadDimension(callback) {
        if (components.length > 0) {
    
          const dims = [];
          for(var i = 0; i < components.length; i++) {
            var obj = components[i];
            var aspectDims = obj.dimensions.filter((dimension) => dimension.display === aspectRatio);
            if (aspectDims.length > 0) {
              dims.push(aspectDims[0]);
            }
            if (i === (components.length - 1)) {
              callback(dims);
            }
            
          }
      }
    }


    // CHANGE ASPECT RATIO
    function setDimensions(dims) {
      handleCloseSettings();
        if (partnerChanges.length > 0) {
          var newDims = [...dims];
          // console.log('partnerChanges', partnerChanges, newDims, dims);
          partnerChanges.forEach(function (partnerItem, partnerIndex) {
          if (partnerItem['id'] !== undefined) {
            // console.log('partnerItem', partnerItem, partnerItem['id'], partnerIndex, newDims);

            var dimPartnerIndex = newDims.findIndex(item => item.id === partnerItem['id']);
            if (dimPartnerIndex > -1) {
              if (partnerItem['x_pos']) {
                newDims[dimPartnerIndex]['x_pos'] = partnerItem['x_pos']
                newDims[dimPartnerIndex]['y_pos'] = partnerItem['y_pos']
              }
              if ((partnerItem['width'])&&(partnerItem['height'])) {
                newDims[dimPartnerIndex]['width'] = partnerItem['width']
                newDims[dimPartnerIndex]['height'] = partnerItem['height']
              }
              if (partnerItem['component_ordering']) {
                newDims[dimPartnerIndex]['component_ordering'] = partnerItem['component_ordering']
              }
              newDims[dimPartnerIndex]['typeName'] = partnerItem['typeName'];
            }
            // console.log('partnerIndex', partnerIndex, partnerChanges.length, partnerItem);
            if ((partnerIndex+1) === partnerChanges.length){
              
              setComponentDimensions(newDims);
            }
          }
          
  
          });
        } else {
          setComponentDimensions(dims);
        }
      }

      loadDimension(setDimensions);
      let elemHeight;
      let elemHeightObj = document.getElementById('canvasForm')
      
      if (elemHeightObj) {
        elemHeight = elemHeightObj.offsetHeight - 2;
        if (aspectRatio === 1) {
          let ratio = elemHeight/1080
         
          setZoomRatio(ratio)
        }
        if (aspectRatio === 2) {
          let ratio = elemHeight/820
      
          setZoomRatio(ratio)
        }

      }
     
      
    }, [aspectRatio]);


    useEffect(() => {

      console.log('component dimensions', componentDimensions)

      var currPartnerChanges = [];
      componentDimensions.forEach(function (dimension, index) {
        if (dimension.is_locked) {
          var partnerIds = dimension.partner_ids;
          partnerIds.forEach(function (partnerId, index0) {
            
            var partnerChangesIndex = currPartnerChanges.findIndex(item => item.id === partnerId);
           
            if (partnerChangesIndex > -1) {
              currPartnerChanges[partnerChangesIndex] = dimension;
              //  console.log('currPartnerChanges:', currPartnerChanges, dimension);
            } else {
              var partnerObj = {};
              var newPartnerObj = Object.assign(partnerObj, dimension);
              newPartnerObj['id'] = partnerId;
              // console.log('newPartnerObj', partnerId, newPartnerObj);
              currPartnerChanges.push(newPartnerObj);
            }
          });
        }
        if (index === (componentDimensions.length - 1)) {
          setPartnerChanges(currPartnerChanges);
          // console.log('componentDimensions: setPartnerChanges', currPartnerChanges);
        }
      });

    }, [componentDimensions]);


    const generalKeyDown = ({ repeat, metaKey, ctrlKey, shiftKey, key }) => {
   
      if (repeat) return
      onUndoRedo(
        undoRedo, 
        sections, 
        setActiveSectionObj, 
        versionNumber, 
        metaKey, ctrlKey, shiftKey, key, 
        versionCount, 
        setUnSavedLayerIds, 
        activeSectionObj,
        setShowLoader,
        fetchJSON,
        setFormError,
        setUnSavedChanges
        )
     
      
    }
    

    useEffect(() => {
     
      if (versionCount) {
      
        // console.log('versionCount', versionCount, versionNumber.current)
      }
    }, [versionCount])
  
    useEffect(() => {
      let versionHistory = JSON.parse(localStorage.getItem('versionHistory'));
      let versionObj = {
        obj: versionHistory, 
        versionCount: versionCount, 
        versionNumber: versionNumber.current
      }
      console.log('version ping', versionObj)

      document.addEventListener('keydown', generalKeyDown)
      return () => document.removeEventListener('keydown', generalKeyDown)

      
    }, [versionCount, activeSectionObj])

    
     useEffect(() => {
       if (newComponentID) {
        const api_url = Config.baseURLcms + 'section-componentdimension-duplicate/';
        var formJSON = {
          'component_id': newComponentID
        }
        function responseTask() {
          fetchJSON(false);
          
        }
        apiCall("POST", api_url, formJSON, responseTask, null, setFormError)

 
    
       }
    }, [newComponentID]);


    useEffect(() => {
      if (newComponentPopUpID) {
       const api_url = Config.baseURLcms + 'section-componentdimension-popup-duplicate/';
       var formJSON = {
         'component_id': newComponentPopUpID
       }
       function responseTask() {
        fetchJSON(false);
        setNewComponentPopUpID(null);
      }
       apiCall("POST", api_url, formJSON, responseTask, null, setFormError)
      
   
      }
   }, [newComponentPopUpID]);


  useEffect(() => {
    
   if (newComponentDimension) {
      // console.log("new component, dimensions loaded", newComponentDimension, componentDimensions);
      fetchJSON(false);

      setTimeout(() => {
        showSettings(newComponentDimension['id'], false, null);
      }, 200);
     
    } 
   
  }, [newComponentDimension]);


    
    useEffect(() => {
      if (sections && sectionId) {
        console.log('sectionId', sectionId)
        
        setSearchParams({page: sectionId});


        let api_url = Config.baseURLcms  + "narrative-section/" + sectionId;

        function responseTask(response) {

          // setSection(response.data);
          setActiveSectionObj(response.data);

        
          console.log('section page get', response.data)

          

          // if (newComponentDimension) {
          //   handleNewComponent();
          // }
          // if (sections) {
          //   let newPagesPos = []
          //   sections.forEach((obj, i) => (
          //     newPagesPos.push({"id": obj.id, "ordering": obj.ordering, "y": i*30})
          //   ));
          //   setPagesPos(newPagesPos);
          //   // console.log('newPagesPos', newPagesPos)
          // }



        }
        apiCall("GET", api_url, null, responseTask, null, setFormError)

     
  
  

        // console.info("CMS: Page JSON", Config.baseURL + '/api/cms/section/' + sectionId + '/')
        // console.info("Layer CMS JSON", Config.baseURL + '/cms/component-dimension/' + sectionId + '/')
        // console.info("App: Page JSON", Config.baseURL + '/api/narrative/' + narrative.id + '/' + sectionId + '/')
        // console.info("App: Narrative JSON", Config.baseURL + '/api/narrative/' + narrative.id + '/')

      }
     
    }, [sectionId]);

    useEffect(() => {
      console.log('activeSectionObj null', activeSectionObj)
      if (activeSectionObj) {
        console.log('activeSectionObj', activeSectionObj)

        if (activeSectionObj.components.length > 0) {
          setComponents(activeSectionObj.components)

          var components = activeSectionObj.components;
          var dims = [];
          for(var i = 0; i < components.length; i++) {
            var obj = components[i];
              var dim = obj.dimensions.filter((dimension) => dimension.display === aspectRatio);
              if (dim.length !== 0) {
                // console.log('obj', obj, dim.length);
                dims.push(dim[0]);
              }
              
              if (i === (components.length - 1)) {
                setComponentDimensions(dims);
                console.log('dims set', dims, i, activeSectionObj.id)
                
              }
          }
  
        } else {
          setComponents([]);
          setComponentDimensions([]);
        }
      }
     
    }, [activeSectionObj, updateFetch]);


    useEffect(() => {
      if (sectionId && sections && swiperInst && sections.length > 0){

        // console.log('sections', sections)
        
        let activeSectionIndex = sections.findIndex(section => section.id === sectionId);
        if (activeSectionIndex > -1) {
          // sections[activeSectionIndex] && setActiveSectionObj(sections[activeSectionIndex]);
          // console.log('activeSectionIndex', activeSectionIndex)
          swiperInst.slideTo(activeSectionIndex, 300)
         
        }
       
      }
      
    }, [sectionId, sections]);

    useEffect(() => {
      if (triggerSaveForm === true) {
        handleLayerSave();
        setTriggerSaveForm(false);
      }
      
    }, [triggerSaveForm, unSavedChanges, componentDimensions])

    // useEffect(() => {
    //   if (activeSettingId && componentDimensions && componentDimensions.length > 0) {
    //     let obj = componentDimensions.find((dimension) => dimension.id === activeSettingId);
    //     updateVersionHistorySub(obj, "layer")
    //   }
    // }, [componentDimensions])


    useEffect(() => {
      if (activeSettingId) {
        if (activeSettingId && componentDimensions && componentDimensions.length > 0) {

          updateVersionHistorySub(activeSectionObj, "page")

        }
      }
    }, [activeSettingId, componentDimensions])

  useEffect(() => {
    if (dropDown) {
      animDropDown(true, activeSettingId, dropDown);
    } else {
      animDropDown(false, activeSettingId, dropDown)
    }
  }, [dropDown])

  useEffect(() => {
    if (activeSettingId) {
      stageLayerIds(activeSettingId)
      setShowPages(false)
    } else {
      setShowPages(true)
    }
  }, [activeSettingId])

  useEffect(() => {
    if (multiSelectIds) {
      
      multiSelectIds.forEach((id => {
        stageLayerIds(id)
      }));
     
    } else {
    
    }
  }, [multiSelectIds])



  function showSettings(objID, isSave, event){
    // setUnSavedChanges(true);

    showSettingsMain(objID, isSave, event, multiSelectIds, setMultiSelectIds, setActiveSettingId, componentDimensions, setComponentDimensions, setUnSavedChanges, setTriggerSaveForm);

  }

  function fetchJSON(hasNewObjId){
    setShowLoader(true);
    let page = searchParams.get("page");
    let pageVal = null;
    console.log('pageVal', pageVal, page, isNaN(page))
    if (isNaN(page)) {
    
    } else {
      pageVal = parseInt(page)
     
    }
    
    setSectionId(null)
    fetchJSONMain(hasNewObjId, id, setNarrative, setNarrativeHeadings, setSections, sectionId, setActiveSectionObj, setSectionId, setShowLoader, setShowFullCanvas, swiperInst, setFormError, pageVal, setSection, setUpdateFetch, updateFetch)
    
  }


    function handleSectionScrollOffset(e) {

      const timeout = setTimeout(() => {
        
        var scroll_offset = e.target.value;
        var newSections = [...sections];
        var objIndex = newSections.findIndex(x => x.id ===sectionId);
        var newObj = newSections[objIndex];
        var changesCopy = [ ...sectionChanges ];
        var sectionObj = {}
        sectionObj['id'] = sectionId;
        sectionObj['scroll_offset'] = scroll_offset
        sectionObj['ordering'] = newObj['ordering'];
        sectionObj['title'] = newObj['title'];
        sectionObj['active'] = newObj['active'];
        changesCopy.push(sectionObj);

        setSectionChanges(changesCopy);
        setSections(newSections);
        setUnSavedChanges(true);
        setSectionId(sectionId);

      }, 600);
      return () => {
        clearTimeout(timeout);
      };
      
    }

    function handleSectionOnlySave(){

      if (unSavedChanges) {
        handleLayerSave()
       
      } else {
        for(var i = 0; i < sectionChanges.length; i++) {
          if (sectionChanges[i].id === sectionId) {
            handleSectionSave(i);
          } 
          
          
        }   

      }

      
     


     
    }

    function handleNarrativeSave(field, value) {
      setShowLoader(true)
      const api_url = Config.baseURLcms + 'narrative-settings/' + narrative.id + "/";
      var dataJSON = {};
      if (field !== 'title') {
        dataJSON['title'] = narrative.title
      }
      // dataJSON['active'] = narrative.active;
      dataJSON[field] = value;
      
      // console.log('handleNarrativeSave', dataJSON, api_url);
      function responseTask() {
        fetchJSON(false);
        setNarrativeChanges([]);
        if (field === "active") {
          navigate('/')
        }
      }
      apiCall("POST", api_url, dataJSON, responseTask, null, setFormError)

    }

    function handleNarrativeHeadingEdit(field, value, objId) {
      // console.log('handleNarrativeHeadingEdit', activeNarrativeHeading, field, value, objId)
      if (activeNarrativeHeading || objId) {
        let objIndex;
        let title = value;
        let newObjs = [...narrativeHeadings];
        if (objId) {
          objIndex = newObjs.findIndex(x => x.id === objId );
        } else {
          objIndex = newObjs.findIndex(x => x.id === activeNarrativeHeading.id );
        }
        
        let newObj = newObjs[objIndex];


        // console.log('newObj', newObj)
        if (newObj) {
          newObj[field] = value;
          setNarrativeHeadings(newObjs);
        }
  
        const api_url = Config.baseURLcms + 'narrativeheading-edit/';
        let formData = {}
        if (objId) {
          formData['id'] = objId
        } else {
          formData['id'] = activeNarrativeHeading.id
          console.log(activeNarrativeHeading.id, value)
        }
        
        formData[field] = value
        function responseTask() {
          fetchJSON(false)
          setEditPageForm(null);
          formInput.current = null;
        }

        apiCall("POST", api_url, formData, responseTask, null, setFormError)

      }
      
    }

    function handleCloseSettings() {
      if (activeSettingId) {
        setActiveSettingId(null);
        // setShowManifest(false);
      } 
      
    }

    function handleComponentDimensionUpdate(dataJSON, objID, isLast) {

      console.log('handleComponentDimensionUpdate', dataJSON, objID, isLast)

      const api_url = Config.baseURLcms + 'component-dimension/' + objID + '/';
      function responseTask() {
        if (isLast === true) {
          // fetchSection(sectionId)
          let obj = componentDimensions.find((dimension) => dimension.id === activeSettingId);
          if (obj && obj.animations && obj.animations.length > 0) {
            
          } else {
            // fetchJSON();
            // setShowLoader(false);
            console.log('handleComponentDimensionUpdate, no anim', obj)
          }
         
         
          setUnSavedLayerIds([]);
          setFormError(null)
        }
        // setShowLoader(false);
      }
      apiCall('POST', api_url, dataJSON, responseTask, null, setFormError)
      
    }

    function handleAddAnimation(dataJSON) {
      
      const api_url = Config.baseURLcms + 'componentdimension-animation-add/';
      function responseTask() {
        // fetchSection(sectionId)
        // fetchJSON();
      }
      apiCall('POST', api_url, dataJSON, responseTask, null, setFormError)
      
    }
    function handleReloadSection(response) {
      fetchJSON(false);
     
    }
    
    function handleEditAnimation(dataJSON) {
      const api_url = Config.baseURLcms + 'componentdimension-animation-edit/';
      function responseTask() {
        // fetchSection(sectionId)
        // fetchJSON();
      }
      apiCall('POST', api_url, dataJSON, responseTask, null, setFormError)
    }

    function handleSectionSave(i) {
      console.log('handleSectionSave', i)
      setShowLoader(true)
      const api_url = Config.baseURLcms + 'section-edit/';
      var dataJSON;
      dataJSON = {
        'id': sectionChanges[i].id,
        'scroll_offset': sectionChanges[i].scroll_offset,
        'title': sectionChanges[i].title,
        'active': sectionChanges[i].active,
        'force_version': forceVersion
   
      }
      console.log('handleSectionSave', dataJSON, api_url, forceVersion);
      function responseTask(response) {
        handleReloadSection(response);
      }
      apiCall('POST', api_url, dataJSON, responseTask, null, setFormError)
      

    }
    
    function onNewPage(){
      let pageCount = narrative.cms_sections.length;
      if (pageCount < Config.validations.maxPages) {
       setShowPageForm(true);

      } else {
        let newError = {
          "heading": "Note",
          "text": "You current have <strong>" + pageCount + "</strong> pages, the maximum number of pages permitted in a narrative."
        };
        setFormError(newError);
      }
    
    }

    function handleAddSection() {

     
      
      let pageCount = narrative.cms_sections.length;
      if (pageCount < Config.validations.maxPages) {
        setShowLoader(true);
        setShowPageForm(false)
        setShowNarrativeHeadingForm(false);
        const api_url = Config.baseURLcms + 'section-add/';
        var dataJSON = {
          'narrative_id': narrative.id,
          'title': formInput.current
        }
        function responseTask(response){
          localStorage.removeItem("versionHistory");
          versionNumber.current = -1
          setVersionCount(0);
          setTimeout(() => {
            fetchJSON(response.data)
          }, 200);
          
          setUnSavedChanges(false);
        }
        apiCall('POST', api_url, dataJSON, responseTask, null, setFormError)

      } else {
        let newError = {
          "heading": "Note",
          "text": "You current have <strong>" + pageCount + "</strong> pages, the maximum number of pages permitted in a narrative."
        };
        setFormError(newError);
      }
    
    }

    function handleDeleteSection(choice) {

      localStorage.removeItem("versionHistory");

      // console.log('handleSectionDelete', sectionId, choice);
      if (choice === 'cancel') {
        setShowDeleteSection(false);
      } else {
        setShowLoader(true);
        const api_url = Config.baseURLcms + 'section-delete/';
        var dataJSON = {
          'section_id': sectionId,
        }
        function response() {
          fetchJSON(false);
          // setSectionId(sections[0]['id']);
          setShowDeleteSection(false);
          handlePageClick(sections[0]);
        }
        apiCall('POST', api_url, dataJSON, response, null, setFormError)
  

      }

    }

    function handleAspectLock(objID) {
      if (showFormatLockWarning !== false) {
        var newComponents = [...componentDimensions];
        var newIndex = newComponents.findIndex(item => item.id === objID);

        // console.log('isLocked', newIndex, objID);

        var newObj = newComponents[newIndex];
        var is_locked = newObj['is_locked'];
        if (is_locked === true) {
          newObj['is_locked'] = false;

        } else {
          newObj['is_locked'] = true;
        }

        setShowFormatLockWarning(false);
        setShowLoader(true);

        const api_url = Config.baseURLcms + 'component/' + newObj.component_id + "/";
        var dataJSON = {
          'is_locked': newObj['is_locked']
        }
        function responseTask(response){
          fetchJSON(response.data)
        }
        apiCall('POST', api_url, dataJSON, responseTask, null, setFormError)

        // setComponentDimensions(newComponents);
        // setUnSavedChanges(true);
        
      } else {
        setShowFormatLockWarning(objID);
      }
      
    }

  function handleBackBtn(step){
    if (step === 'step1') {
      setShowError(true);
    } 
    if (step === 'cancel') {
      setShowError(false);
      setShowDeleteSection(false)
    } 
   
    if (step === 'force') {
      if (!showPages) {
        // console.log('handleBackBtn: force')
       setShowPages(true);
        setActiveSettingId(null);
        
        setShowError(false);
      } else {
        navigate('/narratives/' + narrative.id)
        setShowError(false);
        
      }
    } 
  }

  function handleSectionActive(value) {
    // console.log('handleSectionActive')
    var newSections = [...sections];
    var objIndex = newSections.findIndex(x => x.id ===sectionId);
    var newObj = newSections[objIndex];
    var changesCopy = [ ...sectionChanges ];
    var sectionObj = {}
    sectionObj['id'] = sectionId;
    sectionObj['scroll_offset'] = newObj['scroll_offest']
    sectionObj['ordering'] = newObj['ordering'];
    sectionObj['title'] = newObj['title'];
    sectionObj['active'] = value;
    changesCopy.push(sectionObj);

    setSectionChanges(changesCopy);
    setSections(newSections);
    setUnSavedChanges(true);
    setSectionId(sectionId);
  }



  function handlePageClick(menuItemObj, e){
    
    let otherSelects;
    if (unSavedChanges) {
      let newError = {
        "heading": "Warning",
        "text": "You have unsaved changes.",
        "btn2Text": "Save & Continue",
        "onBtn2Click": handleLayerSave,
        "onBtn2": menuItemObj,
        "btn3Text": "Don't Save",
        "onBtn3Click": handleDontSave,
        "onBtn3": menuItemObj
      };
      // handleLayerSave
      setFormError(newError);
      // console.log('unSavedChanges', unSavedChanges)
    } else {
      if (e && e.shiftKey) {
        if (multiSelectPageIds.length > 0) {
          otherSelects = [ ...multiSelectPageIds ]
          let otherSelectsIndex = otherSelects.findIndex(a => a === menuItemObj.id);
          // let card = document.getElementById('card_' + objID);
          if (otherSelectsIndex > -1) {
            otherSelects.splice(otherSelectsIndex, 1)
          } else {
            otherSelects.push(menuItemObj.id)
          }
        } else {
          otherSelects = [menuItemObj.id]
        }
        setMultiSelectPageIds(otherSelects);
      }
      
      setFormError(null);
      handleCloseSettings();
      if (menuItemObj.page) {
        setSectionId(menuItemObj.page);
        setActiveNarrativeHeading(menuItemObj);
      } else if (menuItemObj.page === null){
        setActiveNarrativeHeading(menuItemObj);
      } else {
        setSectionId(menuItemObj.id);
      }

      localStorage.removeItem("versionHistory");
      versionNumber.current = -1
      setVersionCount(0);
      
    }
    
   

    

 
    
    
    // setActiveMenuItem(menuItemObj)
  }


function handleOnKeyUp(e, field, objId) {
    if (field === "dimension_text") {
      tempTextObj.current = e.target.value;
    }
    if (field === "narrativeTitle") {
      if (e.key === "Enter") {
        handleNarrativeSave("title", e.target.value)
      } else {
        // let nCopy = [...narrativeChanges];
        let nCopy = []
        let nCopyObj = {}
        nCopyObj['title'] = e.target.value
        nCopy.push(nCopyObj)
        setNarrativeChanges(nCopy);
        // console.log('nCopy', nCopy, narrativeChanges.length)
      }
    }
    if (field === "narrativeHeading") {
      if (e.key === "Enter") {
        handleNarrativeHeadingEdit('heading', e.target.value, objId);
      }

    }
}

  function handleNarrativeAdd(value, value2) {
    // console.log('handleNarrativeAdd', value, value2)
    setShowLoader(true)
    const api_url = Config.baseURLcms + 'narrativeheading-add/';
    var dataJSON = {};
    // if (field !== 'heading') {
    //   dataJSON['heading'] = narrative.title
    // }
    dataJSON['narrative_id'] = id;
    dataJSON['heading'] = value;
    dataJSON['page_id'] = value2;
    

    // console.log('dataJSON', dataJSON)
    function responseTask() {
    
      fetchJSON(false);
      setShowNarrativeHeadingForm(false);
      tempNarrativeHeading.current = null;
    }
    apiCall("POST", api_url, dataJSON, responseTask, null, setFormError)

  }

  function handleCreateNarrativeHeading(inputId, inputId2) {
    let value = document.getElementById(inputId).value;
    let value2 = document.getElementById(inputId2).value;
    
    if (value && value2) {
      handleNarrativeAdd(value, value2)
    } else {
      if (value && !value2) {
        gsap.fromTo('#narrativeHeadingNew_page .anim', {color: "red", duration: 1}, {color: "white", duration: 1})
      }
      if (!value && value2) {
        gsap.fromTo('#narrativeHeadingNew', {background: "red"}, {background: "#555555"})
      }
      if (!value && !value2) {
        gsap.fromTo('#narrativeHeadingNew_page .anim', {color: "rgba(255,0,0,0.2)", duration: 1}, {color: "white", duration: 1})
        gsap.fromTo('#narrativeHeadingNew', {background: "rgba(255,0,0,0.2)"}, {background: "#555555"})
      }
    }
  }

  function updateNarrativeHeadingForm(fieldId, value, label) {
    // console.log(fieldId, value, label)
    if (fieldId === 'narrativeHeadingPage' && value) {
      tempNarrativeHeading.current = label
      document.getElementById('narrativeHeadingPage').value = value
      setDropDown(null);
      handleNarrativeHeadingEdit('page_id', value)
    }
  }

  function handleDropDown(e, field) {
    if (field === dropDown) {
      setDropDown(null); 
    } else {
      setDropDown(field)
    }
  }

  function reNameHeading() {
    handleNarrativeHeadingEdit('heading', formInput.current, editPageForm);
  }

  function handleAddNewTab() {
    
  }

    return (
        <section className="component on" id="narrativeDetail">



        {(showLoader)?(<div id="loading"><img src={Loader} alt="" /></div>):null}

        {(formError)&&<Modal heading={formError.heading} text={formError.text} onBtn1Click={setFormError} onBtn1={null} btn1Text={"Cancel"} onBtn2Click={formError.onBtn2Click} onBtn2={formError.onBtn2} btn2Text={formError.btn2Text} onBtn3Click={formError.onBtn3Click} onBtn3={formError.onBtn3} btn3Text={formError.btn3Text} />}

        {(newTab)&&<Modal heading={"New Tab"} formInput={<div className="settingsRow"><input type="text" autoComplete="off" id="newTabName" required onChange={(e) => {formInput.current = e.target.value}} placeholder="Add a Name" /></div>} onBtn1Click={setNewTab} onBtn1={false} btn1Text={"Cancel"} onBtn2Click={handleAddNewTab} onBtn2={formInput.current} btn2Text={"Create"} />}

        {(showError)&&(<div className="modalContainer"><div className="modal"><span className="textContainer"><h3>Unsaved Changes</h3><p>Are you sure you want to go back?</p></span> <div className="fullRow errorRow"><span onClick={() => handleBackBtn('cancel')}>Cancel</span> <span onClick={() => handleBackBtn('force')}>Continue</span></div></div></div>)}

        {(showDeleteSection)&&(<div className="modalContainer"><div className="modal"><span className="textContainer"><h3>Caution</h3><p>Are you sure you want to delete this page?<br />This action cannot be undone.</p></span> <div className="fullRow errorRow"><span onClick={() => handleBackBtn('cancel')}>Cancel</span> <span onClick={() => handleDeleteSection('continue')}>Delete</span></div></div></div>)}

        {(showFormatLockWarning !== false)&&(
          <Modal heading={"Caution"} text={"<p>This layer's settings are locked to all devices.<br/>Click continue to detach formats and update the layers on each device separately</p>"} onBtn1Click={setShowFormatLockWarning} onBtn1={false} btn1Text={"Cancel"} onBtn2Click={handleAspectLock} onBtn2={showFormatLockWarning} btn2Text={"Continue"} />
        )}

        {showPageForm && <Modal heading={"New Page"} formInput={<div className="settingsRow"><input type="text" autoComplete="off" id="narrativeTitle" required onChange={(e) => {formInput.current = e.target.value}} placeholder="Add a Title" /></div>} onBtn1Click={setShowPageForm} onBtn1={false} btn1Text={"Cancel"} onBtn2Click={handleAddSection} onBtn2={formInput.current} btn2Text={"Create"} />}

        {editPageForm && <Modal heading={"Page Title"} formInput={<div className="settingsRow"><input type="text" autoComplete="off" id="pageTitle" required onChange={(e) => {formInput.current = e.target.value}} placeholder="Add a Title" /></div>} onBtn1Click={setEditPageForm} onBtn1={false} btn1Text={"Cancel"} onBtn2Click={reNameHeading} onBtn2={formInput.current} btn2Text={"Save"} />}

        {(showNarrativeHeadingForm)&&(<div className="modalContainer"><div className="modal"><span><h3>New Navigation Item</h3>
        <div className="settingsRow">
         <input type="text" autoComplete="off" id="narrativeHeadingNew" placeholder="Add a Heading" />
        </div>
        <div className="settingsRow" id="settingsRow_navSection">
          <input type="hidden" autoComplete="off" id="narrativeHeadingPage" />
          <span id={"settingsRow_navSection"}></span>
          <div  className="ui pageDropDown modalBtn" onClick={(e) => handleDropDown(e, 'navSection')}>{tempNarrativeHeading.current ?<span className="dropDownSelection ui">{tempNarrativeHeading.current}</span>:<span className="btn1"><span className="anim">Add Page</span></span>}
          
          </div>
            
            <span className="inputField dropDownField" id={"inputField_navSection_new"}>
              <span className="dropDown">
                <span className="dropDownChoices">
                  {sections
                    .filter(a => a.title !== null)
                    .map((obj, i) => (
                    <span key={'sectionHeading_'+i} className={"dropDownChoice ui"} onClick={() => updateNarrativeHeadingForm('narrativeHeadingPage', obj.id, obj.title)}>{obj.title}</span>
                  ))}
                </span>
              </span>
            </span>
        
        </div>
        </span> <div className="fullRow errorRow"><span onClick={() => setShowNarrativeHeadingForm(false)}>Cancel</span> <span onClick={() => handleCreateNarrativeHeading("narrativeHeadingNew", "narrativeHeadingPage")}>Create</span></div></div></div>)}

        <NarrativeTools 
          activeSectionObj={activeSectionObj} 
          section={section}
          aspectRatio={aspectRatio}
          setAspectRatios={setAspectRatios}
          sectionId={sectionId}
          newMedia={newMedia}
          showFullCanvas={showFullCanvas}
          user={props.user}
          narrative={narrative}
          zoomNumber={zoomNumber}
          zoomRatio={zoomRatio}
          unSavedChanges={unSavedChanges}
          setShowFullCanvas={setShowFullCanvas}
          setNewMedia={setNewMedia}
          setShowLoader={setShowLoader}
          setNewComponentID={setNewComponentID}
          setNewComponentDimension={setNewComponentDimension}
          setFormError={setFormError}
          setZoomNumber={setZoomNumber}
          setZoomRatio={setZoomRatio}
          onMouseEnter={props.onMouseEnter}
          onMouseLeave={props.onMouseLeave}
          setNewTab={setNewTab}
          fetchJSON={fetchJSON}
          publishOn={publishOn}
          setPublishOn={setPublishOn}
      
        />

        <div className={"formPage " + (showLoader)?(null):(' ready')}>

          {(narrative) &&
          <LeftNav 
            showPages={showPages}
            componentDimensions={componentDimensions}
            narrative={narrative}
            multiSelectPageIds={multiSelectPageIds}
            unSavedChanges={unSavedChanges}
            activeSettingId={activeSettingId}
            activeSectionObj={activeSectionObj}
            sectionId={sectionId}
            narrativeHeadings={narrativeHeadings}
            activeNarrativeHeading={activeNarrativeHeading}
            fetchJSON={fetchJSON}
            user={props.user}
            handlePageClick={handlePageClick}
            showSettings={showSettings}
            reload={reload}
            activeStateId={activeStateId}
            setComponentDimensions={setComponentDimensions}
            setUnSavedChanges={setUnSavedChanges}
            setShowError={setShowError}
            setShowDeleteSection={setShowDeleteSection}
            setShowPages={setShowPages}
            setActiveSettingId={setActiveSettingId}
            setShowPageForm={setShowPageForm}
            onNewPage={onNewPage}
            setNarrativeHeadings={setNarrativeHeadings}
            setSectionId={setSectionId}
            setShowLoader={setShowLoader}
            setActiveSectionObj={setActiveSectionObj}
            setMultiSelectPageIds={setMultiSelectPageIds}
            setEditPageForm={setEditPageForm}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
            
          />
          }
              
          {componentDimensions && componentDimensions.length > 0 && <Frame 
          aspectRatio={aspectRatio}
          showFullCanvas={showFullCanvas}
          componentDimensions={componentDimensions}
          showSettings={showSettings}
          tempTextObj={tempTextObj}
          zoomRatio={zoomRatio}
          multiSelectIds={multiSelectIds}
          lockAr={lockAr}
          narrative={narrative}
          section={section}
          selection={selection}
          activeSectionObj={activeSectionObj}
          activeSettingId={activeSettingId}
          setMultiSelectIds={setMultiSelectIds}
          activeStateId={activeStateId}
          setComponentDimensions={setComponentDimensions}
          setUnSavedChanges={setUnSavedChanges}
          setTriggerSaveForm={setTriggerSaveForm}
          setActiveSettingId={setActiveSettingId}
          setDropDown={setDropDown}
          setSelection={setSelection}
          updateFetch={updateFetch}
        />}

          {activeSectionObj && activeSectionObj.states.length > 0 && <TabButtons
            aspectRatio={aspectRatio}
            section={section}
            activeSectionObj={activeSectionObj}
            activeStateId={activeStateId}
            setActiveStateId={setActiveStateId}
            
          />}

          {!activeSettingId && activeSectionObj && 
          <PageSettings 
            user={props.user}
            activeSettingId={activeSettingId}
            activeSectionObj={activeSectionObj}
            handleOnKeyUp={handleOnKeyUp}
            showSettings={showSettings}
            unSavedChanges={unSavedChanges}
            setShowLoader={setShowLoader}
            setSectionId={setSectionId}
            componentDimensions={componentDimensions}
            fetchJSON={fetchJSON}
            handleSectionScrollOffset={handleSectionScrollOffset}
            handleSectionOnlySave={handleSectionOnlySave}
            handleSectionActive={handleSectionActive}
            setShowDeleteSection={setShowDeleteSection}
            sections={sections}
            sectionChanges={sectionChanges}
            setSections={setSections}
            setUnSavedChanges={setUnSavedChanges}
            setSectionChanges={setSectionChanges}
            setForceVersion={setForceVersion}
            handleLayerSave={handleLayerSave}
          />
          }

          {activeSettingId && sectionId &&
          <LayerSettings
            user={props.user}
            activeSettingId={activeSettingId}
            componentDimensions={componentDimensions}
            aspectRatio={aspectRatio}
            newMedia={newMedia}
            acceptValues={acceptValues}
            multiSelectIds={multiSelectIds}
            section={activeSectionObj}
            sectionId={sectionId}
            unSavedChanges={unSavedChanges}
            unSavedLayerIds={unSavedLayerIds}
            stageLayerIds={stageLayerIds}
            newAnimObj={newAnimObj}
            lockAr={lockAr}
            animationPresets={animationPresets}
            showSettings={showSettings}
            fetchJSON={fetchJSON}
            activeStateId={activeStateId}
            versionNumber={versionNumber.current}
            setShowLoader={setShowLoader}
            setNewMedia={setNewMedia}
            setUnSavedChanges={setUnSavedChanges}
            setComponentDimensions={setComponentDimensions}
            setActiveSettingId={setActiveSettingId}
            setAcceptValues={setAcceptValues}
            setNewComponentPopUpID={setNewComponentPopUpID}
            setNewAnimObj={setNewAnimObj}
            setFormError={setFormError}
            showFormatLockWarning={showFormatLockWarning}
            setShowFormatLockWarning={setShowFormatLockWarning}
            setLockAr={setLockAr}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
            selection={selection}
            setSelection={setSelection}
            updateVersionHistorySub={updateVersionHistorySub}
          />}
          
            <Timeline 
              sections={sections}
              sectionId={sectionId}
              setSwiperInst={setSwiperInst} 
              aspectRatio={aspectRatio} 
              handlePageClick={handlePageClick} 
            />

        </div>
        
      </section>
    );
}
export default Canvas;